<div class="min-h-100">
  <div
    id="account-detail"
    class="c-pointer bg-ice-blue-2"
    data-toggle="dropdown"
  >
    <section class="d-flex flex-column">
      <span class="primary-bold f16 purple-brown text-capitalize">{{
        userName
      }}</span>
      <span class="primary-regular f12 waav-gray-dark" *ngIf="selAccount">
        <!-- selAccount.entityType=="INDIVIDUAL"?selAccount.entityType:'ORG - '+selAccount.entityType }} -->
        <!-- {{ selAccount.entityType }} -->
      </span>
    </section>
    <img class="dropdown-toggle" src="./assets/images/down.svg" alt="" />
    <div class="dropdown">
      <div class="dropdown-menu user-dropdown">
        <a
          [routerLink]="['/change-password']"
          class="c-pointer dropdown-item primary-regular f16 waav-dark-main pb10 pt10"
        >
          {{ "MENU.changepassword" | translate }}</a
        >
        <a
          [routerLink]="['/update-email']"
          class="c-pointer dropdown-item primary-regular f16 waav-dark-main pb10 pt10"
          >{{ "MENU.changeemail" | translate }}</a
        >
        <!-- <a href="https://bricklet.com.au/faq" target="_blank"
          class="c-pointer dropdown-item primary-regular f16 waav-dark-main pb10 pt10"> {{ 'MENU.faq'| translate }}</a> -->
        <a
          class="c-pointer dropdown-item primary-regular f16 waav-dark-main pb10 pt10"
          (click)="openSupportPopup()"
        >
          {{ "MENU.support" | translate }}</a
        >
        <a
          class="c-pointer dropdown-item primary-bold f16 palette-generic-red pb10 pt10 logout"
          (click)="logout()"
        >
          {{ "MENU.logout" | translate }}</a
        >
      </div>
    </div>
  </div>
</div>

<div class="modal fade username-account-modal" id="switchAccModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title f18 primary-semi-bold">Confirmation</h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p class="primary-regular">Do you want to switch the Account?</p>

        <div class="row d-flex justify-content-end">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <button
              type="button"
              (click)="switchAccount(switchAccId)"
              class="btn btn-primary outlined w-100 pt-2 pb-2 mt-2"
              data-bs-dismiss="modal"
            >
              Yes
            </button>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <button
              type="button"
              class="btn btn-primary w-100 pt-2 pb-2 mt-2"
              data-bs-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade username-account-modal" id="supportPopup">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title f18 primary-semi-bold">Support</h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p class="primary-regular">
          Please email your queries to
          <bdi class="wp-charcoal">help@invcar.com</bdi>
        </p>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

 
  constructor(private toastr:ToastrService) { }

  ngOnInit(): void {
    // $(document).ready(function() {

    //   var curPage = 1;
    //   var numOfPages = $(".skw-page").length;
    //   var animTime = 1000;
    //   var scrolling = false;
    //   var pgPrefix = ".skw-page-";
    
    //   function pagination() {
    //     scrolling = true;
    
    //     $(pgPrefix + curPage).removeClass("inactive").addClass("active");
    //     $(pgPrefix + (curPage - 1)).addClass("inactive");
    //     $(pgPrefix + (curPage + 1)).removeClass("active");
    
    //     setTimeout(function() {
    //       scrolling = false;
    //     }, animTime);
    //   };
    
    //   function navigateUp() {
    //     if (curPage === 1) return;
    //     curPage--;
    //     pagination();
    //   };
    
    //   function navigateDown() {
    //     if (curPage === numOfPages) return;
    //     curPage++;
    //     pagination();
    //   };
    
    //   $(document).on("mousewheel DOMMouseScroll", function(e) {
    //     if (scrolling) return;
    //     if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
    //       navigateUp();
    //     } else { 
    //       navigateDown();
    //     }
    //   });
    
    //   $(document).on("keydown", function(e) {
    //     if (scrolling) return;
    //     if (e.which === 38) {
    //       navigateUp();
    //     } else if (e.which === 40) {
    //       navigateDown();
    //     }
    //   });
    
    // });

  //   $('.slider').slick({
  //     loop:true,
  //     margin:10,
  //     dots:true,
  //     nav:false,
  //     mouseDrag:true,
  //     autoplay:true,
  //     animateOut: 'slideOutUp',
  //     responsive:{
  //         0:{
  //             items:1
  //         },
  //         600:{
  //             items:1
  //         },
  //         1000:{
  //             items:1
  //         }
  //     }
  // });

  
$('.slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: true,
  autoplay:true,
  loop:true,
  mouseDrag:true
});

  }

  public  subscribeForm = new FormGroup({
    email :  new FormControl('')
  });
  subscribeUser(){
    // if(this.subscribeForm.value){
      this.toastr.success("Thanks for subscription..!!!","Subscribe User")
    // }
  }


  

}

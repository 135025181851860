<div class="row w-100 m-0" id="bank-tiles-container">
  <div *ngIf="bankAccounts" class="child row">
    <div class="tile col-12">
      <div class="top">
        <div class="left">
          <img  src="/assets/images/withdraw-icons/bank-icon.svg" alt="">
        </div>
        <div class="right">
          <span>{{bankAccounts.accountName}}</span>
          <span>{{bankAccounts.accountNumber}}</span>
          <span>{{bankAccounts.status}}</span>
        </div>
      </div>
      <!-- <div class="bottom">
        <button class="remove btn" (click)="deleteAccountPopup(bankAccounts.bankGUID)">
        <img src="/assets/images/action-icons/delete.svg" alt="">   {{ 'DASHBOARD.remove' | translate }}
        </button>
      </div> -->
    </div>
  </div>
</div>

<div class="modal fade" id="deleteModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title f18 primary-semi-bold">{{ 'DASHBOARD.confirmation' | translate }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="primary-regular">{{ 'DASHBOARD.doyouwanttodeletebankaccount' | translate }}</p>

        <div class="row d-flex justify-content-end">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <button type="button" (click)="deleteAccount()" class="btn btn-primary outlined w-100"
              data-bs-dismiss="modal">{{ 'DASHBOARD.yes' | translate }}</button>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <button type="button" class="btn btn-primary w-100" data-bs-dismiss="modal">{{ 'DASHBOARD.no' | translate }}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
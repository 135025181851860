<div class="container property-section">
    <div class="content-section">

    <h2>BENEFITS</h2>
    <p>InvCar offers a unique opportunity to invest in rental vehicles through fractional ownership, making it more
        accessible and affordable than traditional investment methods. By eliminating the need for high upfront costs
        and time-consuming vehicle management, InvCar simplifies the investment process. Our platform provides a diverse
        selection of vehicles to invest in, allowing you to diversify your portfolio while receiving hassle-free,
        monthly returns from rental income. Additionally, the InvCar marketplace enables you to buy or sell shares with
        ease, giving you greater control over your investments. Choose InvCar for a seamless and innovative approach to
        rental vehicle investments that prioritizes flexibility, accessibility, and customer satisfaction.</p>
</div>
</div>
<div class="container-fluid" id="detail-container">
  <div class="container" *ngIf="propertyAllDetails">
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent detail-nav">
      <a class="navbar-brand" [routerLink]="['/marketplace']"><i class="fa fa-arrow-left" aria-hidden="true"></i>
        Go Back</a>
    </nav>
    <div class="col-lg-12 col-md-12 col-sm-12" id="gallery-offer-container">
      <div class="row d-flex flex-row justify-content-between">
          <div class="col-lg-6 col-md-6 col-sm-6 ">
            <img [src]="propertyDetails.bannerImage" style="width:100%">
            <div class="col-12 subCarsImages photo-galery-with-zoom">
            <div class="row">
            <div *ngFor="let cars of propertyGallery;let idx=index;" class="col-md-4 img-container">
                <img [src]="cars.image" alt="Image not found" style="width:100%;" (click)="onImgView()">
            </div>
            </div>
          </div>

          <div class="modal fade mx-auto" id="view-Zoom-img">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg modal-xl">
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: 0;">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> 
                <div class="modal-body">
                    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                      <div class="carousel-inner">
                        <div *ngFor="let pic of propertyGallery; let i = index" class="carousel-item {{ (i == 0) ? 'active' : ''}}">
                          <img class="d-block img-fluid w-100" [src]="pic.image" >
                      </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>     
              </div>
            </div>
          </div>
        
            <div id="about-property">
              <h3 class="primary-bold"> {{ 'PRD.aboutthisproperty' | translate }}</h3>
              <p class="primary-regular">
                {{propertyDetails?.aboutThisCar}}
              </p>
            </div>
            <div id="design-property">
              <h3 class="primary-bold"> DESIGN AND SPECIFICATIONS</h3>
              <p class="primary-regular">                
                {{propertyDetails?.designsAndSpecifications}}
              </p>

          
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="row">
              <div class="table-detail-container">
              <div class="col-12 d-flex flex-column">
                <div>
                  <h4 class="f32 clr-1">{{propertyDetails?.carMake | uppercase}} {{propertyDetails?.carModel | uppercase}}</h4>
                  <p class="f12 clr-2">{{propertyDetails?.address}}</p>
                </div>
             
              </div>
              <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <table class="table detail-table">
                  <tbody>
                    <tr>
                      <td>{{ 'PRD.propertytype' | translate }}</td>
                      <td>{{propertyDetails?.carType}}</td>
                    </tr>
                    <tr>
                      <td>{{ 'PRD.carowner' | translate }}</td>
                      <td>Test Owner</td>
                    </tr>
                    <tr>
                      <td>{{ 'PRD.propertyvalue' | translate }}</td>
                      <td>${{propertyDetails?.carValue | number : '0.2-2'}}</td>
                    </tr>
              
                    <tr>
                      <td> {{ 'PRD.estimatedYieldPerYear' | translate }}</td>
                      <td>${{propertyDetails?.estimatedYieldPerYear| number : '0.2-2'}}</td>
                    </tr>
              
                  </tbody>
          
                </table>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <table class="table detail-table ">
                  <tbody>
                    <tr>
                      <td>{{ 'PRD.totalshares' | translate }}</td>
                      <td>{{propertyDetails?.totalShares}}</td>
                    </tr>
                    <tr>
                      <td> {{ 'PRD.sharesavailable' | translate }}</td>
                      <td>{{propertyDetails?.sharesAvailable}}</td>
                    </tr>
                  
                    <tr>
                      <td> {{ 'PRD.estincome' | translate }} &nbsp;
                        <span class="d-inline-block"  data-toggle="tooltip" title="Income will vary based on the current conditions">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                      </td>
                      <td>${{propertyDetails?.estimatedRentPerMonth | number : '0.2-2'}}</td>
                    </tr>
                    <tr>
                    <td>{{ 'PRD.odometer' | translate }}</td>
                    <td>{{propertyDetails?.odometer | number}}</td>
                  </tr>
                  </tbody>
           
                </table>
              </div>
            </div>
            </div>
              <div class="col-12">
                <table class="table " style="margin-bottom:1px;">
                  <tbody>
                    <tr>
                      <td class="keyLeft" colspan="2">{{ 'PRD.sharesprice' | translate }} </td>
                      <td class="keyRight" colspan="2">${{propertyDetails?.sharePrice}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <!-- <div class="col-6">
                    <button class="btn btn-secondary fullBtn"> {{ 'PRD.addtowishlist' | translate }}</button>
                  </div> -->
                  <div class="col-12">
                    <button class="btn btn-primary fullBtn" (click)="showCart(propertyDetails.carGUID,propertyDetails.bannerImage)" [disabled]="(propertyDetails.sharesAvailable === 0)"> {{ 'PRD.addtocart' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="confirmModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
            <div class="modal-body">
                <div id="sign-in-box" class="align-self-center" >
                    <form  class="row col-12 mt-4 mx-0" autocomplete="off">
                        <div class="form-group col-12">
                            <label for="email" class="form-label"> This purchase will replace the current items in your shopping cart. Would you like to continue..?</label>
                        </div>
                        <div class="form-group col-12">
                            <div class="row">
                                <div class="col-6">
                                    <button class="btn btn-primary w-100 mt-2" (click)="onBuyNow(selectOrderGUID)"> {{'MKP.yes' | translate }}</button>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-primary outlined w-100 mt-2" data-bs-dismiss="modal" aria-label="Close">
                                        {{'MKP.no' | translate }}</button>
                                </div>
                            </div>
                        </div>
                
                    </form>
                </div>
                
            


            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="sameDataModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div id="sign-in-box" class="align-self-center" >
                    <form  class="row col-12 mt-4 mx-0" autocomplete="off">
                        <div class="form-group col-12">
                            <label for="email" class="form-label"> This car is present in cart.!!!</label>
                        </div>
                        <div class="form-group col-12">
                            <div class="row">
                                <div class="col-6 text-center">
                                    <button class="btn btn-primary w-100 mt-2" data-bs-dismiss="modal" aria-label="Close">
                                        {{'MKP.ok' | translate }}</button>
                                </div>
                            </div>
                        </div>
                
                    </form>
                </div>
                
            


            </div>
        </div>
    </div>
</div>
</div>





import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { HttpHeaders } from '@angular/common/http';
import { StorageService } from '../utils/storage.service';
import { Storage } from '../enum/storage';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  token: string;

  constructor(
    private apiManagerService: ApiManagerService,
    public storageService: StorageService

  ) {

  }
  getTransactions(data) {
    return this.apiManagerService.postRequest(RestEnds.transactionList, data);
  }

  getDepositTransactions(data) {
    return this.apiManagerService.postRequest(RestEnds.depositTransaction, data);
  }

  putDepositCancellation(guid) {
    return this.apiManagerService.putRequestQueryParam(RestEnds.depositCancellation, `depositGUID=` + guid);
  }

  getWithdrawTransactions(data) {
    return this.apiManagerService.postRequest(RestEnds.withdrawTransaction, data);
  }

  putWithdrawCancellation(guid) {
    return this.apiManagerService.putRequestQueryParam(RestEnds.withdrawCancellation, `withdrawalGUID=` + guid);
  }

  getDownloadDeposit(data) {
    this.token = this.storageService.getItem(Storage.AUTH_TOKEN);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`,
    });
    return this.apiManagerService.getRequest(RestEnds.downloadDepositReceipt + "?ledgerGUID=" +  data.ledgerGUID as string + "&timeZoneName=" + "+%2B08%3A00" as any, { headers, responseType: 'blob', observe: 'response' });
  }


  getDownloadWithdraw(data) {
    this.token = this.storageService.getItem(Storage.AUTH_TOKEN);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`,
    });
    return this.apiManagerService.getRequest(RestEnds.downloadWithdrawReceipt  + "?ledgerGUID=" +  data.ledgerGUID as string + "&timeZoneName=" + "+%2B08%3A00" as any, { headers, responseType: 'blob', observe: 'response' });
    
  }

  getDividendTransactions(data) {
    return this.apiManagerService.postRequest(RestEnds.devidendTransaction, data);
  }

  getTitlePendingTransactions(data) {
    return this.apiManagerService.postRequest(RestEnds.pendingTitleList, data);
  }

  getRentalHistory(data) {
    return this.apiManagerService.postRequest(RestEnds.rentalTransaction, data);
  }

  getRentalReceipt(guid: string) {
    return this.apiManagerService.getRequestQueryParam(RestEnds.rentalReceipt, 'rentReceiptReferenceNo=' + guid);
  }
}


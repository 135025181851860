<div class="container property-section">
    <div class="content-section">
    <h2>INVCAR FOR <br> INVESTORS</h2>
    <p>Welcome to InvCar for Investors, your one-stop destination for harnessing the power of fractional rental vehicle
        investments. Our platform is designed with you in mind, offering an accessible and intuitive way to invest in
        rental vehicles without the traditional barriers and hassles.</p>

    <p> With InvCar, you'll enjoy the benefits of fractional ownership, a diverse range of investment options, monthly
        returns, and the flexibility to buy and sell shares in our marketplace. Join the growing community of investors
        who have discovered the advantages of partnering with InvCar, and let us guide you on your journey towards
        building a rewarding and diversified investment portfolio. Embrace the future of rental vehicle investments with
        InvCar today.</p>
</div>
</div>
import { Component, OnInit } from '@angular/core';
export interface Tabs {
  title: string;
  link: string;
  img: string;
  logged: boolean | null;
}

export interface Collection {
  title: string;
  link: string;
  img: string;
  logged: boolean | null;
}

declare var $: any;

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {
  isShown: boolean = false;
  path: string = 'assets/images/leftsidebar-icons/';
  isLogged = false;
  tabs: Tabs[] = [
    {
      title: 'HOME',
      link: '/home',
      img: 'home',
      logged: false,
    },
    {
      title: 'MARKETPLACE',
      link: '/marketplace',
      img: 'car-marketplace',
      logged: false,
    },
    // {
    //   title: 'WHAT_INVCAR',
    //   link: '/faq',
    //   img: 'whatinvcar',
    //   logged: false,
    // },
    // {
    //   title: 'HOW_IT_WORKS',
    //   link: '/how_it_works',
    //   img: 'works',
    //   logged: false,
    // },
    // {
    //   title: 'INVESTORS',
    //   link: '/investors',
    //   img: 'investors',
    //   logged: false,
    // },
    // {
    //   title: 'DISTRIBUTORS',
    //   link: '/distributors',
    //   img: 'distributors',
    //   logged: false,
    // },
    // {
    //   title: 'WHY_INVCAR',
    //   link: '/about_us',
    //   img: 'faq',
    //   logged: false,
    // },
    // {
    //   title: 'PARTNER',
    //   link: '/partner',
    //   img: 'partners',
    //   logged: false,
    // },
    {
      title: 'CONTACT',
      link: '/contact',
      img: 'contact',
      logged: false,
    }
  ];

  collections:Collection[]=[
    {
      title: 'About',
      link: '/about_us',
      img: 'about',
      logged: false,
    },
    {
      title: 'Benefits',
      link: '/benefits',
      img: 'benefits',
      logged: false,
    },
    {
      title: 'Our Partners',
      link: '/our_partners',
      img: 'partners',
      logged: false,
    },
    {
      title: 'Our Team',
      link: '/team',
      img: 'team',
      logged: false,
    },
    {
      title: 'Media/Press',
      link: '/media',
      img: 'media',
      logged: false,
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

  submenuShow() {
    this.isShown = true;
  }

  submenuHide() {
    this.isShown = false;
  }

}

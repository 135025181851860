<div class="col-lg-12 col-md-12 col-sm-12 h-50" style="overflow-x: hidden;">

    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 verify_email__common-panel">
        <div class="common-body">
          <a href="">
            <img src="../../../../assets/InvCar_Logo.svg">
          </a>
  
          <span class="contents">INVEST IN CARS <br> A BETTER WAY</span>
          <p class="invDetails">InvCar helps you grow your income by
            allowing you to invest in rental vehicles</p>
        </div>
    
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-4 login__card">
 
    <div class="row m-0 p-0 text-center">
        <div class="col-12 mt-5 p-0">
            <p class="verify-title wp-charcoal">
                 <!-- {{ 'SIGNUP.verifyyouremail' | translate }} -->
                 PLEASE,CONFIRM <br>
                 YOUR EMAIL..
            </p>
        </div>
    </div>
    <div class="row m-3 p-0 text-center">
        <div class="col-12 m-0 p-0">
            <p class="verify-content generic-palette-b-w-gray">
                Hi <bdi class="primary-bold name-blue">{{emailID}}!</bdi> To cofirm you email, you just have to click on
                the link/url we have sent to your email.
            </p>
        </div>
    </div>

    <div class="row m-0 p-0 justify-content-center mb-4">
        <div class="col-12 m-0  p-0 text-center align-middle">
            <button class="btn btn-primary btn-lg form-control resend-button" (click)="onResendMail()">
                 {{ 'SIGNUP.resendemail' | translate }}
            </button>
            <p class="f16 generic-palette-b-w-gray secondary-semi-bold mt-3"> {{ 'SIGNUP.alreadyverified' | translate }}
                <a
                class="water-blue" href="marketplace/list?login=true"> {{ 'SIGNUP.clickhere' | translate }}</a></p>
        </div>
    </div>
  </div>
  </div>
  </div>
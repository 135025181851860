<div class="property-list">
    <div class="container">
        <div class="content-section">
        <div class="row">
            <div class="col-md-12">
                <div class="property-wrap">

                    <div class="property-single row" *ngFor="let r of properties; let i = index">
                        <div class="col-lg-6 col-md-12 col-12" style="padding:0">
                            <!-- <div style="border:1px solid #192149;"> -->
                            <a class="c-pointer" (click)="onDetail(r,'1')">
                                <img [src]="r.bannerImage" alt="">
                            </a>
                            <!-- <div class="row">
                                <div class="col-4">
                                    <img [src]="r.bannerImage" alt="">
                                </div>
                                <div class="col-4">
                                    <img [src]="r.bannerImage" alt="">
                                </div>
                                <div class="col-4">
                                    <img [src]="r.bannerImage" alt="">
                                </div>
                            </div> -->
                            <div class="col-lg-4 col-md-12 col-sm-12 col-12 sub-images">
                                <img [src]="r.bannerImage1" alt="">
                                <img [src]="r.bannerImage2" alt="">
                                <img [src]="r.bannerImage3" alt="">
                               </div>
                        <!-- </div> -->
                        </div>

                        <div class="col-lg-6 col-md-12 col-12 card-bg">
                            <div class="row">
                                <div class="col-md-12 mb-1">
                                    <h2 class="mb-0 secondary-bold">
                                        {{r.carMake}} {{r.carModel}}
                                    </h2>
                                    <!-- <a class="secondary-regular f14 tertiary-light">
                                        {{r.address}}, {{r.suburb}}, {{r.state}} - {{r.zipCode}}
                                    </a> -->
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col-md-12 mt-1 d-flex flex-wrap justify-content-between primary-regular">
                                    <p class="f16">
                                        <img class="icon" src="/assets/images/floor-icons/floor.svg" alt="">
                                        {{r.sqm}} Sqm
                                    </p>
                                    <ng-container *ngFor="let a of r.propertyAmenities">
                                        <p class="f16">
                                            <img class="icon" [src]="a.iconURL" alt="">
                                            {{a.count}}
                                        </p>
                                    </ng-container>
                                </div>
                            </div> -->

                            <div class="row box-bottom-container">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 box-bottom">
                                    <!-- <label for="" class="key" >{{ 'MKP.propertyvalue' | translate }}</label>
                                    <label for="" class="val">${{r.totalBrickletValue| number: "1.0-2"}}</label> -->
                                    <label for="" class="key">Located</label>
                                    <label for="" class="val">{{(r.city.length>10)? (r.city | slice:0:10)+'...':(r.city) }} </label>

                                    <!-- <div class="dropdown">
                                        <button class="btn btn-primary dropdown-toggle share_link" type="button"
                                        i18n="@@PLB-Sharewithothers" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                             {{ 'MKP.sharewithothers' | translate }}
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="dropdown-item" [href]="emailContent(r)"
                                                i18n="@@PLB-Email" title="Share by Email"><i class="fa fa-envelope"></i>&nbsp;{{ 'MKP.email' | translate }}</a>
                                            </li>
                                            <li><a class="dropdown-item c-pointer" (click)="copyBrowserURL(r)" i18n="@@PLB-CopyLink"><i
                                                 class="fa fa-copy"></i>&nbsp;{{ 'MKP.copylink' | translate }}</a></li>
                                        </ul>
                                    </div> -->
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 box-bottom country">
                                    <!-- <label for="" class="key" i18n="@@PLB-BrickletsAvailable">{{ 'MKP.brickletsavailable' | translate }} </label>
                                    <label for="" class="val">{{r.brickletsForSale}}</label> -->
                                    <label for="" class="key">Country</label>
                                    <!-- <label for="" class="val">{{r.address}}</label> -->
                                    <label for="" class="val">{{(r.address.length>13)? (r.address | slice:0:13)+'...':(r.address) }}</label>
                                    <!-- <button class="btn btn-primary" (click)="onDetail(r,'1')" i18n="@@PLB-Propertydetails">{{ 'MKP.propertydetails' | translate }}</button> -->
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4  box-bottom year">
                                    <!-- <label for="" class="key" i18n="@@PLB-BrickletPrice" >{{ 'MKP.brickletsprice' | translate }} </label>
                                    <label for="" class="val">${{r.askPrice | number: "1.0-2"}}</label> -->
                                    <label for="" class="key">Year</label>
                                    <label for="" class="val">{{r.year}}</label>
                                    <!-- <button i18n="@@PLB-Placeanorder" class="btn btn-primary" (click)="onBuyModal(r.propertyGUID)">
                                        {{ 'MKP.placeonorder' | translate }}
                                    </button> -->
                                </div>
                            </div>
                            <table class="table" style="border-collapse: separate;font-weight:300">
                                <tr>
                                    <td class="tkey">CAR VALUE</td>
                                    <td class="tvalue">${{r.totalInvCarValue}}</td>
                                </tr>
                                <tr>
                                    <td class="tkey">ESTIMATE YIELD PER YEAR</td>
                                    <td class="tvalue">${{r.estimatedYieldPerYear}}</td>
                                </tr>
                                <tr>
                                    <td class="tkey">ESTIMATE RENT PER MONTH</td>
                                    <td class="tvalue">${{r.estimatedRentPerMonth}}</td>
                                </tr>
                                <tr>
                                    <td class="tkey">SHARES AVAILABLE</td>
                                    <td class="tvalue">{{r.sharesAvailable}}</td>
                                </tr>
                                <tr>
                                    <td class="tkey">ODOMETER (Kms)</td>
                                    <td class="tvalue">{{r.odometer | number}}</td>
                                </tr>
                            </table>
                            <!-- <div class="row" style="padding-top:2px;padding-bottom:10px;">
                                <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                                    <label class="shareVal">SHARE PRICE</label>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                                    <label class="shareVal">${{r.sharePrice}}</label>
                                </div>
                            </div> -->
                            <div class="d-flex justify-content-between" style="padding-top:2px;padding-bottom:10px;">
                                <div class="">
                                    <label class="shareVal">SHARE PRICE</label>
                                </div>
                                <div class="">
                                    <label class="shareVal">${{r.sharePrice}}</label>
                                </div>
                            </div>
                            <div >
                                <button class="btn btn-primary cartBtn col-lg-12 col-12" (click)="onBuyModal(r.carGUID)" [disabled]="(r.sharesAvailable === 0)"> Add to cart</button>
                            </div>
                            <!-- <div class="row"> -->
                                <!-- <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                                    <button class="likeBtn"  [ngClass]="{'red': selectedItem == r.carGUID}" (click)="Wishlists(r.carGUID)">
                                        <i class="fa fa-heart" aria-hidden="true"></i>
                                    </button>                      
                                </div> -->
                                <!-- <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                                    <button class="btn btn-primary ms-2 cartBtn" (click)="onBuyModal(r.carGUID)" [disabled]="(r.sharesAvailable === 0)"> Add to cart</button>
                                </div> -->
                           
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
            <div class="modal-body">
                <div id="sign-in-box" class="align-self-center" >
                    <form  class="row col-12 mt-4 mx-0" autocomplete="off">
                        <div class="form-group col-12">
                            <label for="email" class="form-label"> This purchase will replace the current items in your shopping cart. Would you like to continue..?</label>
                        </div>
                        <div class="form-group col-12">
                            <div class="row">
                                <div class="col-6">
                                    <button class="btn btn-primary w-100 mt-2" (click)="onBuyNow(selectOrderGUID)"> {{'MKP.yes' | translate }}</button>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-primary outlined w-100 mt-2" data-bs-dismiss="modal" aria-label="Close">
                                        {{'MKP.no' | translate }}</button>
                                </div>
                            </div>
                        </div>
                
                    </form>
                </div>
                
            


            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="sameDataModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div id="sign-in-box" class="align-self-center" >
                    <form  class="row col-12 mt-4 mx-0" autocomplete="off">
                        <div class="form-group col-12">
                            <label for="email" class="form-label"> This car is present in cart.!!!</label>
                        </div>
                        <div class="form-group col-12">
                            <div class="row">
                                <div class="col-6 text-center">
                                    <button class="btn btn-primary w-100 mt-2" data-bs-dismiss="modal" aria-label="Close">
                                        {{'MKP.ok' | translate }}</button>
                                </div>
                            </div>
                        </div>
                
                    </form>
                </div>
                
            


            </div>
        </div>
    </div>
</div>
</div>
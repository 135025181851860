import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrgPersonalInfo } from 'src/app/core/interfaces/org-individual-kyc.interface';
import { LookupService } from 'src/app/core/services/lookup.service';

@Component({
  selector: 'app-org-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {

  countryList = [];
  personalInfoForm: FormGroup;

  @Output() OrgPersonalInfo = new EventEmitter();
  @Input() storedData: OrgPersonalInfo | null;

  preStatus = false;
  constructor(
    private lookUpService: LookupService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.getCountryList();
    this.personalInfoForm = new FormGroup({
      countryOfResidence: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required, Validators.pattern(/^(([A-Za-z]([ ]{1})[A-Za-z])||([A-Za-z]))+$/)]),
      middleName: new FormControl('', [Validators.pattern(/^[A-Za-z ]+$/)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(/^(([A-Za-z]([ ]{1})[A-Za-z])||([A-Za-z]))+$/)]),
      dob: new FormControl(new Date(), [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required])
    });

    this.OrgPersonalInfo.emit(null);

    this.personalInfoForm.statusChanges.subscribe(s => {
      if (s && s === 'VALID') {

        this.OrgPersonalInfo.emit(this.getFormData());
        this.preStatus = true;
      } else {
        if (this.preStatus) {
          this.OrgPersonalInfo.emit(null);
        }
        this.preStatus = false;
      }
    });
    if (this.storedData) {
      const data = this.storedData;
      this.setStoredValue(
        data.countryOfResidenceGUID,
        data.firstName,
        data.middleName,
        data.lastName,
        data.dateOfBirth,
        data.gender,
        data.email
      );
    }
  }

  getCountryList() {
    this.lookUpService.getCountryList().subscribe(
      (s: any) => {
        this.countryList = s.data.countries;
      },
      (e) => {

      }
    );
  }

  canShowError(control: string) {
    const field = this.personalInfoForm.controls[control];
    return (!field.valid) && (field.touched) && (field.dirty);
  }

  hasError(control: string, error: string) {
    return this.personalInfoForm.controls[control].hasError(error);
  }

  getValue(control: string) {
    return this.personalInfoForm.controls[control].value;
  }

  // tslint:disable:object-literal-shorthand
  setStoredValue(
    country: string,
    firstName: string,
    middleName: string | null,
    lastName: string,
    dob: string,
    gender: string,
    email: string) {

    this.personalInfoForm.setValue({
      countryOfResidence: country,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      dob: new Date(dob),
      gender: gender,
      email: email
    });
  }
  // tslint:enable:object-literal-shorthand

  getFormData() {
    const formData = new FormData();
    formData.append('PI_FirstName', this.getValue('firstName'));
    formData.append('PI_MiddleName', this.getValue('middleName'));
    formData.append('PI_LastName', this.getValue('lastName'));
    formData.append('PI_DateOfBirth', this.datePipe.transform(this.getValue('dob'), 'yyyy-MM-dd'));
    formData.append('PI_Gender', this.getValue('gender'));
    formData.append('PI_Email', this.getValue('email'));
    formData.append('PI_CountryOfResidence', this.getValue('countryOfResidence'));

    return formData;
  }

}

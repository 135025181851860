import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from 'src/app/core/enum/storage';
import { AccountService } from '../../../core/services/account.service';
import { StorageService } from '../../../core/utils/storage.service';
import { HelperService } from '../../../core/utils/helper.service';

export interface Managers{
  name: string;
  email: string;
  mobile: string;
}
@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.scss']
})
export class AccountManagerComponent implements OnInit {

  managers: Managers[] = [];
  
  addManagerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required])
  });
  constructor(
    private accountService: AccountService,
    private storageService: StorageService,
    private helper: HelperService
  ) {
    // this.managers = [
    //   {
    //     name: 'Alfonso Porcelli',
    //     email: 'a.porcelli@lakeba.com',
    //     mobile: '0451 571 873'
    //   }
    // ]
    this.getManagersList();
   }

  ngOnInit(): void {
   // this.getManagersList();
  }

  getManagersList() {
    let guid = this.storageService.getItem(Storage.ACCOUNT_ID);

    this.accountService.getManagerList(guid).subscribe(
      (r: any) => {
        let result = r.data;
        this.managers = result.managers;
        
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  deleteViewerPopup(data: any){
    return data;
  }

  get name() {
    return this.addManagerForm.get('name');
  }

  get email() {
    return this.addManagerForm.get('email');
  }

  get phone() {
    return this.addManagerForm.get('phone');
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Pagination, defaultPagination } from 'src/app/core/interfaces/pagination';
import { TransactionsService } from 'src/app/core/services/transactions.service';
import { HelperService } from 'src/app/core/utils/helper.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
declare var bootstrap: any;

@Component({
  selector: 'app-weekly-income',
  templateUrl: './weekly-income.component.html',
  styleUrls: ['./weekly-income.component.scss']
})
export class WeeklyIncomeComponent implements OnInit {
 
  incomeLists: any[] = [];
  pagination: Pagination = defaultPagination;
  totalRecords = 0;
  isDataloaded = false;
  timeZone: string;
  

  constructor(
    private helper: HelperService,
    private transactionsService: TransactionsService,
    private dialog: MatDialog,
    public translate: TranslateService
  ) {
    // translate.addLangs(['English', 'Korean']);
    // translate.setDefaultLang('English');
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/English|Korean/) ? browserLang : 'Korean');
   }

  ngOnInit(): void {
    this.getRecords();
  }

  getRecords() {
    this.helper.showSpinner();
    this.transactionsService.getDividendTransactions(this.pagination).subscribe(
      (res: any) => {
        this.isDataloaded = true;
        this.incomeLists = res.data.transactions.records;
        this.totalRecords = res.data.transactions.totalRecord;
        this.helper.hideSpinner();
      },
      (error: string) => {
        this.isDataloaded = true;
        this.helper.error(error);
        this.helper.hideSpinner();
      }
    )
  }

  // private getFileNameFromResponse(response: any): string {
  //   const contentDisposition = response.headers.get('content-disposition');
  //   const matches = /filename=([^;]+)/g.exec(contentDisposition);
  //   console.log(matches)
  //   const fileName = matches && matches.length > 1 ? matches[1] : matches[1];
  //   return fileName;
  // }
//   private getFileNameFromResponse(response: any): string {
//     const contentDisposition = response.headers.get('content-disposition');
//     if (!contentDisposition) {
//         console.error('Content-Disposition header is missing.');
//         return ''; // Or handle the error in an appropriate way
//     }

//     const matches = /filename=([^;]+)/g.exec(contentDisposition);
//     if (!matches || matches.length < 2 || !matches[1]) {
//         console.error('Filename not found in Content-Disposition header.');
//         return ''; // Or handle the error in an appropriate way
//     }

//     const fileName = matches[1];
//     return fileName;
// }


  private saveFile(data: Blob, fileName: string): void {
    const blob = new Blob([data], { type: data.type });

    // Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    this.timeZone =  (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  OnDepositDownloadReceipt(guid:string,filename:string) {
    let depositData = {
      ledgerGUID : guid,
      timeZoneName :this.timeZone
    }
    // this.helper.toastInfo('processing...');
    this.helper.showSpinner();
    this.transactionsService.getDownloadDeposit(depositData).subscribe(
      (r: any) => {
        this.helper.hideSpinner();
        // const fileName = this.getFileNameFromResponse(r);
        this.saveFile(r.body, filename);
        this.getRecords();
        // let blob = new Blob([r], { type: 'application/pdf' });

        // var downloadURL = window.URL.createObjectURL(r);
        // var link = document.createElement('a');
        // link.href = downloadURL;
        // link.download = "Receipt.pdf";
        // link.click();
        // this.getRecords();
      },
      (e) => {
        this.helper.hideSpinner();
        this.helper.error(e);
      }
    );
  }

  OnWithdrawDownloadReceipt(guid:string,filename:string) {
  let withdrawData = {
    ledgerGUID : guid,
    timeZoneName :this.timeZone
  }
    // this.helper.toastInfo('processing...');
    this.helper.showSpinner();
    this.transactionsService.getDownloadWithdraw(withdrawData).subscribe(
      (r: any) => {
        this.helper.hideSpinner();
        // const fileName = this.getFileNameFromResponse(r);
        this.saveFile(r.body, filename);
        this.getRecords();
      },
      (e) => {
        this.helper.hideSpinner();
        this.helper.error(e);
      }
    );
  }

  onPageChange(event: null | Pagination) {
    if (event) {
      this.pagination = event;
      this.getRecords();
    }
  }

  onCancelDeposit(guid) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Do you want to cancel?'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      

      if (data) {
        this.helper.showSpinner();
        this.transactionsService.putDepositCancellation(guid).subscribe(
          (r: any) => {
            this.helper.hideSpinner();
            const result = r;
            //this.helper.success(result.msg);
            this.getRecords();
          },
          (e) => {
            this.helper.hideSpinner();
            this.helper.error(e);
          }
        );
      }
    })


  }

  expandBody(id: string, e) {
    e.stopPropagation();
    e.preventDefault();
    
    const tbody = document.querySelectorAll('.mobile-table-container tbody');
    let prevSel = '';
    const btn = e.target;
    [].forEach.call(tbody, function (el) {
      if (el.classList.contains('show')) {
        prevSel = el.id;
      }
      el.classList.remove("show");
    });
    const selBtn = document.querySelectorAll('.expander-btn');
    [].forEach.call(selBtn, function (el) {
      el.classList.remove("active");
    });
    if (prevSel !== id) {
      const sel = document.getElementById(id);
      sel.classList.add('show');
      
      
      btn.classList.add('active');
    }else{
      btn.classList.remove('active');
    }
  }
}

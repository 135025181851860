import { AbstractControl, ValidatorFn } from '@angular/forms';

export function PatternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value === '') {
          let newLocal: any = null;
        return newLocal;
      }
        let newLocal_1: any = !regexp.test(value) ? { patternInvalid: { regexp } } : null;
      return newLocal_1;
    };
  }

<input type="checkbox" id="check"> <label class="chat-btn" for="check" > <i class="fas fa-comment-alt comment"></i> <i
    class="fa fa-times close"></i> </label>
<div class="wrapper">
<div class="header">
    <h6>InvCar - Share you queries</h6>
</div>
<div class="text-center p-2"> <span>Please fill out the form to contact!</span> </div>
<div class="chat-form">
    <form [formGroup]="helpDeskForm">
     <input type="text" class="form-control" formControlName="name" [ngClass]="{'is-invalid':clicked && helpform.name.errors}" placeholder="Name"> 
     <div *ngIf="clicked && helpform.name.errors" class="invalid-feedback">
        <div *ngIf="helpform.name.errors.required">Fullname is required</div>
      </div>
     <input type="email" class="form-control" formControlName="emailAddr" [ngClass]="{'is-invalid':clicked && helpform.emailAddr.errors}" placeholder="Email"> 
     <div *ngIf="clicked && helpform.emailAddr.errors" class="invalid-feedback">
        <div *ngIf="helpform.emailAddr.errors.required">Email is required</div>
        <div *ngIf="helpform.emailAddr.errors.email">Email must be a valid email address</div>
    </div>
     <textarea class="form-control" formControlName="textArr" [ngClass]="{'is-invalid':clicked && helpform.textArr.errors}" placeholder="Your Text Message">
        <div *ngIf="helpform.textArr.errors.required">Textarea is required</div>

     </textarea> 

     <button class="btn btn-success btn-block" (click)="NotifyUser()">Submit</button>
    </form>
    </div>
</div>
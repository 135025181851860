<div class="row mt-4 mb-4">
  <ng-container *ngIf="accounts.length > 0">
    <h2 class="primary-bold waav-dark-main f30">{{ 'DASHBOARD.bankaccounts' | translate }}</h2>
    <div class="col-lg-12" id="setting-container">
      <form [formGroup]="editBankAccForm">
        <table class="table primary desktop-table">
          <thead>
            <tr>
              <th>{{ 'DASHBOARD.accountname' | translate }}</th>
              <th>{{ 'DASHBOARD.accountnumber' | translate }}</th>
              <th>{{ 'DASHBOARD.accountbsb' | translate }}</th>
              <th>{{ 'DASHBOARD.bankname' | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let account of accounts">


              <tr *ngIf="account.bankGUID != editAccountId; else showEdit">
                <td class="bold">{{ account.accountName }}</td>
                <td class="bold">{{ account.accountNumber }}</td>
                <td class="bold">{{ account.bsb }}</td>
                <td class="bold">{{ account.bankName }}</td>
                <td class="bold">
                  <img src="../../../../assets/images/action-icons/edit.svg" class="cursor-pointer"
                    (click)="enableEdit(account)">
                  <img src="../../../../assets/images/action-icons/delete.svg" class="cursor-pointer"
                    (click)="deleteAccountPopup(account.bankGUID)">
                </td>
              </tr>

              <ng-template #showEdit>
                <tr class="edit-row">
                  <td class="bl1">
                    <input formControlName="AccountName" [value]="account.accountName" type="text" class="form-control"
                      placeholder="{{ 'DASHBOARD.egzondo' | translate }}" />
                    <div *ngIf="canShowError('AccountName', true)">
                      <p class="error-txt" *ngIf="hasError('AccountName', 'required')">
                        
                        {{ 'DASHBOARD.accountnameisrequired' | translate }}
                      </p>
                      <p class="error-txt" *ngIf="hasError('AccountName', 'pattern')">
                        
                        {{ 'DASHBOARD.accountnameshouldbecharacters' | translate }}
                      </p>
                    </div>
                  </td>
                  <td class="bl3">
                    <input formControlName="AccountNumber" [value]="account.accountNumber" type="text"
                      class="form-control" placeholder="{{ 'DASHBOARD.egaccnumber' | translate }}" />
                    <div *ngIf="canShowError('AccountNumber', true)">
                      <p class="error-txt" *ngIf="hasError('AccountNumber', 'required', true)">
                        
                        {{ 'DASHBOARD.accountnumberisrequired' | translate }}
                      </p>
                      <p class="error-txt" *ngIf="hasError('AccountNumber', 'pattern', true)">
                        
                        {{ 'DASHBOARD.accountnumbershouldbedigits' | translate }}
                      </p>
                    </div>
                  </td>
                  <td class="bl4">
                    <input formControlName="AccountBSB" [value]="account.bsb" type="text" class="form-control"
                      placeholder="{{ 'DASHBOARD.egaccbsb' | translate }}" />
                    <div *ngIf="canShowError('AccountBSB', true)">
                      <p class="error-txt" *ngIf="hasError('AccountBSB', 'required', true)">
                        
                        {{ 'DASHBOARD.bsbisrequired' | translate }}
                      </p>
                      <p class="error-txt" *ngIf="hasError('AccountBSB', 'pattern', true)">
                        
                        {{ 'DASHBOARD.bsbmustbe6digitnumber' | translate }}
                      </p>
                    </div>
                  </td>

                  <td class="bl5">
                    {{editBankAccForm.get("BankName").value}}
                  </td>
                  <td class="bl5">
                    <div class="row">
                      <div class="col-6">
                        <img src="../../../../assets/images/action-icons/cancel.png" class="cursor-pointer edit-cancel"
                          (click)="disableEdit()">

                      </div>
                      <div class="col-6">
                        <img src="../../../../assets/images/action-icons/check.png" class="cursor-pointer edit-save"
                          (click)="updateBank(account.bankGUID)">
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
          </tbody>
        </table>

        <div class="mobile-table-container">
          <div *ngFor="let r of accounts" class="bg-waav-bright-light mb-4">
            <table class="table mobile">
              <thead>
                <tr>
                  <th>
                    <div class="d-flex flex-column">
                      <span class="primary-bold f18 waav-dark-main">{{r.accountName}}</span>
                      <p class="secondary-regular f14 generic-palette-b-w-gray m-0">{{r.accountNumber}}</p>
                    </div>
                  </th>
                  <th class="text-end vertical-align-middle">
                    <p class="m-0 expander-btn" (click)="expandBody(r.accountNumber,$event)"><img
                        src="assets/images/arrow-right.svg" alt=""></p>

                  </th>
                </tr>
              </thead>
              <tbody *ngIf="r.bankGUID != editAccountId; else showEdit" class="collapse" [id]="r.accountNumber">
                <tr>
                  <td>{{ 'DASHBOARD.accountname' | translate }}</td>
                  <td> {{r.accountName}}</td>
                </tr>
                <tr>
                  <td>{{ 'DASHBOARD.accountnumber' | translate }}</td>
                  <td> {{r.accountNumber}}</td>
                </tr>
                <tr>
                  <td>{{ 'DASHBOARD.bsb' | translate }}</td>
                  <td> {{r.bsb}}</td>
                </tr>
                <tr>
                  <td>{{ 'DASHBOARD.bankname' | translate }}</td>
                  <td>
                    {{r.bankName}}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <label class="waav-gray-dark primary-regular f12">
                      <img src="../../../../assets/images/action-icons/edit.svg" class="cursor-pointer"
                        (click)="enableEdit(r)">{{ 'DASHBOARD.edit' | translate }}
                    </label>
                    <label class="palette-generic-red primary-regular f12 ms-2">
                      <img src="../../../../assets/images/action-icons/delete.svg" class="cursor-pointer"
                        (click)="deleteAccountPopup(r.bankGUID)">{{ 'DASHBOARD.delete' | translate }}
                    </label>
                  </td>
                </tr>
              </tbody>

              
                <ng-template #showEdit>
                  <tbody>
                  <tr class="edit-row">
                    <td>{{ 'DASHBOARD.accountname' | translate }}</td>
                    <td class="bl1">
                      <input formControlName="AccountName" [value]="r.accountName" type="text"
                        class="form-control" placeholder="{{ 'DASHBOARD.egzondo' | translate }}" />
                      <div *ngIf="canShowError('AccountName', true)">
                        <p class="error-txt" *ngIf="hasError('AccountName', 'required')">
                          
                          {{ 'DASHBOARD.accountnameisrequired' | translate }}
                        </p>
                        <p class="error-txt" *ngIf="hasError('AccountName', 'pattern')">
                          
                          {{ 'DASHBOARD.accountnameshouldbecharacters' | translate }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> {{ 'DASHBOARD.accountnumber' | translate }}</td>
                    <td class="bl3">
                      <input formControlName="AccountNumber" [value]="r.accountNumber" type="text"
                        class="form-control" placeholder="{{ 'DASHBOARD.egaccnumber' | translate }}" />
                      <div *ngIf="canShowError('AccountNumber', true)">
                        <p class="error-txt" *ngIf="hasError('AccountNumber', 'required', true)">
                          
                          {{ 'DASHBOARD.accountnumberisrequired' | translate }}
                        </p>
                        <p class="error-txt" *ngIf="hasError('AccountNumber', 'pattern', true)">
                          
                          {{ 'DASHBOARD.accountnumbershouldbedigits' | translate }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> {{ 'DASHBOARD.bsb' | translate }}</td>
                    <td class="bl4">
                      <input formControlName="AccountBSB" [value]="r.bsb" type="text" class="form-control"
                        placeholder="{{ 'DASHBOARD.egaccbsb' | translate }}" />
                      <div *ngIf="canShowError('AccountBSB', true)">
                        <p class="error-txt" *ngIf="hasError('AccountBSB', 'required', true)">
                          
                          {{ 'DASHBOARD.bsbisrequired' | translate }}
                        </p>
                        <p class="error-txt" *ngIf="hasError('AccountBSB', 'pattern', true)">
                          
                          {{ 'DASHBOARD.bsbmustbe6digitnumber' | translate }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td  class="bl5">
                      <div class="row">
                        <div class="col-6">
                          <img src="../../../../assets/images/action-icons/cancel.png"
                            class="cursor-pointer edit-cancel" (click)="disableEdit()">

                        </div>
                        <div class="col-6">
                          <img src="../../../../assets/images/action-icons/check.png" class="cursor-pointer edit-save"
                            (click)="updateBank(r.bankGUID)">
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                </ng-template>
              

            </table>
          </div>
        </div>


      </form>
    </div>
  </ng-container>

  <h2 class="primary-bold waav-dark-main f20"> {{ 'DASHBOARD.addanewbankaccount' | translate }}</h2>
  <form [formGroup]="addAccountForm">
    <div class="row col-lg-12" id="add-container">
      <div class="col-lg-3 form-group">
        <label class="form-label f14">{{ 'DASHBOARD.accountname' | translate }}</label>
        <input type="text" formControlName="AccountName" class="form-control" maxlength="50"
          placeholder="{{ 'DASHBOARD.exaccountname' | translate }}">
        <div *ngIf="canShowError('AccountName')">
          <p class="error-txt" *ngIf="hasError('AccountName', 'required')">
            
            {{ 'DASHBOARD.accountnameisrequired' | translate }}
          </p>
          <p class="error-txt" *ngIf="hasError('AccountName', 'pattern')">
            
            {{ 'DASHBOARD.accountnameshouldbecharacters' | translate }}
          </p>
        </div>
      </div>

      <div class="col-lg-3 form-group">
        <label class="form-label f14">{{ 'DASHBOARD.accountnumber' | translate }}</label>
        <input type="text" formControlName="AccountNumber" class="form-control" maxlength="12"
          placeholder="{{ 'DASHBOARD.exaccountnumber' | translate }}">
        <div *ngIf="canShowError('AccountNumber')">
          <p class="error-txt" *ngIf="hasError('AccountNumber', 'required')">
            
            {{ 'DASHBOARD.accountnumberisrequired' | translate }}
          </p>
          <p class="error-txt" *ngIf="hasError('AccountNumber', 'pattern')">
            
            {{ 'DASHBOARD.accountnumbershouldbedigits' | translate }}
          </p>
        </div>
      </div>

      <div class="col-lg-3 form-group">
        <label class="form-label f14">{{ 'DASHBOARD.accountbsb' | translate }}</label>
        <input type="text" formControlName="AccountBSB" class="form-control" maxlength="6"
          placeholder="{{ 'DASHBOARD.exaccountbsb' | translate }}">
        <div *ngIf="canShowError('AccountBSB')">
          <p class="error-txt" *ngIf="hasError('AccountBSB', 'required')">
            
            {{ 'DASHBOARD.bsbisrequired' | translate }}
          </p>
          <p class="error-txt" *ngIf="hasError('AccountBSB', 'pattern')">
            
            {{ 'DASHBOARD.bsbmustbe6digitnumber' | translate }}
          </p>
        </div>
      </div>
      <div class="form-group col-lg-3">
        <button class="btn btn-primary w-100" [disabled]="!addAccountForm.valid" (click)="addBankAccount()">{{ 'DASHBOARD.addbankaccount' | translate }}</button>
      </div>

    </div>

  </form>
</div>

<div class="modal fade" id="deleteModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title f18 primary-semi-bold">{{ 'DASHBOARD.confirmation' | translate }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="primary-regular">{{ 'DASHBOARD.doyouwanttodeletebankaccount' | translate }}</p>

        <div class="row d-flex justify-content-end">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <button type="button" (click)="deleteAccount()" class="btn btn-primary outlined w-100"
              data-bs-dismiss="modal">{{ 'DASHBOARD.yes' | translate }}</button>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <button type="button" class="btn btn-primary w-100" data-bs-dismiss="modal">{{ 'DASHBOARD.no' | translate }}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SignInRoutingModule } from './sign-in-routing.module';
import { SignInComponent } from './sign-in.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { TranslateModule } from '@ngx-translate/core';
import { SignUpModule } from '../sign-up/sign-up.module';


@NgModule({
  declarations: [
    SignInComponent
  ],
  imports: [
    CommonModule,
    SignInRoutingModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SignUpModule
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LfqYiUkAAAAAJw4DNJAtxOmBpWUJGzPNzZOBXK8',
    } as RecaptchaSettings,
  }],
  exports:[
    SignInComponent
  ]
})
export class SignInModule { }

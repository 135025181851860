<!-- <div id="app-container">
    <div id="header">
        <app-header *ngIf="showHeader"></app-header>
    </div>
    <main [ngClass]="{'half-height':showHeader, 'full-height':!showHeader}">
        <router-outlet></router-outlet>
    </main>
    <div id="footer">
        <app-footer *ngIf="showHeader"></app-footer>
    </div>
</div>
<ngx-spinner></ngx-spinner>
<app-podcast-floating-window></app-podcast-floating-window> -->

<ng-container>
    <app-common-panel></app-common-panel>
</ng-container>
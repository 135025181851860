<div class="container property-section">
    <div class="content-section">
    <div class="col-12">
        <div class="media-card">
            <h6>Making Wealth</h6>
            <h3>BRICKLET PARTNERS WITH TAVUNI CAPITAL <br> PARTNERS TO MAKE HOMES MORE AFFORDABLE <br> WITH $100 MILLION
                FUND</h3>
            <span> November 2021 -Leading Australian Proptech marketplace Bricklet, today announced the appointment of
                property industry titan Ironfish founder & CEO Joseph Chou to its board.</span>
            <div class="row" style="padding-top:10px;padding-bottom:10px;">
                <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                    <img src="../../../assets/images/media-icons/printer.png">
                </div>
                <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                    <img src="../../../assets/images/media-icons/linkedin.png">
                </div>
                <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                    <img src="../../../assets/images/media-icons/twitter.png">
                </div>
                <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                    <img src="../../../assets/images/media-icons/facebook.png">
                </div>
            </div>
            <div class="row" style="font-size:14px;width:600px">
                <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                    <span>Source: <b>The Australian Post</b></span>
                </div>
                <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                    <span>Published: <b>22 Aug 2022</b></span>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-6">
            <div class="col-12">
                <div class="media-card">
                    <h6>Making Wealth</h6>
                    <h4>BRICKLET PARTNERS WITH TAVUNI CAPITAL PARTNERS TO MAKE HOMES MORE AFFORDABLE WITH $100 MILLION
                        FUND</h4>

                    <div class="row" style="font-size:12px;width:400px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                            <span>Source: <b>The Australian Post</b></span>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                            <span>Published: <b>22 Aug 2022</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="col-12">
                <div class="media-card">
                    <h6>Making Wealth</h6>
                    <h4>BRICKLET PARTNERS WITH TAVUNI CAPITAL PARTNERS TO MAKE HOMES MORE AFFORDABLE WITH $100 MILLION
                        FUND</h4>

                    <div class="row" style="font-size:12px;width:400px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                            <span>Source: <b>The Australian Post</b></span>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                            <span>Published: <b>22 Aug 2022</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-6">
            <div class="col-12">
                <div class="media-card">
                    <h6>Making Wealth</h6>
                    <h4>BRICKLET PARTNERS WITH TAVUNI CAPITAL PARTNERS TO MAKE HOMES MORE AFFORDABLE WITH $100 MILLION
                        FUND</h4>

                    <div class="row" style="font-size:12px;width:400px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                            <span>Source: <b>The Australian Post</b></span>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                            <span>Published: <b>22 Aug 2022</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="col-12">
                <div class="media-card">
                    <h6>Making Wealth</h6>
                    <h4>BRICKLET PARTNERS WITH TAVUNI CAPITAL PARTNERS TO MAKE HOMES MORE AFFORDABLE WITH $100 MILLION
                        FUND</h4>

                    <div class="row" style="font-size:12px;width:400px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                            <span>Source: <b>The Australian Post</b></span>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-6">
                            <span>Published: <b>22 Aug 2022</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  propertyFilter = new BehaviorSubject(null);
  propertyList = this.propertyFilter.asObservable();
  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  updatePropertyList(data: any) {
    this.propertyFilter.next(data);
  }
  getStates(countryId?:any) {
    if (countryId) {
      return this.apiManagerService.getRequestParam(RestEnds.states, countryId);
    } else {
      return this.apiManagerService.getRequest(RestEnds.states, null);
    }
  }

  getPropertyDevelopers() {
    return this.apiManagerService.getRequest(RestEnds.developers, null);
  }


  getCountryList() {
    return this.apiManagerService.getRequest(RestEnds.getCountryList,null);
  }

  getIdentityType(){
    return this.apiManagerService.getRequest(RestEnds.getIdentityType,null);
  }

  getEntityList() {
    return this.apiManagerService.getRequest(RestEnds.getEntityList,null);
  }

  getAddressProofList() {
    return this.apiManagerService.getRequest(RestEnds.getAddressProofList,null);
  }

  getExchangeTypes() {
    return this.apiManagerService.getRequest(RestEnds.getExchangeTypes,null);
  }

  getTrustTypes() {
    return this.apiManagerService.getRequest(RestEnds.getTrustTypes,null);
  }

  getUnTrustTypes() {
    return this.apiManagerService.getRequest(RestEnds.getUnTrustTypes,null);
  }
}

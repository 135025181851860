import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataShareService } from 'src/app/core/services/data-share.service';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-private-company',
  templateUrl: './private-company.component.html',
  styleUrls: ['./private-company.component.scss']
})
export class PrivateCompanyComponent implements OnInit {

  @Output() privateFormEmitter = new EventEmitter<any>();

  sendFiles = {
    docType: 'COMPANY_EXTRACT',
    docText: 'FRONT SIDE'
  };

  addressFormsDataObject;

  privateCompanyFormDetails = {
    ACN: '',
    ABN: '',
    companyName: '',
    companyExtract: '',
    companyExtractImgUrl: '',
    officeAddressFormData: {},
    principalAddressFormData: {},
    privateCompanyFormIsValid: false
  }

  companyBasicFormIsValid = false;
  officeAddressFormIsValid = false;
  principalAddressFormIsValid = false;
  privateCompanyFormIsValid = false;

  addressFormTitles = {
    registerOffice: 'Registered Office Address Info.',
    principalOffice: 'Principal place of business'
  }

  constructor(
    private dataShareService: DataShareService,
    private imageService: ImageService
  ) { }


  getPrivateCompanyFormData() {
    this.dataShareService.currentPrivateCompanyFormData.subscribe(
      res => {
        if (res) {
          this.addressFormsDataObject = res;
          this.privateCompanyFormDetails.companyExtractImgUrl = this.addressFormsDataObject.companyExtractURL
          this.setCompanyExtractImageUrl(this.addressFormsDataObject);
          this.setFormValues(res);
          this.privateCompanyForm.get('companyExtract').setValue(true);
        }
      }
    )
  }

  setCompanyExtractImageUrl(data) {
    this.imageService.appendImageFile({
      previewUrl: data.companyExtractURL,
      isFileExist: true,
      docType: 'COMPANY_EXTRACT'
    });
  }


  setFormValues(data) {
    this.privateCompanyForm.get('ACN').setValue(data.acn);
    this.privateCompanyForm.get('ABN').setValue(data.abn);
    this.privateCompanyForm.get('companyName').setValue(data.companyName);
  }

  privateCompanyForm = new FormGroup({
    ACN: new FormControl('', Validators.required),
    ABN: new FormControl('', Validators.required),
    companyName: new FormControl('', Validators.required),
    companyExtract: new FormControl(false, [Validators.pattern('true')])
  });

  canShowError(control: string) {
    const field = this.privateCompanyForm.controls[control];
    return (!field.valid) && (field.touched) && (field.dirty);
  }

  hasError(control: string, error: string) {
    return this.privateCompanyForm.controls[control].hasError(error);
  }

  getValue(control: string) {
    return this.privateCompanyForm.controls[control].value;
  }

  getImageFiles(e) {
    if (e.imgFile || e.imgUrl) {
      this.privateCompanyForm.get('companyExtract').setValue(true);
    } else {
      this.privateCompanyForm.get('companyExtract').setValue(false);
    }

    if (e.imgFile) {
      this.privateCompanyFormDetails.companyExtract = e.imgFile;
      this.privateCompanyFormDetails.companyExtractImgUrl = '';
    }
    if (e.imgUrl) {
      this.privateCompanyFormDetails.companyExtractImgUrl = e.imgUrl;
      this.privateCompanyFormDetails.companyExtract = '';
    }

    // this.privateCompanyFormDetails.companyExtract = e.imgFile;
    // this.privateCompanyFormDetails.companyExtractImgUrl = e.imgUrl;
    this.checkFormValidation();
  }

  getRegisterAddressFormData(formData) {
    this.privateCompanyFormDetails.officeAddressFormData = formData.formObject;
    this.officeAddressFormIsValid = formData.isFormValid;
    this.checkFormValidation();
  }

  getPrincipalAddressFormData(formData) {
    this.privateCompanyFormDetails.principalAddressFormData = formData.formObject;
    this.principalAddressFormIsValid = formData.isFormValid;
    this.checkFormValidation();
  }

  checkFormValidation() {
    if (this.officeAddressFormIsValid && this.principalAddressFormIsValid && this.companyBasicFormIsValid) {
      this.privateCompanyFormIsValid = true;
    } else {
      this.privateCompanyFormIsValid = false;
    }
    this.privateCompanyFormDetails.privateCompanyFormIsValid = this.privateCompanyFormIsValid;
    this.privateFormEmitter.emit(this.privateCompanyFormDetails);
  }

  checkBasicFormValidation() {
    this.privateCompanyForm.statusChanges.subscribe(s => {
      this.privateCompanyFormDetails.ACN = this.getValue('ACN');
      this.privateCompanyFormDetails.ABN = this.getValue('ABN');
      this.privateCompanyFormDetails.companyName = this.getValue('companyName');

      if (s && s === 'VALID') {
        this.companyBasicFormIsValid = true;
      } else {
        this.companyBasicFormIsValid = false;
      }
      this.checkFormValidation();
    });
  }





  ngOnInit() {
    this.checkBasicFormValidation();
    this.getPrivateCompanyFormData();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifyDepositComponent } from './verify-deposit.component';
import { Routes } from '@angular/router';
const routes: Routes = [
  {
    path:'verify-deposit', component: VerifyDepositComponent
  }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class VerifyDepositRoutingModule { }

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseForm } from 'src/app/core/interfaces/base-form';
import { FormControlService } from 'src/app/core/services/form-control.service';

@Component({
  selector: 'app-dynamic-form-container',
  templateUrl: './dynamic-form-container.component.html',
  styleUrls: ['./dynamic-form-container.component.scss']
})
export class DynamicFormContainerComponent implements OnInit {

  @Input() questions: BaseForm<string>[] | null = [];
  form!: FormGroup;
  payLoad = '';

  constructor(private qcs: FormControlService) { }

  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as BaseForm<string>[]);
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor() {

  }

  checkFileStatus(files: any, callback: any, fileCount?: number, fileTypes?: string[]) {

    const imageFile: File = files[0];

    const responseData = {
      fileType: true,
      previewPath: null,
      fileExt: this.getFileExtension(imageFile.name),
      imageFile,
      name: imageFile.name,
      errorMsg: ''
    };

    if (fileTypes && fileTypes.length > 0) {
      if (!this.checkFileType(fileTypes, this.getFileExtension(imageFile.name))) {
        responseData.fileType = false;
        responseData.errorMsg = 'Invalid File Extension';
        callback(responseData);
      }
    }

    const reader = new FileReader();
    reader.onload = e => {
      responseData.previewPath = reader.result;
      callback(responseData);
    };
    reader.readAsDataURL(imageFile);

  }

  checkFileType(allowedTypes: string[], fileType: string) {
    let searchFlag = false;
    allowedTypes.forEach((type) => {
      if (fileType === type) {
        searchFlag = true;
      }
    });
    return searchFlag;
  }

  getFileExtension(fileName: string) {
    return fileName.substr((fileName.lastIndexOf('.') + 1));
  }

}

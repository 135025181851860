import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { defaultPagination, Pagination } from 'src/app/core/interfaces/pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit, OnDestroy {

  @Input('totalRecords') totalRecords: any;
  @Input('pagination') pagination: null | Pagination;
  @Output() pageChange = new EventEmitter();
  public config: Pagination = {
    PageNum: defaultPagination.PageNum,
    PageSize: defaultPagination.PageSize
  };

  constructor() {


  }

  ngOnInit(): void {
    if (this.pagination) {
      this.config = this.pagination;
    }
  }

  ngOnDestroy(): void {

  }

  onPageChange(pages: number) {
    this.config.PageNum = pages;
    this.pageChange.emit(this.config);
  }

}

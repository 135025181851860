import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RestEnds } from '../rest-api/rest-ends';


@Injectable({
  providedIn: 'root'
})
export class SignalRServiceService {
  private hubConnection: signalR.HubConnection;
  private paymentSuccessSubject = new Subject<void>();
  constructor() { 
    // this.startConnection();
  }

  public startConnection () {
    this.hubConnection = new signalR.HubConnectionBuilder()
      // .withUrl(`https://dev-webapi.invcar.com/api/1.0/notify`)
      .withUrl(environment.restApiBaseUrl+RestEnds.notify)
      .build();

      this.hubConnection
          .start()
          .then(() => console.log('SignalR connection started'))
          .catch(err => console.log('Error while starting SignalR connection: ' + err));

      this.hubConnection
          .on('BroadcastMessage',(type:string,payload:string,accountGUID:string,)=>{
            let response:any = {
              type:type,
              payload:payload,
              accountGUID:accountGUID
            } 
            this.paymentSuccessSubject.next(response);
          })

  }

  getPaymentSuccessObservable(): Observable<void> {
    return this.paymentSuccessSubject.asObservable();
 }
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from 'src/app/core/enum/storage';
import { StorageService } from '../../../core/utils/storage.service';
import { AccountService } from '../../../core/services/account.service';
import { ResponseDTO } from '../property-detail/property-detail.component';
import { HelperService } from '../../../core/utils/helper.service';
declare var $: any;

export interface Viewers{
  name: string;
  email: string;
  mobile: string;
  accountMemberGUID: any;
}

@Component({
  selector: 'app-account-viewer',
  templateUrl: './account-viewer.component.html',
  styleUrls: ['./account-viewer.component.scss']
})
export class AccountViewerComponent implements OnInit {

  viewers: Viewers[] = [];
  viewerGuid;
  isDeleting: boolean;
  @Output() listevent = new EventEmitter<boolean>();

  addViewerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
    // email: new FormControl('', [Validators.required]),
    // phone: new FormControl('', [Validators.required])
  });

  constructor(
    private storageService: StorageService,
    private accountService: AccountService,
    private helper: HelperService
  ) {
    // this.viewers = [
    //   {
    //     name: 'Alfonso Porcelli',
    //     email: 'a.porcelli@lakeba.com',
    //     mobile: '0451 571 873'
    //   }
    // ]
    this.getViewersList();
   }

  ngOnInit(): void {
    this.accountService.currentMessage.subscribe((msg) => {
      if (msg === true) {
        this.getViewersList();
      }
    });
  }

  getViewersList() {
    let guid = this.storageService.getItem(Storage.ACCOUNT_ID);
    
    
    this.accountService.getViewerList(guid).subscribe(
      (r: any) => {
        let result = r.data;
        this.viewers = result.viewers;
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  deleteViewerPopup(guid) {
    this.viewerGuid = guid;
    $('#deleteViewerModal').modal('show');
  }

  deleteViewer() {
    this.helper.showSpinner();
    $('#deleteViewerModal').modal('hide');
    this.isDeleting = true;
    this.accountService.deleteViewer(this.viewerGuid).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
        this.helper.hideSpinner();
        this.getViewersList();
        this.isDeleting = false;
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
        this.isDeleting = false;
      }
    );
  }


  get name() {
    return this.addViewerForm.get('name');
  }

  get email() {
    return this.addViewerForm.get('email');
  }

  get phone() {
    return this.addViewerForm.get('phone');
  }


  addNewViewerForm() {
    this.helper.showSpinner();
    let email = this.getValue('email');
    this.accountService.addViewer(email).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
        this.helper.hideSpinner();
        this.addViewerForm.reset();
       this.listevent.emit(true);
       
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  getValue(control: string) {
    return this.addViewerForm.controls[control].value;
  }

  canShowError(control: string, isEdit?: boolean) {
    let field = this.addViewerForm.controls[control];
    return (!field.valid) && (field.touched) && (field.dirty);
  }

  hasError(control: string, error: string, isEdit?: boolean) {
    return this.addViewerForm.controls[control].hasError(error);
}
}

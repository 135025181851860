import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Storage } from 'src/app/core/enum/storage';
import { AccountService } from 'src/app/core/services/account.service';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataShareService } from 'src/app/core/services/data-share.service';
import { KycService } from 'src/app/core/services/kyc.service';
import { HelperService } from 'src/app/core/utils/helper.service';
import { StorageService } from 'src/app/core/utils/storage.service';
import { RapidIdDialogComponent } from '../rapid-id-dialog/rapid-id-dialog.component';

declare var $: any;
export interface Accounts {
  accountGUID: string;
  accountName: string;
  isPremimum: boolean;
  entityType: string | null;
}

@Component({
  selector: 'app-username-dropdown',
  templateUrl: './username-dropdown.component.html',
  styleUrls: ['./username-dropdown.component.scss']
})
export class UsernameDropdownComponent implements OnInit, OnDestroy {

  arrowToggle = false;
  userName = "Unknown";
  isKycVerified = false;
  accList: Accounts[] = [];
  switchAccId = "";
  selAccount = null;

  isIndKycCompleted = false;

  @Input() showAddAccount;
  isLoggedIn = false;

  kycStatus = {
    isApproved: false,
    isSubmitted: false,
    isRejected: false,
    rapidIDVerificationStatus: 1,
    userType: ''
  };
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private storageService: StorageService,
    private helper: HelperService,
    private appService: AppService,
    private kycService: KycService,
    private dataShareService: DataShareService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const fullName = this.storageService.getItem(Storage.FULL_NAME);
    if (fullName && fullName !== "") {
      this.userName = fullName;
    }
    this.accountList();
    if (this.showAddAccount == undefined) {
      this.showAddAccount = true;
    }

    this.appService.isLoggedIn().subscribe(
      (s) => {
        this.isLoggedIn = s;
      }
    );

    this.kycService.getKycStatusSingleAccount().subscribe(
      (s: any) => {
        if (s.data) {
          this.kycStatus = {
            isApproved: s.data.isKYC1Approved,
            isRejected: s.data.isKYC1FormSubmitted,
            isSubmitted: s.data.isKYC1Rejected,
            rapidIDVerificationStatus: s.data.rapidIDVerificationStatus,
            userType: s.data.userType
          }
        }

      },
      (e) => {

      }
    );
  }

  accountList() {

    this.accountService.AccountList.subscribe(
      (s: any) => {
        if (s) {
          if (s.accList.length > 0) {
            this.isIndKycCompleted = true;
          }

          this.accList = s.accList;
          if (s.isAccSelected) {
            this.accList = this.accList.filter(
              (d) => {
                if (d.accountGUID == s.selAcc) {
                  this.selAccount = d;
                }
                return d.accountGUID != s.selAcc
              }
            );
          }
        }
      },
      (e) => {

      }
    );
  }

  switchAccount(id: string) {
    this.helper.showSpinner();
    this.accountService.switchAccount(id).subscribe(
      (r: any) => {

        const res = r.data.credential;

        this.storageService.setItem(Storage.AUTH_TOKEN, res.token);
        this.storageService.setItem(Storage.REFRESH_TOKEN, res.refreshToken);
        this.storageService.setItem(Storage.FULL_NAME, res.fullName);
        this.storageService.setItem(Storage.USER_TYPE, res.userType);
        this.storageService.setItem(Storage.ACCOUNT_ID, id);
        this.storageService.setItem(Storage.ROLE, res.roleType.toUpperCase());
        this.storageService.setItem(Storage.IS_ACCOUNT_SELECTED, "true");
        //this.accountService.setSelAccount(res.accountName);

        this.helper.success("Account has switched successfully");

        window.location.reload();
      },
      (e) => {
        this.helper.error("Account switch failed");
        this.helper.hideSpinner();
      }
    );
  }

  logout() {
    this.helper.showSpinner();
    this.authService.logout();
  }

  openSwitchAccModal() {
    $("#switchAccModal").modal("show");
  }

  openSupportPopup(){
    $("#supportPopup").modal({
      backdrop: false
    });
    $("#supportPopup").modal("show");
  }

  onCreateAccount(type: string) {
    if (this.kycStatus.userType !== '' && this.kycStatus.userType !== type) {
      let data = {
        Type: type,
      };

      this.kycService.postUserType(data).subscribe(
        (res) => {
          this.dataShareService.sendVerificationType(type);
          if (type == "INDIVIDUAL") {
            this.helper.navigate('/kyc');
          } else {
            this.helper.navigate('/kyc/org-landing');
          }
        },
        (error) => {
          this.helper.error(error);
        }
      );
    } else {
      if (type == "INDIVIDUAL") {
        this.helper.navigate('/kyc');
      } else {
        this.helper.navigate('/kyc/org-landing');
      }
    }
  }

  openRapidIdDialog() {
    let dialogRef = this.dialog.open(RapidIdDialogComponent, {
      data: {
        kycStatus: this.kycStatus
      },
      panelClass: 'custom-static-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  ngOnDestroy() {
    //this.accountService.emptyAccounts();
  }

}

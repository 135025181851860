import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '../enum/storage';
import { AuthService } from '../services/auth.service';
import { HelperService } from '../utils/helper.service';
import { StorageService } from '../utils/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private helper: HelperService,
    private storageService: StorageService
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isLoggedIn()) {
      if (route.data && route.data.role) {
        const roles: string[] = route.data.role;
        if (!this.storageService.getItem(Storage.ROLE)) {
          // this.helper.info('Please Choose account to view this page');
         // this.router.navigate(['/account-list']);
          return true;
        }
        const res = roles.filter(r => r == this.storageService.getItem(Storage.ROLE));
        if (res.length > 0) {
          return true;
        } else {
          if (state.url.includes('dashboard') && !state.url.includes('settings') && this.storageService.getItem(Storage.ROLE) === "VIEWER") {
            this.router.navigate(['/dashboard/settings']);
          } else {
            this.helper.error('You are not allowed to view this page');
            return false;
          }
        }
      }
      return true;
    } else {
      this.helper.warning('Login to view this page');
      this.router.navigate(['/marketplace/list?login=true']);
      return false;

    }

  }

}

<div class="row row align-items-center justify-content-center mt-5 mb-5">
    <div class="col-xl-1 col-lg-2 col-md-2 col-sm-4 col-5">
        <img class="w-100" [src]="imageSrc" alt="No Records found..">
    </div>
    <div class="col-12">
        <p class="secondary-regular f16 waav-gray-dark text-center"> <i>{{description | translate}}</i> </p>
        <div class="text-center" *ngIf="navigateLink">
            <a class="secondary-bold f16 primary-main" [routerLink]="navigateLink">{{navigateText | translate}}</a>
        </div>
    </div>
</div>
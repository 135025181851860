import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from 'src/app/core/services/account.service';
import { KycService } from 'src/app/core/services/kyc.service';
import { RapidIdService } from 'src/app/core/services/rapidId.service';
import { countryCodes } from 'src/app/core/utils/countryCodes';
import { HelperService } from 'src/app/core/utils/helper.service';
import * as moment from 'moment';

@Component({
  selector: 'app-rapid-id-dialog',
  templateUrl: './rapid-id-dialog.component.html',
  styleUrls: ['./rapid-id-dialog.component.scss']
})
export class RapidIdDialogComponent implements OnInit {
  mobileNumber = '';
  verifyForm = new FormGroup({
    countryCode: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d*$/)])
  });
  countryCodes = countryCodes;
  kycStatus;
  isResend = false;
  count = 0;


  constructor(
    public dialogRef: MatDialogRef<RapidIdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    private rapidIdService: RapidIdService,
    public helper: HelperService,
    private kycService: KycService
  ) {

    this.getKycStatus();

  }

  getKycStatus() {
    this.kycService.getKycStatusSingleAccount().subscribe(
      (s: any) => {
        if (s.data) {
          this.kycStatus = {
            isApproved: s.data.isKYC1Approved,
            isRejected: s.data.isKYC1FormSubmitted,
            isSubmitted: s.data.isKYC1Rejected,
            rapidIDVerificationStatus: s.data.rapidIDVerificationStatus,
            userType: s.data.userType,
            initDate: s.data.rapidIDInitiatedDate+'Z'
          }
          if (this.kycStatus.rapidIDVerificationStatus == 2) {
            const initDate = moment(this.kycStatus.initDate);
            const currentDate = moment(new Date());
            console.log(initDate);
            console.log(currentDate);

            const diff = currentDate.diff(initDate, 'minutes');
            console.log(diff);
            if (diff > 15) {
              this.isResend =true;
            }

          }
        }
      },
      (e) => {

      }
    );
  }

  ngOnInit(): void {
    this.accountService.getUserProfileDetails().subscribe(
      (r: any) => {
        // let mobile = r.data.personalInfo.mobile;
        // const code = mobile.substring(0, mobile.length - 10);
        // mobile = mobile.substring(mobile.length - 10, mobile.length);
        console.log(r.data.personalInfo);
        this.verifyForm.patchValue({
          mobile: r.data.personalInfo.mobile,
          countryCode: r.data.personalInfo.countryCode
        });

      },
      (e) => {

      }
    );
  }

  onSubmit() {
    this.helper.showSpinner();
    const data = {
      mobileNumber: this.verifyForm.controls['mobile'].value,
      countryCode: this.verifyForm.controls['countryCode'].value
    };

    if (!this.isResend) {
      this.rapidIdService.initiateRapidId(data).subscribe(
        (r) => {
          this.getKycStatus();
          this.helper.hideSpinner();
        },
        (e) => {
          this.helper.hideSpinner();
          this.helper.error(e);
        }
      );
    } else {
      this.rapidIdService.resendRapidId(data).subscribe(
        (r) => {
          this.getKycStatus();
          this.helper.hideSpinner();
          this.isResend = false;
        },
        (e) => {
          this.helper.hideSpinner();
          this.helper.error(e);
        }
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-buy-initiated-dialog',
  templateUrl: './buy-initiated-dialog.component.html',
  styleUrls: ['./buy-initiated-dialog.component.scss']
})
export class BuyInitiatedDialogComponent implements OnInit {

  totalAmount = 0;
  qty: 0;
  id = '';
  isBpp = false;
  constructor(
    public dialogRef: MatDialogRef<BuyInitiatedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService
  ) {
    this.totalAmount = this.data.amount;
    this.qty = this.data.qty;
    this.id = this.data.id;
    this.isBpp = this.data.isBpp;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

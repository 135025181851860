import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DepositService } from 'src/app/core/services/deposit.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-verify-deposit',
  templateUrl: './verify-deposit.component.html',
  styleUrls: ['./verify-deposit.component.scss']
})
export class VerifyDepositComponent implements OnInit {
  checkoutSessionId: string = '';

  constructor(private helper:HelperService,
    private depositService: DepositService,
    private route: ActivatedRoute,

  ) {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.checkoutSessionId = params["checkout_session_id"];
      console.log("1",this.checkoutSessionId);
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params =>{
      this.checkoutSessionId = params['checkout_session_id'];
      if (this.checkoutSessionId) {
        console.log('Checkout Session ID:', this.checkoutSessionId);

      } else {
        console.error('Session ID not found in the URL');
      }
     });
     
    // this.verifyDepositEmail();
  }

  verifyDepositEmail() {
    this.helper.showSpinner();
    // this.emailConfirmationDTO.l5OT = this.l5OT;
    // this.emailConfirmationDTO.xt8y = this.xt8y;

    this.depositService.verifyDepositRequest(this.checkoutSessionId).subscribe(res => {
      console.log("verifyres",res);
      let result: any = res;
      if (result.data !== null) {
        this.helper.hideSpinner();
        this.helper.navigate('marketplace/list?login=true');
        this.helper.success(result.msg);
      } else {
        this.helper.hideSpinner();
        this.helper.error(result.msg);
      }
    },
      error => {
        this.helper.hideSpinner();
        this.helper.error(error);
      });
  }

}

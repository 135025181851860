<div class="container-fluid" id="mobile-footer">
    <div class="row" *ngIf="isLogged" >
        <div *ngFor="let tab of tabs" class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <a *ngIf="(tab.logged && isLogged)" class="nav-link text-center" [routerLink]="tab.link"
                routerLinkActive="active" #rla="routerLinkActive">
                <img [src]="rla.isActive?path+tab.img+'-inactive.svg':path+tab.img+'-inactive.svg'" alt="">
            </a>
            
        </div>
        <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <a *ngIf="isLogged" class="nav-link text-center" routerLink="logout()" routerLinkActive="active" (click)="logout()"
                #rla="routerLinkActive">
                <span class="secondary-semi-bold" > <img src="../../../assets/images/header-icons/login-icons/logout-inactive.svg"> </span>
            </a>
        </div>

        
    </div>
    

    <!-- <div class="row">
    <ng-container *ngFor="let tab of tabs" class="col-3">
        <ng-container *ngIf="(tab.logged && isLogged) || (!tab.logged) ">
            <li class="nav-item">
                <a class="nav-link" [routerLink]="tab.link" routerLinkActive="active"
                    #rla="routerLinkActive">
                    <img [src]="rla.isActive?path+tab.img+'-active.svg':path+tab.img+'-inactive.svg'"
                        alt="">
                    <span class="secondary-semi-bold">{{ ('HDR.' + tab.title ) | translate }}</span>
                    <i class="fas fa-sort-up"></i>
                </a>
            </li>
        </ng-container>
    </ng-container>
</div> -->

</div>
<!-- <div class="container-fluid bg-waav-bright-light mt-5">
    <div class="container">
      

        <footer>
           

            <div class="footer-widget">
                <div class="footer-logo">
                    <a href="index.html"><img src="../../../assets/InvCar-Dark-Horizontal.png" class="img-fluid" alt="logo"></a>
                </div>
               
                <div class="footer-social-icon">
                    <span>Follow us</span>
                    <ul class="social-icons">
                        <li><a class="facebook" href="https://www.facebook.com/people/InvCar/100086400216867/" target="blank"><i class="fab fa-facebook"></i></a></li>
                        <li><a class="instagram" href="https://www.instagram.com/invcar/" target="blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a class="linkedin" href="https://www.linkedin.com/company/invcar/" target="blank"><i class="fab fa-linkedin"></i></a></li>   
                        <li><a class="twitter" href="https://www.twitter.com/InvCar/" target="blank"><i class="fab fa-twitter"></i></a></li>

                      </ul>
                </div>
                
            </div>
            
            <nav id="nav-desktop">
                <h3 class="subscribe-header">Stay up to date</h3>
               
                <p style="font-family: 'Roboto Condensed', sans-serif;font-weight: 400;color:#000;font-size: 16px;">Don't miss to subscribe for our news feeds, latest updates, subscribe now.</p>
                <div class="subscribe-form">
                    <form [formGroup]="subscribeForm">
                        <input type="text" formControlName="email" placeholder="Email Address"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                        <button (click)="subscribeUser()"><i class="fab fa-telegram-plane subscribeIcon" ></i></button>
                    </form>
                </div>

            </nav>
            <nav id="nav-desktop">
                <h3>Company Info</h3>
                <ul style="margin-top:27px;">
                    <li>
                        <a routerLink="terms_and_conditions">{{ 'FTR.terms&conditions' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="privacy_policy">{{ 'FTR.privacypolicy' | translate }}</a>
                    </li>
                   
                    <li>
                        <a (click)="openContactPopup()"> {{ 'FTR.contact' | translate }}</a>
                    </li>
                </ul>
            </nav>
              
        </footer>
        <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
              <p class="copyright-text">{{ 'FTR.allrightsreserved' | translate }}
              </p>
            </div>
    </div>
</div>
</div>

<div class="modal fade username-account-modal" id="contactPopup">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title f18 primary-semi-bold">Contact</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="primary-regular">
                    Please email your queries to
                    <bdi class="wp-charcoal"><a href="mailto:help@invcar.com" target="blank">help@invcar.com</a></bdi>
                </p>
            </div>
        </div>
    </div>
</div> -->
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storage } from '../enum/storage';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { HelperService } from '../utils/helper.service';
import { StorageService } from '../utils/storage.service';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apiManager: ApiManagerService,
    private storageService: StorageService,
    private router: Router,
    private appService: AppService,
    private helper: HelperService
  ) { }

  signIn(data: {
    EmailID: string,
    Password: string,
    // Captcha: string
  }) {
    return this.apiManager.postRequest(RestEnds.login, data);
  }

  signInNpd(data: {
    EmailID: string,
    Password: string,
  }) {
    return this.apiManager.postRequest(RestEnds.loginNPD, data);
  }

  signUp(data: any) {
    return this.apiManager.postRequest(RestEnds.signUp, data)
  }

  forgotpassword(data: any) {
    return this.apiManager.postRequest(RestEnds.forgotPassword, data);

  }

  resetpassword(data: any) {
    return this.apiManager.postRequest(RestEnds.resetPassword, data);
  }

  resendEmail(email: any) {
    return this.apiManager.getRequestQueryParam(RestEnds.resendMailAfterSignup, `email=${email}`)
  }

  verifyEmail(data: any) {
    return this.apiManager.postRequest(RestEnds.verifyEmail, data);
  }

  getCampaignDetails(id: string) {
    return this.apiManager.getRequestQueryParam(RestEnds.campaignDetails, 'campaginGUID=' + id);
  }

  getRefreshToken(): Observable<string> {
    const data = {
      RefreshToken: this.storageService.getItem(Storage.REFRESH_TOKEN)
    };
    return this.apiManager.postRequest(RestEnds.refreshToken, data).pipe(
      map((root: any) => {
        this.storageService.setItem(
          Storage.AUTH_TOKEN,
          root.data.credential.token
        );
        this.storageService.setItem(
          Storage.REFRESH_TOKEN,
          root.data.credential.refreshToken
        );
        return root.data.credential.token;
      },
      )
    );
  }

  isLoggedIn() {
    const value = this.storageService.getItem(Storage.IS_LOGGED);
    if (value === "true") {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.apiManager.getRequest(RestEnds.logout, null).subscribe(
      (s) => {
        //this.router.navigateByUrl('/login');
      },
      (e) => {
        //this.router.navigateByUrl('/login');
      }
    );
    this.storageService.clear();
    this.appService.loginStatus.next(false);
    this.helper.hideSpinner();
    this.router.navigateByUrl('home');
    this.storageService.enableCartMenu();
    //window.location.reload();
  }

  getInitialKycStatus() {
    this.storageService.getItem(Storage.INITIAL_KYC_STATUS);
  }

}

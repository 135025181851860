<div mat-dialog-title class="mb-0">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-main ps-5 pe-5 pos-rel">
            <h2 class="m-0 secondary-bold f20 purple-brown"> BUY Lot</h2>
            <img src="/assets/images/dialog-close.svg" class="trade-dialog-close mobile" (click)="closeDialog()" title="Close dialog" alt="close_dialog">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ps-5 pe-5 pos-rel desktop">
            <h2 class="m-0 secondary-bold f20 dark-indigo">Summary</h2>
            <img src="/assets/images/dialog-close.svg" class="trade-dialog-close" (click)="closeDialog()" title="Close dialog" alt="close_dialog">
        </div>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="buyBrickletForm">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-light ps-5">
                <div class="mt-5">
                    <label class="primary-light waav-dark-main f20 mb-5">Your balance
                        <span class="primary-bold purple-brown">${{accountBalance|number:'0.0-2'}}</span>
                    </label>

                    <div class="row align-items-center mb-5">
                        <div class="col-lg-8 col-md-7 col-sm-12">
                            <label for="" class="primary-bold f24 waav-dark-main">
                                How many Lot
                                would you like to buy?
                            </label>
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-12">
                            <input type="text" class="input-dialog purple-brown" formControlName="NoOfBricklet"
                                (keyup)="getMarketPrice()">
                        </div>
                    </div>
                </div>
                <div>
                    <!-- <ul class="primary-regular f14 generic-palette-dark-light">
                        <li>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.</li>
                        <li>Aenean dignissim pellentesque felis</li>
                        <li>Morbi in sem quis dui placerat ornare. Pellentesque odio nisi</li>
                        <li>euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat.</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 bg-white ps-5">

                <div>
                    <div id="bricklet-history">
                        <div *ngFor="let r of historyList" class="tiles">
                            <p class="primary-regular f16 generic-palette-dark-light"> <span
                                    class="secondary-bold f16 waav-dark-main">{{r.availBricklet}} Bricklet</span> from 1
                                user</p>
                            <p class="secondary-bold f20 purple-brown">{{r.availBricklet}} x ${{r.price
                                |number:'0.0-2'}}</p>
                        </div>
                        <div class="tiles">
                            <p class="primary-regular f16 generic-palette-dark-light">
                                <span class="secondary-bold f16 waav-dark-main">Tax and Fees</span>
                                ({{totalTaxPercentage}}% inclusive of the following)
                            </p>
                            <p class="secondary-bold f20 purple-brown">
                                ${{ (feeList?.buyerStampDutyFee+feeList?.buyerPropertyTransferFee+feeList?.buyerAdministrationFee) | number : '0.0-2'}}
                            </p>
                        </div>
                        <div class="tiles tax">
                            <p class="secondary-bold f12 generic-palette-dark-light">
                                - Stamp Duty
                            </p>
                        </div>
                        <div class="tiles tax">
                            <p class="secondary-bold f12 generic-palette-dark-light">
                                - Conveyancing Costs
                            </p>
                        </div>
                        <!-- <div class="tiles tax">
                            <p class="secondary-bold f12 generic-palette-dark-light">
                                - Stamp duty ({{taxList?.buyerStampDutyTax}}%)
                            </p>
                            <p class="primary-regular f12 generic-palette-dark-light">
                                ${{feeList?.buyerStampDutyFee | number : '0.0-2'}}
                            </p>
                        </div>
                        <div class="tiles tax">
                            <p class="secondary-bold f12 generic-palette-dark-light">

                                - Property transfer ({{taxList?.buyerPropertyTransferTax}}%)
                            </p>
                            <p class="primary-regular f12 generic-palette-dark-light">
                                ${{feeList?.buyerPropertyTransferFee | number : '0.0-2'}}</p>
                        </div>
                        <div class="tiles tax">
                            <p class="secondary-bold f12 generic-palette-dark-light">
                                - Administration fee ({{taxList?.buyerAdministrationTax}}%)
                            </p>
                            <p class="primary-regular f12 generic-palette-dark-light">
                                ${{feeList?.buyerAdministrationFee | number : '0.0-2'}}</p>
                        </div> -->
                    </div>
                    <div id="tax-calc">

                    </div>
                </div>

                <div>

                    <div class="checkbox-container">
                        <label class="checkbox-label">
                            I agree to the terms and conditions.
                            <a *ngIf="pdfLink!==''" [href]="pdfLink" target="_blank" class="f13"> <i class="fas fa-external-link-alt primary-main"></i></a>
                            <input type="checkbox" formControlName="termsAndCondition" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="checkbox-container">
                        <label class="checkbox-label">
                            <!-- I have read and understood the Supplementary Information Memorandum and I acknowledge and
                            agree to
                            be
                            bound by the terms of the Supplementary Information Memorandum. -->
                            I provide this authority and instruction to act on the purchase.
                            <a *ngIf="legalDocLink!==''" [href]="legalDocLink" target="_blank" class="f13"> <i class="fas fa-external-link-alt primary-main"></i></a>
                            <input type="checkbox" formControlName="legalDocAgreement" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="p-0 m-0">
    <div class="row m-0 w-100">
        <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-light ps-5">

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 row m-0 align-items-center justify-content-between ps-5 pe-4 pb-4">
            <div class="col-lg-5 col-md-6 col-sm-12 col-12 d-flex flex-column">
                <span class="primary-regular f16 generic-palette-dark-light">Total amount</span>
                <span class="primary-bold f40 primary-main">$ {{totalAmount|number:'0.0-2'}}</span>

            </div>
            <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                <button class="btn btn-primary w-100" (click)="onSubmit()" [disabled]="!buyBrickletForm.valid">Buy
                    Lot</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>
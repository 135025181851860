<div style="text-align:center">
  <div>
  <webcam [height]="200" [width]="200" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam && !errors.length"
  [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
  (initError)="handleInitError($event)"></webcam>
  <br/>
  <div class="row" >
    <div class="col-12" style="text-align: center;" *ngIf="!errors.length">
      <!-- <div class="actionBtn" (click)="triggerSnapshot();" title="Take Picture">📸</div> -->
      <button class="borderBtn btn btn-info" (click)="triggerSnapshot();" title="Take Picture">Take Picture</button>
    </div>
    <!-- <div class="col-6"> -->
      <!-- <div class="actionBtn" (click)="showNextWebcam(true);" title="Switch Camera">🔁</div> -->
      <!-- <button class="borderBtn btn btn-info" (click)="showNextWebcam(true);" title="Switch Camera">Switch Camera</button> -->

    <!-- </div> -->
  
  </div>
  </div>
  </div>
  <div class="col-12" *ngIf="errors.length > 0">
  <h4>Note:</h4>
  <p>Once allow camera to Complete the KYC Process</p>
</div>
  <!-- <ul *ngFor="let error of errors">
  <li>{{error | json}}</li>
  </ul> -->
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

export interface ImageType {
  previewUrl: string;
  isFileExist: boolean;
  docType: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  public imageFile = new BehaviorSubject<ImageType[] | null>([]);
  public getStoredImage = this.imageFile.asObservable();

  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  getImageBlob(filename: string) {
    return this.apiManagerService.getBlobRequest(RestEnds.getKycBlob + '?filename=' + filename, { responseType: 'blob' });
  }

  updateImageFile(data: ImageType[]) {
    this.imageFile.next(data);
  }

  appendImageFile(data: ImageType) {
    this.imageFile.next([...this.imageFile.getValue(), data]);
  }

  removeObj(docType: string) {
    const files = this.imageFile.getValue();
    if (files) {
      const filterFiles = files.filter((f) => {
        return f.docType !== docType;
      });
      this.imageFile.next(filterFiles);
    }
  }

  emptyImageFile() {
    this.imageFile.next([]);
  }


}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataShareService {
  constructor() {}

  private updateVerificationType = new BehaviorSubject<any>("");
  currentVerificationType = this.updateVerificationType.asObservable();

  private updatestepperMenuList = new BehaviorSubject<any>("");
  currentstepperMenuList = this.updatestepperMenuList.asObservable();

  private updateKYCResetKey = new BehaviorSubject<any>("");
  currentKYCResetKey = this.updateKYCResetKey.asObservable();

  private updatePrivateCompanyFormData = new BehaviorSubject<any>("");
  currentPrivateCompanyFormData = this.updatePrivateCompanyFormData.asObservable();

  private updateStepperDetails = new BehaviorSubject<any>("");
  currentStepperDetails = this.updateStepperDetails.asObservable();

  sendVerificationType(message: any) {
    this.updateVerificationType.next(message);
  }

  sendstepperMenuList(message: any) {
    this.updatestepperMenuList.next(message);
  }

  sendPrivateCompanyFormData(message: any) {
    this.updatePrivateCompanyFormData.next(message);
  }

  sendKYCResetKey(message: any) {
    this.updateKYCResetKey.next(message);
  }

  sendUpdateStepperTriggerEvent(message: any) {
    this.updateStepperDetails.next(message);
  }

  linkAll() {
    // this.updatestepperMenuList.subscribe();
    // this.updateStepperDetails.subscribe();
    // this.updatePrivateCompanyFormData.subscribe();
  }

  unLinkAll(){
    this.updatestepperMenuList.complete();
    this.updateStepperDetails.complete();
    this.updatePrivateCompanyFormData.complete();
  }
}

<mat-dialog-content class="buy-init-dialog">
    <div class="row align-items-center justify-content-center flex-column w-100 m-0">

        <p class="primary-bold f40 waav-dark-main m-0 text-center col-xl-8 col-lg-8 col-md-10 col-sm-12">Congratulations
            for your purchase</p>
        <p *ngIf="isBpp"
            class="primary-regular f20 generic-palette-b-w-gray text-center col-xl-10 col-lg-10 col-md-12 col-sm-12">
            The initial amount of $ {{totalAmount | number :'0.0-2'}} has been debited from your account balance for this purchase. Please check your
            email to proceed with signature formalities on the document and the next steps.
            <br>
            You will receive an email from “no-reply@Kreano.com”.
        </p>
        
        <p *ngIf="!isBpp"
            class="primary-regular f20 generic-palette-b-w-gray text-center col-xl-8 col-lg-8 col-md-10 col-sm-12">
            The amount of $ {{totalAmount | number :'0.0-2'}} , regarding {{qty}} Lot's, has been debited from your
            account balance.
        </p>

        <img class="col-xl-3 col-lg-3 col-md-4 col-sm-8" src="/assets/images/buy-initiated.svg" alt="">
        <div class="row justify-content-between col-xl-10 col-lg-10 col-md-12 col-sm-12 m-0">
            <a routerLink="/dashboard/pending-title" class="col-lg-5 col-md-6 col-sm-12" (click)="closeDialog()">
                <button class="btn btn-primary light w-100">{{ 'POB.viewreceipttransaction' | translate }}</button>
            </a>

            <a class="col-lg-5 col-md-6 col-sm-12" (click)="closeDialog()">
                <button class="btn btn-primary w-100"> {{ 'POB.gotomarketplace' | translate }}</button>
            </a>
        </div>
    </div>

</mat-dialog-content>
<!-- <mat-dialog-actions class="p-0">


</mat-dialog-actions> -->
import { Injectable } from '@angular/core';
import { Pagination } from '../interfaces/pagination';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private apiManager: ApiManagerService,
  ) { }

  accountOverview() {
    return this.apiManager.getRequest(RestEnds.accountOverview, null);
  }

  myBricklets(data: Pagination) {
    return this.apiManager.postRequest(RestEnds.AllinvestmentList, data)
  }

  myBppBricklets(data: Pagination) {
    return this.apiManager.postRequest(RestEnds.bppBricklets, data)
  }

  titlePending(data: Pagination) {
    return this.apiManager.postRequest(RestEnds.AllTitleTransfer, data)
  }

  TitleTransfer(data: Pagination) {
    return this.apiManager.postRequest(RestEnds.titleTransfer, data)
  }
  
  initiatePendingOrder(data) {
    return this.apiManager.postRequest(RestEnds.initiatePendingOrder, data)
  }

  sendDocumentToMail(Id: string) {
    return this.apiManager.getRequestQueryParam(RestEnds.sendDocMail, 'buyOrderHeadersGUID=' + Id);
  }

  signPOAManually() {
    return this.apiManager.postRequest(RestEnds.signPOAManually,null)
  }

  coOwnershipDocSign(Id) {
    return this.apiManager.getRequestQueryParam(RestEnds.sendDocMail, 'buyOrderHeadersGUID=' + Id);
  }

  getExistingOwners(data){
    return this.apiManager.postRequest(RestEnds.existingOwners,data)
  }

  watchList(data: Pagination) {
    return this.apiManager.postRequest(RestEnds.AllWatchlist, data)
  }

  viewBppInstallment(data: any) {
    return this.apiManager.postRequest(RestEnds.bppInstallment, data)
  }

  payBppInstallment(data: any) {
    return this.apiManager.postRequest(RestEnds.bppInstallmentPayment, data)
  }

  myBppBrickletsOnSell(data: Pagination) {
    return this.apiManager.postRequest(RestEnds.bppBrickletsOnSell, data)
  }

  updateEntity(data:any) {
    return this.apiManager.putRequest(RestEnds.updateentityname, data)
  }

  
}

import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordModule } from './pages/authentication/forgot-password/forgot-password.module';
import { SignInModule } from './pages/authentication/sign-in/sign-in.module';
import { SignUpModule } from './pages/authentication/sign-up/sign-up.module';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';

import { ToastrModule } from 'ngx-toastr';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from './core/interceptors/auth-token.interceptor';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { EmailVerifyHomeComponent } from './pages/authentication/email-verification/email-verify-home/email-verify-home.component';
import { EmailConfirmComponent } from './pages/authentication/email-verification/email-confirm/email-confirm.component';
import { ResetPasswordModule } from './pages/authentication/reset-password/reset-password.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GenerateRentalStatementComponent } from './pages/generate-rental-statement/generate-rental-statement.component';
import { VerifyNewEmailComponent } from './pages/verify-new-email/verify-new-email.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CommomTermsAndConditionsComponent } from './pages/commom-terms-and-conditions/commom-terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { LeftSidebarComponent } from './pages/left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from './pages/right-sidebar/right-sidebar.component';
import { CommonPanelComponent } from './pages/common-panel/common-panel.component';
import { InvestmentCardComponent } from './pages/investment-card/investment-card.component';
import { InvestorsComponent } from './pages/investors/investors.component';
import { DistributorsComponent } from './pages/distributors/distributors.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { AboutComponent } from './pages/about/about.component';
import { BenefitsComponent } from './pages/benefits/benefits.component';
import { TeamComponent } from './pages/team/team.component';
import { MediaComponent } from './pages/media/media.component';
import { OurPartnersComponent } from './pages/our-partners/our-partners.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { InboxComponent } from './pages/inbox/inbox.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { DebounceClickDirective } from './shared/directives/debounce-click.directive';
import { VerifyDepositRoutingModule } from './pages/dashboard/deposit/verify-deposit/verify-deposit-routing.module';
import {WebcamModule} from 'ngx-webcam';
// import { FaqComponent } from './pages/faq/faq.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    EmailVerifyHomeComponent,
    EmailConfirmComponent,
    GenerateRentalStatementComponent,
    VerifyNewEmailComponent,
    CommomTermsAndConditionsComponent,
    PrivacyPolicyComponent,
    LeftSidebarComponent,
    RightSidebarComponent,
    CommonPanelComponent,
    InvestmentCardComponent,
    InvestorsComponent,
    DistributorsComponent,
    PartnerComponent,
    AboutComponent,
    BenefitsComponent,
    TeamComponent,
    MediaComponent,
    OurPartnersComponent,
    ContactUsComponent,
    InboxComponent,
    HelpdeskComponent,
    DebounceClickDirective,
    
    // FaqComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    SignInModule,
    SignUpModule,
    // ForgotPasswordModule,
    ResetPasswordModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      iconClasses: {
        error: 'bk-toast-error',
        info: 'bk-toast-info',
        success: 'bk-toast-success',
        warning: 'bk-toast-warning',
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxSkeletonLoaderModule.forRoot(),
    VerifyDepositRoutingModule,
    WebcamModule
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    CurrencyPipe,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]

})
export class AppModule { }

<div *ngIf="(isDataLoaded && records.length>0) || loader">
    <h2 class="title-border">
        You might also like
        <a routerLink="/community/all-article/you-might-like" class="view-more">View more</a>
    </h2>

    <div *ngIf="!isDataLoaded" class="row">
        <div *ngFor="let r of [1,2,3]" class="col-lg-4 col-md-4 col-sm-12">
            <div class="image-container">
                <ngx-skeleton-loader [theme]="{'height':'210px'}" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="bottom">
                <ngx-skeleton-loader [theme]="{'height':'30px'}" appearance="line"></ngx-skeleton-loader>
                <div class="d-flex flex-row justify-content-between align-items-center ">
                    <div class="d-flex flex-row ">
                        <div class="d-flex justify-content-center align-items-center">
                            <ngx-skeleton-loader [theme]="{'height':'30px'}" appearance="circle"></ngx-skeleton-loader>
                        </div>
                        <div class="d-flex flex-column ms-3">
                            <ngx-skeleton-loader [theme]="{'height':'20px', 'width':'150px'}" appearance="line">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader [theme]="{'width':'50%'}" appearance="line"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div class="d-flex flex-row">
                        <ngx-skeleton-loader [theme]="{'height':'40px', 'width': '40px'}" appearance="line">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{'height':'40px','width': '40px', 'margin-left':'8px'}"
                            appearance="line">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isDataLoaded" class="row">
        <div *ngFor="let r of records" class="col-lg-4 col-md-4 col-sm-12 mb-4">
            <div class="image-container">
                <img [src]="r.thumbnailURL" class="logo" alt="">
            </div>
            <div class="bottom">
                <a [routerLink]="'/community/feed/'+r.articleGUID">
                    <h3 class="title article-title">{{r.title | slice:0:45}}{{r.description.length>45?'...':''}}</h3>
                </a>
                <!-- <h3 class="title primary-bold f24 waav-dark-dark">
                    {{r.title}}
                </h3> -->
                <div class="d-flex flex-row justify-content-between align-items-center ">
                    <div class="d-flex flex-row ">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="/assets/images/bricklet-icon.svg" alt="">
                        </div>
                        <div class="d-flex flex-column ms-3">
                            <p class="secondary-semi-bold f16 primary-main mb-1">Bricklet Exclusive</p>
                            <p class="secondary-regular f14 waav-gray-dark m-0">{{r.createdDate | date :'MMM dd'
                                }}.{{r.stateName}}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-row">
                        <!-- <img src="/assets/images/icon-share.svg" class="share-icon" alt="">
                        <img *ngIf="r.isSaved" src="/assets/images/icon-saved.svg" class="save-icon" alt=""
                            (click)="unSaveArticle(r.articleGUID)">
                        <img *ngIf="!r.isSaved" src="/assets/images/icon-save.svg" class="save-icon" alt=""
                            (click)="saveArticle(r.articleGUID)"> -->
                            <app-share-save-icon 
                            type="article"
                            [guid]="r.articleGUID"
                            [title]="r.title"
                            [status]="r.isSaved"
                            ></app-share-save-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
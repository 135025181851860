<div class="container property-section">
    <h3 style="text-align: center;">{{ "FTR.privacypolicy" | translate }}</h3>
    <p>
        InvCar respects your privacy and is committed to protecting your personal data. This privacy policy will explain how we collect, use, and protect your personal information when you use our website.

    </p>
    <h5>Information Collected
    </h5>
    <p>InvCar may collect the following personal information from users:
    </p>
    <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Mailing address</li>
        <li>Phone number</li>
        <li>Payment information</li>
        <li>Purchase history</li>
        <li>IP address and location data</li>
    </ul>
    <p>We collect this information to provide users with a better experience and improve our services. We may also use this information to communicate with users about their account and provide them with updates on new products and promotions.
    </p>
   

    <h5>Data Security: 
    </h5>
    <p>InvCar takes the security of your personal information seriously and uses reasonable measures to protect it from unauthorized access, disclosure, or alteration. We store your personal information on secure servers and use encryption and other security technologies to protect it during transmission.
    </p>

    <h5>Sharing Personal Information
    </h5>
    <p>InvCar does not sell or rent personal information to third parties. We may share personal information with service providers who assist us in delivering our services, such as payment processors and shipping providers. We may also share personal information as required by law, such as in response to a court order or subpoena.
    </p>

    <h5>Data Retention
    </h5>
    <p>InvCar retains personal information for as long as necessary to provide our services and comply with legal obligations. Users can request that their personal information be deleted from our records at any time.</p>

    <h5>User Rights</h5>
    <p>Users have the right to access, correct, or delete their personal information that we hold. Users may also request that we restrict the processing of their personal information or object to the processing of their personal information. To exercise these rights, please contact us using the information provided below.
    </p>

    <h5>Contact Us
    </h5>
    <p>If you have any questions or concerns about this Privacy Policy, please contact us at: <br>
       InvCar Address: <span style="font-weight: 600;">Level 2, 6 The Corso, Manly NSW 2095, Australia</span> <br>
    <span>Email: <span style="font-weight: 600;"> <a href="mailto: help@invcar.com ">help@invcar.com  </a> </span></span>
    </p>
    <h5>Changes to this Privacy Policy
    </h5>
    <p>InvCar reserves the right to update this Privacy Policy at any time. We will post the updated policy on our website and provide notice to users via email or through our website. Your continued use of our website after any changes to the policy will signify your acceptance of the updated policy.
    </p>
</div>
<div class="w-100 bg-waav-bright-light p-2 d-flex flex-row align-items-center justify-content-between">
    <span class="primary-semi-bold f20 waav-dark-main">Address Information</span>
</div>
<form [formGroup]="addressInfoForm" >
    <div class="row w-100">
        <div class="col-12">
            <div class="row">
                <div class="form-group col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <label for="unitNumber" class="form-label">Unit No</label>
                    <input type="text" formControlName="unitNumber" class="form-control" NumbersOnly
                        [allowDecimals]="false" [allowSign]="false" />
                    <!-- <div *ngIf="canShowError('unitNumber')">
                        <p class="error-txt" *ngIf="hasError('unitNumber','required')">Unit Number is required</p>
                    </div> -->
                </div>
                <div class="form-group col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <label for="streetNumber" class="form-label required">Street No</label>
                    <input type="text" formControlName="streetNumber" class="form-control" />
                    <div *ngIf="canShowError('streetNumber')">
                        <p class="error-txt" *ngIf="hasError('streetNumber','required')">Street Number is required</p>
                    </div>
                </div>
                <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="streetName" class="form-label required">Street Name</label>
                    <input type="text" formControlName="streetName" class="form-control" />
                    <div *ngIf="canShowError('streetName')">
                        <p class="error-txt" *ngIf="hasError('streetName','required')">Street Name is required</p>
                    </div>
                </div>
                <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="streetType" class="form-label required">Street Type</label>
                    <input type="text" formControlName="streetType" class="form-control" />
                    <div *ngIf="canShowError('streetType')">
                        <p class="error-txt" *ngIf="hasError('streetType','required')">Street type is required</p>
                    </div>
                </div>
                <div class="form-group col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <label for="postCode" class="form-label required">Post code</label>
                    <input type="text" formControlName="postCode" class="form-control" NumbersOnly
                        [allowDecimals]="false" [allowSign]="false" maxlength=8 />
                    <div *ngIf="canShowError('postCode')">
                        <p class="error-txt" *ngIf="hasError('postCode','required')">Post code is required</p>
                    </div>
                </div>
                <div class="form-group col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <label for="state" class="form-label required">State</label>
                    <!-- <input type="text" formControlName="state" class="form-control" /> -->
                    <select formControlName="state" class="form-control">
                        <option value="">Choose</option>
                        <option *ngFor="let s of stateList" [value]="s.stateGUID">{{s.shortName}}</option>
                    </select>
                    <div *ngIf="canShowError('state')">
                        <p class="error-txt" *ngIf="hasError('state','required')">State is required</p>
                    </div>
                </div>
                <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="suburb" class="form-label required">Suburb</label>
                    <input type="text" formControlName="suburb" class="form-control" />
                    <div *ngIf="canShowError('suburb')">
                        <p class="error-txt" *ngIf="hasError('suburb','required')">Suburb is required</p>
                    </div>
                </div>
                <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label for="additionalInfo" class="form-label">Additional information (optional) </label>
                    <input type="text" formControlName="additionalInfo" class="form-control" />
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="w-100 bg-waav-bright-light p-2 d-flex flex-row align-items-center justify-content-between">
                <span class="primary-semi-bold f20 waav-dark-main">Proof of Address</span>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <label for="proofDocType" class="form-label">
                        Select a type of document
                    </label>
                    <select formControlName="proofDocType" class="form-control">
                        <option value="">Choose document</option>
                        <option *ngFor="let d of docList" [value]="d.addressProofTypeGUID">{{d.type}}</option>
                    </select>
                    <div *ngIf="canShowError('proofDocType')">
                        <p class="error-txt" *ngIf="hasError('proofDocType','required')">Document type is required</p>
                    </div>
                </div>
                <div class="form-group col-12">
                    <app-img-upload [sendFiles]="sendFiles" (getFiles)="getFiles($event)"></app-img-upload>

                </div>
            </div>
        </div>
    </div>
</form>
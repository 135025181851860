<mat-dialog-content class="mat-content">
    <div class="row justify-content-center align-items-center text-center">
        <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 col-xs-12">
            <img src="/assets/images/bricklet-icon.svg" alt="">
            <h1 class="primary-bold f30 waav-dark-dark mb-2">Welcome to Bricklet</h1>
            <p class="secondary-regular f16 tertiary-dark mb-3">
                The beauty of Bricklet is that it enables people to build a diverse property investment portfolio
                consisting
                of quality property fragments.
            </p>
        </div>
    </div>
    <div class="row mt-4 justify-content-center align-items-center">
        <div class="col-xl-6 col-lg-6 col-md-7 col-sm-10 col-xs-12">
            <div class="d-flex flex-column">
                <span class="primary-semi-bold f24 waav-dark-dark">
                    What's new
                </span>
                <div class="row mt-2" *ngFor="let r of records">
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4">
                        <img [src]="r.iconSrc" alt="">
                    </div>
                    <div class="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-xs-8">
                        <div class="d-flex flex-column">
                            <span class="primary-semi-bold f18 waav-dark-dark"> {{r.title}} </span>
                            <p class="secondary-regular f16 tertiary-dark">
                                {{r.description}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center mt-3">
                    <a routerLink="/community" class="col-xl-6 col-lg-6 col-md-10 col-sm-12">
                        <button class="btn btn-primary w-100">Explore now</button>
                    </a>
                </div>

            </div>
        </div>
    </div>
</mat-dialog-content>
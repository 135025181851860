export enum RestEnds {

    //Authentication
    posts = "posts",
    signUp = "Account/signup",
    login = "Account/login",
    refreshToken = "account/refreshtoken",
    logout = "Account/logout",
    campaignDetails = "Campaign",
    forgotPassword = "Account/forgetpassword",
    resendMailAfterSignup = "account/resendmail",
    verifyEmail = "Account/verifyemail",
    resetPassword = "Account/resetpassword",

    //Property
    // propertyList = "Property/list",
    // propertyDetails = "property/details",

    brickletPriceHistory = "property/brickletpricehistory",
    coOwnersDetails = "property/coownershopdetails",
    buyBricklet = "BuySell/buy",
    buyBppBricklet = "BuySell/buyBPP",
    sellBricklet = "BuySell/sell",
    sellBppBricklet = "buysell/bppsell",
    buyerMarketPrice = "BuySell/marketprice",
    bppBuyerMarketPrice = "BuySell/bppmarketprice",
    sellerMarketPrice = "BuySell/sellermarketprice",
    bppSellerMarketPrice = "BuySell/bppsellermarketprice",
    sellIsAvailable = "BuySell/sellisavailable",
    buyIsAvailable = "BuySell/buyisavailable",
    bppBuyIsAvailable = "BuySell/BPPbuyisavailable",
    bppSellIsAvailable = "buysell/bppsellisavailable",
    bppSign = "BuySell/docsigninitiate",
    favProperty = "Property/favorite",

    //Lookups
    states = "Lookup/state",
    developers = "Lookup/propertydeveloper",
    getCountryList = "Lookup/country",
    getIdentityType = "lookup/identityType",
    getEntityList = "Lookup/entitytype",
    getExchangeTypes = "Lookup/exchangetypes",
    getTrustTypes = "Lookup/trusttypes",
    getUnTrustTypes = "Lookup/untrusttypes",
    getAddressProofList = "Lookup/addressprooftype",

    // Dashboard
    bank = "withdraw/bank",
    bankAvailable = "withdraw/bank/available",
    withdrawAmount = "withdraw/request",
    getBrickletBankDetails = "Deposit/branktransfer",
    accountSummary = "Account/summary",
    poliDeposit = "Deposit/poli",
    poliSuccess = "Deposit/polisuccess",
    poliFail = "Deposit/polifail",
    poliCancel = "Deposit/policancel",
    cryptoCreatePayment = "deposit/createpayment",
    AllinvestmentList = "Property/myinvestmentlist",
    bppBricklets = "property/mybrickletsonbpp",
    bppBrickletsOnSell = "property/mybrickletsonbppsell",
    bppInstallment = "property/viewinstallmentstatement",
    bppInstallmentPayment = "property/bppinstallmentmanualpayment",
    AllWatchlist = "Property/mywatchlist",
    AllTitleTransfer = "Property/myprovisionalinvestmentlist",
    accountOverview = "Account/overview",
    transactionList = "BuySell/transactions",
    pendingTitleList = "BuySell/pendingbuyorders",
    sellOrderList = "BuySell/pendingorder",
    sellOrderCancel = "buysell/cancelsellorder",
    depositTransaction = "Deposit/transactions",
    devidendTransaction = 'Dividend/transactions',
    depositCancellation = "Deposit/cancel",
    withdrawTransaction = "Withdraw/transaction",
    withdrawCancellation = "Withdraw/cancel",
    downloadDepositReceipt = "Deposit/download",
    downloadWithdrawReceipt = "withdraw/download",
    rentalTransaction = "Rent/rentdistributions",
    rentalReceipt = "rent/rentalstatement",
    TFNnumber = "bank/tfn",
    notification = "Account/Notification",
    changePassword = "Account/changepassword",
    userProfile = "Account/personalinfo",
    profileImage = "account/updateprofile",
    payIdGenerate = "Deposit/generatePayID",
    payIdDetails = "Deposit/payid",
    depositRequest = "Deposit/request",
    depositVerifyRequest = "Deposit/verify",
    swapBricklet = "buysell/swap",
    titleTransfer = "NPDProperty/orderlist",
    initiatePendingOrder="NPDOrder/convertorder",
    sendDocMail="buysell/coownershipdocsignrequest",
    signPOAManually="buysell/poadoc",
    coOwnershipDocSign="buysell/coownershipdocsignrequest",
    existingOwners="property/propertyexistingownerdetails",
    withdrawaccountdetails="withdraw/accountdetails",
    updateentityname="account/updateentityname",



    //KYC
    userStatus = "Account/status",
    kycVerifyLevel1 = "account/kycverification",
    getKYCstatus = "kyc/status",
    getKYClevel2InstructionMail = "account/sendmekyc2instruction",
    verifyKycOrganisation = "KYC/organisation",
    KycUserType = "kyc/userType",
    KycVerifyStatus= 'kyc/submit',
    KycStepperDetail = "kyc/stepperdetails",
    IndividualKycPersonalInfo = "kyc/personalinfo",
    IndividualKycAddressInfo = "kyc/addressinfo",
    IndividualKycIdProof1 = "kyc/identityproof1",
    IndividualKycIdProof2 = "kyc/identityproof2",
    IndividualKycAddressProof = "kyc/addressproof",
    IndividualKycAcceptTC = "kyc/acceptterms",
    getKycStatusSingleAccount ="kyc/kycstatus",
    getKycPublicKey = "kyc/keys/public-key",
    KycKeyExchange = "kyc/keys/exchange",
    
    kyc2TandC = "kyc/level2",
    OrgKycEntityProfile = "kyc/entityprofile",
    OrgKycTrustee = "smsf/trustee",
    OrgKycUbo = "smsf/ubo",
    OrgKycSettlor = "smsf/settler",
    OrgKycAcceptTC = "kyc/orgacceptterms",
    kycTrustee = "kyc/trustee",
    kycSkipStep = "kyc/skip",
    resetKYC = "kyc/reset",
    bankTransferConfirmation = "Deposit/completbranktransfer",
    getKycBlob = "kyc/getkycproof",
    getProfileImageBlob = "account/getprofile",
    
    stepOneSMSF = "smsf/stepone",
    stepOnePrivateCompany = "kyc/PrivateCompanyEntity/stepone",
    stepOneSoleTrader = "kyc/SoleTraderEntity/stepone",
    stepOnePartnership = "kyc/partnershipentity/stepone",
    stepOnePublicCompany = "kyc/PublicCompanyEntity/stepone",
    stepOneRegTrust = "kyc/RegulatedTrustEntity/stepone",
    stepOneUnRegTrust = "kyc/UnRegulatedTrustEntity/stepone",

    stepTwoPrivateCompany = "kyc/PrivateCompanyEntity/steptwo",
    stepTwoPartnership = "kyc/partnershipentity/steptwo",
    stepTwoSoleTrader = "kyc/SoleTraderEntity/steptwo",
    stepTwoUnregulatedTrust = "kyc/UnRegulatedTrustEntity/steptwo",

    //Rapid ID
    initiateRapidId ="RapidID/sent",
    resendRapidId ="RapidID/resent",

    //ACCOUNTS
    accounts = "Account/accounts",
    switchAccount = "Account/switch",
    addViewer = "Account/viewer",
    getViewersList = "Account/viewers",
    deleteViewers = "Account/viewer",
    searchViewer = "Account/searchviewer",
    managers = "Account/managers",
    updateEmail = "account/emailchangerequest",
    verifyNewEmail = "account/verifynewemail",
    cancelUpdateEmail = "account/cancelemailrequest",

    //COMMUNITY
    newsFeed = "News/list",
    newsDetail = "News/details",
    commCategory = "community/categories",
    commSubCategory = "community/subcategories",
    articleBanner = "community/banner",
    articleRecommended = "community/recommendedforyou",
    articleTopStories = "community/topstories",
    articleInspiredSaved = "community/inspiredfromsavedlist",
    articlePopular = "community/popularrightnow",
    articleYouMightLike = "community/youmightlike",
    saveCommunity = "community/savedetails",
    unSaveCommunity = "community/removesaveddetails",
    getArticle = "community/readarticlebyguid",
    videoRecommended = "community/recommendedforyouvideos",
    videoTrendingProperties = "community/trendinginproperties",
    videoSuccessStory = "community/successstories",
    videoNewlyAdded = "community/newlyadded",
    communitySaved = "community/saveddetails",
    podcastBanner = "community/podcastbanner",
    podcast = "community/podcasts",
    podcastCategories = "community/podcastcategories",
    podcastInvestors = "community/curatedforinvestors",
    podcastNewlyAdded = "community/newlyaddedpodcasts",

    //NPD
    propertyList = "NPDCar/npdcarlist",
    propertyListWithLogin = "NPDCar/npdcarlist",
    propertyDetails = "NPDCar/npdcardetails",
    isLoggedPropertyDetails = "NPDCar/npdcardetails",
    marketPrice = "NPDCar/npdcarmarketprice",
    guestCart = "npdcart",
    cart = "cart",
    order = "order",
    cartDetails = 'cart/detail',
    registerNPD = "NPDProperty/registerwithplaceorder",
    placeOrder = "NPDProperty/placeorders",
    NPDTitleTransfer = "NPDProperty/myprovisionalinvestmentlist",
    loginNPD = "NPDAccount/login",
    buyDocSignInitiate = 'BuySell/buydocsigninitiate',
    signDeedDocument = 'BuySell/coownershipdocsignrequest',
    buySellTransaction = 'BuySell/transaction',
    contact = 'contact-us',
    orderstatus = 'order/status',
    pendingOrders = 'dashboard/car-order/pending',
    pendingOrdersGuid = 'dashboard/cart',
    pendingOrderId = 'order',
    completeOrders = 'dashboard/car-order/complete',
    checkoutAPI = 'checkout',
    portpolio = 'account/portfolio',


    //signalR Notify URL
    notify = 'notify'
}

export enum Storage {
    IS_LOGGED = 'is_logged',
    AUTH_TOKEN = 'auth_token',
    REFRESH_TOKEN = 'refresh_token',
    FULL_NAME = 'full_name',
    ROLE = 'role',
    USER_TYPE = 'user_type',
    IS_ACCOUNT_SELECTED = 'is_account_selected',
    ACCOUNT_ID = 'accountGuid',
    INITIAL_KYC_STATUS = 'initial_kyc_status',
    IS_POA_COMPLETED = 'is_poa_completed',
    USER_ROLE="user_role",
    PURCHASE_PENDING = "purchase_pending",
    PAID = "paid",
    PURCHASE_COMPLETED = "purchase_completed",
    BASIC_FLOW = "basic_flow"
}

<div class="container property-section">
    <h3 style="text-align: center;">{{ "FTR.terms&conditions" | translate }}</h3>
    <p>
        Welcome to InvCar, the web marketplace for buying and selling shares of rental cars. These Terms and Conditions
        of Use ("Terms") govern your use of InvCar's platform, including its website and any mobile applications
        (collectively, the "Platform").
    </p>
    <p>
        By accessing or using the Platform, you agree to these Terms, as well as our Privacy Policy, which is
        incorporated by reference into these Terms. If you do not agree to these Terms, please do not access or use the
        Platform.
    </p>

    <ul>
        <li>Eligibility To use the Platform, you must be at least 18 years of age and legally able to enter into
            contracts in your jurisdiction. Additionally, you must complete the Know Your Customer ("KYC") verification
            process to be able to deposit funds and purchase shares on the Platform.
        </li>

        <li>Registration and Account Security To use certain features of the Platform, you may need to register for an
            account. You agree to provide accurate and complete information when registering for an account and to keep
            your account information and login details secure. You are solely responsible for all activity that occurs
            under your account.</li>

        <li>Ownership of Shares Purchasing shares on the Platform represents ownership of a percentage of the rental
            revenue generated by the rental cars listed on the Platform, but does not grant ownership of the rental cars
            themselves.
        </li>

        <li>Investment Risks Investing in shares carries inherent risks, and you should seek independent financial
            advice before investing. The Platform does not guarantee any returns on investments and is not responsible
            for any losses or damages incurred by you.
        </li>

        <li>Liability InvCar is not responsible for any damages resulting from user error or misuse of the Platform.
            InvCar's liability for any losses or damages incurred by you is limited to the amount of the transaction
            that gave rise to the claim.</li>

        <li>Governing Law These Terms are governed by the laws of the United States and Australia, and any disputes will
            be resolved in accordance with those laws.
        </li>

        <li>Modifications InvCar reserves the right to modify these Terms at any time, and any changes will be effective
            immediately upon posting on the Platform. Your continued use of the Platform constitutes acceptance of the
            updated Terms.
        </li>

        <li>Termination InvCar may terminate or suspend your account at any time for any reason, including without
            limitation, violation of these Terms or illegal activity on the Platform.</li>

        <li>Intellectual Property All intellectual property rights related to the Platform belong to InvCar. You may not
            use the Platform's content or technology for any unauthorized purposes.
        </li>

        <li>General Provisions These Terms constitute the entire agreement between you and InvCar and govern your use of
            the Platform. If any provision of these Terms is found to be invalid or unenforceable, the remaining
            provisions shall remain in full force and effect. InvCar's failure to enforce any right or provision of
            these Terms shall not constitute a waiver of such right or provision.
        </li>

    </ul>


</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-account-dashboard',
  templateUrl: './no-account-dashboard.component.html',
  styleUrls: ['./no-account-dashboard.component.scss']
})
export class NoAccountDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChange,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import { LookupService } from "src/app/core/services/lookup.service";
import { HelperService } from "src/app/core/utils/helper.service";

@Component({
  selector: 'app-public-company',
  templateUrl: './public-company.component.html',
  styleUrls: ['./public-company.component.scss']
})
export class PublicCompanyComponent implements OnInit, OnChanges {
  entitySubForm: FormGroup = new FormGroup({
    ABN: new FormControl(""),
    ACN: new FormControl("", [Validators.required]),
    CompanyName: new FormControl("", [Validators.required]),
    ID_Exchange: new FormControl("", [Validators.required]),
  });
  isFormValid = false;
  @Output() publicFormData: EventEmitter<any> = new EventEmitter();
  @Input() storedData: any;

  exchangeList: [
    {
      id: number;
      description: string;
    }
  ] = null;
  constructor(
    private lookupService: LookupService,
    private cs: HelperService
  ) {}

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.storedData) {
      this.assignValue(changes.storedData.currentValue);
    }
  }

  ngOnInit() {
    this.lookupService
      .getExchangeTypes()
      .pipe(
        map((s: any) => {
          if (s) {
            return s.data.entityTypes;
          }
        })
      )
      .subscribe(
        (r) => {
          this.exchangeList = r;
        },
        (e) => {
          this.cs.error("Unable to retrieve Exchange Types");
        }
      );

    // if (this.storedData) {
    //   this.assignValue(this.storedData);
    // }

    this.checkFormValidation();
    this.assignValue(this.storedData);
  }

  checkFormValidation() {
    this.entitySubForm.statusChanges.subscribe((status) => {
      if (status === "VALID") {
        this.isFormValid = true;
        this.publicFormData.emit(this.entitySubForm.value);
      } else {
        if (this.isFormValid) {
          this.isFormValid = false;
          this.publicFormData.emit(null);
        }
      }
    });
  }

  getValue(control: string) {
    return this.entitySubForm.controls[control].value;
  }

  canShowError(control: string) {
    const field = this.entitySubForm.controls[control];
    return !field.valid && field.touched && field.dirty;
  }

  hasError(control: string, error: string) {
    return this.entitySubForm.controls[control].hasError(error);
  }

  assignValue(storedData: any) {
    if (storedData) {
      this.entitySubForm.setValue({
        ABN: storedData.abn,
        ACN: storedData.acn,
        CompanyName: storedData.companyName,
        ID_Exchange: storedData.iD_Exchange,
      });
    }
  }
}

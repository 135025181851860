
<div>

  <div class="container-fluid" >
    <div class="row "> <!-- Center align the entire row -->
      <!-- Left side column -->
      <div class="col-md-7 custom-background">
        <div class="row justify-content-center align-items-center  "> <!-- Updated here -->
          <div class="col-12 mt-5 marginleft_head"> <!-- Updated here -->
            <a href="#">
              <img src="../../../../assets/InvCar_Logo.svg" alt="InvCar Logo">
  
          
            </a>
          </div>
          <div class="col-12 mt-5 marginleft_content"> 
            <!-- <img class="mt-5" src="../../../../assets/invset_car_text.png" alt="InvCar Logo"><br>
            <img class="mt-5" src="../../../../assets/car_text.png" alt="InvCar Logo"> -->
             
            <span class="contents">INVEST IN CARS <BR>IN BETTER WAY</span>
            <p class="invDetails mt-2">INVCAR helps you grow your income by <BR> allowing you to invest in rental vehicles</p>
    <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;</p>
          </div>
        </div>
      </div>
    
      <!-- Right side column -->
      <div class="col-md-5">
        <!-- <button class="btn btn-danger close-button"  routerLink="/marketplace/list">
          <i class="fa fa-times"></i> 
        </button> -->
        <div id="sign-in-box" *ngIf="showSignInForm">
          <h2 class="secondary-bold wp-charcoal f40">
            {{ "LGN.login" | translate }}
          </h2>
          <span>{{ 'LGN.donothaveanaccount' | translate }} <a routerLink="/signup">{{ 'LGN.clickhere' | translate }}</a></span>
          <form [formGroup]="signInForm" class="mt-4" autocomplete="off">
            <div class="form-group">
              <label for="email" class="form-label">{{ "LGN.email" | translate }}</label>
              <input type="email" placeholder="{{ 'LGN.enteremailaddress' | translate }}" formControlName="email" class="form-control" id="email" autocomplete="off">
              <div *ngIf="helper.canShowError(signInForm, 'email')">
                <p class="form-error" *ngIf="helper.hasError(signInForm, 'email', 'required')">{{ "LGN.emailisrequired" | translate }}</p>
                <p class="form-error" *ngIf="helper.hasError(signInForm, 'email', 'email')">{{ "LGN.invalidemail" | translate }}</p>
              </div>
            </div>
            <div class="form-group position-relative">
              <label for="password" class="form-label">{{ "LGN.password" | translate }}</label>
              <div class="input-group"> <!-- Wrap input and eye icons in a group -->
                  <input [type]="showPassword ? 'text' : 'password'" placeholder="{{ 'LGN.enterpassword' | translate }}" formControlName="password" class="form-control" id="password" autocomplete="off">
                  <span class="input-group-addon eye-icon" (click)="showPassword = !showPassword">
                      <i class="fas" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i> <!-- Adjusted ngClass for eye icon -->
                  </span>
              </div>
              <p class="form-error" *ngIf="helper.canShowError(signInForm, 'password') && helper.hasError(signInForm, 'password', 'required')">{{ "LGN.passwordisrequired" | translate }}</p>
          </div>
            <div class="form-group">
              <button class="btn btn-primary w-100 mt-2" (click)="onSubmit()" [disabled]="!signInForm.valid">{{ "LGN.login" | translate }}</button>
            </div>
            <div class="form-group d-flex flex-column align-items-center justify-content-center">
              <label class="f16 generic-palette-b-w-gray primary-regular">{{ "LGN.didyouforgotyourpassword" | translate }}
                <a class="water-blue primary-bold c-pointer" (click)="showSignInForm = false">{{ "LGN.clickhere" | translate }}</a>
              </label>
            </div>
          </form>
        </div>

        <div id="forgot-box"   *ngIf="!showSignInForm">
          <h2 class="secondary-bold wp-charcoal f40">
            {{ "FPWD.forgotpassword" | translate }}
          </h2>
          <div
            class="mt-4 secondary-bold f14 ng-star-inserted text-center row flex-column align-items-center justify-content-center"
            *ngIf="isCheckMail"
          >
            <p class="text-center col-lg-8 col-md-10 col-12">
              An email has been sent to you with a link to reset your password.
              <br />
              <br />
              If you have any further issues with your account, please feel free to
              contact us on
              <bdi class="wp-yellow">help@invcar.com</bdi>
            </p>
        
            <p class="f16 generic-palette-b-w-gray primary-regular">
              {{ "FPWD.rememberitnow" | translate }}
              <a
                class="water-blue primary-bold c-pointer"
                (click)="showSignInForm = true"
              >
                {{ "FPWD.login" | translate }}</a
              >
            </p>
        
            <button
              class="btn btn-primary outlined col-3 mt-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              (click)="showSignInForm = true"
            >
              Close
            </button>
          </div>
          <form
            *ngIf="!isCheckMail"
            [formGroup]="forgotForm"
            class="row col-12 mt-4 mx-0"
            autocomplete="off"
          >
            <div class="form-group col-12">
              <label for="email" class="form-label">{{
                "FPWD.email" | translate
              }}</label>
              <input
                type="email"
                formControlName="email"
                class="form-control"
                id="email"
                autocomplete="off"
                placeholder="{{ 'FPWD.enteremailaddress' | translate }}"
                [readonly]="isCheckMail"
              />
            </div>
        
            <div
              class="form-group col-12 d-flex flex-column align-items-center justify-content-center"
            >
              <label class="f16 generic-palette-b-w-gray primary-regular"
                >{{ "FPWD.rememberitnow" | translate }}
                <a
                  class="water-blue primary-bold c-pointer"
                  (click)="showSignInForm = true"
                  >{{ "FPWD.login" | translate }}</a
                ></label
              >
            </div>
            <div class="form-group col-12">
              <div class="row">
                <div class="col-6">
                  <button
                    class="btn btn-primary w-100 mt-2"
                    (click)="onRequestLink()"
                    [disabled]="!forgotForm.valid"
                  >
                    Reset Password
                  </button>
                </div>
                <div class="col-6">
                  <button
                    class="btn btn-primary outlined w-100 mt-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    (click)="showSignInForm = true"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
             </div>
        
    
  
   
      </div>
    </div>
  </div>
  

</div>






<div class="modal fade" id="createAccount">
  <div class="modal-dialog">
      <div class="modal-content">
          <!-- <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
          <div class="modal-body">
              <app-sign-up></app-sign-up>
          </div>
      </div>
  </div>
</div>
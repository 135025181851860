<form [formGroup]="entitySubForm">
    <div class="row w-100">
        <div class="col-6 form-group">
            <label for="" class="form-label required">ACN</label>
            <input type="text" formControlName="ACN" class="form-control">
            <div *ngIf="canShowError('ACN')">
                <p class="error-txt" *ngIf="hasError('ACN','required')">ACN is required</p>
            </div>
        </div>
        <div class="col-6 form-group">
            <label for="" class="form-label">ABN</label>
            <input type="text" formControlName="ABN" class="form-control">
        </div>
        <div class="col-6 form-group">
            <label for="" class="form-label required">Company Name</label>
            <input type="text" formControlName="CompanyName" class="form-control">
            <div *ngIf="canShowError('CompanyName')">
                <p class="error-txt" *ngIf="hasError('CompanyName','required')">Company Name is required</p>
            </div>
        </div>
        <div class="col-6 form-group">
            <label for="" class="form-label required">Exchange listed on</label>
            <select formControlName="ID_Exchange" class="form-control">
                <option value="">Choose </option>
                <option *ngFor="let c of exchangeList" [value]="c.id">{{c.description}}</option>
            </select>
            <div *ngIf="canShowError('ID_Exchange')">
                <p class="error-txt" *ngIf="hasError('ID_Exchange','required')">Exchange listed on is required</p>
            </div>
        </div>
    </div>
</form>
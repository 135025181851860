import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class PodcastService {

  private FloatingWindowEvent = new BehaviorSubject(null);
  public getFloatingWindowDetails = this.FloatingWindowEvent.asObservable();

  constructor(
    private apiManager: ApiManagerService
  ) { }

  updateFloatingWindow(data: any) {
    this.FloatingWindowEvent.next(data);
  }

  getPodcastBanner(stateId: string) {
    return this.apiManager.getRequestQueryParam(RestEnds.podcastBanner, 'stateGUID=' + stateId);
  }

  getPodcast(data: any) {
    return this.apiManager.postRequest(RestEnds.podcast, data);
  }

  getPodcastCategories() {
    return this.apiManager.getRequest(RestEnds.podcastCategories, null);
  }

  getPodcastInvestors(data: any) {
    return this.apiManager.postRequest(RestEnds.podcastInvestors, data);
  }

  getPodcastNewlyAdded(data: any) {
    return this.apiManager.postRequest(RestEnds.podcastNewlyAdded, data);
  }
}

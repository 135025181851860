<div [formGroup]="form">
    <label [attr.for]="formField.key">{{formField.label}}</label>

    <div [ngSwitch]="formField.controlType">

        <input *ngSwitchCase="'textbox'" [formControlName]="formField.key" [id]="formField.key" [type]="formField.type">

        <select [id]="formField.key" *ngSwitchCase="'dropdown'" [formControlName]="formField.key">
            <option *ngFor="let opt of formField.options" [value]="opt.key">{{opt.value}}</option>
        </select>

    </div>

    <div class="errorMessage" *ngIf="!isValid">{{formField.label}} is required</div>
</div>
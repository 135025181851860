export class StaticMessage {
    public static readonly amountReqMsg = "Amount is required";
    public static readonly invalidAmountMsg = "Invalid amount";
  
    // tslint:disable-next-line: max-line-length
    public static readonly passwordPattern =
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
    public static readonly curPwdReq = "Current password is required";
    public static readonly newPwdReq = "New password is required";
    public static readonly rePwdReq = "Confirm password is required";
  
    // tslint:disable-next-line: max-line-length
    public static readonly pwdPatternAlert =
      "Password should contain atleast 8 characters including Uppercase, special characters and numbers";
    public static readonly pwdMismatchAlert = "Mismatch password";
    //public static readonly passportNumberPattern = '^([a-zA-Z]{1,2})([0-9]{7})';
    //public static readonly passportNumberPattern = '^([a-z A-Z 0-9]{1,2})([0-9]{6,9})';
    public static readonly passportNumberPattern = "^([a-z A-Z 0-9]{1,10})";
    
  }
  
  export const ADDRESS_PROOF_LIST = [
    {
      label: "Bank Statement",
      value: "BANK_STATEMENT",
    },
    {
      label: "Water Bill",
      value: "WATER_BILL",
    },
    {
      label: "Electricity Bill",
      value: "ELECTRICITY_BILL",
    },
    {
      label: "Gas Bill",
      value: "GAS_BILL",
    },
    {
      label: "Credit card Bill",
      value: "CREDIT_CARD_BILL",
    },
  ];
  
  export class StepperRoutePaths {
    public static readonly verificationType = "/kyc";
    public static readonly orgEntity = "/kyc/org-landing";
    public static readonly personalInfo = "/kyc/individual/personal-information";
    public static readonly addressInfo = "/kyc/individual/address-information";
    public static readonly idProof1 = "/kyc/individual/id-proof-1";
    public static readonly idProof2 = "/kyc/individual/id-proof-2";
    public static readonly addressProof = "/kyc/individual/address-proof";
    public static readonly KYC1termsAndConditions = "/kyc/individual/tc";
    public static readonly KYC2termsAndConditions = "/kyc/tc2";
    public static readonly orgPending = "/kyc/org-pending";
    public static readonly individualPending = "/kyc/ind-pending";
    public static readonly rejected = "/kyc/reject";
    public static readonly success = "/kyc/success";
    public static readonly entityProfile = "/kyc/org/entity-list";
    public static readonly trustee = "/kyc/org/trustee";
    public static readonly ubo = "/kyc/org/ubo";
    public static readonly settlor = "/kyc/org/settlor";
    public static readonly saveAndExit = "/dashboard";
  }
  
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

  @Input() imageSrc;
  @Input() description;
  @Input() navigateLink;
  @Input() navigateText;

  constructor() { }

  ngOnInit(): void {
    if (!this.imageSrc) {
      this.imageSrc = '/assets/images/empty-state.svg';
    }
    if(!this.description){
      this.description = 'No records found...';
    }
    if(!this.navigateText){
      this.navigateText = 'Add record';
    }
  }

}

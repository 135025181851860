<div class="container property-section">
    <div class="content-section">

    <h2>OUR PARTNERS</h2>
    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec
        urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet
        viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse
        potenti.</p>

    <p>Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero eu augue. Morbi purus
        libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum hendrerit tortor. Sed
        semper lorem at felis. Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc
        sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus sed, urna.</p>
        <br>
    <div class="row">
        <div class="col-4 col-sm-6 col-sm-6 col-lg-4">
            <img src="../../../assets/images/partners/Lakeba logo.png">
        </div>
        <div class="col-4 col-sm-6 col-sm-6 col-lg-4">
            <img src="../../../assets/images/partners/Bricklet logo.png">
        </div>
    </div>
</div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investment-card',
  templateUrl: './investment-card.component.html',
  styleUrls: ['./investment-card.component.scss']
})
export class InvestmentCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PropertyService } from 'src/app/core/services/property.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.required, Validators.minLength(10),
      Validators.pattern("^[1-9][0-9]*$")]),
    emailAddr: new FormControl('', [Validators.required, Validators.pattern(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )]),
    invcarCustomer: new FormControl('yes', Validators.required),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),

  })

  get firstname() {
    return this.contactForm.get('firstName')
  }
  get lastname() {
    return this.contactForm.get('lastName')
  }
  get mobile() {
    return this.contactForm.get('mobile')
  }
  get email() {
    return this.contactForm.get('emailAddr')
  }
  get invcarcustomer() {
    return this.contactForm.get('invcarCustomer')
  }
  get subject() {
    return this.contactForm.get('subject')
  }
  get message() {
    return this.contactForm.get('message')
  }

  constructor(private propertyService: PropertyService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService) { }

  ngOnInit(): void {

  }

  onlyNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}


  contactSubmit() {
    this.spinner.show();
    let data = this.contactForm.value
      this.propertyService.getContactFormData(data).subscribe(
        (s: any) => {
          this.spinner.hide();
          // this.toaster.success(s.data.message);
          this.toaster.success("Thanks for submitting, our support team will contact you shortly");
          this.contactForm.reset();

        },
        (e: string) => {
          this.toaster.error(e);
          this.spinner.hide();
        }
      );
    }
  }





<!-- <div class="filter-box">
    <div class="mp-filter desktop tablet mobile sm-mobile">
        <label class="label">Properties by</label>
        <div class="prop-type">
            <label (click)="filterData.IsOwnedPropertyList=false;filterData.isWatchList=false;sendFilterData()"
                [ngClass]="{'active': !filterData.IsOwnedPropertyList && !filterData.isWatchList}">All</label>
            <label (click)="filterData.IsOwnedPropertyList=false;filterData.isWatchList=true;sendFilterData()"
                [ngClass]="{'active': filterData.isWatchList}">Watchlist</label>
            <label (click)="filterData.IsOwnedPropertyList=true;filterData.isWatchList=false;sendFilterData()"
                [ngClass]="{'active': filterData.IsOwnedPropertyList}">Owned</label>
        </div>
    </div>

    <div class="mp-filter desktop">
        <div class="checkbox-container" id="tc1">
            <label [ngClass]="{'checkbox-label':true, 'active':filterData.isBrickletsForSale }">
                On Sale
                <input type="checkbox" [(ngModel)]="filterData.isBrickletsForSale"
                    (click)="filterData.isBrickletsForSale=!filterData.isBrickletsForSale;sendFilterData()" />
                <span class="checkmark"></span>
            </label>
        </div>
    </div>

    <div class="mp-filter desktop">
        <div class="checkbox-container" id="tc2">
            <label [ngClass]="{'checkbox-label':true, 'active':filterData.isBPPPropertyList }">
                On BPP
                <input type="checkbox" [(ngModel)]="filterData.isBPPPropertyList"
                    (click)="filterData.isBPPPropertyList=!filterData.isBPPPropertyList;sendFilterData()" />
                <span class="checkmark"></span>
            </label>
        </div>
    </div>

    <div class="mp-filter tablet mobile">
        <div [ngClass]="{'fil-icon box-padding':true, 'active':filterData.isBPPPropertyList}"
            (click)="filterData.isBPPPropertyList=!filterData.isBPPPropertyList; sendFilterData();">
            <img src="/assets/images/bpp-icon.svg" alt="On BPP">
        </div>
    </div>

    <div class="mp-filter tablet mobile">
        <div [ngClass]="{'fil-icon box-padding':true, 'active':filterData.isBrickletsForSale}"
            (click)="filterData.isBrickletsForSale=!filterData.isBrickletsForSale; sendFilterData();">
            <img src="/assets/images/for-sale-icon.svg" alt="For sale">
        </div>
    </div>

    <div class="mp-filter tablet mobile">
        <div class="fil-icon box-padding" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false"
            aria-controls="collapseFilter">
            <img src="/assets/images/filter-icon.svg" alt="Filter">
        </div>
    </div>

    <div class="mp-filter mobile">
        <div class="fil-icon box-padding" data-bs-toggle="collapse" data-bs-target="#collapseSearch" aria-expanded="false"
            aria-controls="collapseSearch">
            <img src="/assets/images/search.svg" alt="Search">
        </div>
    </div>

    <div class="mp-filter sm-mobile">
        <div class="mp-filter tablet mobile">
            <div [ngClass]="{'fil-icon':true, 'active':filterData.isBrickletsForSale}"
                (click)="filterData.isBrickletsForSale=!filterData.isBrickletsForSale; sendFilterData();">
                <img src="/assets/images/for-sale-icon.svg" alt="For sale">
            </div>
        </div>
    
        <div class="mp-filter tablet mobile">
            <div class="fil-icon" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false"
                aria-controls="collapseFilter">
                <img src="/assets/images/filter-icon.svg" alt="Filter">
            </div>
        </div>
    
        <div class="mp-filter mobile">
            <div class="fil-icon" data-bs-toggle="collapse" data-bs-target="#collapseSearch" aria-expanded="false"
                aria-controls="collapseSearch">
                <img src="/assets/images/search.svg" alt="Search">
            </div>
        </div>
    </div>



    <div id="location-desktop" class="mp-filter desktop">
        <label for="location" class="label">Location</label>
        <select class="form-control label-value" id="location" #location (change)="stateChange()"
            [(ngModel)]="filterData.stateGUID">
            <option value="">All Australia</option>
            <option *ngFor="let s of stateList" [value]="s.stateGUID">{{s.name}}</option>
        </select>
    </div>

    <div class="mp-filter custom-slider desktop">
        <label for="range" class="label">Price Range</label>
        <div class="box-padding">
            <ng5-slider (userChangeEnd)="priceChange($event)" [(value)]="value" [(highValue)]="highValue"
                [options]="options"></ng5-slider>
        </div>
    </div>
    <div class="mp-filter desktop">
        <label for="order" class="label">Sort By</label>
        <div class="dropdown">
            <select class="form-control label-value" id="order" #order [(ngModel)]="filterData.sortyBy"
                (change)="orderByChange()">
                <option value="newest">Newest first</option>
                <option value="lowprice">Price - Low</option>
                <option value="highprice">Price - High</option>
            </select>
        </div>
    </div>
    <div class="mp-filter desktop tablet box-padding">
        <div class="search-img my-auto">
            <input #propertySearchInput type="text" class="form-control search" autocomplete="off" placeholder="Search"
                [(ngModel)]="filterData.search">
            <img for="search" src="../../../../assets/images/search.svg" alt="">
        </div>
    </div>


    <div class="col-12 filter-box p-0" id="filter-accordion">
        <div id="collapseFilter" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#filter-accordion">
            <div class="accordion-body">
                <div class="col-md-4 col-12 form-group pb-2">
                    <label for="location" class="label">Location</label>
                    <div class="dropdown">
                        <select class="form-control label-value mobile-panel" id="location" #location (change)="stateChange()"
                            [(ngModel)]="filterData.stateGUID">
                            <option value="">All Australia</option>
                            <option *ngFor="let s of stateList" [value]="s.stateGUID">{{s.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4 col-12 form-group custom-slider pb-3">
                    <label for="range" class="label pb-4">Price Range</label>
                    <ng5-slider (userChangeEnd)="priceChange($event)" [(value)]="value" [(highValue)]="highValue"
                        [options]="options"></ng5-slider>
                </div>
                <div class="col-md-4 col-12 form-group pb-2">
                    <label for="order" class="label">Sort By</label>
                    <div class="dropdown">
                        <select class="form-control label-value mobile-panel" id="order" #order [(ngModel)]="filterData.sortyBy"
                            (change)="orderByChange()">
                            <option value="newest">Newest first</option>
                            <option value="lowprice">Price - Low</option>
                            <option value="highprice">Price - High</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div id="collapseSearch" class="accordion-collapse collapse" aria-labelledby="headingSearch"
            data-bs-parent="#filter-accordion">
            <div class="accordion-body">
                <div class="col-12 form-group my-auto search-img pb-2">
                    <input #propertySearchInputMobile type="text" class="form-control search mobile-panel" autocomplete="off"
                        placeholder="Search" [(ngModel)]="filterData.search">
                    <img for="search" src="../../../../assets/images/search.svg" alt="">
                </div>
            </div>
        </div>

    </div>

</div> -->

<div class="property-search">
    <div class="container ">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12 m-auto">
                <h1 class="text-center wp-charcoal" >{{ 'MKP.buypropertyfragments' | translate }}</h1>
                <form>
                    <div class="search-form tooltip-example">
                        <!-- <input type="text" name="search-input" class="search-input" #propertySearchInput
                        i18n="@@PLB-Searchbyregion" placeholder="{{ 'MKP.searchbyregion' | translate }}" data-bs-placement="bottom"
                            title="Please fill out this field" [(ngModel)]="filterData.search">
                        <button   class="btn btn-primary btn-search" (click)="sendFilterData()">
                            {{ 'MKP.search' | translate }}
                        </button> -->
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
<!-- <div id="no-investor" class="bg-white d-flex flex-column align-items-center justify-content-center">
    <p class="text-center primary-semi-bold f30 waav-dark-main col-lg-6 col-md-8 col-sm-12">
        Please, create an investor profile
        to unlock these features
    </p>
    <div class="row col-xl-3 col-lg-3 col-md-4 col-sm-10 col-xs-12">
        <a routerLink="/kyc">
            <button class="btn btn-primary w-100">
                Create Now
            </button>
        </a>
    </div>
</div> -->

<div class="row justify-content-center bg-white" id="no-investor" >
    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-12 box">
        <!-- <h2 class="primary-bold f30 waav0dark-main">
            {{ 'UNL.createaninvestorprofile' | translate }}
        </h2>
        <p class="primary-regular f18 generic-palette-b-w-gray">
            {{ 'UNL.aninvestorprofileisanoutline' | translate }}
        </p>
        <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12">
                <p class="primary-bold f20 waav-dark-main">
                    {{ 'UNL.benefitsofaninvestorprofile' | translate }}</p>
                <ul>
                    <li>
                        <img src="/assets/images/icon-tick.svg" class="icon-tick" alt="">
                        <span class="primary-regular f16 tertiary-dark">
                            {{ 'UNL.buyandsellbricklets' | translate }}
                            </span>
                    </li>
                    <li>
                        <img src="/assets/images/icon-tick.svg" class="icon-tick" alt="">
                        <span class="primary-regular f16 tertiary-dark">
                            {{ 'UNL.earnrentalincomeonyourbricklets' | translate }}
                            </span>
                    </li>
                    <li>
                        <img src="/assets/images/icon-tick.svg" class="icon-tick" alt="">
                        <span class="primary-regular f16 tertiary-dark">
                            {{ 'UNL.withdrawmoneydirectlyintoyourbankaccount' | translate }}</span>
                    </li>
                    <li>
                        <img src="/assets/images/icon-tick.svg" class="icon-tick" alt="">
                        <span class="primary-regular f16 tertiary-dark">
                            {{ 'UNL.investinpropertywithlessthan' | translate }}
                            </span>
                    </li>
                </ul>
                <div class="row mt-5">
                    <div class="col-xl-5 col-lg-5 col-md-8 col-sm-10 col-xs-12" >
                        <button class="btn btn-primary w-100" (click)="openRapidIdDialog()">
                            {{ 'UNL.getstarted' | translate }}
                        </button>
                    </div>
                </div>

            </div>
            <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                <img src="/assets/images/dashboard-screenshot.png" alt="" class="screenshot">
            </div>
        </div> -->
        <!-- <p> -->
            <h2 class="primary-bold f30 waav0dark-main">
                Next Step: Verify 
            </h2>
            <br>
            The next step to accessing all the benefits of the platform is to complete your ID check by clicking the “Verify My ID” button.
            An SMS will be sent to your phone to start the process.
        <!-- </p> -->
    </div>
</div>
<form [formGroup]="medicareForm">
    <div class="row">

        <div class="col-12">
            <div class="row">
                <div class="form-group col-12">
                    <label for="MedicardCardNo" class="form-label required">Card Number</label>
                    <input type="text" NumbersOnly [allowDecimals]="false" [allowSign]="false"
                        formControlName="MedicardCardNo" class="form-control" />
                    <div *ngIf="canShowError('MedicardCardNo')">
                        <p class="error-txt" *ngIf="hasError('MedicardCardNo','required')">Card Number
                            is required
                        </p>
                        <p class="error-txt" *ngIf="hasError('MedicardCardNo','minlength')">
                            Card Number should be 10 digit
                        </p>
                    </div>
                </div>
                <div class="form-group col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <label for="MedicardFullName" class="form-label required">Full name</label>
                    <input type="text" formControlName="MedicardFullName" class="form-control" />
                    <div *ngIf="canShowError('MedicardFullName')">
                        <p class="error-txt" *ngIf="hasError('MedicardFullName','required')">Full name
                            is required
                        </p>
                    </div>
                </div>
                <div class="form-group col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <label for="MedicardReferenceNo" class="form-label required">Individual No.</label>
                    <input type="text" formControlName="MedicardReferenceNo" class="form-control" />
                    <div *ngIf="canShowError('MedicardReferenceNo')">
                        <p class="error-txt" *ngIf="hasError('MedicardReferenceNo','required')">Individual No
                            is required
                        </p>
                    </div>
                </div>
                <div class="form-group  col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <label for="MedicardCardColor" class="form-label required">Card Color</label>

                    <select class="form-control" formControlName="MedicardCardColor">
                        <option value="">Select...</option>
                        <option *ngFor="let list of medicareCardColorList" [value]="list.value">{{list.label}}</option>
                    </select>

                    <div *ngIf="canShowError('MedicardCardColor')">
                        <p class="error-txt" *ngIf="hasError('MedicardCardColor','required')">Card color
                            is required
                        </p>
                    </div>
                </div>

                <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <label for="Date of Birth" class="form-label required">Expiry Date</label>
                    <div class="date-info">
                        <input #dt type="text" formControlName="MedicardExpiryDate" class="form-control" tabindex="4"
                            bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                            [bsValue]="getValue('MedicardExpiryDate')" readonly placeholder="expiry date" />
                        <img for="dt" src="../../../assets/images/calendar-icons/calendar.svg" id="date-img" alt=""
                            (click)="dt.click()" />
                    </div>
                    <!-- <div class="w-100 position-relative">
                        <input type="text" formControlName="MedicardExpiryDate" class="form-control" tabindex="4" bsDatepicker
                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" [bsValue]="getValue('MedicardExpiryDate')" readonly />
                        <img src="assets/images/date.svg" id="date-img" alt="">
                    </div> -->
                    <div *ngIf="canShowError('MedicardExpiryDate')">
                        <p class="error-txt" *ngIf="hasError('MedicardExpiryDate','required')">Expiry date is required
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="form-group col-12">
                    <label for="" class="form-label required">Medicare card (front)</label>
                    <app-img-upload [sendFiles]="sendFiles" (getFiles)="getFiles($event)"></app-img-upload>
                </div>
            </div>
        </div>

    </div>
</form>
<!-- <div class="row justify-content-end mt-4 mb-5 me-3"> -->
<div
    [ngClass]="{'row justify-content-end align-items-center': true, 'bg-waav-bright-light':true, 'fixed-stepper': true}">
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6 py-2">
        <button [disabled]="!stepperFormInfo?.isFormValid" (click)="save(saveTypes.saveOnly)"
            class="btn-primary outlined w-100"> Save & exit</button>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6 py-2">
        <button [disabled]="!stepperFormInfo?.isFormValid" (click)="save(saveTypes.saveAndNext)"
            class="btn-primary w-100"> Next</button>
    </div>
</div>
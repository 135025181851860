<div id="mySidenav" class="sidenav">
  <ul class="navbar-nav me-auto  mb-2 mb-lg-0 navbar-desktop">
    <ng-container *ngFor="let tab of tabs">
      <ng-container *ngIf="(tab.logged && isLogged) || (!tab.logged) ">
        <li class="nav-item">

          <a class="nav-link" [routerLink]="tab.link" routerLinkActive="active" *ngIf="tab.title !== 'WHY_INVCAR'" (click)="submenuHide()"
            #rla="routerLinkActive">
            <!-- <img [src]="rla.isActive?path+tab.img+'-active.svg':path+tab.img+'-inactive.svg'"
                          alt=""> -->
                 <img class="menuIcons" [src]="rla.isActive?path+tab.img+'-inactive.svg':path+tab.img+'-inactive.svg'"
                          alt="">
            <span class="secondary-regular" [class.active]="rla.isActive">{{ ('HDR.' + tab.title ) | translate }}</span>
            &nbsp;<div class="line" *ngIf="rla.isActive && tab.title !== 'DISTRIBUTORS'"></div>
            &nbsp;<div class="Dline" *ngIf="rla.isActive && tab.title === 'DISTRIBUTORS'"></div>
          </a>

          <a class="nav-link" [routerLink]="tab.link" routerLinkActive="active" (click)="submenuShow()"
            *ngIf="tab.title === 'WHY_INVCAR'" #rla="routerLinkActive">
            <span class="secondary-regular activeMenu" *ngIf="isShown">{{ ('HDR.' + tab.title ) | translate }}</span>
            <span class="secondary-regular" *ngIf="!isShown">{{ ('HDR.' + tab.title ) | translate }}</span>

            <!-- &nbsp;<div class="line" *ngIf="rla.isActive"></div> -->
          </a>


          <ul class="navbar-nav me-auto  mb-2 mb-lg-0 navbar-desktop" *ngIf="isShown && (tab.title === 'WHY_INVCAR')">
            <ng-container *ngFor="let subMenu of collections">
              <li class="nav-item">
                <a class="nav-link subMenuSideNav" [routerLink]="subMenu.link" routerLinkActive="active" #rlaMenu="routerLinkActive">
                  <img class="submenuIcons" [src]="rlaMenu.isActive?path+subMenu.img+'-inactive.svg':path+subMenu.img+'-inactive.svg'"
                          alt="">
                  <span class="secondary-regular" [class.active]="rlaMenu.isActive">{{ (subMenu.title ) }}</span>
                  &nbsp;<div class="line" *ngIf="rlaMenu.isActive"></div>
                </a>
              </li>
            </ng-container>
          </ul>
        </li>

      </ng-container>
    </ng-container>
  </ul>
  <div class="bottom-section">
  <!-- <span class="left"><a style="font-size:11px;" [routerLink]="['/terms_and_conditions']">Terms and conditions</a><a style="font-size:11px;" [routerLink]="['/privacy_policy']">Privacy policy</a></span> -->
  <a [routerLink]="['/terms_and_conditions']"><p class="terms"><u>Terms and conditions</u> &nbsp;& &nbsp;</p></a> <a [routerLink]="['/privacy_policy']"><p class="privacy"><u>Privacy policy</u></p></a>
  <div class="row social-columns">
    <div class="col-1 social-icons">
        <a class="social-media" href="https://www.linkedin.com/company/invcar/" target="blank"><i class="fab fa-linkedin" style="color:#A1A5B8 !important;font-size: 20px;"></i></a>
    </div>
    <div class="col-1 social-icons">
      <a class="social-media" href="https://www.instagram.com/invcar/" target="blank"><i class="fab fa-instagram" style="color:#A1A5B8 !important;font-size: 20px;"></i></a>   
     </div>
    <div class="col-1 social-icons">
      <a class="social-media" href="https://www.facebook.com/people/InvCar/100086400216867/" target="blank"><i class="fab fa-facebook" style="color:#A1A5B8 !important;font-size: 20px;"></i></a> 
     </div>
  </div>
</div>
</div>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { propertyGallery } from '../property-gallery/property-gallery.component';
import { PropertyService } from '../../../core/services/property.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/core/utils/helper.service';
import { BuySellService } from 'src/app/core/services/buy-sell.service';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BuyDialogComponent } from '../buy-dialog/buy-dialog.component';
import { SellDialogComponent } from '../sell-dialog/sell-dialog.component';
import { StorageService } from 'src/app/core/utils/storage.service';
import { Storage } from 'src/app/core/enum/storage';
import { BuyBppDialogComponent } from '../buy-bpp-dialog/buy-bpp-dialog.component';
import { SellBppDialogComponent } from '../sell-bpp-dialog/sell-bpp-dialog.component';
import { AccountService } from 'src/app/core/services/account.service';
import { AppService } from 'src/app/core/services/app.service';
import { CartService } from 'src/app/core/services/cart.service';
declare var $: any;
import { AgmMap, MouseEvent } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomResponse } from '../property-list-box/property-list-box.component';

export interface PropertyGallery {
  image: string;
  video?: null | string;
}
export interface offer {
  key: string;
  value: string;
}
export interface ResponseDTO {
  response: {
    message: string;
    data: any;
  };
}
export interface propDetail {
  totalBricklet: Number;
  BrickletAvailable: Number;
  minSalePrice: string;
  maxSalePrice: string;
  brickletOwned: Number;
}
export interface Price {
  priceDate: string;
  price: string;
}
export interface Document {
  documentName: string;
  documentDate: string;
  pdfFileName: string;
}
export interface BrickletValue {
  lastValuation: string;
  valuediff1: string;
  valuediffer2: string;
  latestSell: string;
  latestSellDays: Number;
}
export interface PropertyValue {
  lastValuation: string;
  valuediff1: string;
  valuediffer2: string;
  totalBricklets: string;
  totalPrice: string;
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  content: string;
  color: string;
  iconUrl: string;
}
@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss'],
})
export class PropertyDetailComponent implements OnInit, OnDestroy {
  propertyGallery: PropertyGallery[] = [];
  index: any;
  offerInfo: offer[] = [];
  propDetail: propDetail;
  propertyDetails: any;
  amenities: any[] = [];

  priceInfo: Price[] = [];
  propDocument: Document[] = [];
  propHighlights: any[] = [];
  brickletValue: BrickletValue;
  propertyValue: PropertyValue;

  propertyId = '';
  propertyAllDetails: any;

  sellAvailable = false;
  buyAvailable = false;

  videoInPlay = false;
  isFavorite;

  role: string = '';
  isBpp = false;
  isBppEligibleUser = null;
  isSellBpp = null;
  isBuyBpp = null;

  lockTransaction = false;

  isLogged = false;
  cartShow:boolean = false;
  @ViewChild('agmMap') agmMap: AgmMap
  carName: any;
  modalImg : any;
  selectOrderGUID: string;
  cartResp: CustomResponse;

  constructor(
    private propertyService: PropertyService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private helper: HelperService,
    private buySellService: BuySellService,
    public dialog: MatDialog,
    private storageService: StorageService,
    private modalService: NgbModal,
    private accountService: AccountService,
    private appService: AppService,
    private cartService: CartService,
    public translate: TranslateService
  ) {
    this.propertyId = this.route.snapshot.params['id'];
    if (this.route.snapshot.queryParams['bpp']) {
      // this.isBpp = true;
    }

    this.role = this.storageService.getItem(Storage.ROLE);
    this.lat = 0;
    this.lng = 0;
    this.zoom = 14;
    this.markers = [];

  }

  ngOnInit(): void {

    //this.checkTradable();
    this.isLogged = this.appService.loginStatus.getValue();
    this.getPropertyDetail();
    if (this.isLogged) {
      this.getPriceHistory();
      this.checkForInvestorProfile();
    }

  }

  checkForInvestorProfile() {
    this.accountService.AccountList.subscribe(
      (s) => {
        if (s.accList.length > 0) {
          this.lockTransaction = false;
        } else {
          this.lockTransaction = true;
        }
      }
    );
  }

  ngOnDestroy() {
    $('#view-history').modal('hide');
  }

  onChange(idx: any) {
    this.index = idx;
  }

  open(content) {
    //TODO: hidden for disable bpp
    // this.modalService.open(content, { centered: true, ariaLabelledBy: 'modal-basic-title', modalDialogClass: 'border-0' }).result.then((result) => {
    // }, (reason) => {
    // });
    let dialogRef;
    dialogRef = this.dialog.open(SellDialogComponent, {
      data: {
        id: this.propertyId,
        qty: this.propertyDetails.brickletOwned
      },
      panelClass: 'custom-static-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPropertyDetail();
        this.getPriceHistory();
      }
    });
  }

  openBuyModal(content) {
    this.modalService.open(content, { centered: true, ariaLabelledBy: 'modal-basic-title', modalDialogClass: 'border-0' }).result.then((result) => {

    }, (reason) => {
    });
  }

  getPriceHistory() {
    this.propertyService.getBrickletPriceList(this.propertyId).subscribe(
      (s: any) => {
        this.priceInfo = s.data.priceInflation;
      },
      (e) => {

      }
    );
  }

  getPropertyDetail() {
    this.helper.showSpinner();
    let property;
    if (!this.isLogged) {
      property = this.propertyService.getPropertyDetails(this.propertyId);
    } else {
      property = this.propertyService.getIsLoggedPropertyDetails(this.propertyId);
    }
    property.subscribe(
      (s: any) => {
        // this.isBppEligibleUser = s.data.propertyDetails.isEligibleNextBuy;
         this.propertyAllDetails = s.data.carDetails;
        this.propertyDetails = s.data.carDetails.carDetails;

        // this.propDocument = s.data.propertyDetails.propertyDocument;
        // this.propHighlights = s.data.propertyDetails.propertyHighlights;
        // this.offerInfo = s.data.propertyDetails.propertyDynamicAttributes;
        // this.amenities = s.data.propertyDetails.propertyAmenities;
        // this.isFavorite = this.propertyDetails.isFavorite;
        if (this.propertyGallery.length <= 0) {
          this.propertyGallery = s.data.carDetails.carGallery;
          // this.setSlider();
        }
        this.map();

        this.helper.hideSpinner();
      },
      (error) => {
        this.helper.hideSpinner();
      }
    );
  }

  showCart(guid:string,img:string){
    // localStorage.setItem('cartItem', guid);
    // localStorage.setItem('cartQty', '1');
    // this.appService.passValue(this.cartShow);
    // this.cartService.passCartImg(img);

    this.selectOrderGUID = guid;
    if(!this.isLogged){
       if(this.selectOrderGUID === localStorage.getItem('cartItem')){
        $('#sameDataModal').modal('show');
       }else if(localStorage.getItem('cartItem')){
        $('#confirmModal').modal('show');
       }else{
        this.onBuyNow(guid);
       }
    }else{
      if(this.selectOrderGUID === localStorage.getItem('carGuid')){
        $('#sameDataModal').modal('show');
       }else if(localStorage.getItem('cartGuid')){
        $('#confirmModal').modal('show');
       }else{
        this.onBuyNow(guid);
       }
  }
}

onBuyNow(guid: string) {
  $('#confirmModal').modal('hide');
  if (this.isLogged) {
    this.addToCart(guid, 1);
  } else {
   localStorage.setItem('cartItem', guid);
   localStorage.setItem('cartQty', '1');
    // this.router.navigateByUrl('/cart');
    this.appService.passValue(this.cartShow);

  }
}


  onView() {
    $('#view-history').modal('show');
  }

  openBuyDialog() {
    let dialogRef;
    if (this.isBuyBpp) {
      dialogRef = this.dialog.open(BuyBppDialogComponent, {
        data: { id: this.propertyId },
        panelClass: 'custom-static-dialog'
      });
    } else {
      dialogRef = this.dialog.open(BuyDialogComponent, {
        data: { id: this.propertyId },
        panelClass: 'custom-static-dialog'
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPropertyDetail();
        this.getPriceHistory();
      }
    });
  }

  onDownload(url: any) {
    this.translate.get(['PRD']).subscribe((translations) => {
      this.toastrService.info(translations.PRD.downloadinprogress);
    });

  }


  setSlider() {

    //this.propertyGallery = 
    let tempSliders = [];
    this.propertyGallery.forEach((r: any, i) => {
      if (this.isVideo(r.image)) {
        tempSliders.push({ video: r.image })
      } else {
        tempSliders.push({ image: r.image })
      }
    });
    this.propertyGallery = tempSliders;

    setTimeout(() => {
      $(".slider").slick({
        infinite: true,
        arrows: true,
        adaptiveHeight: true,
        slidesToShow: 2,
        slidesToScroll: 1
        //dots: true
      }).on('afterChange', (event, slick, currentSlide) => {
        if (this.videoInPlay) {
          this.playVideo();
        }
      });

      $(".thumbnail-slider").slick({
        infinite: false,
        arrows: true,
        adaptiveHeight: true,
        slidesPerRow: 3,
        rows: 1,
        slidesToScroll: 1,
        dots: true
      }).on('afterChange', (event, slick, currentSlide) => {
        if (this.videoInPlay) {
          this.playVideo();
        }
      });

    }, 500);
  }

  playVideo() {
    const videoPlayer = document.getElementById('videoPlayer') as HTMLVideoElement;
    if (!this.videoInPlay) {
      videoPlayer.play();
      this.videoInPlay = true;
    } else {
      videoPlayer.pause();
      this.videoInPlay = false;
    }
  }

  pauseVideo() {
    const videoPlayer = document.getElementById('videoPlayer') as HTMLVideoElement;
    if (this.videoInPlay) {
      videoPlayer.pause();
      this.videoInPlay = false;
    }

  }

  thumbClick(index) {
    this.pauseVideo();
    $('.slider').slick('slickGoTo', index);

  }

  isVideo(url: string) {
    const urlSplit = url.split('.');
    const ext: string = urlSplit[urlSplit.length - 1];
    if (ext.includes('mp4') || ext.includes('wma') || ext.includes('mov')) {
      return true
    } else {
      return false;
    }
  }

  onWatch() {
    this.helper.showSpinner();
    this.propertyService.updateFavProperty(this.propertyId).subscribe(
      (s: any) => {
        this.helper.hideSpinner();
        this.translate.get(['PRD']).subscribe((translations) => {
          if (this.isFavorite == true) {
            this.helper.success(translations.PRD.propertyremovedfromwatchlist);
          } else {
            this.helper.success(translations.PRD.propertyaddedtowatchlist);
          }
          // this.helper.info(translations.MKP.infopropertyurlcopied);
        });

        //this.helper.success(s.msg);
        this.isFavorite = !this.isFavorite;
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  canBuyOrdinary() {
    const response = (this.propertyDetails?.brickletsAvailable) && this.role !== 'VIEWER';
    if (this.propertyDetails?.isPropertyOwner) {
      if (this.propertyDetails?.isOwnBrickletsSold) {
        return response;
      } else {
        return false;
      }
    } else {
      return response;
    }
  }

  canBuyBpp() {
    const response = (this.propertyDetails?.bppBrickletsAvailable) && this.role !== 'VIEWER';
    if (this.propertyDetails?.isPropertyOwner) {
      if (this.propertyDetails?.isOwnBrickletsSold) {
        return response;
      } else {
        return false;
      }
    } else {
      return response;
    }
  }

  canBuy() {

    let response = (this.propertyDetails?.brickletsAvailable || this.propertyDetails?.bppBrickletsAvailable) && this.role !== 'VIEWER';
    // if (this.isBpp) {
    //   response = this.propertyDetails?.bppBrickletsAvailable && this.role !== 'VIEWER';
    // }
    if (this.propertyDetails?.isPropertyOwner) {
      if (this.propertyDetails?.isOwnBrickletsSold) {
        return response;
      } else {
        return false;
      }
    } else {
      return response;
    }
  }

  canSell() {
    //if (!this.isBpp) {
    return this.propertyDetails?.brickletOwned && this.role !== 'VIEWER';
    //}
    //return false;
  }

  sellRadioOption(event) {
    this.isSellBpp = event.value;
  }

  submitSellBtnHandler() {
    this.modalService.dismissAll();
    let dialogRef;
    if (this.isSellBpp === 'true') {
      dialogRef = this.dialog.open(SellBppDialogComponent, {
        data: {
          id: this.propertyId,
          qty: this.propertyDetails.brickletOwned
        },
        panelClass: 'custom-static-dialog'
      });
    } else {
      dialogRef = this.dialog.open(SellDialogComponent, {
        data: {
          id: this.propertyId,
          qty: this.propertyDetails.brickletOwned
        },
        panelClass: 'custom-static-dialog'
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPropertyDetail();
        this.getPriceHistory();
      }
    });
  }

  buyRadioOption(event) {
    this.isBuyBpp = event.value;
  }

  submitBuyBtnHandler() {
    this.modalService.dismissAll();
    let dialogRef;
    if (this.isBuyBpp === 'true') {
      dialogRef = this.dialog.open(BuyBppDialogComponent, {
        data: { id: this.propertyId },
        panelClass: 'custom-static-dialog'
      });
    } else {
      dialogRef = this.dialog.open(BuyDialogComponent, {
        data: { id: this.propertyId },
        panelClass: 'custom-static-dialog'
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPropertyDetail();
        this.getPriceHistory();
      }
    });
  }
  // onBuyNow() {
  //   if (this.isLogged) {
  //     this.addToCart(this.propertyId, 1);
  //   } else {
  //     localStorage.setItem('cartItem', this.propertyId);
  //     localStorage.setItem('cartQty', '1');
  //     this.helper.navigate('/cart');
  //   }
  // }

  addToCart(guid: string, qty: any) {
    const data = {
      carGUID: guid,
      Quantity: Number(qty),
    };
    this.helper.showSpinner();
    this.cartService.addCart(data).subscribe(
      (response: CustomResponse) => {
        // this.router.navigateByUrl('/cart');
        // console.log('resp',s);
        console.log(response);
         this.cartResp = response;
         this.cartService.cartObject(this.cartResp);
        //  let cartGuid = cartResc.cartGUID;
        //  let carGuid = cartResc.carGUID;
        //  let orderGuid = cartResc.orderGUID;
        // localStorage.setItem('cartGuid',cartGuid);
        // localStorage.setItem('carGuid',carGuid);
        // localStorage.setItem('orderGuid',orderGuid);
        this.appService.passValue(this.cartShow);
        this.helper.hideSpinner();
      },
      (e) => {
        this.helper.error(e);
        // this.toastr.error(e)
        this.helper.hideSpinner();
      }
    );
  }

  // addToCart(guid: string, qty: any) {
  //   this.helper.showSpinner();
  //   const data = {
  //     PropertyGUID: guid,
  //     Quantity: Number(qty)
  //   }
  //   this.cartService.addCart(data).subscribe(
  //     (s) => {
  //       this.helper.navigate('/cart');
  //       this.helper.hideSpinner();
  //     },
  //     (e) => {
  //       this.helper.error(e);
  //       this.helper.hideSpinner();
  //     }

  //   );

  // }

  previous

  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  public markers: any[];
  public lat: number;
  public lng: number;
  public zoom: number;

  map() {
    this.lat = +this.propertyDetails.latitude;
    this.lng = +this.propertyDetails.longitude;
    this.markers.push({
      position: {
        lat: +this.propertyDetails.latitude,
        lng: +this.propertyDetails.longitude
      },
      label: {
        color: "#293d7f",
        fontWeight: '700',
        fontSize: '20px',
        animation: 'DROP',
      }
    });
  }

  webSiteRedirect() {
    window.open(this.propertyDetails.website);
  }

  textSearchGoogle(URL) {
    let search = this.propertyDetails.name + " " + this.propertyDetails.address + " " + this.propertyDetails.state;
    window.open('http://google.com/search?q=' + search);
  }

  onImgView() {
    $('#view-Zoom-img').modal('show');
    // this.modalImg = imgPath;

  }
 
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-email-verify-home',
  templateUrl: './email-verify-home.component.html',
  styleUrls: ['./email-verify-home.component.scss']
})
export class EmailVerifyHomeComponent implements OnInit {

  emailID: string ='';

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private helper: HelperService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(p => {
      console.log(p);
      this.emailID = p['email'];
    });
  }

  onResendMail() {
    this.helper.showSpinner();
    this.authService.resendEmail(this.emailID).subscribe(
      res => {
        let result: any = res;
        if (result.data !== null) {
          this.helper.hideSpinner();
          this.helper.success(result.msg);
        } else {
          this.helper.hideSpinner();
          this.helper.error(result.msg);
        }
      },
      error => {
        this.helper.hideSpinner();
        this.helper.error(error);
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commom-terms-and-conditions',
  templateUrl: './commom-terms-and-conditions.component.html',
  styleUrls: ['./commom-terms-and-conditions.component.scss']
})
export class CommomTermsAndConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div mat-dialog-title class="mb-0">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-main ps-5 pe-5 pos-rel">
            <h2 class="m-0 secondary-bold f20 purple-brown"> BUY Lot ON BPP</h2>
            <img src="/assets/images/dialog-close.svg" class="trade-dialog-close mobile" (click)="closeDialog()"
                title="Close dialog" alt="close_dialog">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ps-5 pe-5 pos-rel desktop">
            <h2 class="m-0 secondary-bold f20 dark-indigo">Summary</h2>
            <img src="/assets/images/dialog-close.svg" class="trade-dialog-close" (click)="closeDialog()"
                title="Close dialog" alt="close_dialog">
        </div>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="layer" *ngIf="!isEligibleUser"></div>
    <form [formGroup]="buyBrickletForm">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-light ps-5">
                <div class="mt-5">
                    <label class="primary-light waav-dark-main f20 mb-5">Your balance
                        <span class="primary-bold purple-brown">${{accountBalance|number:'0.0-2'}}</span>
                    </label>
                    <p class="primary-regular f16 generic-palette-dark-light">Bricklet Payment Plan (BPP) is available
                        for this property</p>

                    <div class="row align-items-center mb-5">
                        <div class="col-lg-8 col-md-7 col-sm-12">
                            <label for="" class="primary-bold f24 waav-dark-main">
                                How many Bricklets
                                would you like to buy using BPP?
                            </label>
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-12">
                            <input type="text" class="input-dialog purple-brown" formControlName="NoOfBricklet"
                                (keyup)="getMarketPrice()">
                        </div>
                    </div>
                    <p type="button" class="primary-regular f16 generic-palette-dark-light text-underline-hover"
                    placement="bottom" triggers="click" tooltipClass="bpp-tooltip" [autoClose]="true" ngbTooltip="Pay 10% deposit plus stamp duty, conveyancing fees now and then pay the remaining amount in 18 equal installments over 18 months.">
                        - What is Bricklet payment plan?
                    </p>
                    <p type="button" class="primary-regular f16 generic-palette-dark-light" *ngIf="BPPTermsCondition!==''">
                        - Read the Bricklet payment plan Terms and Conditions?
                        <a [href]="BPPTermsCondition" target="_blank" class="f13"> <i
                            class="fas fa-external-link-alt primary-main"></i></a>
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 bg-white ps-5">

                <div>
                    <div id="bricklet-history">
                        <!-- <div *ngFor="let r of historyList" class="tiles">
                            <p class="primary-regular f16 generic-palette-dark-light"> <span
                                    class="secondary-bold f16 waav-dark-main">{{r.availBricklet}} bricklet</span> from 1
                                user</p>
                            <p class="secondary-bold f20 primary-main">{{r.availBricklet}} x ${{r.price
                                |number:'0.0-2'}}</p>
                        </div> -->
                        <div class="tiles">
                            <p class="primary-regular f14 generic-palette-dark-light">
                                Bricklet Cost
                            </p>
                            <p class="secondary-bold f18 purple-brown">
                                ${{ brickletCost | number:'0.0-2'}}
                            </p>
                        </div>
                        <div class="tiles">
                            <p class="primary-regular f14 generic-palette-dark-light">
                                10% Initial Amount
                            </p>
                            <p class="secondary-bold f18 purple-brown">
                                ${{ initialAmount | number:'0.0-2'}}
                            </p>
                        </div>
                        <div class="tiles">
                            <p class="primary-regular f14 generic-palette-dark-light">
                                6% inclusive of following
                            </p>
                            <p class="secondary-bold f18 purple-brown">
                                ${{ taxAmount | number:'0.0-2'}}
                            </p>
                        </div>
                        <div class="tiles tax">
                            <p class="secondary-bold f12 generic-palette-dark-light">
                                - Stamp Duty
                            </p>
                        </div>
                        <div class="tiles tax">
                            <p class="secondary-bold f12 generic-palette-dark-light">
                                - Conveyancing Costs
                            </p>
                        </div>
                        <div class="tiles">
                            <p class="primary-regular f14 generic-palette-dark-light">
                                BPP Setup Cost
                            </p>
                            <p class="secondary-bold f16 purple-brown">
                                ${{ setupFee | number:'0.0-2'}}
                            </p>
                        </div>
                        <div class="tiles">
                            <p class="primary-regular f14 generic-palette-dark-light">
                                Total Bricklet Cost
                            </p>
                            <p class="secondary-bold f18 purple-brown">
                                ${{ totalAmount | number:'0.0-2'}}
                            </p>
                        </div>
                        <div class="tiles">
                            <p class="primary-regular f14 generic-palette-dark-light">
                                Total Initial Payment
                            </p>
                            <p class="secondary-bold f18 purple-brown">
                                ${{ totalInitialAmount | number:'0.0-2'}}
                            </p>
                        </div>
                    </div>
                    <div id="tax-calc">

                    </div>
                </div>

                <div>

                    <div class="checkbox-container">
                        <label class="checkbox-label">
                            I agree to the terms and conditions.
                            <a *ngIf="pdfLink!==''" [href]="pdfLink" target="_blank" class="f13"> <i
                                    class="fas fa-external-link-alt primary-main"></i></a>
                            <input type="checkbox" formControlName="termsAndCondition" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="checkbox-container">
                        <label class="checkbox-label">
                            I provide this authority and instruction to act on the purchase.
                            <a *ngIf="legalDocLink!==''" [href]="legalDocLink" target="_blank" class="f13"> <i
                                    class="fas fa-external-link-alt primary-main"></i></a>
                            <input type="checkbox" formControlName="legalDocAgreement" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="p-0 m-0">
    <div class="layer" *ngIf="!isEligibleUser"></div>
    <div class="row m-0 w-100">
        <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-light ps-5">

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 row m-0 align-items-center justify-content-end ps-5 pe-4 pb-4">

            <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                <button class="btn btn-primary w-100" placement="top" triggers="hover" tooltipClass="bpp-tooltip" [autoClose]="true" ngbTooltip="If you agree to the Terms and Conditions and wish to proceed, click SIGN BPP button and we will send you this document to sign. Once you sign and have sufficient funds in your account you will be able to purchase this Bricklet under the BPP" (click)="onSubmit()"
                    [disabled]="!buyBrickletForm.valid">SIGN BPP</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>
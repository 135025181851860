import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { ImageService } from 'src/app/core/services/image.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-img-upload',
  templateUrl: './img-upload.component.html',
  styleUrls: ['./img-upload.component.scss']
})
export class ImgUploadComponent implements OnInit {

  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  docName = 'Image';
  imageFile: File = null;
  previewPath;
  fileInput: HTMLInputElement;
  uploadedDocName = '';
  pdfUrl = 'assets/images/pdf-small.png';
  fileExt = '';
  fileName = '';
  // idNumber = Math.floor(Math.random() * 6) + 1;
  prefix = Math.floor(Math.random() * (50 - 1 + 1) + 1);
  suffix = Math.floor(Math.random() * (99 - 1 + 1) + 1);
  idNumber = this.prefix + '-' + Math.floor(Math.random() * (10000 - 1 + 1) + 1) + '-' + this.suffix;

  @Output() getFiles = new EventEmitter();

  @Input() sendFiles: {
    docType: string;
    docText: string;
  };
  constructor(
    private commonService: HelperService,
    private fileUploadService: FileUploadService,
    private imageService: ImageService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    this.docName = this.sendFiles.docText;
    this.imageService.getStoredImage.subscribe(
      (v) => {
        if (v && v.length > 0) {
          v.forEach((e) => {
            if (e.docType === this.sendFiles.docType && e.previewUrl) {
              this.uploadedDocName = e.previewUrl;
              this.getBlob(this.uploadedDocName);
              this.fileName = this.getUrlFileName(e.previewUrl);
              this.fileExt = this.getUrlFileExtension(e.previewUrl);
              this.emitFiles();
            }
          });
        }
      }
    );

  }

  ngAfterViewInit() {
    this.fileInput = document.getElementById('file-input' + this.idNumber) as HTMLInputElement;

    let fileLabel = document.getElementById('file-input-div' + this.idNumber) as HTMLDivElement;

    this.fileInput.addEventListener('change', (e) => {
      this.handler((e.target as HTMLInputElement).files);
    });
    this.fileInput.addEventListener('drop', (e) => {
      

      this.handler(e);
      // e.preventDefault();
    });
  }

  handler(selFiles) {
    if (selFiles.length > 0) {
      this.fileUploadService.checkFileStatus(
        selFiles,
        (s: {
          fileType: boolean,
          previewPath: string,
          fileExt: string,
          imageFile: File,
          name: string,
          errorMsg: string
        }) => {
          if (s.fileType) {
            this.uploadedDocName = '';
            this.fileExt = s.fileExt;
            this.imageFile = s.imageFile;
            this.fileName = s.name.substr(0, s.name.lastIndexOf('.'));
            this.previewPath = s.previewPath;
            this.emitFiles();
          } else {
            this.fileInput.value = null;
            this.commonService.info(s.errorMsg);
            // this.removeImage();
          }
        },
        1,
        ['jpg', 'jpeg', 'png', 'pdf']
      );
    }
  }

  removeImage() {
    if (this.uploadedDocName !== '' || this.previewPath !== '') {
      this.uploadedDocName = '';
      this.fileName = '';
      this.previewPath = '';
      this.imageFile = null;
      this.fileUpload.nativeElement.value = '';
      this.emitFiles();
    } else {
      this.commonService.error('There is no file to remove');
    }
  }

  uploadImage() {
    this.fileInput.click();
  }

  emitFiles() {
    this.getFiles.emit({
      imgFile: this.imageFile,
      imgUrl: this.uploadedDocName
    });
  }

  getBlob(docName: string) {
    if (docName && docName !== '') {
      this.imageService.getImageBlob(docName).subscribe(
        (blobImg: any) => {
          const blob = new Blob([blobImg], {
            type: 'application/octet-stream'
          });
          const url = URL.createObjectURL(blob);
          this.previewPath = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        },
        (e) => {
          

        }
      );
    }
  }

  getUrlFileExtension(url: string) {
    // const fileName = url.substr((url.lastIndexOf('/') + 1));
    return url.substr((url.lastIndexOf('.') + 1));
  }

  getUrlFileName(url: string) {
    // const fileName = url.substr((url.lastIndexOf('/') + 1));
    return url.substr(0, url.lastIndexOf('.'));
  }

  ngOnDestroy() {
    this.imageService.removeObj(this.sendFiles.docType);
  }

  handleEnter(ev) {

    ev.preventDefault();

    if (ev.dataTransfer.items) {

      this.fileInput.files = ev.dataTransfer.files;
      this.handler(ev.dataTransfer.files);

      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
        }
      }
    } else {
      this.fileInput.files = ev.dataTransfer.files;
      this.handler(ev.dataTransfer.files);
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        
      }
    }

    //this.handler(e);
  }

  dragOverHandler(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }

}

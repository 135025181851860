<div id="mySidenav" class="rightsidenav">
  <div class="row" style="padding:11px 1px 8px 19px;margin-top: -11px;">
    <div class="col-4 social-icons">
      <a class="social-media" (click)="showCartTabValue()" target="blank"><i class="fa fa-shopping-cart"
          style="color:#A1A5B8 !important;font-size: 20px;"></i></a>
    </div>
    <div class="col-4 social-icons">
      <!-- <a class="social-media" (click)="showWishlistValues()"><i class="fa fa-heart"
          style="color:#A1A5B8 !important;font-size: 20px;"></i></a> -->
    </div>
    <div class="col-3 social-icons">
      <a class="social-media" (click)="closeSidebar()"  target="blank"><i class="fa fa-times"
          style="color:#A1A5B8 !important;font-size: 20px;"></i></a>
    </div>
  </div>
  <br>
  <!-- <div class="col-12" style="text-align:center">
    <h2 class="title-property">Honda Accord Hybrid</h2>

    <img [src]="cartImg" width="200"
    height="100">
    <br>

    <div class="row" style="text-align: center;margin-top: 14px;">
      
    <div class="input-group" style="padding:0px 90px;">
      <span class="input-group-prepend">
          <button type="button" class="btn btn-outline-secondary btn-number"  (click)="decrement()">
              <span class="fa fa-minus"></span>
          </button>
      </span>
      <span style="width:50px;margin-top:5px;">{{counter}}</span>
      <span class="input-group-append">
          <button type="button" class="btn btn-outline-secondary btn-number" (click)="increment()">
              <span class="fa fa-plus"></span>
          </button>
      </span>
  </div>

  </div>

    <div class="row checkout-section">
      <div class="col-9" *ngIf="!getWishList">
        <h4 style="padding-left:25px;text-align: left;">TOTAL</h4>
      </div>
      <div class="col-3" *ngIf="!getWishList">
        <span class="">$</span>1000
      </div>
      <button class="btn btn-primary" style="margin-left: 30px;width:80%;" (click)="checkout()" *ngIf="!getWishList">Checkout</button>
      <button class="btn btn-primary" style="margin-left: 30px;width:80%;" (click)="checkout()" *ngIf="getWishList">View All</button>
  
    </div>
  
  
  </div> -->
  
  <div class="col-12" *ngIf="marketPriceData !== null">

    <h2 class="title-property" *ngIf="!isLoggedIn">{{marketPriceData?.cartResponseDTO?.carMake | uppercase}} {{(marketPriceData?.cartResponseDTO?.carModel | uppercase) }}</h2>
   
    <div class="row" *ngIf="isLoggedIn">
    <div class="col-9">
      <h2 class="title-property">{{marketPriceData?.cartResponseDTO?.carMake | uppercase}} 
        {{(marketPriceData?.cartResponseDTO?.carModel.length>10)? (marketPriceData?.cartResponseDTO?.carModel | slice:0:8  | uppercase)+'...':(marketPriceData?.cartResponseDTO?.carModel | uppercase) }}</h2>
    </div>
    <div class="col-2">
      <span (click)="removeCart()" style="cursor: pointer;"><i class="fa fa-trash"></i></span>
    </div>
  </div>
   
    <a
    [routerLink]="
      '/marketplace/detail/' + marketPriceData?.cartResponseDTO?.carGUID
    "
  >
    <img
      width="220"
      height="100"
      [src]="marketPriceData?.cartResponseDTO?.bannerImage"
      class="prop-banner"
      alt=""
      loading="lazy"
  />
  
</a>
<div class="row counter-block" *ngIf="!isLoggedIn">
  <div class="input-group">
    <span class="input-group-prepend">
        <button type="button" class="btn btn-outline-secondary btn-number"  (click)="decrement()" [disabled]="cartInputQty === 1">
            <span class="fa fa-minus"></span>
        </button>
    </span>
    <span class="qnty">{{cartInputQty}}</span>
    <span class="input-group-append">
        <button type="button" class="btn btn-outline-secondary btn-number" (click)=" increment()" [disabled]="cartInputQty >= noOfShares">
            <span class="fa fa-plus"></span>
        </button>
    </span>
</div>
</div>

<div class="row counter-block" *ngIf="isLoggedIn">
  <div class="input-group">
    <span class="input-group-prepend">
        <button type="button" class="btn btn-outline-secondary btn-number" appDebounceClick (debounceClick)="decrement();updateCart( this.cartItemId,cartInputQty)" [debounceTime]="700" [disabled]="cartInputQty === 1">
            <span class="fa fa-minus"></span>
        </button>
    </span>
    <span class="qnty">{{cartInputQty}}</span>
    <span class="input-group-append">
        <button type="button" class="btn btn-outline-secondary btn-number" appDebounceClick (debounceClick)=" increment();updateCart(this.cartItemId,cartInputQty)" [debounceTime]="700" [disabled]="(cartInputQty >= noOfShares) || accountBalance <= (marketPriceData?.cartResponseDTO?.total)">
          <span class="fa fa-plus"></span>
      </button>
    </span>
</div>
</div>


  <!-- <div class="col-12" *ngIf="isLoggedIn">
    <button class="btn btn-primary btn-sm update-btn" (click)="updateCart(marketPriceData?.cartResponseDTO?.carGUID,cartInputQty)" >Update Cart</button>
    <button class="btn btn-primary btn-sm update-btn">Remove Cart</button>

  </div> -->
    <!-- <img src="../../../assets/images/landing-page/2.png" style="width: 282px; 
    padding: 24px;">-->
    <!-- <div class="row">
      <div class="col-sm-9 mx-auto">
        <input
              [(ngModel)]="cartInputQty"
              type="number"
              class="input-text qty text"
              title="Qty"
              inputmode="numeric"
              autocomplete="off"
              min="1"
              step="1"
              (keyup)="checkInputValue()"
            /> 
            
      </div>
  </div>-->
  <div class="row checkout-section">
    <div class="col-4" *ngIf="!getWishList">
      <h4 style="padding-left:10px;">TOTAL</h4>
    </div>
    <div class="col-8 tolAmount" *ngIf="!getWishList">
      <h3 *ngIf="!isLoggedIn"><span class="totalBlnc">$</span>{{ marketPriceData?.cartResponseDTO?.subTotal * cartInputQty  | number: "0.0-2"  }}</h3>
      <h3 *ngIf="isLoggedIn"><span class="totalBlnc">$</span>{{ marketPriceData?.cartResponseDTO?.total  | number: "0.0-2"  }}</h3>
    </div>
    <span class="insuff_blnc" *ngIf="accountBalance <= (marketPriceData?.cartResponseDTO?.total) && isLoggedIn" routerLink="/dashboard/deposit">Insufficient Balance..!!! Please Deposit
    </span>
    <div class="col-12">
    <button class="btn btn-primary" style="margin-left: 10px;width:100%;" (click)="checkout()" *ngIf="!getWishList" [disabled]="accountBalance <= (marketPriceData?.cartResponseDTO?.total) && isLoggedIn">Checkout</button>
    <button class="btn btn-primary viewAllBtn"  (click)="checkout()" *ngIf="getWishList">View All</button>
  </div>
  </div>
  </div>

  <div class="container" *ngIf="marketPriceData === null">
    <div class="entry-content" *ngIf="isCartEmpty()">
      <p class="cart-empty-text text-center primary-regular">
        {{ "CART.yourcartiscurrentlyempty" | translate }}
      </p>
      <p class="return-to-shop">
        <a href="/marketplace/list">
          <button class="btn btn-primary btn-sm" style="border-radius: 4px;">
            {{ "CART.backtomarketplace" | translate }}
          </button>
        </a>
      </p>
    </div>
  </div>

  <div class="container" *ngIf="getWishList === null">
    <div class="entry-content" >
      <p class="cart-empty-text text-center primary-regular">
        {{ emptyWishlist}}
      </p>
    </div>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.scss']
})
export class HelpdeskComponent implements OnInit {
  clicked: boolean = false;
  helpDeskForm: FormGroup;
  constructor( private formBuilder: FormBuilder,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.helpDeskForm = this.formBuilder.group({
      name: ['', Validators.required],
      emailAddr: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      textArr: ['', Validators.required]
    })
  }

  get helpform() {
    return this.helpDeskForm.controls;
  }

  NotifyUser() {
    this.clicked = true;
    if (this.helpDeskForm.invalid) {
      return;
    } else {
      this.toastr.success("Thanks for the message. We'll get back to you as soon as we can...!!!", "Success");
      this.clicked = false;
      this.helpDeskForm.reset();
    }

  }


}

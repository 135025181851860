<ng-container *ngIf="kycStatus">
    <ng-container *ngIf="kycStatus.rapidIDVerificationStatus==1 || kycStatus.rapidIDVerificationStatus==4 || isResend">
        <h1 mat-dialog-title class="wp-charcoal f25 secondary-bold">Verify my ID</h1>
        <mat-dialog-content class="sell-initiated">
            <div class="d-flex align-items-center justify-content-center flex-column w-100">
                <p *ngIf="kycStatus.rapidIDVerificationStatus==4" class="secondary-bold f18 wp-charcoal text-center">
                    your account verification has failed. Try again.
                </p>
                <form action="" [formGroup]="verifyForm">
                    <div class="form-group">
                        <label for="mobile" class="form-label required">Mobile number</label>
                        <div class="col-12 row">
                            <div class="col-4 d-flex">
                                <select class="form-control px-0" formControlName="countryCode" placeholder="+91">
                                    <option value="">Choose</option>
                                    <option *ngFor="let r of countryCodes" [value]="r">{{r.substr(1)}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-8">
                                <input type="text" class="form-control" formControlName="mobile">
                            </div>
                        </div>
                        <div class="">
                            <p class="form-error"
                                *ngIf="helper.canShowError(verifyForm,'countryCode') && helper.hasError(verifyForm, 'countryCode','required')">
                                Please select country code
                            </p>
                            <p class="form-error"
                                *ngIf="helper.canShowError(verifyForm,'mobile') && helper.hasError(verifyForm, 'mobile','pattern')">
                                Please enter valid number
                            </p>
                        </div>
                    </div>

                    <p class="primary-regular f13 wp-yellow">Verification link will be send to your mobile number. <wbr/>Please confirm your mobile number.
                    </p>

                </form>

            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="row justify-content-end w-100">
                <div class="col-lg-5 col-md-5 col-sm-12">
                    <button class="btn btn-primary w-100 my-1" (click)="onSubmit()"
                        [disabled]="verifyForm.invalid">{{ isResend?'Resend':'Confirm' }}</button>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-12">
                    <button class="btn btn-primary outlined w-100 my-1" (click)="closeDialog()">Close</button>
                </div>
            </div>
        </mat-dialog-actions>
    </ng-container>

    <ng-container *ngIf="kycStatus.rapidIDVerificationStatus==2 && !isResend">
        <mat-dialog-content class="sell-initiated">
            <p class="secondary-bold f25 wp-charcoal text-center">
                Verification in Progress
            </p>
            <div class="img-container">
                <img src="/assets/images/rapid-id-inprogress.jpg" alt="" class="w-100">
            </div>
            <p class="primary-regular f18 wp-charcoal text-center">
                An SMS has been sent to your mobile number: {{verifyForm.controls['mobile'].value}} <br />
                Please follow the steps on your phone to complete the ID check. <br />
                You have 15 minutes to complete the process.
            </p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="justify-content-center d-flex w-100">
                <button class="btn btn-primary outlined m-1" (click)="closeDialog()">Close</button>
            </div>
        </mat-dialog-actions>
    </ng-container>

    <ng-container *ngIf="kycStatus.rapidIDVerificationStatus==3">
        <mat-dialog-content class="sell-initiated">
            <p class="secondary-bold f25 wp-charcoal text-center">
                Your account has verified.
            </p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="justify-content-center d-flex w-100">
                <button class="btn btn-primary outlined my-1" (click)="closeDialog()">Close</button>
            </div>
        </mat-dialog-actions>
    </ng-container>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { HelperService } from 'src/app/core/utils/helper.service';
export interface Tabs {
  title: string;
  link: string;
  img: string;
  logged: boolean | null;
}
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  path: string = 'assets/images/header-icons/login-icons/';
  isLogged = false;
  tabs: Tabs[] = [
    {
      title: 'DASHBOARD',
      link: '/dashboard',
      img: 'dashboard',
      logged: true,
    },
    {
      title: 'INBOX',
      link: '/inbox',
      img: 'inbox',
      logged: true,
    },
    {
      title: 'TRANSACTION',
      link: '/dashboard/transactions',
      img: 'transactions',
      logged: true,
    },
    {
      title: 'USER',
      link: '/personal-info',
      img: 'user',
      logged: true,
    }
  ];

  subscribeForm: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  helpDeskForm: FormGroup;
  constructor(private appService: AppService, private router: Router, private toastr: ToastrService, private formBuilder: FormBuilder, private helper:HelperService,
    private authService:AuthService,) { }

  ngOnInit(): void {
    this.appService.loginStatus.subscribe(
      (s) => {
        this.isLogged = s;
      }
    );

    this.subscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
    this.helpDeskForm = this.formBuilder.group({
      name: ['', Validators.required],
      emailAddr: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      textArr: ['', Validators.required]
    })
  }

  get f() {
    return this.subscribeForm.controls;
  }

  get helpform() {
    return this.helpDeskForm.controls;
  }

  terms = "https://invcar.com/terms-and-conditions";
  privacy = "https://invcar.com/privacy-policy";

  openContactPopup() {
    $("#contactPopup").modal({
      backdrop: false
    });
    $("#contactPopup").modal("show");
  }

  termsPage() {
    this.router.navigate(['/terms_and_conditions']);
  }

  privacyPage() {
    this.router.navigate(['/privacy_policy']);

  }

  NotifyUser() {
    this.clicked = true;
    if (this.helpDeskForm.invalid) {
      return;
    } else {
      this.toastr.success("Thanks for the message. We'll get back to you as soon as we can...!!!", "Success");
      this.clicked = false;
      this.helpDeskForm.reset();
    }

  }

  subscribeUser() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.subscribeForm.invalid) {
      return;
    } else {
      this.toastr.success("Thanks for subscription..!!!", "Subscribed User")
      this.submitted = false;
      this.subscribeForm.reset();
    }
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.subscribeForm.value))
  }

  logout() {
    this.helper.showSpinner();
    this.authService.logout();
  }
}

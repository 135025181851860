import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCapitalize]'
})
export class CapitalizeDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('blur') onBlur() {
    if (this.el.nativeElement.value) {
      const elValue = String(this.el.nativeElement.value).toLowerCase();
      let arrWords = elValue.split(' ');
      arrWords= arrWords.filter(r=> r!=='');
      for (let i = 0; i < arrWords.length; i++) {
        const arr: string[] = arrWords[i].split('');
        arr[0] = arr[0].toUpperCase();
        arrWords[i]= arr.join('');
      }
      
      //this.el.nativeElement.value = arr.join('');
      this.control.control.setValue(arrWords.join(' '));
    }
  }

}

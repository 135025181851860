import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BuySellService } from 'src/app/core/services/buy-sell.service';
import { HelperService } from 'src/app/core/utils/helper.service';
import { SellInitiatedDialogComponent } from '../sell-initiated-dialog/sell-initiated-dialog.component';

@Component({
  selector: 'app-sell-dialog',
  templateUrl: './sell-dialog.component.html',
  styleUrls: ['./sell-dialog.component.scss'],
})
export class SellDialogComponent implements OnInit {
  sellableBricklet = 0;

  pdfLink = '';
  legalDocLink = '';

  historyList = [];
  taxList;
  feeList: any;
  totalAmount = 0;
  receiveAmount = 0;

  @ViewChild('brickletAmount', { static: true })
  brickletAmountInput: ElementRef;

  sellBrickletForm = new FormGroup({
    amount: new FormControl('', [Validators.required]),
    NoOfBricklet: new FormControl('', [Validators.required]),
    termsAndCondition: new FormControl(false, Validators.pattern('true')),
    legalDocAgreement: new FormControl(false, Validators.pattern('true')),
  });

  constructor(
    public dialogRef: MatDialogRef<SellDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private sellService: BuySellService,
    private helper: HelperService
  ) {
    this.sellableBricklet = data.qty;
  }

  ngOnInit(): void {
    // const legalDocControl = <FormControl>this.sellBrickletForm.get('legalDocAgreement');
    // const termsandConditionsControl = <FormControl>this.sellBrickletForm.get('termsAndCondition');
    // legalDocControl.valueChanges.subscribe(value => {
    //   if (this.legalDocLink) {
    //     legalDocControl.setValidators([Validators.pattern('true')]);
    //   }
    //   else {
    //     legalDocControl.setValidators(null);
    //   }
    //   legalDocControl.updateValueAndValidity();
    // });

    // termsandConditionsControl.valueChanges.subscribe(value => {
    //   if (this.pdfLink) {
    //     termsandConditionsControl.setValidators([Validators.pattern('true')]);
    //   }
    //   else {
    //     termsandConditionsControl.setValidators(null);
    //   }
    //   termsandConditionsControl.updateValueAndValidity();
    // })

    this.isSellAvailable();

    fromEvent(this.brickletAmountInput.nativeElement, 'keyup')
      .pipe(debounceTime(1000))
      .subscribe((s) => {
        this.getMarketPrice();
      });
  }

  getMarketPrice() {
    const data = {
      PropertyGUID: this.data.id,
      Quantity: parseInt(this.sellBrickletForm.get('NoOfBricklet').value),
      SellPrice: this.sellBrickletForm.get('amount').value,
    };
    if (data.Quantity > 0 && data.SellPrice > 0) {
      this.helper.showSpinner();
      this.sellService.getSellerMarketPrice(data).subscribe(
        (r: any) => {
          this.helper.hideSpinner();
          this.historyList = r.data.marketPrice.nearPriceRange;
          this.feeList = r.data.marketPrice.fees;
          this.taxList = r.data.marketPrice.tax;
          this.totalAmount = r.data.marketPrice.totalAmount;
          this.receiveAmount = r.data.marketPrice.youReceive;
        },
        (e) => {
          this.helper.error(e);
          this.helper.hideSpinner();
          this.historyList = [];
          this.feeList = null;
          this.taxList = null;
          this.totalAmount = 0;
          this.receiveAmount = 0;
        }
      );
    } else {
      this.historyList = [];
      this.feeList = null;
      this.taxList = null;
      this.totalAmount = 0;
      this.receiveAmount = 0;
    }
  }

  onSubmit() {
    const data = {
      PropertyGUID: this.data.id,
      NoOfBricklet: parseInt(this.sellBrickletForm.get('NoOfBricklet').value),
      SellPrice: this.sellBrickletForm.get('amount').value,
    };
    this.helper.showSpinner();
    this.sellService.sellBricklet(data).subscribe(
      (r: any) => {
        this.helper.hideSpinner();
        this.helper.success(r.msg);
        this.openSuccess();
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  get amount() {
    return this.sellBrickletForm.get('amount').value;
  }

  get NoOfBricklet() {
    return this.sellBrickletForm.get('NoOfBricklet').value;
  }

  openSuccess() {
    const dialogRef = this.dialog.open(SellInitiatedDialogComponent, {
      data: {
        id: this.data.id,
        totalAmount: this.receiveAmount,
        noOfBricklets: this.sellBrickletForm.get('NoOfBricklet').value,
        isBpp: false,
      },
    });
    this.dialogRef.close(true);
    dialogRef.afterClosed().subscribe((s) => {
      this.dialogRef.close(true);
    });
  }

  isSellAvailable() {
    this.sellService
      .isBrickletAvailableToSell({ PropertyGUID: this.data.id })
      .subscribe(
        (r: any) => {
          const data: any = r.data;

          this.pdfLink = data.sellDetail.termsConditionPDF;
          // this.legalDocLink = data.sellDetail.legalDocumentPDF;
          this.helper.hideSpinner();
        },
        (e) => {
          this.helper.hideSpinner();
        }
      );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

<div class="container">
    <div class="col-12">
        <div class="inbox-header">
            <div class="row">
                <ul class="list-group list-group-horizontal">
                    <h5 class="filter">FILTER BY</h5>&nbsp;&nbsp;
                    <li class="list-group-item">
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          View all
                        </label>
                      </div>
                    </li>
                    <li class="list-group-item">
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1">
                        <label class="form-check-label" for="flexCheckDefault">
                            Important & Pending Requests
                        </label>
                      </div>
                    </li>
                    <li class="list-group-item">
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
                        <label class="form-check-label" for="flexCheckDefault">
                            Selling orders
                        </label>
                      </div>
                    </li>
                    <li class="list-group-item">
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3">
                        <label class="form-check-label" for="flexCheckDefault">
                            With Attachments
                        </label>
                      </div>
                    </li>
                    <li class="list-group-item">
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault4">
                        <label class="form-check-label" for="flexCheckDefault">
                            Offers
                        </label>
                      </div>
                    </li>
                    <li class="list-group-item">
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault5">
                        <label class="form-check-label" for="flexCheckDefault">
                            Starred
                        </label>
                      </div>
                    </li>
                  </ul>
            </div>
            
        </div>
      
        <div class="row">
            <div class="col-4">
                <div class="wallet">
                    <div class="list-group list-group-flush border-bottom scrollarea">
                        <a href="#" class="list-group-item list-group-item-action active py-3 lh-tight" aria-current="true">
                          <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">Welcome to INVCAR</strong>
                            <small><i class="fa fa-star"></i></small>
                          </div>
                          <div class="col-10 mb-1 small">22 Aug 2022 - 4.18</div>
                        </a>
                        <div style="border-bottom:1px solid #E8E9EE"></div>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                          <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">You have 2 documents to be signed</strong>
                            <small class="text-muted"><i class="fa fa-star"></i></small>
                          </div>
                          <div class="col-10 mb-1 small">22 Aug 2022 - 4.18</div>
                        </a>
                        <div style="border-bottom:1px solid #E8E9EE"></div>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                          <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            <small class="text-muted"><i class="fa fa-star"></i></small>
                          </div>
                          <div class="col-10 mb-1 small">22 Aug 2022 - 4.18</div>
                        </a>
                        <div style="border-bottom:1px solid #E8E9EE"></div>
                  
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight" aria-current="true">
                          <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            <small class="text-muted"><i class="fa fa-star"></i></small>
                          </div>
                          <div class="col-10 mb-1 small">22 Aug 2022 - 4.18</div>
                        </a>
                        <div style="border-bottom:1px solid #E8E9EE"></div>

                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                          <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            <small class="text-muted"><i class="fa fa-star"></i></small>
                          </div>
                          <div class="col-10 mb-1 small">22 Aug 2022 - 4.18</div>
                        </a>
                        <div style="border-bottom:1px solid #E8E9EE"></div>
                        <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                          <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">List group item heading</strong>
                            <small class="text-muted"><i class="fa fa-star"></i></small>
                          </div>
                          <div class="col-10 mb-1 small">22 Aug 2022 - 4.18</div>
                        </a>
                      
                      </div>
                </div>
            </div>
            <div class="col-8">
                <div class="inbox-content">
                  <h4>WELCOME TO INVCAR'S</h4>
                  <small>22 Aug 2022 - 4.18</small>
                  <p>
                    Paragraphs
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit.
                    Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel,
                    nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget
                    blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.
                </p>
                <p>
                    Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero
                    eu augue. Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed
                    lectus. Praesent elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum
                    volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien
                    ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus sed, urna.
                </p>
                <div class="row">
                    <!-- <div class="col-6">
                        <button class="btn btn-secondary btn-sm withdraw">Sell shares</button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-primary btn-sm deposit">Buy shares</button>
                    </div> -->
                    
                </div>
                </div>
            </div>
           
        </div>
        
      </div>
</div>
<div mat-dialog-title class="mb-0">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-main ps-5 pe-5 pos-rel">
            <h2 class="m-0 secondary-bold f20 purple-brown"> SELL LOT ON BPP</h2>
            <img src="/assets/images/dialog-close.svg" class="trade-dialog-close mobile" (click)="closeDialog()" title="Close dialog" alt="close_dialog">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ps-5 pe-5 pos-rel desktop">
            <h2 class="m-0 secondary-bold f20 dark-indigo">SUMMARY</h2>
            <img src="/assets/images/dialog-close.svg" class="trade-dialog-close" (click)="closeDialog()" title="Close dialog" alt="close_dialog">
        </div>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="sellBrickletForm">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-light ps-5">
                <div class="mt-5">
                    <label class="primary-light waav-dark-main f20 mb-5">Property balance
                        <span class="primary-bold purple-brown">{{sellableBricklet}} Shares</span>
                    </label>

                    <div class="row align-items-center mb-3">
                        <div class="col-lg-8 col-md-7 col-sm-12">
                            <label for="" class="primary-bold f24 waav-dark-main">
                                How many Lot would you like to sell?
                            </label>
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-12">
                            <input type="text" class="input-dialog purple-brown" NumbersOnly [allowDecimals]="false" formControlName="NoOfBricklet" (keyup)="getMarketPrice()">
                        </div>
                    </div>
                    <div class="row align-items-center mb-5">
                        <div class="col-lg-8 col-md-7 col-sm-12">
                            <label for="" class="primary-bold f24 waav-dark-main">
                                Price per shares?
                            </label>
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-12">
                            <input type="text" class="input-dialog purple-brown" formControlName="amount" #brickletAmount >
                        </div>
                    </div>
                </div>
                <div>
                    <ul class="primary-regular f14 generic-palette-dark-light">
                        <li>{{'SELL.placeordertosellwillplaceyourbricklet' | translate}} <a href="https://ipan-x.com/" class=" primary-main" target="_blank">ipan-x.com</a> .</li>
                        <li>Orders will remain for sale until purchased by another user or cancelled.</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 bg-white ps-5">

                <div>
                    <div id="bricklet-history">
                        <div class="tiles">
                            <p class="primary-regular f16 generic-palette-dark-light">
                                <span class="secondary-bold f16 waav-dark-main"> {{ NoOfBricklet }} Shares</span>
                                placed to sell
                            </p>
                            <p class="secondary-bold f20 purple-brown">{{ NoOfBricklet }} x {{ amount | number:'0.0-2' }}</p>
                        </div>

                        <div class="tiles">
                            <p class="primary-regular f16 generic-palette-dark-light">
                                <span class="secondary-bold f16 waav-dark-main">Tax and Fees</span>
                                ({{taxList?.totalTax}}
                                % of total amount)
                            </p>
                            <p class="secondary-bold f20 purple-brown">
                                ${{ feeList?.totalFee | number : '0.0-2'}}
                            </p>
                        </div>
                    </div>
                    <div id="tax-calc">

                    </div>
                </div>

                <div>

                    <div class="checkbox-container">
                        <label class="checkbox-label">
                            I agree the terms and conditions.
                            <a *ngIf="pdfLink!==''" [href]="pdfLink" target="_blank" class="f13"> <i class="fas fa-external-link-alt primary-main"></i></a>
                            <input type="checkbox" formControlName="termsAndCondition" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="checkbox-container">
                        <label class="checkbox-label">
                            <!-- I have read and understood the Supplementary Information Memorandum and I acknowledge and
                            agree to
                            be
                            bound by the terms of the Supplementary Information Memorandum. -->
                            I provide this authority and instruction to act on the sale.
                            <a *ngIf="legalDocLink!==''" [href]="legalDocLink" target="_blank" class="f13"> <i class="fas fa-external-link-alt primary-main"></i></a>
                            <input type="checkbox" formControlName="legalDocAgreement" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="p-0 m-0">
    <div class="row m-0 w-100">
        <div class="col-lg-6 col-md-6 col-sm-12 bg-waav-bright-light ps-5">

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 row m-0 align-items-center justify-content-between ps-5 pe-4 pb-4">
            <div class="col-lg-5 col-md-6 col-sm-12 col-12 d-flex flex-column">
                <span class="primary-regular f16 generic-palette-dark-light">Amount you will receive when your Shares are sold</span>
                <span class="primary-bold f40 sencodary-main">${{receiveAmount | number : '0.0-2'}}</span>

            </div>
            <div class="col-lg-5 col-md-6 col-sm-12 col-12 align-self-end">
                <button class="btn btn-primary w-100" (click)="onSubmit()" [disabled]="!sellBrickletForm.valid">Place order to sell</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>
<h1 mat-dialog-title>Confirmation</h1>
<div mat-dialog-content>
    <p>{{data.title}}</p>
</div>
<div mat-dialog-actions align="end">

    <div class="row justify-content-between col-xl-10 col-lg-10 col-md-12 col-sm-12 mb-3">
        <button class="btn btn-primary light col-lg-5 col-md-6 col-sm-12" (click)="onNo()">No</button>

        <button class="btn btn-primary col-lg-5 col-md-6 col-sm-12" (click)="onYes()">Yes</button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class RapidIdService {

  constructor(private apiManagerService: ApiManagerService) { }

  initiateRapidId(data: any) {
    return this.apiManagerService.postRequest(RestEnds.initiateRapidId, data);
  }

  resendRapidId(data: any) {
    return this.apiManagerService.postRequest(RestEnds.resendRapidId, data);
  }

}

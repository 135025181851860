import { Injectable } from '@angular/core';
import { Pagination } from '../interfaces/pagination';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  // public cartImgValue =  new BehaviorSubject<boolean>(true);

  public cartImgValue = new BehaviorSubject<any>({});
  accountInfoCurrent = this.cartImgValue.asObservable();

  public cartBadgeEnable = new BehaviorSubject<boolean>(true);

  private cartSource = new BehaviorSubject<any>({});
  currentObject = this.cartSource.asObservable();

  private checkoutSource = new BehaviorSubject<any>({});
  OrderDetailsObject = this.checkoutSource.asObservable();

  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  getCart() {
    return this.apiManagerService.getRequest(RestEnds.cart, null);
  }

  getCartValue(cartID: string) {
    const data =  cartID;
    return this.apiManagerService.getRequestParam(RestEnds.cart, data)
  }

  getCartDetails(){
    return this.apiManagerService.getRequest(RestEnds.cartDetails, null)
  }

  addCart(data: any) {
    return this.apiManagerService.postRequest(RestEnds.cart, data)
  }

  updateCart(data: any) {
    return this.apiManagerService.putRequest(RestEnds.cart, data)
  }

  updateCheckoutCart(data: any) {
    return this.apiManagerService.putRequest(RestEnds.order, data)
  }

  deleteCart(){
    return this.apiManagerService.deleteRequest(RestEnds.cart );
  }

  deleteCartOrder(orderID){
    return this.apiManagerService.deleteRequest(RestEnds.order + "?orderGUID=" + orderID);
  }

  orderStatus(orderId:string,status:string){
    return this.apiManagerService.putRequestQueryParam(RestEnds.orderstatus, `orderGUID=${orderId} &status=${status}`)

  }

  checkout(data:any){
    return this.apiManagerService.postRequest(RestEnds.checkoutAPI, data)

  }

  getPortfolioData(){
    return this.apiManagerService.getRequest(RestEnds.portpolio, null);

  }

  getPendingOrdersLists(){
    return this.apiManagerService.getRequest(RestEnds.pendingOrders, null);
  }

  getCompleteOrdersLists(){
    return this.apiManagerService.getRequest(RestEnds.completeOrders, null);
  }

  getCartGuidForPendingOrders(data:any){
    const cartIdData = data.carGUID
    return this.apiManagerService.getRequestParam(RestEnds.pendingOrdersGuid ,cartIdData)
  }
  getOrderFlowForPendingOrders(data:any){
    const orderIdData = data.orderGUID
    return this.apiManagerService.getRequestParam(RestEnds.pendingOrderId ,orderIdData)
  }

  emptyCart(guid: string) {
    return this.apiManagerService.deleteRequest(RestEnds.cart + '?propertyGUID=' + guid)
  }

  registerNpd(data: any) {
    return this.apiManagerService.postRequest(RestEnds.registerNPD, data)
  }

  getStates(countryId?: any) {
    if (countryId) {
      return this.apiManagerService.getRequestParam(RestEnds.states, countryId);
    } else {
      return this.apiManagerService.getRequest(RestEnds.states, null);
    }
  }

  placeOrder(data: any) {
    return this.apiManagerService.postRequest(RestEnds.placeOrder, data)
  }

  titlePending(data: Pagination) {
    return this.apiManagerService.postRequest(RestEnds.NPDTitleTransfer, data)
  }

  passCartImg(dataVal) {
    //passing the data as the next observable
    this.cartImgValue.next(dataVal);
  }

  passCartBadge(data){
    this.cartBadgeEnable.next(data);
  }

  cartObject(object: any) {
    this.cartSource.next(object);
  }

  checkoutObject(orderData:any){
    this.checkoutSource.next(orderData);
  }

}

import { MapsAPILoader } from '@agm/core';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { StaticMessage } from 'src/app/core/utils/constants';
import { countryCodes } from 'src/app/core/utils/countryCodes';
import { HelperService } from 'src/app/core/utils/helper.service';
import { ValidatePassword } from 'src/app/shared/validators/password-validator';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  showPassword = false;
  showConfirmPassword = false;
  abnEnable: Boolean = false;
  isChecked: Boolean = false;
  signupForm: FormGroup = new FormGroup(
    {
      firstName: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]),
      lastName: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
      countryCode: new FormControl('+1', [Validators.required]),
      mobile: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.pattern("^[1-9][0-9]*$"),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(StaticMessage.passwordPattern),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
      // userType: new FormControl('INDIVIDUAL', [Validators.required]),
      address: new FormControl('',[Validators.required]),
      // abn: new FormControl(''),

      // unitNumber: new FormControl(""),
      // streetNumber: new FormControl("", [Validators.required]),
      // streetName: new FormControl("", [Validators.required]),
      // streetType: new FormControl("", [Validators.required]),
      // postCode: new FormControl("", [Validators.required]),
      // state: new FormControl("", [Validators.required]),
      // suburb: new FormControl("", [Validators.required]),
      // countryGUID: new FormControl("", [Validators.required]),
      // additionalInfo: new FormControl(""),
    },
    {
      validators: ValidatePassword.MatchPassword,
    }
  );

  passwordError = StaticMessage.pwdPatternAlert;
  // referralGuid = '';
  // referralDetails: {
  //   compaignName: string;
  //   logo: string;
  // };
  stateList: any[] = [];
  countryList: any[] = [];
  countryCodes = countryCodes;
  // userType: any = ['INDIVIDUAL', 'SMSF', 'COMPANY'];
  // userTypeDefault = 'INDIVIDUAL';

  // changingValue(e) {
  //   this.signupForm.get('abn').reset();
  //   if (e.value == 'INDIVIDUAL') {
  //     this.getEntityname();
  //     this.abnEnable = false;
  //     this.signupForm.get('abn').setValidators([]);
  //     this.signupForm.get('abn').updateValueAndValidity();
  //   } else {
  //     this.getEntityname();
  //     this.signupForm.get('abn').setValidators([Validators.required]);
  //     this.signupForm.get('abn').updateValueAndValidity();
  //     this.abnEnable = true;
  //   }
  // }

  agentName: string;
  campaignName: string;


  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  constructor(
    public helper: HelperService,
    private authService: AuthService,
    private activateRoute: ActivatedRoute,
    private lookupService: LookupService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    // this.activateRoute.queryParams.subscribe((params) => {
    //   this.agentName = params['agent'];
    //   this.campaignName = params['campaign'];
    // });
   
  }

  ngOnInit(): void {
    // this.referralGuid = this.activateRoute.snapshot.params['guid'];

    // if (this.referralGuid) {
    //   this.authService.getCampaignDetails(this.referralGuid).subscribe(
    //     (r: any) => {
    //       this.referralDetails = r.response.data.campaign;
    //     },
    //     (e) => {
    //       this.helper.error(e);
    //       this.referralGuid = '';
    //     }
    //   );
    // }
    this.getStates();
    this.getCountry();
    this.GooglePlaces();
   
  }

  GooglePlaces(){
    this.mapsAPILoader.load().then(() => {
      //  this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.signupForm.patchValue({
            latitude: this.latitude,
            longitude: this.longitude
          });
          this.getAddress(this.latitude, this.longitude);
          //  this.setCurrentLocation();
        });
      });
    });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        // console.log(results);
        // console.log(status);
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
            this.signupForm.get('address').setValue( this.address)

          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  get password() {
    return this.signupForm.get('password');
  }

  get confirmPassword() {
    return this.signupForm.get('confirmPassword');
  }

  // getEntityname(){
  //   this.signupForm.get("abn").setValue(this.signupForm.controls.firstName.value + " " + this.signupForm.controls.lastName.value);
  // }

  passwordMismatch() {
    return this.confirmPassword?.errors?.mismatchedPasswords;
  }

  onSubmit() {
    const data = {
      FirstName: this.signupForm.controls.firstName.value,
      LastName: this.signupForm.controls.lastName.value,
      EmailID: this.signupForm.controls.email.value,
      Password: this.signupForm.controls.password.value,
      Mobile: this.signupForm.controls.mobile.value,
      CountryCode: this.signupForm.controls.countryCode.value,
      Address: this.signupForm.controls.address.value
      //CampaignGUID: this.referralGuid,
      // UserType: this.signupForm.controls.userType.value,
      // EntityName: this.signupForm.controls.abn.value,
      // StreetType: this.signupForm.controls.address.value,
    };
    this.helper.showSpinner();
    this.authService.signUp(data).subscribe(
      (s: any) => {
        this.helper.navigate(
          '/verify-email/' + data.EmailID
        );
        this.helper.hideSpinner();
      },
      (error: any) => {
        this.helper.hideSpinner();
        this.helper.error(error);
      }
    );
  }

  getStates() {
    this.lookupService.getStates().subscribe(
      (s: any) => {
        const res = s.data.states;
        this.stateList = res;
      },
      (e) => {}
    );
  }

  getCountry() {
    this.lookupService.getCountryList().subscribe(
      (s: any) => {
        this.countryList = s.data.countries;
      },
      (e) => {}
    );
  }

  restForm() {
    // this.signupForm.reset();
    // this.changeDetectorRef.detectChanges();
  }
  

  letterOnly(event) : Boolean{
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  onlyNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
}

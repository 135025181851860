<!-- <div class="pos-rel" > -->
    <label class="kyc-img-upload-container" for="file-input{{idNumber}}" (drop)="handleEnter($event)" (dragover)="(dragOverHandler($event))" title="Click here to browse or drop the file">
        <div class="upload-info" *ngIf="!previewPath">
            <p>Drop your file(s) here or <bdi class="wp-yellow primary-bold">Browse</bdi></p>
            <p>Max. file size: 2 MB | Supported files: JPG, PDF,PNG</p>
        </div>
        <div id="preview-container" *ngIf="previewPath">
            <!-- <img [src]="previewPath" *ngIf="fileExt!=='pdf'" alt=""> -->
            <div id="pdf-container" >
                <img [src]="pdfUrl" id="pdf-img" *ngIf="fileExt=='pdf'" alt="">
                <img [src]="previewPath" id="pdf-img" *ngIf="fileExt!=='pdf'" alt="">
                <span class="muli-semi-bold f12 ms-2">
                    {{fileName| slice:0:15}}<bdi *ngIf="fileName.length>15">... </bdi>.{{fileExt}}
                </span>
            </div>
        </div>
    </label>
    <div class="upload-info-outside" *ngIf="previewPath">
        <!-- <p>Drop your file(s) here or <bdi class="pumpkin-orange">Browse</bdi></p> -->
        <p>Max. file size: 4 MB | Supported files: JPG, PDF,PNG</p>
    </div>
    <input class="kyc-img-input" type="file" #fileUpload id="file-input{{idNumber}}" accept=".jpg,.jpeg,.png,.pdf" />
<!-- </div> -->
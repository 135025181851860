import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuySellService } from 'src/app/core/services/buy-sell.service';
import { HelperService } from 'src/app/core/utils/helper.service';
import { BuyInitiatedDialogComponent } from '../buy-initiated-dialog/buy-initiated-dialog.component';

@Component({
  selector: 'app-buy-dialog',
  templateUrl: './buy-dialog.component.html',
  styleUrls: ['./buy-dialog.component.scss']
})
export class BuyDialogComponent implements OnInit {

  pdfLink = '';
  legalDocLink = '';

  historyList = [];
  taxList;
  feeList;
  totalAmount = 0;

  accountBalance = 0;

  buyBrickletForm = new FormGroup({
    NoOfBricklet: new FormControl('', [Validators.required]),
    termsAndCondition: new FormControl(false, Validators.pattern('true')),
    legalDocAgreement: new FormControl(false, Validators.pattern('true'))
  });
  // FIXME:remove static percentage after demo - 18/10/2021
  totalTaxPercentage = 6;
  constructor(
    public dialogRef: MatDialogRef<BuyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private buyService: BuySellService,
    private helper: HelperService,
    private dialog: MatDialog
  ) {

    

  }

  ngOnInit(): void {


    // const legalDocControl = <FormControl>this.buyBrickletForm.get('legalDocAgreement');
    // const termsandConditionsControl = <FormControl>this.buyBrickletForm.get('termsAndCondition');
    // legalDocControl.valueChanges.subscribe(value => {
    //   if (this.legalDocLink) {
    //     legalDocControl.setValidators([Validators.pattern('true')]);
    //   }
    //   else {
    //     legalDocControl.setValidators(null);
    //   }
    //   legalDocControl.updateValueAndValidity();
    // });

    // termsandConditionsControl.valueChanges.subscribe(value => {
    //   if (this.pdfLink) {
    //     termsandConditionsControl.setValidators([Validators.pattern('true')]);
    //   }
    //   else {
    //     termsandConditionsControl.setValidators(null);
    //   }
    //   termsandConditionsControl.updateValueAndValidity();
    // })

    this.isBuyAvailable();
  }

  getMarketPrice() {
    
    let data = {
      PropertyGUID: this.data.id,
      Quantity: parseInt(this.buyBrickletForm.get('NoOfBricklet').value)
    }
    data.Quantity = data.Quantity ? data.Quantity : 0;
    if (data.Quantity > 0) {
      this.helper.showSpinner();
      this.buyService.getBuyerMarketPrice(data).subscribe(
        (r: any) => {
          this.helper.hideSpinner();
          this.historyList = r.data.marketPrice.nearPriceRange;
          this.feeList = r.data.marketPrice.fees;
          this.taxList = r.data.marketPrice.tax;
          this.totalAmount = r.data.marketPrice.totalAmount;
          this.totalTaxPercentage = this.taxList?.buyerStampDutyTax+this.taxList?.buyerPropertyTransferTax+this.taxList?.buyerAdministrationTax;
        },
        (e) => {
          this.helper.error(e);
          this.helper.hideSpinner();
        }
      );
    } else {
      this.historyList = [];
      this.feeList = null;
      this.taxList = null;
      this.totalAmount = 0;
    }
  }

  onSubmit() {
    const data = {
      PropertyGUID: this.data.id,
      NoOfBricklet: this.buyBrickletForm.get('NoOfBricklet').value
    };
    this.helper.showSpinner();
    this.buyService.buyBricklet(data).subscribe(
      (r: any) => {
        this.helper.hideSpinner();
        this.helper.success(r.msg);
        const dialogRef = this.dialog.open(BuyInitiatedDialogComponent, {
          data: {
            id: this.data.id,
            qty: this.buyBrickletForm.get('NoOfBricklet').value,
            amount: this.totalAmount,
            isBpp: false
          }
        });
        this.dialogRef.close(true);
        dialogRef.afterClosed().subscribe(result => {
          this.dialogRef.close(true);
        });
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  isBuyAvailable() {
    this.buyService
      .isBrickletAvailableToBuy({ PropertyGUID: this.data.id })
      .subscribe(
        (r: any) => {
          const data: any = r.data;
          this.accountBalance = data.buyingDetails.balance;
          this.pdfLink = data.buyingDetails.termsConditionPDF;
          //this.legalDocLink = data.buyingDetails.legalDocumentPDF;
          this.helper.hideSpinner();
          this.totalTaxPercentage = data.buyingDetails.administrationFees+data.buyingDetails.propertyTransfer+data.buyingDetails.stampDuty;
        },
        e => {
          this.helper.hideSpinner();
        }
      );
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

<div class="container-fluid">
    <div id="background"></div>
    <div class="top">
        <h1>404</h1>
        <h3>page not found</h3>
    </div>
    <div class="container">
        <div class="ghost-copy">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
            <div class="four"></div>
        </div>
        <div class="ghost">
            <div class="face">
                <div class="eye"></div>
                <div class="eye-right"></div>
                <div class="mouth"></div>
            </div>
        </div>
        <div class="shadow"></div>
    </div>
    <div class="bottom">
        <p>Looks like you got lost... Or we trying to confuse you..</p>
        <p class="mt-1">Let us bring you at </p>
        <div class="buttons">
            <button class="btn" (click)="goto('/marketplace/list?login=true')">Login</button>
            <button class="btn" (click)="goto('/marketplace/list')">Marketplace</button>
        </div>
    </div>

</div>
<div id="floating-window" *ngIf="selPodcast">
    <div id="video-container">
        <div id="video-header" class="d-flex flex-row justify-content-between alig-items-center">
            <h5 class="m-0 primary-semi-bold">{{selPodcast?.title}}</h5>
            <button type="button" (click)="onVideoClose()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <audio controls autoplay id="audio">

        </audio>
    </div>
</div>
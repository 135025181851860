<div class="row m-0" *ngIf="(isDataLoaded && records.length>0 ) || loader">
    <h2 class="ms-3 title-border">
        Inspired from your saved list
        <a routerLink="/community/all-article/inspired" class="view-more">View more</a>
    </h2>
    <div *ngIf="!isDataLoaded">
        <div *ngFor="let r of [1,2,3]" class="col-12 row ms-0 me-0 mb-4">
            <div class="col-lg-5 col-md-5 col-sm-12">
                <ngx-skeleton-loader [theme]="{'height':'120px'}" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="ps-3 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-between">
                <div>
                    <ngx-skeleton-loader [theme]="{'height':'20px'}" appearance="line"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{'height':'50px'}" appearance="line"></ngx-skeleton-loader>
                </div>
                <ngx-skeleton-loader [theme]="{'width':'50%'}" appearance="line"></ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div *ngIf="isDataLoaded">
        <div *ngFor="let r of records" class="col-12 row ms-0 me-0 mb-4">
            <div class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-center">
                <img [src]="r.thumbnailURL" alt="">
            </div>
            <div class="ps-3 col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-between">
                <div>
                    <a [routerLink]="'/community/feed/'+r.articleGUID" class="mb-2">
                        <p class="title article-title">{{r.title | slice:0:45}}{{r.description.length>45?'...':''}}</p>
                    </a>
                    <!-- <p class="primary-bold f16 waav-dark-dark mb-2 title">{{r.title}}</p> -->
                    <p class="primary-regular f14 waav-gray-dark mb-0">
                        {{r.description | slice:0:100}}{{r.description.length>100?'...':''}}
                    </p>
                </div>
                <span class="f14 primary-bold waav-gray-dark mt-3">
                    {{r.createdDate | date :'MMM dd'}}.{{r.stateName}}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mat-content">
  <button mat-button class="close-icon" [mat-dialog-close]="true" style=" float: right; ">
    <mat-icon>close</mat-icon>
</button>
  <div class="row justify-content-center align-items-center text-center">
    <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 col-xs-12">
      <!-- <img src="/assets/InvCar-Dark-Horizontal.png" alt="" width="100"> -->
      <h1 class="primary-bold f30 waav-dark-dark mb-2">Welcome to INVCAR</h1>
      <p class="secondary-regular f12 tertiary-dark mb-3">
        KYC verification is important for the invcar to allow you to explore all features, please complete the KYC by
        simply following 3 steps.
      </p>
    </div>
  </div>
  <div class="row mt-4 justify-content-center align-items-center">
    <div class="col-xl-6 col-lg-6 col-md-7 col-sm-10 col-xs-12">
      <div class="d-flex flex-column">
        <span class="primary-semi-bold f24 waav-dark-dark" [hidden]="initProcess">
          KYC Process
        </span>
        <div class="row mt-2" *ngFor="let r of records" [hidden]="initProcess">
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4">
            <img [src]="r.iconSrc" alt="">
          </div>
          <div class="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-xs-8">
            <div class="d-flex flex-column">
              <span class="primary-semi-bold f18 waav-dark-dark"> {{r.step1}} </span>
              <span class="primary-semi-bold f18 waav-dark-dark"> {{r.step2}} </span>
              <span class="primary-semi-bold f18 waav-dark-dark"> {{r.step3}} </span>

            </div>
          </div>
        </div>
        <div class="row justify-content-center align-items-center mt-5" [hidden]="initProcess">
          <!-- <a (click)="modalClose()" class="col-xl-6 col-lg-6 col-md-10 col-sm-12">
            <button class="btn btn-primary w-100">Close</button>
          </a> -->
          <a routerLink="/dashboard/my-cars" class="col-xl-6 col-lg-6 col-md-10 col-sm-12">
            <button class="btn btn-primary w-100" (click)="proceedNext()">Proceed</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2" [hidden]="docUploadProcess">
    <mat-stepper [linear]="isLinear" #stepper>
      <form [formGroup]="KYCForm">
      <mat-step>
        <ng-template matStepLabel>ID Details</ng-template>

        <mat-form-field class="col-3" style="margin:10px">
          <mat-label>Select User Type</mat-label>
          <mat-select formControlName="IDType">
            <mat-option *ngFor=
            "let i of identityTypeList" [value]="i.id">{{i.name}}</mat-option>
          </mat-select>
          <mat-error>Select type</mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" style="margin:10px">
          <mat-label>ID Number</mat-label>
          <input matInput placeholder="ID Number" formControlName="IDNumber" maxlength="9" 
           required>
          <mat-error *ngIf="!(KYCForm.get('IDNumber')).valid">ID should be in 9 digits</mat-error>
        </mat-form-field>
        <mat-form-field class="col-4" style="margin:10px">
          <mat-label>Upload Document</mat-label>
          <button mat-icon-button matPrefix (click)="f_input.click()" class="attachFile" accept=".pdf">
            <mat-icon>attach_file</mat-icon>
          </button>
          <input type="text" (click)="f_input.click()" readonly matInput formControlName="UploadDoc" accept=".pdf"/>
          <input type="file" multiple hidden #f_input (change)="handleFileInputChange(f_input.files)" formControlName="UploadDoc"/>
          <mat-error>Doc should be accept 5mb size.pdf format</mat-error>
        </mat-form-field>
        <!-- <div class="col-md-2">
          <button class="btn btn-primary w100" mat-button matStepperNext>Next</button>
        </div> -->
        <div class="row justify-content-center align-items-center">
          <!-- <a (click)="modalClose()" class="col-xl-3 col-lg-3 col-md-10 col-sm-12">
            <button class="btn btn-primary w-100">Close</button>
          </a> -->
          <a class="col-xl-3 col-lg-3 col-md-10 col-sm-12">
            <button class="btn btn-primary w100" mat-button matStepperNext (click)="enableCamera()" *ngIf="!(KYCForm.get('IDType')).invalid && !(KYCForm.get('IDNumber')).invalid && !(KYCForm.get('UploadDoc')).invalid ">Next</button>
          </a>
        </div>
        <!-- </form> -->
      </mat-step>
      <mat-step  label="Capture a photo">
          <div class="row">
            <div class="col-md-6">
              <app-camera *ngIf="showWebcam" (pictureTaken)="handleImage($event)"></app-camera>
            </div>
            <div class="col-md-6">
              <div class="snapshot" *ngIf="webcamImage">
                <img [src]="webcamImage.imageAsDataUrl" formControlName="CaptureImage" width="200" height="150" />
              </div>
            </div>
          </div>
          <br>
            <!-- <div class="row">
              <div class="col-md-2">
                <button class="btn btn-primary w100" mat-button matStepperPrevious>Back</button>
              </div>
              <div class="col-md-2">
                <button class="btn btn-primary w100" mat-button matStepperNext>Next</button>
              </div>
            </div> -->
            <div class="row justify-content-center align-items-center">
              <a class="col-xl-3 col-lg-3 col-md-10 col-sm-12">
                <button class="btn btn-primary w100" mat-button matStepperPrevious>Back</button>
              </a>
              <a class="col-xl-3 col-lg-3 col-md-10 col-sm-12">
                <button class="btn btn-primary w100" mat-button matStepperNext [disabled]="!webcamImage">Next</button>
              </a>
            </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Review Details</ng-template>
        <div class="col-12">
          <div class="row" style="margin-left:30%;margin-right:20%">
            <p>ID TYPE : <b>{{ selectedOption}}</b></p>
              <p>ID NUMBER: <b>{{this.getValue("IDNumber")}}</b></p>
              <p *ngIf="this.f">UPLOADED DOCUMENT : <b>{{this.f.name}}</b></p>
              <div class="col-md-10">
                <p>CAPTURED IMAGE</p>
                <div class="snapshot" *ngIf="webcamImage">
                  <img [src]="webcamImage.imageAsDataUrl" formControlName="CaptureImage" width="200" height="150" />
                </div>
              </div>
          </div>
        </div>
        <div>
          <br>
          <br>
          <!-- <div class="row">
            <div class="col-md-2">
              <button class="btn btn-primary w100" mat-button matStepperPrevious>Back</button>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary w100" mat-button (click)="submit()">Submit</button>
            </div>
          </div> -->
          <div class="row justify-content-center align-items-center">
            <a class="col-xl-3 col-lg-3 col-md-10 col-sm-12">
              <button class="btn btn-primary w100" mat-button matStepperPrevious>Back</button>
            </a>
            <a class="col-xl-3 col-lg-3 col-md-10 col-sm-12">
              <button class="btn btn-primary w100" mat-button (click)="submit()">Submit</button>
            </a>
          </div>
        </div>
      </mat-step>
      </form>
    </mat-stepper>

  </div>
  <!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
        {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
      </button> -->

</mat-dialog-content>
import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'date',
})
export class ExtendDatePipe extends DatePipe implements PipeTransform {

   constructor(@Inject(LOCALE_ID) locale: string, private datePipe: DatePipe) {
      super(locale);
   }

   transform(value: any, format: any, timezone?: string, locale?: string): any {
      if (value !== null) {
         value = value + 'Z';
         return super.transform(value, format, timezone, locale);
         // return this.datePipe.transform(value, format, timezone, locale);
      }
      return null;
   }

}

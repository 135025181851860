 <empty-state *ngIf="(incomeLists.length==0)&&(isDataloaded)" [description]="'DASHBOARD.nodividendhistoryfound'"
    [navigateLink]="'/marketplace/list'" [navigateText]="'View marketplace'">
</empty-state>

<div *ngIf="incomeLists.length>0">
    <table class="table primary desktop-table">
        <thead>
            <tr>
                <th>{{ 'DASHBOARD.type' | translate }}</th>
                <th>{{ 'DASHBOARD.amount' | translate }}</th>
                <th>{{ 'DASHBOARD.processdate' | translate }}</th>
                <th>{{ 'DASHBOARD.reference' | translate }}</th>
                <th>{{ 'DASHBOARD.status' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let r of incomeLists">
                <td>{{r.type}}</td>
                <td class="bold">${{r.amount | number : '0.0-2'}}</td>
                <td>{{r.processDate | date : 'dd MMM yyyy'}}</td>
                <td class="">{{r.referenceId}}</td>
                <td class="init bold" *ngIf="r.status=='Initiated'">
                    <span class="cancel" (click)="onCancelDeposit(r.depositGUID)">{{ 'DASHBOARD.cancel' | translate }}</span>
                </td>
                <td class="approved bold" *ngIf="r.status=='succeeded'">{{ 'DASHBOARD.succeeded' | translate }}</td>
                <td class="approved bold" *ngIf="r.status=='paid'">{{ 'DASHBOARD.paid' | translate }}</td>
                <td class="approved bold" *ngIf="r.status=='Approved'">{{ 'DASHBOARD.approved' | translate }}</td>
                <td class="cancelled bold" *ngIf="r.status=='Cancelled'">{{ 'DASHBOARD.cancelled' | translate }}</td>
                <td class="reject bold" *ngIf="r.status=='Rejected'">{{ 'DASHBOARD.rejected' | translate }}</td>
                <td class="pending bold" *ngIf="r.status=='Pending'">{{ 'DASHBOARD.pending' | translate }}</td>
                <!-- <td class="download">
                    <a *ngIf="r.status === 'paid'" (click)="OnWithdrawDownloadReceipt(r.ledgerGUID,r.fileName)">{{ 'DASHBOARD.download' | translate }}</a>
                    <a *ngIf="r.status !== 'paid'" (click)="OnDepositDownloadReceipt(r.ledgerGUID,r.fileName)">{{ 'DASHBOARD.download' | translate }}</a>
                </td> -->
            </tr>
        </tbody>
    </table>

    <div class="mobile-table-container">
        <div *ngFor="let r of incomeLists; let i" class="bg-waav-bright-light mb-4">
            <table class="table mobile">
                <thead>
                    <tr>
                        <th>
                            <div class="d-flex flex-column">
                                <span class="primary-bold f18 waav-dark-main">{{r.type}}</span>
                                <p class="primary-regular f14 generic-palette-b-w-gray m-0">{{r.requestDate | date :
                                    'dd MMM yyyy'}}</p>
                            </div>
                        </th>
                        <th class="text-end vertical-align-middle">
                            <p class="m-0 expander-btn" (click)="expandBody(r.referenceID,$event)">${{r.amount | number : '0.0-2'}} AUD <img src="assets/images/arrow-right.svg" alt=""></p>
                            
                        </th>
                    </tr>
                </thead>
                <tbody class="collapse" [id]="r.referenceID">
                    <tr>
                        <td>{{ 'DASHBOARD.reference' | translate }}</td>
                        <td> <span class="f12">{{r.referenceID}}</span> </td>
                    </tr>
                    <tr>
                        <td>{{ 'DASHBOARD.processdate' | translate }}</td>
                        <td>{{r.processDate | date : 'dd MMM yyyy'}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'DASHBOARD.status' | translate }}</td>
                        <td
                            [ngClass]="{ 'approved': r.status=='Approved', 'cancelled':r.status=='Cancelled', 'reject':r.status=='Rejected' }">
                            {{r.status}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'DASHBOARD.receipt' | translate }}</td>
                        <td>
                            <a *ngIf="r.status === 'paid'" (click)="OnWithdrawDownloadReceipt(r.ledgerGUID,r.fileName)">{{ 'DASHBOARD.download' | translate }}</a>
                            <a *ngIf="r.status !== 'paid'" (click)="OnDepositDownloadReceipt(r.ledgerGUID,r.fileName)">{{ 'DASHBOARD.download' | translate }}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <app-pagination [totalRecords]="totalRecords" (pageChange)="onPageChange($event)"></app-pagination>
</div>
import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class DepositService {

  constructor(private apiManagerService: ApiManagerService) { }

  getBrickletBankDetails(data) {
    return this.apiManagerService.postRequest(RestEnds.getBrickletBankDetails, data);
  }

  bankTransferConfirmation(data) {
    return this.apiManagerService.putRequest(RestEnds.bankTransferConfirmation, data);
  }

  generatePayId() {
    return this.apiManagerService.postRequest(RestEnds.payIdGenerate, null);
  }

  getPayIdDetails() {
    return this.apiManagerService.getRequest(RestEnds.payIdDetails, null)
  }

  createPaymentRequest(data) {
    return this.apiManagerService.postRequest(RestEnds.cryptoCreatePayment, data);
  }

  stripeDepositRequest(data) {
    return this.apiManagerService.postRequest(RestEnds.depositRequest, data)
  }

  verifyDepositRequest(Id){
    return this.apiManagerService.getRequest(RestEnds.depositVerifyRequest,'checkout_session_id=' + Id)
  }
}

<!-- <div *ngIf="propertyGallery.length > 0" id="carouselExampleControls" class="carousel slide mb-4 carousel-box"
  data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item" *ngFor="let img of propertyGallery;let idx=index;"
      [ngClass]="{'active' : idx == counter}" [@thumbState]="idx === counter ? 'active' : 'inactive'">
      <img class="d-block w-100" src={{img.image}} alt="property image">
      <button class="btn btn-watching btn-info " (click)="onWatch()">
        <img src="../../../../assets/images/watch-icon.svg">
        <span>{{isFavorite==true?'Unwatch Property':'Watch Property'}} </span>
      </button>
    </div>
  </div>
  <a class="carousel-control-prev" role="button" data-slide="prev" (click)="prev()">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" role="button" data-slide="next" (click)="next()">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div> -->

<div class="slider">
  <div class="slider-box">
    <img src="/assets/images/video-play.svg" (click)="videoPlayer.play()" class="play-icon" alt="">
    <video class="slider-img" #videoPlayer >
      <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4">
    </video>
  </div>
  <div class="slider-box" *ngFor="let img of propertyGallery;let idx=index;">
    <img class="slider-img" src={{img.image}} alt="">
  </div>
</div>
import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class BuySellService {

  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  sellBricklet(data) {
    return this.apiManagerService.postRequest(
      RestEnds.sellBricklet,
      data
    );
  }
  isBrickletAvailableToSell(data) {
    return this.apiManagerService.postRequest(
      RestEnds.sellIsAvailable,
      data
    );
  }

  isBrickletAvailableToBuy(data) {
    return this.apiManagerService.postRequest(
      RestEnds.buyIsAvailable,
      data
    );
  }

  buyBricklet(data) {
    return this.apiManagerService.postRequest(
      RestEnds.buyBricklet,
      data
    );
  }

  getBuyerMarketPrice(data) {
    return this.apiManagerService.postRequest(
      RestEnds.buyerMarketPrice, data
    );
  }

  getSellerMarketPrice(data) {
    return this.apiManagerService.postRequest(
      RestEnds.sellerMarketPrice, data
    );
  }

  //Bpp buy
  isBrickletAvailableToBppBuy(data) {
    return this.apiManagerService.postRequest(
      RestEnds.bppBuyIsAvailable,
      data
    );
  }

  getBppBuyerMarketPrice(data) {
    return this.apiManagerService.postRequest(
      RestEnds.bppBuyerMarketPrice, data
    );
  }

  buyBppBricklet(data) {
    return this.apiManagerService.postRequest(
      RestEnds.buyBppBricklet,
      data
    );
  }

  //Bpp Sell
  isBrickletAvailableToBppSell(data) {
    return this.apiManagerService.postRequest(
      RestEnds.bppSellIsAvailable,
      data
    );
  }

  getBppSellerMarketPrice(data) {
    return this.apiManagerService.postRequest(
      RestEnds.bppSellerMarketPrice, data
    );
  }

  sellBppBricklet(data) {
    return this.apiManagerService.postRequest(
      RestEnds.sellBppBricklet,
      data
    );
  }

  bppSign(data) {
    return this.apiManagerService.postRequest(
      RestEnds.bppSign,
      data
    );
  }

  buyOnDocSignBricklet(data) {
    return this.apiManagerService.postRequest(
      RestEnds.buyDocSignInitiate,
      data
    );
  }

  SignDocuments(data){
    const DeedData = "orderGUID=" + data.orderGUID;
    return this.apiManagerService.getRequestQueryParam(RestEnds.signDeedDocument, DeedData);
  }

  paywithBalance(data){
    const pay = 'orderGUID=' + data;
    return this.apiManagerService.postRequestQueryParam(RestEnds.buySellTransaction,pay);
  }
}

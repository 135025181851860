import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ValidatePassword {

    static MatchPassword(abstractControl: AbstractControl): ValidationErrors | null {
        let password = abstractControl.get('password')?.value;
        let confirmPassword = abstractControl.get('confirmPassword')?.value;
        if (password != confirmPassword) {
            abstractControl.get('confirmPassword')?.setErrors({
                mismatchedPasswords: true
            })
            return { mismatchedPasswords: true };
        } else {
            return null
        }
    }

}

<div class="row mt-4">
  <h2 class="primary-bold waav-dark-main f30">Managers of this account</h2>
  <div class="col-lg-12" id="setting-container">
    <form>
      <table class="table primary desktop-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let manager of managers">
            <td class="bold">{{ manager.name }}</td>
            <td class="bold">{{ manager.email }}</td>
            <td class="bold">{{ manager.mobile }}</td>
          </tr>
        </tbody>
      </table>
    </form>

    <div class="mobile-table-container">
      <div *ngFor="let manager of managers" class="bg-waav-bright-light mb-4">
        <table class="table mobile">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{{ manager.name }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ manager.email }}</td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td>{{ manager.mobile }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>
<body>

  <!-- <header id=header-wrap>
<nav class="navbar navbar-expand-lg fixed-top scrolling-navbar menu-bg" style="background-color: #192149;height:90px;">
<div class=container style="justify-content: center;">

<div class=navbar-header>
<img src="../../../assets/images/landing-page/InvCar - Bright - Horizontal.svg" style="width:290px;">
</div>

</div> -->

  <!-- <ul class=mobile-menu>
<li>
<a href="#header-wrap">Home</a>
</li>
<li>
<a href="#services">Services</a>
</li>
<li>
<a href="#team">Team</a>
</li>
<li>
<a href="#contact">Contact</a>
</li>
</ul> -->

  <!-- </nav>

</header> -->

  <!-- <section class=intro> -->
   
    <div class="container">
      <div class="content-section">
      <div class="row">
        <div class="col-12">
          <h2 class="large-title">INVEST IN A RENTAL <br> CAR BUSINESS AND <br> EARN PASSIVE INCOME <br> WITHOUT THE HASSLE <br> OF MANAGING A FLEET</h2>
        <!-- </div>
        <div class="col-7"> -->
          <img src="../../../assets/images/landing-page/Isolation_Mode.png" class="intro-img">
        </div>
      </div>

      <p>InvCar is a cutting-edge platform that transforms the way people access and enjoy rental vehicles. Our innovative approach allows for co-ownership opportunities, empowering individuals to build their vehicle portfolio hassle-free. Experience a new and convenient way to be part of the car-sharing community.</p>

       <p>Become a part of the rental car industry and enjoy the benefits without the stress of managing a fleet. With this business model, you can reap the rewards of a profitable venture while leaving all the operational complexities to the car managers as they handle the logistics and ensure seamless operations from beginning to end.</p>
     <br>
     <br>

    <h2 class="works-title">HOW IT WORKS</h2>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 column">
        <div class="card">
          <div class="icon">
            <img src="../../../assets/images/how-its-works-icons/1.png">
          </div>
          <div class="card-content">
            <h5>REGISTER ON <br> INVCAR PLATFORM</h5>
            <span>Register easily. Fill in your info and choose a password.</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 column">
        <div class="card">
          <div class="icon">
            <img src="../../../assets/images/how-its-works-icons/2.png">
          </div>
          <div class="card-content">
            <h5>VERIFY YOUR<br> IDENTITY </h5>
            <span>Check your mail to verify your account.</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 column">
        <div class="card">
          <div class="icon">
            <img src="../../../assets/images/how-its-works-icons/3.png">
          </div>
          <div class="card-content">
            <h5>DEPOSIT ON  <br>YOUR ACCOUNT</h5>
            <span>Transfer money to your account easily.</span>
          </div>
        </div>
      </div>
      <br>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 column">
        <div class="card">
          <div class="icon">
            <img src="../../../assets/images/how-its-works-icons/4.png">
          </div>
          <div class="card-content">
            <h5>BROWSE <br> CARS</h5>
            <span>Make a choice on the marketplace.</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 column">
        <div class="card">
          <div class="icon">
            <img src="../../../assets/images/how-its-works-icons/5.png">
          </div>
          <div class="card-content">
            <h5>SIGN YOUR <br> DEED</h5>
            <span>Once purchased, you receive the deed to sign and finalize the transaction.</span>
          </div>
        </div>
      </div>
       <div class="col-12 col-sm-6 col-md-6 col-lg-4 column">
        <div class="card">
          <div class="icon">
            <img src="../../../assets/images/how-its-works-icons/6.png">
          </div>
          <div class="card-content">
            <h5>TRACK <br> PERFORMANCES</h5>
            <span>Login to your account and track your portfolio performances on the dashboard.</span>
          </div>
        </div>
      </div>
      
    </div>
      </div>
    </div>
      <br>
      <br>
    
  <!-- </section> -->

  
  <!-- <section id=services class=section-padding>
    <div class=container>
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-8 col-xs-12 text-center">

          <h2 class=section-title>
            Creating Passive Income with InvCar</h2>

        </div>
      </div>
      <div class=row>
        <div class="col-lg-6 col-md-6 col-xs-12 fadeInUp" data-animation=fadeInUp>
          <div class=services-item>
           
            <h3><a href="#">Fractional ownership</a></h3>
            <p>Our business offers the unique opportunity to own a fraction of a rental car through blockchain technology. This means that customers can invest in a high-value asset without the burden of full ownership, allowing them to enjoy the benefits of car ownership without the full cost.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 fadeInUp" data-animation=fadeInUp data-delay=300>
          <div class=services-item>
            <img src="../../../assets/images/landing-page/1.png">
          </div>
        </div>
        <br>
        <br>
        <div class="col-lg-6 col-md-6 col-xs-12 fadeInUp mt-3" data-animation=fadeInUp data-delay=300>
          <div class=services-item>
            <img src="../../../assets/images/landing-page/2.png">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 fadeInUp" data-animation=fadeInUp>
          <div class=services-item>
           
            <h3><a href="#">Monthly dividends </a></h3>
            <p> By investing in our rental car fractions, customers can receive monthly dividends from the rental income generated by the vehicle. This means that customers can earn passive income without any active involvement in managing the car. </p>
          </div>
        </div>
        <br>
        <br>
        <div class="col-lg-6 col-md-6 col-xs-12 fadeInUp" data-animation=fadeInUp>
          <div class=services-item>
           
            <h3><a href="#">High returns</a></h3>
            <p>Our business promises customers a return of 33% per year, making it an attractive investment opportunity. With the potential for high returns and passive income, our fractional rental car ownership through blockchain technology is a smart choice for investors looking to diversify their portfolio and maximize their returns. </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 fadeInUp" data-animation=fadeInUp data-delay=300>
          <div class=services-item>
            <img src="../../../assets/images/landing-page/3.png">
          </div>
        </div>


      </div>

      
    </div>
  </section> -->
  
  <!-- <section class="wrap">
    <div class="item" id="item-1">
      <img src="../../../assets/images/landing-page/1.png" alt="">
      <div class="content">
        <h1>Fractional ownership</h1>
        <p>Our business offers the unique opportunity to own a fraction of a rental car through blockchain technology. This means that customers can invest in a high-value asset without the burden of full ownership, allowing them to enjoy the benefits of car ownership without the full cost.</p>
      </div>
    </div>
    <div class="item flip" id="item-2">
      <img src="../../../assets/images/landing-page/2.png" alt="">
      <div class="content">
        <h1>Monthly dividends</h1>
        <p>By investing in our rental car fractions, customers can receive monthly dividends from the rental income generated by the vehicle. This means that customers can earn passive income without any active involvement in managing the car. </p>
      </div>
    </div>
    <div class="item" id="item-3">
      <img src="../../../assets/images/landing-page/3.png" alt="">
      <div class="content">
        <h1>High returns</h1>
        <p>Our business promises customers a return of 33% per year, making it an attractive investment opportunity. With the potential for high returns and passive income, our fractional rental car ownership through blockchain technology is a smart choice for investors looking to diversify their portfolio and maximize their returns.</p>
      </div>
    </div>
  </section> -->
  <!-- <section id=services class=section-padding>
  <div class=container>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-8 col-xs-12 text-center">

      <h2 class=section-title>
        Creating Passive Income with InvCar</h2>

    </div>
    </div>
    
    <div class="slider__wrapper">
 
      <div class="slider">
        <div class="slider__content">
          <div class="slider__text">
            <h3>Fractional ownership</h3>
            <p>Our business offers the unique opportunity to own a fraction of a rental car through blockchain technology. This means that customers can invest in a high-value asset without the burden of full ownership, allowing them to enjoy the benefits of car ownership without the full cost.</p>
          </div>
          <div class="slider__image">
    
            <img src="../../../assets/images/landing-page/1.png">
    
          </div>
    
        </div>
        <div class="slider__content">
          <div class="slider__text">
            <h3>Monthly dividends</h3>
            <p>By investing in our rental car fractions, customers can receive monthly dividends from the rental income generated by the vehicle. This means that customers can earn passive income without any active involvement in managing the car. </p>
          </div>
          <div class="slider__image">
            <img src="../../../assets/images/landing-page/2.png">
    
          </div>
    
        </div>
        <div class="slider__content">
          <div class="slider__text">
            <h3>High returns</h3>
            <p>Our business promises customers a return of 33% per year, making it an attractive investment opportunity. With the potential for high returns and passive income, our fractional rental car ownership through blockchain technology is a smart choice for investors looking to diversify their portfolio and maximize their returns.</p>
          </div>
          <div class="slider__image">
    
            <img src="../../../assets/images/landing-page/3.png">
    
          </div>
    
        </div>
       
      </div>
    
    </div>

  </div>
  </section> -->



  
  <!-- <section id=team class=section-padding>
    <div class=container>
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-8 col-xs-12 text-center">
          <h2 class=section-title>
            Subscribe to our Newsletter</h2>
        </div>
      </div>
      <div class="row mt-5 justify-content-center">
        <div class="col-sm-2 col-md-2 col-lg-3"></div>

          <div class="col-sm-4 col-md-4 col-lg-4">
            <div class=form-group>
              <input class="form-control" type="email" formControlName="email" placeholder="Enter your Email Address">
            </div>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-4">
            <div class=form-submit>
              <button type=submit class="btn btn-common" id=form-submit (click)="subscribeUser()"><i class="fa fa-paper-plane" aria-hidden=true></i> Subscribe</button>
              <div id=msgSubmit class="h3 text-center hidden"></div>
              </div>
          </div>
          <div class="col-sm-2 col-md-2 col-lg-2"></div>


      </div>
    </div>
  </section> -->

  <!-- Content Source: https://www.farmflavor.com/at-home/cooking/10-fun-facts-about-apples/  -->


  <!-- 
<footer class="footer-area section-padding">
<div class=container>
<div class=row>
<div class=col-md-12>
<div class="footer-text text-center">

<p>All Rights Reserved.</p>
</div>
</div>
</div>
</div>
</footer> -->


  <a href="#" class=back-to-top>
    <i class=lni-chevron-up></i>
  </a>


</body>
<!-- </html> -->

<form [formGroup]="privateCompanyForm">

    <div class="row w-100">

        <!-- COMPANY INFORMATION -->

        <div class="col-12">
            <div class="bg-white form-div w-100 mb-4">
                <!-- <p class="exo2-bold muted-blue f20 mb-3">Company information</p> -->

                <div class="form-group col-12">
                    <label class="form-label required">ACN</label>
                    <input type="text" formControlName="ACN" name="ACN"
                        class="form-control" maxlength=30>
                </div>

                <div class="form-group col-12">
                    <label class="form-label required">ABN</label>
                    <input type="text" formControlName="ABN" name="ABN"
                        class="form-control" maxlength=30>
                </div>

                <div class="form-group col-12">
                    <label class="form-label required">Company Name</label>
                    <input type="text" formControlName="companyName" name="companyName"
                        class="form-control" maxlength=30>
                </div>
            </div>
        </div>


        <!-- COMPANY EXTRACT -->

        <div class="col-12">
            <div class="bg-white form-div w-100 mb-4">
                <p class="primary-semi-bold f20 waav-dark-main">Company Extract</p>
                <app-img-upload [sendFiles]="sendFiles" (getFiles)="getImageFiles($event)"></app-img-upload>
            </div>
        </div>


        <!-- REG OFFICE ADDRESS -->

        <div class="col-12">
            <div class="bg-white form-div w-100 mb-4">
                <app-pvt-company-address-form [addressFormsDataObject]="addressFormsDataObject?.registeredOffice"
                    [formTitle]="addressFormTitles.registerOffice"
                    (formDataEmitter)="getRegisterAddressFormData($event)">
                </app-pvt-company-address-form>
            </div>
        </div>

        <!-- PRINCIPAL ADDRESS -->

        <div class="col-12">
            <div class="bg-white form-div w-100 mb-4">
                <app-pvt-company-address-form
                    [addressFormsDataObject]="addressFormsDataObject?.principlePlaceOfBusiness"
                    [formTitle]="addressFormTitles.principalOffice"
                    (formDataEmitter)="getPrincipalAddressFormData($event)">
                </app-pvt-company-address-form>
            </div>
        </div>
    </div>
</form>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrgAddressInfo, OrgAddressProof } from 'src/app/core/interfaces/org-individual-kyc.interface';
import { ImageService } from 'src/app/core/services/image.service';
import { LookupService } from 'src/app/core/services/lookup.service';

@Component({
  selector: 'app-org-address-information',
  templateUrl: './address-information.component.html',
  styleUrls: ['./address-information.component.scss']
})
export class AddressInformationComponent implements OnInit {

  addressInfoForm: FormGroup;
  docList = [];
  stateList = [];
  sendFiles = {
    docType: "ORG-ADDRESS-PROOF",
    docText: "FRONT SIDE",
  };

  preStatus = false;
  @Output() OrgAddressInfo = new EventEmitter();
  @Input() storedData: {
    addressInfo: OrgAddressInfo;
    addressProof: OrgAddressProof;
  };
  proofDoc = {
    file: null,
    url: "",
  };
  constructor(
    private lookupService: LookupService,
    private imageService: ImageService
  ) {}

  ngOnInit() {
    this.lookupService.getAddressProofList().subscribe(
      (s: any) => {
        const result = s.data.addressProofType;
        if (result) {
          this.docList = result;
        }
      },
      (e) => {}
    );

    this.lookupService.getStates().subscribe(
      (s: any) => {
        const res = s.data.states;
        this.stateList = res;
      },
      (e) => {}
    );
    this.addressInfoForm = new FormGroup({
      unitNumber: new FormControl(""),
      streetNumber: new FormControl("", [Validators.required]),
      streetName: new FormControl("", [Validators.required]),
      streetType: new FormControl("", [Validators.required]),
      postCode: new FormControl("", [Validators.required]),
      state: new FormControl("", [Validators.required]),
      suburb: new FormControl("", [Validators.required]),
      additionalInfo: new FormControl(""),
      proofDocType: new FormControl("", [Validators.required]),
      proofDoc: new FormControl(false, [Validators.pattern("true")]),
    });

    if (this.storedData) {
      const info = {
        ...this.storedData.addressInfo,
        ...this.storedData.addressProof,
      };

      this.setStoredValue(
        info.unitNo,
        info.streetNo,
        info.streetName,
        info.streetType,
        info.postalCode,
        info.stateGUID,
        info.suburb,
        info.additionalInfo,
        info.proofTypeGUID,
        true
      );
      this.imageService.appendImageFile({
        isFileExist: true,
        docType: "ORG-ADDRESS-PROOF",
        previewUrl: info.proofURL,
      });
    }

    this.addressInfoForm.statusChanges.subscribe((s) => {
      if (s && s === "VALID") {
        this.OrgAddressInfo.emit(this.getFormData());
        this.preStatus = true;
      } else {
        if (this.preStatus) {
          this.OrgAddressInfo.emit(null);
        }
        this.preStatus = false;
      }
    });
  }

  canShowError(control: string) {
    const field = this.addressInfoForm.controls[control];
    return !field.valid && field.touched && field.dirty;
  }

  hasError(control: string, error: string) {
    return this.addressInfoForm.controls[control].hasError(error);
  }

  getValue(control: string) {
    return this.addressInfoForm.controls[control].value;
  }

  getFormData() {
    const formData = new FormData();
    formData.append("AI_UnitNo", this.getValue("unitNumber"));
    formData.append("AI_StreetNo", this.getValue("streetNumber"));
    formData.append("AI_StreetName", this.getValue("streetName"));
    formData.append("AI_StreetType", this.getValue("streetType"));
    formData.append("AI_PostalCode", this.getValue("postCode"));
    formData.append("AI_StateGUID", this.getValue("state"));
    formData.append("AI_Suburb", this.getValue("suburb"));
    formData.append("AI_AdditionalInfo", this.getValue("additionalInfo"));
    formData.append("PoA_ProofTypeGUID", this.getValue("proofDocType"));
    if (this.proofDoc.file) {
      formData.append("PoA_Proof", this.proofDoc.file);
    }
    if (this.proofDoc.url) {
      formData.append("PoA_ProofURL", this.proofDoc.url);
    }
    return formData;
  }

  // tslint:disable:object-literal-shorthand
  setStoredValue(
    unitNumber: string,
    streetNumber: string,
    streetName: string,
    streetType: string,
    postCode: string,
    state: string,
    suburb: string,
    additionalInfo: string | null,
    docType: string,
    proofDoc: boolean
  ) {
    this.addressInfoForm.setValue({
      unitNumber: unitNumber,
      streetNumber: streetNumber,
      streetName: streetName,
      streetType: streetType,
      postCode: postCode,
      state: state,
      suburb: suburb,
      additionalInfo: additionalInfo,
      proofDocType: docType,
      proofDoc: proofDoc,
    });
  }
  // tslint:enable:object-literal-shorthand

  getFiles(e) {
    if (e) {
      if (e.imgFile) {
        this.proofDoc.file = e.imgFile;
        this.proofDoc.url = "";
      }
      if (e.imgUrl) {
        this.proofDoc.file = null;
        this.proofDoc.url = e.imgUrl;
      }
      this.addressInfoForm.patchValue({
        proofDoc: true,
      });
    } else {
      this.addressInfoForm.patchValue({
        proofDoc: false,
      });
      this.proofDoc.file = null;
      this.proofDoc.url = "";
    }
  }

}

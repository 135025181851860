import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-bpp-installments-dialog',
  templateUrl: './bpp-installments-dialog.component.html',
  styleUrls: ['./bpp-installments-dialog.component.scss']
})
export class BppInstallmentsDialogComponent implements OnInit {

  records = [];
  bppId;
  status;
  dueCount = 0;
  unPaidCount = 0;
  constructor(
    public dialogRef: MatDialogRef<BppInstallmentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardService: DashboardService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    if (this.data.records.length > 0) {
      this.records = this.data.records;
      const unPaidDues = this.records.filter((r, i) => {
        return r.status == null
      });
      this.unPaidCount = unPaidDues.length;
    }
    this.bppId = this.data.bppId;
    this.status = this.data.status;

  }

  payInstalment() {
    let dueIds = [];
    const unPaidDues = this.records.filter((r, i) => {
      return r.status == null
    });
    

    if (unPaidDues.length > 0) {
      if (Number(this.dueCount) <= unPaidDues.length) {
        for (let i = 0; i < Number(this.dueCount); i++) {
          dueIds.push(unPaidDues[i].ref);
        }
        this.helper.showSpinner();
        this.dashboardService.payBppInstallment({
          "BPPBuyHeaderGUID": this.bppId,
          "BPPInstallmentGUID": dueIds
        }).subscribe(
          (r: any) => {
            this.helper.hideSpinner();
            this.helper.success(r.msg);
            this.dueCount = 0;
            this.onViewStatement();
          },
          (error: any) => {
            this.helper.error(error);
            this.helper.hideSpinner();
          }
        );
      } else {
        this.helper.warning('Invalid due');
      }
    } else {
      this.helper.warning('Invalid due');
    }
  }

  onViewStatement() {
    const data = {
      PageNumber: 1,
      PageSize: 18,
      BPPBuyHeadersGUID: this.bppId
    }
    this.helper.showSpinner();
    this.dashboardService.viewBppInstallment(data).subscribe(
      (r: any) => {
        this.helper.hideSpinner();
        this.records = r.data.bppInstallments.viewInstallmentStatement;
        const unPaidDues = this.records.filter((r, i) => {
          return r.status == null
        });
        this.unPaidCount = unPaidDues.length;
        if (this.unPaidCount <= 0) {
          this.status = true;
        }
      },
      (error: any) => {
        this.helper.error(error);
        this.helper.hideSpinner();
      }
    );
  }

  unPaidCounter(i: number) {
    return new Array(i);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/core/services/app.service';
import { StorageService } from 'src/app/core/utils/storage.service';
import { Accounts } from '../../shared/components/username-dropdown/username-dropdown.component';
import { Storage } from 'src/app/core/enum/storage';
import { DOCUMENT } from '@angular/common';
import { HelperService } from 'src/app/core/utils/helper.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subject } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { CartService } from 'src/app/core/services/cart.service';

export interface Tabs {
  title: string;
  link: string;
  img: string;
  logged: boolean | null;
}

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  path: string = 'assets/images/header-icons/login-icons/';
  isLogged = false;
  cartValue: boolean = true;
 

  tabs: Tabs[] = [
    {
      title: 'DASHBOARD',
      link: '/dashboard/my-cars',
      img: 'dashboard',
      logged: true,
    },
    // {
    //   title: 'INBOX',
    //   link: '/inbox',
    //   img: 'inbox',
    //   logged: true,
    // },
    {
      title: 'TRANSACTION',
      link: '/dashboard/transactions/trade',
      img: 'transactions',
      logged: true,
    },
    {
      title: 'DEPOSIT',
      link: '/dashboard/deposit',
      img: 'transactions',
      logged: true,
    },
    {
      title: 'WITHDRAW',
      link: '/dashboard/withdraw',
      img: 'transactions',
      logged: true,
    },
    {
      title: 'USER',
      link: '/personal-info',
      img: 'user',
      logged: true,
    },
    // {
    //   title: 'LOGOUT',
    //   link: 'logout',
    //   img: 'logout',
    //   logged: true,
    // }
  ];
  

  role:string
  IPANX_USER:string;

  accList: Accounts[] = [];
  language: string = '';
  showDashboard: boolean = false;
  cartData: boolean = false;
  cartShow:boolean =false;
  cartBadge: boolean = false;
  loginCartBadge: string;
  accountBalance: any;


  constructor(
    private appService: AppService,
    public translate: TranslateService,
    public storageService: StorageService,
    private route: ActivatedRoute,
    private rout: Router,
    private helper:HelperService,
    private authService:AuthService,
    private accountService:AccountService,
    private cartService:CartService,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (this.storageService.getDefaultLang()) {
      this.language = this.storageService.getDefaultLang();
    } else {
      this.language = 'English';
    }
   
    // translate.addLangs(['en', 'en', 'ko', 'vi']);
    translate.addLangs([
      'English',
      'Chinese',
      'Korean',
      'Vietnamese',
      'Japanese',
      'Indonesian',
      'Thailand',
      'Mongolia',
    ]);
    // translate.setDefaultLang('ch');
    // const browserLang = translate.getBrowserLang();
    translate.use(this.language);
     this.checkUserRole();
    this.route.queryParams.subscribe((params) => {
      if (params.agent) {
        setTimeout(() => {
          this.openSignUp();
        }, 3000);
      }
    });
    this.storageService.checkCartMenu().subscribe(()=>{
      this.checkUserRole();
    })
  }

  checkUserRole(){
    if(this.storageService.hideCartBasedRole()){
       this.tabs.splice(3,1);
    }else{
      if(this.tabs.length < 6){
        this.tabs.push(
          // {
          //   title: 'CART',
          //   link: '/cart',
          //   img: 'cart',
          //   logged: false,
          // }
         )
      }
    }
  
  }

  setStorage(value) {
    this.storageService.setDefaultLang('lang', value);
  }

  ngOnInit(): void {
    this.appService.loginStatus.subscribe((s) => {
      this.isLogged = s;
      this.accountService.depositPaymentSubject.subscribe(refreshBlnc=>{
        this.accountBalance = refreshBlnc;
     });
    });

    if (!this.isLogged && this.route.snapshot.queryParams['login']) {
      this.openAuthModal();
    }
    this.cartCloseFunction();
    this.getCartBadgeData();
  
  }

  ngAfterViewInit() {
    if(this.isLogged){
      this.getAccountBlnc();
    }
  }

  openAuthModal() {
    // $('#authModal').modal('show');
    this.appService.passValue(true);
        this.rout.navigate(['/login'])

  }

  openSignUp() {
    // $('#signup').modal('show');
    this.appService.passValue(true);
    this.rout.navigate(['/signup'])
  }
  showDashboardLongMenu(){
    this.showDashboard = true;
    this.rout.navigateByUrl('/dashboard');    
  }

  showInboxLongMenu(){
    this.showDashboard = true;
    this.rout.navigateByUrl('/inbox');    
  }

  logout() {
    this.helper.showSpinner();
    this.authService.logout();
    this.appService.passValue(true);
    localStorage.clear();
  }
  
  showCart(){
    this.cartData = true;
    this.appService.passValue(this.cartShow);
    
  }

  ValueFromRightSidebar(var1:any)
  {
    console.log("cartdata",var1);
      this.cartData=var1;
  }

  cartCloseFunction(){
    this.appService.closeSubject.subscribe(
      data => {
        this.cartData = data;
        console.log("cartClose",this.cartData);
        //emitting value to parent component using event emitter
        // this.myOutputVal.emit(this.myInputVal + ' from child.');
      }
    );
  }
  

  makeDeposit(){
    this.rout.navigateByUrl('/dashboard/deposit');
  }

  homePage(){
    this.showDashboard = false;
    this.rout.navigateByUrl('/marketplace/list');
  }

  userProfile(){
    this.showDashboard = true;
    this.rout.navigateByUrl('/personal-info');  
  }

  getAccountBlnc(){
    this.accountService.getAccountList().subscribe(
      (r: any) => {
          this.accountBalance = r.data.accounts[0].accountBalance;
        // this.accountBalance = 15000;

      },
      error => {
        this.accountBalance = 0;
        // this.helper.hideSpinner();
        // this.helper.error(error);
      });
  }

  getCartBadgeData(){
    // this.cartBadge = localStorage.getItem('cartItem');
    // this.loginCartBadge = localStorage.getItem('cartGuid');
    this.cartService.cartBadgeEnable.subscribe(
      data => {
        if(localStorage.getItem('cartItem') && !this.isLogged){
          this.cartBadge = false;
        }else if(localStorage.getItem('cartGuid') && this.isLogged){
          this.cartBadge = false;
        }else{
          this.cartBadge = data;

        }
      }
    );
  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   if (document.body.scrollTop > 80 ||     
  //   document.documentElement.scrollTop > 50) {
  //     // document.getElementById('menu').classList.add('menu-bg');
  //     document.getElementById('menu').classList.add('active');
  //   } else {
  //     document.getElementById('menu').classList.add('menu-bg');
  //     document.getElementById('menu').classList.remove('active');

  //   }
  // }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WithdrawService } from 'src/app/core/services/withdraw.service';
import { HelperService } from 'src/app/core/utils/helper.service';

declare var $: any;

@Component({
  selector: 'app-bank-list-tiles',
  templateUrl: './bank-list-tiles.component.html',
  styleUrls: ['./bank-list-tiles.component.scss']
})
export class BankListTilesComponent implements OnInit {

  @Input() bankAccounts;
  @Output() accountRemoveEvent = new EventEmitter();
  accountGuid = '';
  index = '';
  constructor(
    private withdrawService: WithdrawService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
  }

  deleteAccountPopup(guid) {
    this.accountGuid = guid;
    // this.index = index;
    $("#deleteModal").modal("show");
  }

  deleteAccount() {
    this.helper.showSpinner();
    $("#deleteModal").modal("hide");
    this.withdrawService.deleteAccount(this.accountGuid).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
        this.helper.hideSpinner();
        // this.bankAccounts.forEach((r: any) => {
        //   this.bankAccounts.splice(this.index, 1);
        // });
        this.accountRemoveEvent.emit({
          index: this.index
        });
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

}

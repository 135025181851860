



<div >

  <div class="container-fluid" >
    <div class="row ">
      <div class="col-md-7 custom-background">
        <div class="row justify-content-center align-items-center  "> <!-- Updated here -->
          <div class="col-12 mt-5 marginleft_head"> <!-- Updated here -->
            <a href="#">
              <img src="../../../../assets/InvCar_Logo.svg" alt="InvCar Logo">
  
          
            </a>
          </div>
          <div class="col-12 mt-5 marginleft_content"> 
            <!-- <img class="mt-5" src="../../../../assets/invset_car_text.png" alt="InvCar Logo"><br>
            <img class="mt-5" src="../../../../assets/car_text.png" alt="InvCar Logo"> -->
             
            <span class="contents">INVEST IN CARS <BR>IN BETTER WAY</span>
            <p class="invDetails mt-2">INVCAR helps you grow your income by <BR> allowing you to invest in rental vehicles</p>
              <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;</p>
  
          </div>
        </div>
      </div>
    
      <!-- Right side column -->
      <div class="col-md-5 ">
        <!-- <button class="btn btn-danger close-button"  routerLink="/marketplace/list">
          <i class="fa fa-times"></i> 
        </button> -->
        <div class="p-2">
          <div class="row agenborder" *ngIf="agentName">
            <div class="col">{{ campaignName }}</div>
          </div>
  
          <div id="sign-up-box" class="align-self-end mb-2">
            <h2 class="secondary-bold wp-charcoal f40">
              {{ "SIGNUP.joininvcar" | translate }}
            </h2>
       
            <span class="registration-message">Enter your details to create your account.<br>
            Are you already registered?&nbsp;<a routerLink="/login" class="custom-link">Click here</a></span>

            <form [formGroup]="signupForm" class="row " autocomplete="off">
              <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label for="firstName" class="form-label required">
                  {{ "SIGNUP.firstname" | translate }}</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  formControlName="firstName"
                  (keypress)="letterOnly($event)"
                />
                <p
                  class="form-error"
                  *ngIf="
                    helper.canShowError(signupForm, 'firstName') &&
                    helper.hasError(signupForm, 'firstName', 'required')
                  "
                >
                  {{ "SIGNUP.firstnameisrequired" | translate }}
                </p>
              </div>
              <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label for="lastName" class="form-label required">{{
                  "SIGNUP.lastname" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  formControlName="lastName"
                  (keypress)="letterOnly($event)"
  
                />
                <p
                  class="form-error"
                  *ngIf="
                    helper.canShowError(signupForm, 'lastName') &&
                    helper.hasError(signupForm, 'lastName', 'required')
                  "
                >
                  {{ "SIGNUP.lastnameisrequired" | translate }}
                </p>
              </div>
              <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label for="email" class="form-label required">
                  {{ "SIGNUP.email" | translate }}</label
                >
                <input
                  autocomplete="off"
                  type="email"
                  class="form-control"
                  id="email"
                  formControlName="email"
                />
                <p
                  class="form-error"
                  *ngIf="
                    helper.canShowError(signupForm, 'email') &&
                    helper.hasError(signupForm, 'email', 'required')
                  "
                >
                  {{ "SIGNUP.emailisrequired" | translate }}
                </p>
                <p
                  class="form-error"
                  *ngIf="
                    helper.canShowError(signupForm, 'email') &&
                    helper.hasError(signupForm, 'email', 'pattern')
                  "
                >
                  {{ "SIGNUP.emailisnotvalid" | translate }}
                </p>
              </div>
              <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label for="mobile" class="form-label required">
                  {{ "SIGNUP.mobile" | translate }}</label
                >
                <div class="row">
                  <div class="col-4 d-flex">
                    <!-- <select
                      class="form-control px-0"
                      formControlName="countryCode"
                      placeholder="+91"
                    >
                      <option value="">{{ "SIGNUP.code" | translate }}</option>
                      <option *ngFor="let r of countryCodes" [value]="r">
                        {{ r.substr(1) }}
                      </option>
                    </select> -->
                    <input
                      type="text"
                      class="form-control"
                      formControlName="countryCode"
                      maxlength="2" 
                      readonly                   
                     />
                  </div>
                  <div class="col-8">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="mobile"
                      maxlength="10" 
                      (keypress)="onlyNumber($event)"
                    />
                  </div>
                </div>
                <p
                  class="form-error"
                  *ngIf="
                    helper.canShowError(signupForm, 'countryCode') &&
                    helper.hasError(signupForm, 'countryCode', 'required')
                  "
                >
                  {{ "SIGNUP.pleaseselectcountrycode" | translate }}
                </p>
                <p
                  class="form-error"
                  *ngIf="
                    helper.canShowError(signupForm, 'mobile') &&
                    helper.hasError(signupForm, 'mobile', 'required')
                  "
                >
                  {{ "SIGNUP.mobilenoisrequired" | translate }}
                </p>
                <p
                  class="form-error"
                  *ngIf="
                    helper.canShowError(signupForm, 'mobile') &&
                    helper.hasError(signupForm, 'mobile', 'pattern')
                  "
                >
                  {{ "SIGNUP.mobilenoshouldbevalid" | translate }}
                </p>
                <p
                class="form-error"
                *ngIf="
                  helper.canShowError(signupForm, 'mobile') &&
                  helper.hasError(signupForm, 'mobile', 'minlength')
                "
              >
                {{ "SIGNUP.mobilenoshouldbe10digits" | translate }}
              </p>
              </div>
              <div
                class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
              >
                <label for="address" class="form-label required">{{
                  "SIGNUP.address" | translate
                }}</label>
                <input
                  class="form-control" formControlName="address"
                  matInput (keydown.enter)="$event.preventDefault()" autocorrect="off"
                                  autocapitalize="off" spellcheck="off" type="text" #search
                >
                <p
                  class="form-error"
                  *ngIf="
                    helper.canShowError(signupForm, 'address') &&
                    helper.hasError(signupForm, 'address', 'required')
                  "
                >
                  Address is required
                </p>
              </div> 
              <div class="form-group col-lg-6 col-md-6 col-sm-12 pos-rel">
                <label for="password" class="form-label required">{{ "SIGNUP.password" | translate }}</label>
                <div class="input-group">
                    <input
                        autocomplete="off"
                        [type]="showPassword ? 'text' : 'password'"
                        class="form-control"
                        id="password"
                        formControlName="password"
                    />
                    <div class="input-group-append">
                        <span (click)="showPassword = !showPassword" class="input-group-text eye-icon">
                            <i class="fas fa-eye"></i>
                        </span>
                    </div>
                </div>
                <p class="form-error" *ngIf="helper.canShowError(signupForm, 'password') && helper.hasError(signupForm, 'password', 'required')">
                    {{ "SIGNUP.passwordisrequired" | translate }}
                </p>
                <p class="form-error" *ngIf="helper.canShowError(signupForm, 'password') && helper.hasError(signupForm, 'password', 'pattern')">
                    {{ passwordError }}
                </p>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 pos-rel">
                <label for="confirmPassword" class="form-label required">{{ "SIGNUP.confirmpassword" | translate }}</label>
                <div class="input-group">
                    <input
                        autocomplete="off"
                        [type]="showConfirmPassword ? 'text' : 'password'"
                        class="form-control"
                        id="confirmPassword"
                        formControlName="confirmPassword"
                        [readonly]="password.value == ''"
                    />
                    <div class="input-group-append">
                        <span (click)="showConfirmPassword = !showConfirmPassword" class="input-group-text eye-icon">
                            <i class="fas fa-eye"></i>
                        </span>
                    </div>
                </div>
                <p class="form-error" *ngIf="helper.canShowError(signupForm, 'confirmPassword') && helper.hasError(signupForm, 'confirmPassword', 'required')">
                    {{ "SIGNUP.confirmyyourpassword" | translate }}
                </p>
                <p class="form-error" *ngIf="passwordMismatch()">
                    {{ "SIGNUP.passworddoesnotmatch" | translate }}
                </p>
            </div>      
            
            <div class="container mt-2">
              <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="agreeCheck"  (change)="isChecked=!isChecked" required >
                  <label class="form-check-label" for="agreeCheck">
                    By clicking create account you are agreeing with<a [routerLink]="['/privacy_policy']" target="_blank" class="ms-2"><b>Privacy policy</b> </a> and<a [routerLink]="['/terms_and_conditions']" target="_blank" class="ms-2"><b>Terms and conditions </b> </a>                  
                  </label>
              </div>
          </div>
            
         
              <div class="form-group col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <button
                      class="btn btn-primary w-100 "
                      (click)="onSubmit()"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      [disabled]="!signupForm.valid || !isChecked"
                    >
                      {{ "SIGNUP.createaccount" | translate }}
                    </button>
                  </div>
                
                </div>
              </div>
  
            </form>
          </div>
        </div>
    
    
    
  
   
      </div>
    </div>
  </div>
  

</div>







<form [formGroup]="dlForm">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="" class="form-label required">Upload the front side</label>
                <app-img-upload [sendFiles]="sendFilesFront" (getFiles)="getFilesFront($event)"></app-img-upload>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="" class="form-label required">Upload the back side</label>
                <app-img-upload [sendFiles]="sendFilesBack" (getFiles)="getFilesBack($event)"></app-img-upload>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="DrivingLicenseNumber" class="form-label required">Driving License Number</label>
                <input type="text" formControlName="DrivingLicenseNumber" class="form-control" />
                <div *ngIf="canShowError('DrivingLicenseNumber')">
                    <p class="error-txt" *ngIf="hasError('DrivingLicenseNumber','required')">Driving License Number
                        is required
                    </p>
                </div>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="StateGUID" class="form-label required">State Issued</label>

                <select class="form-control" formControlName="StateGUID">
                    <option value="">Choose state</option>
                    <option *ngFor="let state of stateList" [value]="state.stateGUID">{{state.name}}</option>
                </select>

                <div *ngIf="canShowError('StateGUID')">
                    <p class="error-txt" *ngIf="hasError('StateGUID','required')">State
                        is required
                    </p>
                </div>
            </div>
        </div>
    </div>
</form>
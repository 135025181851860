import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountService } from '../../../core/services/account.service';
import { HelperService } from '../../../core/utils/helper.service';
import { WithdrawService } from '../../../core/services/withdraw.service';
declare var $: any;

export interface Accounts{
  bankGUID: string;
  accountName: string;
  accountHolder: string;
  accountNumber: string;
  bsb: string;
  bankName: string;
}

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss']
})
export class BankAccountComponent implements OnInit {
  accounts: Accounts[] = [];
  addAccountForm = new FormGroup({
    AccountName: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9 ]{5,30}")
    ]),
    AccountNumber: new FormControl('', [
      Validators.required,
      Validators.pattern("^[0-9]{6,9}")
    ]),
    AccountBSB: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern("^[0-9]{6}")
    ])
  });

  editAccountId: string;
  editBankAccForm = new FormGroup({
    AccountName: new FormControl("", [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9 ]{5,30}")
    ]),
    BankName: new FormControl(""),
    AccountNumber: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]{6,9}"),
    ]),
    AccountBSB: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]{6}"),
    ]),
  });
  accountGuid: any;
  isDeleting: boolean;
  constructor(
    private withdrawService: WithdrawService,
    private helper: HelperService
  ) {
    this.getBanks();
   }

  ngOnInit(): void {
  }

  getBanks() {
    this.withdrawService.getBankList().subscribe(
      (s: any) => {
        this.accounts = s.data.bankList;
      },
      (e) => {}
    );
  }

  get AccountName() {
    return this.addAccountForm.get('AccountName');
  }

  get AccountNumber() {
    return this.addAccountForm.get('AccountNumber');
  }
  get AccountBSB() {
    return this.addAccountForm.get('AccountBSB');
  }


  canShowError(control: string, isEdit?: boolean) {
    let field = this.addAccountForm.controls[control];
    if (isEdit) {
      field = this.editBankAccForm.controls[control];
    }
    return !field.valid && field.touched && field.dirty;
  }

  hasError(control: string, error: string, isEdit?: boolean) {
    if (isEdit) {
      return this.editBankAccForm.controls[control].hasError(error);
    } else {
      return this.addAccountForm.controls[control].hasError(error);
    }
  }

  addBankAccount() {
    this.helper.showSpinner();
    this.withdrawService.addBankAccount(this.getFormData()).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
        this.helper.hideSpinner();
        this.addAccountForm.reset();
        this.getBanks();
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  getFormData() {
    return {
      AccountName: this.getValue("AccountName"),
      AccountNumber: this.getValue("AccountNumber"),
      BSB: this.getValue("AccountBSB")
    };
  }

  getValue(control: string) {
    return this.addAccountForm.controls[control].value;
  }


  deleteAccountPopup(guid) {
    this.accountGuid = guid;
    $("#deleteModal").modal("show");
  }

  deleteAccount() {
    this.helper.showSpinner();
    $("#deleteModal").modal("hide");
    this.isDeleting = true;
    this.withdrawService.deleteAccount(this.accountGuid).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
        this.helper.hideSpinner();
        this.getBanks();
        this.isDeleting = false;
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
        this.isDeleting = false;
      }
    );
  }

  getEditFormData() {
    return {
      SplitBankGUID: this.editAccountId,
      AccountName: this.editBankAccForm.get("AccountName").value,
      AccountNumber: this.editBankAccForm.get("AccountNumber").value,
      BSB: this.editBankAccForm.get("AccountBSB").value,
    };
  }

  updateBank(accId: string): void {
    this.helper.showSpinner();
    if(!this.editBankAccForm.valid) {
      
      this.helper.info("please fill the required fields");
      this.helper.hideSpinner();
    } else {
    this.withdrawService.updateBankAccount(this.getEditFormData()).subscribe(
      (s: any) => {
        this.helper.hideSpinner();
        this.helper.success(s.data.message);
        this.disableEdit();
        this.getBanks();
      },
      (e) => {
        this.helper.hideSpinner();
        this.helper.error(e);
      }
    );
    }
  }

  disableEdit() {
    this.editAccountId = "";
    this.editBankAccForm.reset();
  }

  enableEdit(f) {
    this.editAccountId = f.bankGUID;
    this.editBankAccForm.patchValue({
      AccountName: f.accountName,
      AccountNumber: f.accountNumber,
      BankName: f.bankName,
      AccountBSB: f.bsb,
    })
  }

  expandBody(id: string, e) {
    e.stopPropagation();
    e.preventDefault();
    
    const tbody = document.querySelectorAll('.mobile-table-container tbody');
    let prevSel = '';
    const btn = e.target;
    [].forEach.call(tbody, function (el) {
      if (el.classList.contains('show')) {
        prevSel = el.id;
      }
      el.classList.remove("show");
    });
    const selBtn = document.querySelectorAll('.expander-btn');
    [].forEach.call(selBtn, function (el) {
      el.classList.remove("active");
    });
    if (prevSel !== id) {
      const sel = document.getElementById(id);
      sel.classList.add('show');
      
      
      btn.classList.add('active');
    }else{
      btn.classList.remove('active');
    }
  }


}

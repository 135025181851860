<div class="container property-section">
    <div class="content-section">

        <h2>ASK US ANYTHING.<br>
            OUR TEAM IS WAITING <br>
            FOR YOUR QUESTION</h2>

        <div id="sign-in-box" class="align-self-center">
            <form [formGroup]="contactForm" class="row col-12 mt-4 mx-0" autocomplete="off">
                <div class="form-group col-6">
                    <label for="firstName" class="form-label">
                        First name</label>
                    <input type="text" placeholder="Enter First name" formControlName="firstName" class="form-control"
                        id="firstName" autocomplete="off" />
                    <span *ngIf="!firstname?.valid && (firstname?.touched || firstname?.dirty)">
                        <span *ngIf="firstname?.errors?.required">
                            <span class="text-danger">First name is Invalid</span>
                        </span>
                    </span>
                </div>
                <div class="form-group col-6 pos-rel">
                    <label for="lastName" class="form-label">
                        Last name</label>
                    <input type="text" placeholder="Enter Last name" formControlName="lastName" class="form-control"
                        id="lastName" autocomplete="off" />
                    <span *ngIf="!lastname?.valid && (lastname?.touched || lastname?.dirty)">
                        <span *ngIf="lastname?.errors?.required">
                            <span class="text-danger">Last name is Invalid</span>
                        </span>
                    </span>

                </div>

                <div class="form-group col-6 pos-rel">
                    <label for="mobile" class="form-label">
                        Mobile</label>
                        <div class="countryCode"><input type="text" placeholder="Enter Mobile no" formControlName="mobile"  maxlength="10" class="form-control"
                        id="mobile" autocomplete="off" (keypress)="onlyNumber($event)" /></div>
                    <span *ngIf="!mobile?.valid && (mobile?.touched || mobile?.dirty)">
                        <span *ngIf="mobile?.errors?.required">
                            <span class="text-danger">Mobile is Invalid</span>
                        </span>
                        <span *ngIf="mobile?.errors?.minlength">
                            <span class="text-danger">Mobile should be 10 Digits</span>
                        </span><br>
                        <span *ngIf="mobile?.errors?.pattern">
                            <span class="text-danger">Enter a valid Mobile No</span>
                        </span>
                    </span>
                </div>

                <div class="form-group col-6 pos-rel">
                    <label for="email" class="form-label">
                        Email</label>
                    <input type="text" placeholder="Enter Email Address" formControlName="emailAddr"
                        class="form-control" id="emailAddr" autocomplete="off" />
                    <span *ngIf="!email?.valid && (email?.touched || email?.dirty)">
                        <span *ngIf="email?.errors?.required">
                            <span class="text-danger">Email is Invalid</span>
                        </span>
                        <span *ngIf="email?.errors?.pattern">
                            <span class="text-danger">Enter a valid Email Address</span>
                        </span>
                    </span>

                </div>
                <div class="form-group col-12 pos-rel">
                    <label for="password" class="form-label">
                        Are you a InvCar customer?</label>

                    <div class="btn-group col-12" data-toggle="buttons">
                        <input type="radio" class="check-btn" name="invcarCustomer" id="green" value="yes"
                            formControlName="invcarCustomer" />
                        <label for="green" class="btn btn-default"> Yes, I am a customer </label>
                        <input type="radio" class="check-btn" name="invcarCustomer" id="blue" value="no"
                            formControlName="invcarCustomer" />
                        <label for="blue" class="btn btn-default"> No, I am not a customer </label>
                    </div>
                    <span *ngIf="!invcarcustomer?.valid && (invcarcustomer?.touched || invcarcustomer?.dirty)">
                        <span *ngIf="invcarcustomer?.errors?.required">
                            <span class="text-danger"> customer is Invalid</span>
                        </span>
                    </span>

                </div>
                <div class="form-group col-12 pos-rel">
                    <label for="subject" class="form-label">
                        Subject</label>
                    <input type="text" placeholder="Enter your Subject" formControlName="subject" class="form-control"
                        id="subject" autocomplete="off" />
                    <span *ngIf="!subject?.valid && (subject?.touched || subject?.dirty)">
                        <span *ngIf="subject?.errors?.required">
                            <span class="text-danger">Subject is Invalid</span>
                        </span>
                    </span>

                </div>
                <div class="form-group col-12 pos-rel">
                    <label for="message" class="form-label">
                        Message</label>
                    <input type="text" placeholder="Message" formControlName="message" class="form-control" id="message"
                        autocomplete="off" />
                    <span *ngIf="!message?.valid && (message?.touched || message?.dirty)">
                        <span *ngIf="message?.errors?.required">
                            <span class="text-danger">Message is Invalid</span>
                        </span>
                    </span>
                </div>


                <div class="form-group col-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <button class="btn btn-primary w-100 mt-2" [disabled]="!contactForm.valid" (click)="contactSubmit()">
                                Send
                            </button>
                        </div>

                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent implements OnInit {

  records = [
    {
      title: 'Community',
      iconSrc: '/assets/images/header-icons/community-active.svg',
      description: 'Find the right content to help you understand Bricklet on a deeper level.'
    },
    {
      title: 'Marketplace',
      iconSrc: '/assets/images/header-icons/marketplace-active.svg',
      description: 'Browse through various houses, compare properties, and buy or sell easily.'
    },
    {
      title: 'Dashboard',
      iconSrc: '/assets/images/header-icons/dashboard-active.svg',
      description: 'Keep track of your investment and get the most out of Bricklet with an improved experience.'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

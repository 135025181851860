import { Component, Input, OnInit } from '@angular/core';
import { CommunityService } from 'src/app/core/services/community.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-share-save-icon',
  templateUrl: './share-save-icon.component.html',
  styleUrls: ['./share-save-icon.component.scss']
})
export class ShareSaveIconComponent implements OnInit {
  @Input() type;
  @Input() guid;
  @Input() title;
  @Input() status;
  constructor(
    private helper: HelperService,
    private communityService: CommunityService
  ) { }

  ngOnInit(): void {
  }

  saveArticle(id: string) {
    this.helper.showSpinner();
    const data = {
      ArticleGUID: this.type == 'article' ? id : "",
      VideoGUID: this.type == 'video' ? id : "",
      PodcastGUID: this.type == 'podcast' ? id : ""
    }
    this.communityService.saveCommunity(data).subscribe(
      (s: any) => {
        this.helper.success(s.msg);
        this.helper.hideSpinner();
        this.status = true;
      },
      (e: string) => {
        this.helper.hideSpinner();
        this.helper.error(e);
      }
    );
  }

  unSaveArticle(id: string) {
    this.helper.showSpinner();
    const data = {
      ArticleGUID: this.type == 'article' ? id : "",
      VideoGUID: this.type == 'video' ? id : "",
      PodcastGUID: this.type == 'podcast' ? id : ""
    }
    this.communityService.unSaveCommunity(data).subscribe(
      (s: any) => {
        this.helper.success(s.msg);
        this.helper.hideSpinner();
        this.status = false;
      },
      (e: string) => {
        this.helper.hideSpinner();
        this.helper.error(e);
      }
    );
  }

}

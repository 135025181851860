<mat-dialog-content class="sell-initiated">
    <div class="row align-items-center justify-content-center flex-column w-100">

        <p *ngIf="isBpp" class="primary-bold f40 waav-dark-main m-0 text-center col-xl-8 col-lg-8 col-md-10 col-sm-12">
            Sell Order For {{bricklet}} Shares On Payment Plan Is Successful.    
        </p>
        <p *ngIf="!isBpp" class="primary-bold f40 waav-dark-main m-0 text-center col-xl-8 col-lg-8 col-md-10 col-sm-12">
            Sell Order For {{bricklet}} Shares Is Successful.
        </p>
        <p class="primary-regular f20 generic-palette-b-w-gray text-center col-xl-8 col-lg-8 col-md-10 col-sm-12">
            <!-- The amount of , regarding {{bricklet}} Bricklets, will appear on your account balance up to 5 business days after somebody buy. -->
            <!-- Your account will be credited the amount of ${{amount | number:'0.2-2'}} for each Bricklet sold at this price.<br/>
            The proceeds will be credited within 5 Business Days. -->
            Your account will be credited with the amount of ${{amount | number:'0.2-2'}} for each Lot sold at this price within 5 Business Days.
        </p>
        <img class="col-xl-4 col-lg-4 col-md-5 col-sm-8" src="/assets/images/sell-initiated.svg" alt="">
        <div class="row justify-content-between col-xl-10 col-lg-10 col-md-12 col-sm-12 mt-5">
            <a routerLink="/dashboard/pending-orders" class="col-lg-5 col-md-6 col-sm-12" (click)="closeDialog()">
                <button class="btn btn-primary light w-100">{{ 'POS.viewreceipttransaction' | translate }}</button>
            </a>
            
            <a [routerLink]="'/marketplace/detail/'+id" class="col-lg-5 col-md-6 col-sm-12" (click)="closeDialog()">
                <button class="btn btn-secondary w-100"> {{ 'POS.gotomarketplace' | translate }}</button>
            </a>
        </div>
    </div>

</mat-dialog-content>
<!-- <mat-dialog-actions class="p-0">


</mat-dialog-actions> -->
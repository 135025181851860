import { Component, Input, OnInit } from '@angular/core';
import { defaultCommunityFilter, CommunityFilter } from 'src/app/core/interfaces/community-request.interface';
import { ArticleService } from 'src/app/core/services/article.service';
import { CommunityService } from 'src/app/core/services/community.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-article-recommended',
  templateUrl: './article-recommended.component.html',
  styleUrls: ['./article-recommended.component.scss']
})
export class ArticleRecommendedComponent implements OnInit {

  @Input() viewAll;
  filterData = defaultCommunityFilter;
  recommended = [];
  isRecommendedLoaded = false;
  loaderRecommend = false;
  constructor(
    private articleService: ArticleService,
    private communityService: CommunityService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    if (!this.viewAll) {
      this.viewAll = true;
    } else {
      this.viewAll = false;
    }

    this.communityService.getCommunityFilter.subscribe(s => {
      if (s) {
        this.filterData = s;
        this.getRecord();
      }
    });

    //this.getRecord();
  }

  getRecord() {
    let data: CommunityFilter = this.filterData;
    data.PageSize = 2;
    this.isRecommendedLoaded = false;
    this.loaderRecommend = true;
    this.articleService.getArticleRecommended(data).subscribe(
      (s: any) => {
        this.recommended = s.data.articles.articles;
        this.isRecommendedLoaded = true;
        this.loaderRecommend = false;
        //this.helper.hideSpinner();
      },
      (e: string) => {
        //this.helper.hideSpinner();
        this.helper.error(e);
        this.isRecommendedLoaded = true;
        this.loaderRecommend = false;
      }
    );
  }

}

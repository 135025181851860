<div class="w-100 bg-waav-bright-light p-2 d-flex flex-row align-items-center justify-content-between">
    <span class="primary-semi-bold f20 waav-dark-main">Personal Information</span>
</div>

<form [formGroup]="personalInfoForm">
    <div class="row w-100">
        <div class="form-group col-12">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label for="countryOfResidence" class="form-label required">Country of Residence</label>
                <select formControlName="countryOfResidence" class="form-control">
                    <option value="">Choose country</option>
                    <option *ngFor="let c of countryList" [value]="c.countryGUID">{{c.name}}</option>
                </select>
                <div *ngIf="canShowError('countryOfResidence')">
                    <p class="error-txt" *ngIf="hasError('countryOfResidence','required')">Country is required</p>
                </div>
            </div>
        </div>
        <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="firstName" class="form-label required">First name</label>
            <input appCapitalize type="text" formControlName="firstName" class="form-control" tabindex="1" />
            <span class="f12 generic-palette-b-w-gray">Please fill First, Middle Name in the First Name column</span>
            <div *ngIf="canShowError('firstName')">
                <p class="error-txt" *ngIf="hasError('firstName','required')">First name is required</p>
                <p class="error-txt" *ngIf="hasError('firstName','pattern')">Please enter a valid name. Only alphabet with space allowed.</p>
            </div>
        </div>

        <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 d-none">
            <label for="middleName" class="form-label required">Middle name</label>
            <input appCapitalize type="text" formControlName="middleName" class="form-control" tabindex="2" />
            <div *ngIf="canShowError('middleName')">
                <p class="error-txt" *ngIf="hasError('middleName','pattern')">Allowed characters A-Za-z</p>
            </div>
        </div>

        <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="lastName" class="form-label required">Last name</label>
            <input appCapitalize type="text" formControlName="lastName" class="form-control" tabindex="3" />
            <div *ngIf="canShowError('lastName')">
                <p class="error-txt" *ngIf="hasError('lastName','required')">Last name is required</p>
                <p class="error-txt" *ngIf="hasError('lastName','pattern')">Please enter a valid name. Only alphabet with space allowed.</p>
            </div>
        </div>
        <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="Date of Birth" class="form-label required">Date of Birth</label>
            <div class="w-100 position-relative">
                <input type="text" formControlName="dob" class="form-control" tabindex="4" bsDatepicker
                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" [bsValue]="this.getValue('dob')"
                    readonly />
                <img src="assets/images/calendar.svg" id="date-img" alt="">
            </div>
            <div *ngIf="canShowError('dob')">
                <p class="error-txt" *ngIf="hasError('dob','required')">Date of birth is required</p>
            </div>
        </div>
        <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="gender" class="form-label required">Gender</label>
            <select formControlName="gender" class="form-control" tabindex="5">
                <option value="">Choose gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
            </select>
            <div *ngIf="canShowError('gender')">
                <p class="error-txt" *ngIf="hasError('gender','required')">Gender is required</p>
            </div>
        </div>
        <div class="form-group col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12">
            <label for="email" class="form-label required">Email</label>
            <div class="w-100 position-relative">
                <input type="text" formControlName="email" class="form-control" tabindex="6" />
                <img src="assets/images/email.svg" id="phone-img" alt="">
            </div>
            <div *ngIf="canShowError('email')">
                <p class="error-txt" *ngIf="hasError('email','required')">Email is required</p>
                <!-- <p class="error-txt" *ngIf="hasError('email','pattern')">Invalid Email</p> -->
            </div>
        </div>
    </div>
</form>
import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  getPropertyList(data: any,login) {
    let api = login ? RestEnds.propertyListWithLogin :RestEnds.propertyList;
    return this.apiManagerService.postRequest(api, data);
  }

  getPropertyDetails(GUID: any) {
    const data = `carGUID=${GUID}`;
    return this.apiManagerService.getRequestQueryParam(RestEnds.propertyDetails, data);
  }

  getIsLoggedPropertyDetails(GUID: any) {
    const data = `carGUID=${GUID}`;
    return this.apiManagerService.getRequestQueryParam(RestEnds.isLoggedPropertyDetails, data);
  }

  updateFavProperty(propertyID: string) {
    const data = `carGUID=${propertyID}`;
    return this.apiManagerService.getRequestQueryParam(RestEnds.favProperty, data);
  }

  getBrickletPriceList(id: any) {
    return this.apiManagerService.getRequestQueryParam(RestEnds.brickletPriceHistory, 'propertyGUID=' + id);
  }

  getMarketPrice(guid: string, qty: number) {
    const data = {
      carGUID: guid,
      quantity: qty
    };

    return this.apiManagerService.postRequest(RestEnds.guestCart, data)
  }

  getContactFormData(data){
    return this.apiManagerService.postRequest(RestEnds.contact, data)

  }
}

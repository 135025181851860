<div class="container property-section">
    <div class="content-section">
    <h2>VISIONARIES, MAKERS <br>
        & STRATEGISTS TOGETHER <br>
        TO MAKE IT HAPPEN</h2>
    <p>Meet the dedicated and talented team behind InvCar! With diverse backgrounds and a shared passion for
        revolutionizing rental vehicle investments, our team works tirelessly to provide you with a seamless and
        innovative investing experience. Explore the photos and job descriptions of our team members to learn more about
        the individuals who drive InvCar's success.</p>
    <br>
    <div class="row">
        <div class="col-4 col-sm-6 col-sm-6 col-lg-3">
            <img src="../../../assets/images/team/1.png" width="200">
        </div>
        <div class="col-4 col-sm-6 col-sm-6 col-lg-3">
            <img src="../../../assets/images/team/2.png" width="200">
        </div>
        <div class="col-4 col-sm-6 col-sm-6 col-lg-3">
            <img src="../../../assets/images/team/3.png" width="200">
        </div>
        <div class="col-4 col-sm-6 col-sm-6 col-lg-3">
            <img src="../../../assets/images/team/4.png" width="200">
        </div>
    </div>
</div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PipesModule } from './pipes/pipes.module';
import { AgmCoreModule } from '@agm/core';

//Shared Directives
import { NumbersOnly } from './directives/checkNumber.directive';
import { CapitalizeDirective } from './directives/capitalize.directive';

//Shared Components
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NoInvestorComponent } from './components/no-investor/no-investor.component';
import { UsernameDropdownComponent } from './components/username-dropdown/username-dropdown.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { AccountManagerComponent } from './components/account-manager/account-manager.component';
import { AccountViewerComponent } from './components/account-viewer/account-viewer.component';
import { BankAccountComponent } from './components/bank-account/bank-account.component';
import { PropertyDetailComponent } from './components/property-detail/property-detail.component';
import { PropertyGalleryComponent } from './components/property-gallery/property-gallery.component';
import { PropertyListBoxComponent } from './components/property-list-box/property-list-box.component';
import { PropertyListFilterComponent } from './components/property-list-filter/property-list-filter.component';
import { DynamicFormContainerComponent } from './components/dynamic-form-container/dynamic-form-container.component';
import { PassportComponent } from './components/kyc/id-proofs/passport/passport.component';
import { MedicardComponent } from './components/kyc/id-proofs/medicard/medicard.component';
import { DrivingLicenseComponent } from './components/kyc/id-proofs/driving-license/driving-license.component';
import { ImgUploadComponent } from './components/kyc/img-upload/img-upload.component';
import { StepperSubmitComponent } from './components/stepper-submit/stepper-submit.component';
import { NoAccountDashboardComponent } from './components/no-account-dashboard/no-account-dashboard.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PvtCompanyAddressFormComponent } from './components/kyc/pvt-company-address-form/pvt-company-address-form.component';
import { PrivateCompanyComponent } from './components/kyc/private-company/private-company.component';
import { OrgIndividualComponent } from './components/kyc/org-individual/org-individual.component';
import { AddressInformationComponent } from './components/kyc/org-individual/address-information/address-information.component';
import { PersonalInformationComponent } from './components/kyc/org-individual/personal-information/personal-information.component';
import { ProofOfIdentityComponent } from './components/kyc/org-individual/proof-of-identity/proof-of-identity.component';
import { PublicCompanyComponent } from './components/kyc/public-company/public-company.component';
import { BuyDialogComponent } from './components/buy-dialog/buy-dialog.component';
import { SellDialogComponent } from './components/sell-dialog/sell-dialog.component';
import { BuyInitiatedDialogComponent } from './components/buy-initiated-dialog/buy-initiated-dialog.component';
import { SellInitiatedDialogComponent } from './components/sell-initiated-dialog/sell-initiated-dialog.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { BankListTilesComponent } from './components/bank-list-tiles/bank-list-tiles.component';
import { ArticleYouMayLikeComponent } from './components/article-you-may-like/article-you-may-like.component';
import { ArticlePopularRightNowComponent } from './components/article-popular-right-now/article-popular-right-now.component';
import { ArticleInspiredComponent } from './components/article-inspired/article-inspired.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ArticleRecommendedComponent } from './components/article-recommended/article-recommended.component';
import { ShareSaveIconComponent } from './components/share-save-icon/share-save-icon.component';
import { AddWithdrawDialogComponent } from './components/add-withdraw-dialog/add-withdraw-dialog.component';
import { WelcomeDialogComponent } from './components/welcome-dialog/welcome-dialog.component';
import { PodcastFloatingWindowComponent } from './components/podcast-floating-window/podcast-floating-window.component';
import { BuyBppDialogComponent } from './components/buy-bpp-dialog/buy-bpp-dialog.component';
import { BppInstallmentsDialogComponent } from './components/bpp-installments-dialog/bpp-installments-dialog.component';
import { SellBppDialogComponent } from './components/sell-bpp-dialog/sell-bpp-dialog.component';
import { SwapDialogComponent } from './components/swap-dialog/swap-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { RapidIdDialogComponent } from './components/rapid-id-dialog/rapid-id-dialog.component';
import { EntityUpdateDialogComponent } from './components/entity-update-dialog/entity-update-dialog.component';
import { KycModalComponent } from './components/kyc/kyc-modal/kyc-modal.component';
import { CameraComponent } from './components/kyc/camera/camera.component';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {WebcamModule} from 'ngx-webcam';
import { WeeklyIncomeComponent } from '../pages/dashboard/transactions/weekly-income/weekly-income.component';

const sharedComponents = [
  PageNotFoundComponent,
  NoInvestorComponent,
  UsernameDropdownComponent,
  AccountManagerComponent,
  AccountViewerComponent,
  BankAccountComponent,
  PropertyDetailComponent,
  PropertyGalleryComponent,
  PropertyListBoxComponent,
  PropertyListFilterComponent,
  DynamicFormComponent,
  DynamicFormContainerComponent,
  PassportComponent,
  MedicardComponent,
  DrivingLicenseComponent,
  ImgUploadComponent,
  StepperSubmitComponent,
  NoAccountDashboardComponent,
  PaginationComponent,
  NumbersOnly,
  CapitalizeDirective,
  PrivateCompanyComponent,
  PublicCompanyComponent,
  OrgIndividualComponent,
  AddressInformationComponent,
  PersonalInformationComponent,
  ProofOfIdentityComponent,
  PvtCompanyAddressFormComponent,
  BuyDialogComponent,
  SellDialogComponent,
  BuyInitiatedDialogComponent,
  SellInitiatedDialogComponent,
  EmptyStateComponent,
  BankListTilesComponent,
  ArticleYouMayLikeComponent,
  ArticlePopularRightNowComponent,
  ArticleInspiredComponent,
  ArticleRecommendedComponent,
  ConfirmDialogComponent,
  ShareSaveIconComponent,
  AddWithdrawDialogComponent,
  WelcomeDialogComponent,
  PodcastFloatingWindowComponent,
  BuyBppDialogComponent,
  BppInstallmentsDialogComponent,
  SellBppDialogComponent,
  SwapDialogComponent,
  RapidIdDialogComponent,
  EntityUpdateDialogComponent,
  WeeklyIncomeComponent
];

const sharedModules = [
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  BsDatepickerModule.forRoot(),
  Ng5SliderModule,
  NgxSpinnerModule,
  NgbPaginationModule,
  MatDialogModule,
  MatRadioModule,
  NgxSkeletonLoaderModule,
  ScrollingModule,
  NgbTooltipModule,
  PipesModule,
  AgmCoreModule.forRoot({
    // please get your own API key here:
    // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    // apiKey: 'AIzaSyCS353Pxlsh_9cmmL1sNxfh08QIps02cfY',
    apiKey: "AIzaSyCzshtXAEeTfK6yaQkvUucGk1GZBAit0bU"
  })
];

@NgModule({
  declarations: [
    sharedComponents,
    KycModalComponent,
    CameraComponent 
   ],
  imports: [
    CommonModule,
    RouterModule,
    sharedModules,
    TranslateModule,
    MatInputModule,
    MatStepperModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    WebcamModule
  ],
  exports: [
    sharedComponents,
    sharedModules,
    TranslateModule
  ]
})
export class SharedModule { }

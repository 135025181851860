<form [formGroup]="passportForm">

    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label for="" class="form-label required">Passport image</label>
                <app-img-upload [sendFiles]="sendFiles" (getFiles)="getFiles($event)"></app-img-upload>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="" class="form-label required">Country of passport issued</label>
            <select class="form-control" formControlName="issuedCountry">
              <option value="">Choose country</option>
              <option *ngFor="let c of countryList" [value]="c.countryGUID">{{
                c.name
                }}</option>
            </select>
            <div *ngIf="canShowError('issuedCountry')">
              <p class="error-txt" *ngIf="hasError('issuedCountry', 'required')">
                Issued Country is required
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="" class="form-label required">Country of Residence</label>
            <select class="form-control" formControlName="residentCountry">
              <option value="">Choose country</option>
              <option *ngFor="let c of countryList" [value]="c.countryGUID">{{
                c.name
                }}</option>
            </select>
            <div *ngIf="canShowError('residentCountry')">
              <p class="error-txt" *ngIf="hasError('residentCountry', 'required')">
                Country of Residence is required
              </p>
            </div>
          </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="form-group">
                <label for="PassportNumber" class="form-label required">Passport Number</label>
                <input type="text" formControlName="PassportNumber" class="form-control" />
                <div *ngIf="canShowError('PassportNumber')">
                    <p class="error-txt" *ngIf="hasError('PassportNumber','required')">Passport Number is
                        required
                    </p>
                </div>
            </div>
        </div>
    </div>
</form>
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HelperService } from '../../../core/utils/helper.service';
import { WithdrawService } from '../../../core/services/withdraw.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuyDialogComponent } from '../buy-dialog/buy-dialog.component';

enum CheckBoxType { payID, bank, NONE };

@Component({
  selector: 'app-add-withdraw-dialog',
  templateUrl: './add-withdraw-dialog.component.html',
  styleUrls: ['./add-withdraw-dialog.component.scss']
})
export class AddWithdrawDialogComponent implements OnInit, OnDestroy {

  withdrawAddForm: FormGroup;

  check_box_type = CheckBoxType;

  currentlyChecked: CheckBoxType = CheckBoxType.bank;
  base64textString: any;
  selectedFile: any;


  constructor(
    private helper: HelperService,
    private withdrawService: WithdrawService,
    public dialogRef: MatDialogRef<BuyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.withdrawAddForm = new FormGroup({
      AccountName: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z \-\']+')]),
      Routing: new FormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.pattern("^[1-9][0-9]*$")
      ]),
      AccountNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.pattern("[0-9]*$")
      ]),

      BankDocument: new FormControl('', Validators.required)
    });
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }

  canShowError(control: string) {
    const field = this.withdrawAddForm.controls[control];
    return (!field.valid) && (field.touched) && (field.dirty);
  }

  hasError(control: string, error: string) {
    return this.withdrawAddForm.controls[control].hasError(error);
  }

  fileChange(event) {
     this.selectedFile = event.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (this.selectedFile && this.selectedFile.size <= maxSizeInBytes) {
      // File size is within the limit, proceed with handling the file
      this.selectedFile = event.target.files[0];
      const fileSize = this.selectedFile.size;
            // You can now upload the file to your backend server or perform other actions
    } else {
      // File size exceeds the limit, show an error message or take appropriate action
      event.target.value = null;
      this.helper.error("File size exceeds the limit of 5MB.");
    }
    
}
  addBankAccount() {
    const formData = new FormData();
    formData.append('AccountName', this.getValue("AccountName"));
    formData.append('Routing', this.getValue("Routing"));
    formData.append('AccountNumber', this.getValue("AccountNumber"));
    formData.append('BankDocument',this.selectedFile);

    this.helper.showSpinner();
    this.withdrawService.addBankAccount(formData).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
        this.helper.info("Your account details submitted for review, once approved you can initiate withdraw.");
        this.withdrawService.changeMessage(true);
        this.dialogRef.close();
        this.helper.hideSpinner();
        this.withdrawAddForm.reset();
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
        this.closeDialog();
      }
    );
  }

  letterOnly(event) : Boolean{
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }
 
  getValue(control: string) {
    return this.withdrawAddForm.controls[control].value;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  selectCheckBox(targetType: CheckBoxType) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    if (this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }

    this.currentlyChecked = targetType;
  }
}

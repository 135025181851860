import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SellOrderService } from 'src/app/core/services/sell-order.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
    selector: 'app-swap-dialog',
    templateUrl: './swap-dialog.component.html',
    styleUrls: ['./swap-dialog.component.scss']
})

export class SwapDialogComponent implements OnInit {

    swapForm = new FormGroup({
        NoofBricklets: new FormControl(null)
    });

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SwapDialogComponent>,
        private sellService: SellOrderService,
        private helper: HelperService) {
    }

    ngOnInit() {
        this.swapForm.controls.NoofBricklets.setValidators([Validators.required, Validators.min(1), Validators.max(parseInt(this.data.availableBrickletToSwap))]);
    }

    closeDialog() {
        this.dialogRef.close();
    }

    canShowError(control: string) {
        const field = this.swapForm.controls[control];
        return field.invalid && (field.dirty || field.touched);
    }

    hasError(control: string, error: string) {
        return this.swapForm.controls[control].hasError(error);
    }

    onSubmit() {
        const data = {
            SellOrderGUID: this.data.SellOrderGUID,
            NoOfBricklets: parseInt(this.swapForm.get('NoofBricklets').value),
            IsNormaltoBPP: this.data.isBpp === true ? false : true
        }
        this.helper.showSpinner();
        this.sellService.swapBricklet(data).subscribe(
            (r: any) => {
                this.helper.hideSpinner();
                this.helper.success(r.msg);
                this.dialogRef.close(true);
            },
            (e) => {
                this.helper.error(e);
                this.helper.hideSpinner();
            }
        );
    }


}
<div>
    <div class="child">
        <app-org-personal-information [storedData]="sData.pInfo" (OrgPersonalInfo)="getPersonalInfo($event)">
        </app-org-personal-information>
    </div>
    <div class="child">
        <app-org-address-information [storedData]="sData.aInfo" (OrgAddressInfo)="getAddressInfo($event)">
        </app-org-address-information>
    </div>
    <div class="child">
        <app-proof-of-identity [config]='{ title:"Proof of Identity 1", data:sData.idProof1 }'
            (OrgIdProofInfo)="getIdProof1($event)">
        </app-proof-of-identity>
    </div>
    <div class="child">
        <app-proof-of-identity [config]='{ title:"Proof of Identity 2", data:sData.idProof2 }'
            (OrgIdProofInfo)="getIdProof2($event)"></app-proof-of-identity>
    </div>
</div>
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private toast: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {

  }

  success(content: string, title?: string) {
    this.toast.success(content, title ? title : 'Success', {timeOut: 3000});
  }

  error(content: string, title?: string) {
    if (content !== 'Refresh Token is expired' && content !== "Authorization is missing" && content !=="Token is expired" && typeof content == 'string') {
      this.toast.error(content, title ? title : 'Error',{timeOut: 3000});
    }
  }

  info(content: string, title?: string) {
    this.toast.info(content, title ? title : 'Info', {timeOut: 2000});
  }

  warning(content: string, title?: string) {
    this.toast.warning(content, title ? title : 'Warning',{timeOut: 3000});
  }

  canShowError(form: FormGroup, control: string) {
    const field = form.controls[control];
    return !field.valid && field.touched && field.dirty;
  }

  hasError(form: FormGroup, control: string, error: string) {
    return form.controls[control].hasError(error);
  }

  navigate(path: string) {
    this.router.navigateByUrl(path);
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }
}

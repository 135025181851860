import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CommunityFilter } from '../interfaces/community-request.interface';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  private communityCategory = new BehaviorSubject<any>(null);
  getCommunityCategory = this.communityCategory.asObservable();

  private articleCategory = new BehaviorSubject<any>(null);
  getArticleCategory = this.articleCategory.asObservable();

  private communityFilter = new BehaviorSubject<any>(null);
  getCommunityFilter = this.communityFilter.asObservable();

  constructor(
    private apiManager: ApiManagerService
  ) { }

  getCategory() {
    return this.apiManager.getRequest(RestEnds.commCategory, null);
  }

  getSubCategory(Id: string) {
    return this.apiManager.getRequestQueryParam(RestEnds.commSubCategory, 'categoryGUID=' + Id);
  }

  saveCommunity(data: any) {
    return this.apiManager.postRequest(RestEnds.saveCommunity, data);
  }

  // unSaveCommunity(id: string) {
  //   return this.apiManager.deleteRequest(RestEnds.unSaveCommunity + '?articleGUID=' + id);
  // }

  unSaveCommunity(data: any) {
    return this.apiManager.postRequest(RestEnds.unSaveCommunity, data);
  }

  saveCommunityCategory(data: any) {
    return this.communityCategory.next(data);
  }

  saveArticleCategory(data: any) {
    return this.articleCategory.next(data);
  }

  saveCommunityFilter(data: CommunityFilter) {


    if (data.StartDate == "TODAY") {
      data.StartDate = moment().subtract(1, 'days').format();
      data.EndDate = moment().format();
    }
    if (data.StartDate == "WEEK") {
      data.StartDate = moment().subtract(7, 'days').format();
      data.EndDate = moment().format();
    }
    if (data.StartDate == "MONTH") {
      data.StartDate = moment().subtract(30, 'days').format();
      data.EndDate = moment().format();
    }
    if (data.StartDate == "ALL TIME") {
      data.StartDate = '';
      data.EndDate = '';
    }

    return this.communityFilter.next(data);
  }

  getCommunitySavedList(data: any) {
    return this.apiManager.postRequest(RestEnds.communitySaved, data);
  }
}

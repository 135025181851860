import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProofOfIdentity } from 'src/app/core/interfaces/org-individual-kyc.interface';
import { KycOrgService } from 'src/app/core/services/kyc-org.service';

@Component({
  selector: 'app-proof-of-identity',
  templateUrl: './proof-of-identity.component.html',
  styleUrls: ['./proof-of-identity.component.scss']
})
export class ProofOfIdentityComponent implements OnInit, OnDestroy {

  selDoc = '';
  formData = null;

  proofList = [
    { value: 'DRIVING_LICENSE', text: 'Driver Licence' },
    { value: 'MEDICARE', text: 'Medicare' },
    { value: 'PASSPORT', text: 'Passport' },
  ];

  filterProofList = this.proofList;

  idNumber = Math.floor(Math.random() * (10000 - 1 + 1) + 1);

  @Input() config: {
    title: string,
    data: ProofOfIdentity
  };
  @Output() OrgIdProofInfo = new EventEmitter();
  preStatus = false;
  constructor(
    private kycOrgService: KycOrgService
  ) { }

  ngOnInit() {
    this.emitData();
    this.selDoc = this.config.data ? this.config.data.poI1_ProofType : '';
    if (this.selDoc) {
      this.kycOrgService.updateSelectedProof({
        proof: this.selDoc,
        id: this.idNumber
      });
    }
    this.kycOrgService.getSelectedProof.subscribe((s) => {
      if (s) {
        if (s.length > 0) {
          let list;
          s.forEach((p: any) => {
            if (p.id !== this.idNumber) {
              list = this.proofList.filter((f:any) => {
                if (f.value !== p.proof) {
                  return f;
                }
              });
            }
          });
          if (list) {
            this.filterProofList = list;
          } else {
            this.filterProofList = this.proofList;
          }
        } else {
          this.filterProofList = this.proofList;
        }
      } else {
        this.filterProofList = this.proofList;
      }
    });
  }

  docChange() {
    if (this.selDoc) {
      this.kycOrgService.updateSelectedProof({
        proof: this.selDoc,
        id: this.idNumber
      });
    } else {
      this.kycOrgService.removeSelectedProof(this.idNumber);
    }
    this.formData = null;
    this.emitData();
  }

  getMedData(e) {
    if (e) {
      const formData = new FormData();
      formData.append('ProofType', 'MEDICARE');
      formData.append('CardColor', e.MedicardCardColor);
      formData.append('CardNumber', e.MedicardCardNo);
      formData.append('IndividualNo', e.MedicardReferenceNo);
      formData.append('ExpireDate', e.MedicardExpiryDate);
      formData.append('FullName', e.MedicardFullName);
      if (e.AttachmentFiles[0].file) {
        formData.append('MedicareImage', e.AttachmentFiles[0].file);
      }
      if (e.AttachmentFiles[0].url) {
        formData.append('MedicareImageURL', e.AttachmentFiles[0].url);
      }
      this.formData = formData;

    } else {
      this.formData = null;
    }
    this.emitData();
  }

  getDLData(e) {
    if (e) {
      const formData = new FormData();
      formData.append('ProofType', 'DRIVING_LICENSE');

      formData.append('DrivingLicenseNo', e.DrivingLicenseNumber);
      formData.append('StateIssuedGUID', e.StateGUID);

      if (e.AttachmentFiles[0].file) {
        formData.append('DrivingLicenseFrontSide', e.AttachmentFiles[0].file);
      }

      if (e.AttachmentFiles[0].url) {
        formData.append('DrivingLicenseFrontSideURL', e.AttachmentFiles[0].url);
      }

      if (e.AttachmentFiles[1].file) {
        formData.append('DrivingLicenseBackSide', e.AttachmentFiles[1].file);
      }

      if (e.AttachmentFiles[1].url) {
        formData.append('DrivingLicenseBackSideURL', e.AttachmentFiles[1].url);
      }

      this.formData = formData;
    } else {
      this.formData = null;
    }
    this.emitData();
  }

  getPassportData(e) {
    if (e) {
      const formData = new FormData();
      formData.append('ProofType', 'PASSPORT');
      formData.append('PassportNo', e.PassportNumber);
      formData.append('CountryOfResidence', e.residentCountry);
      formData.append('CountryOfPassportIssued', e.issuedCountry);
      if (e.AttachmentFiles[0].file) {
        formData.append('PassportImage', e.AttachmentFiles[0].file);
      }

      if (e.AttachmentFiles[0].url) {
        formData.append('PassportImageURL', e.AttachmentFiles[0].url);
      }

      this.formData = formData;
    } else {
      this.formData = null;
    }
    this.emitData();
  }

  emitData() {
    this.OrgIdProofInfo.emit(this.formData);
    if (this.formData) {
      this.preStatus = true;
    } else {
      this.preStatus = false;
    }
  }

  ngOnDestroy() {
    this.kycOrgService.removeSelectedProof(this.idNumber);
  }

}

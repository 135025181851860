<div class="bg-waav-bright-light p-4" *ngIf="(isDataLoaded && records.length>0) || loader">
    <h2 class="primary-bold f18 purple-brown ms-2 mb-4">
        <img src="/assets/images/icon-trending.svg" alt="" class="me-2">
        Popular right now
    </h2>
    <div *ngIf="!isDataLoaded">
        <div *ngFor="let r of [1,2,3]" class="row">
            <div class="col-lg-12 row m-0 mb-4">
                <ngx-skeleton-loader [theme]="{'height':'20px'}" appearance="line"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{'height':'50px'}" appearance="line"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{'width':'50%'}" appearance="line"></ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row" *ngFor="let r of records">
        <div class="col-lg-12 row m-0 mb-4">
            <a [routerLink]="'/community/feed/'+r.articleGUID" class="mb-3">
                <h3 class="title article-title">{{r.title}}</h3>
            </a>
            <!-- <h3 class="primary-bold f16 waav-dark-dark  title">
                {{r.title}}
            </h3> -->
            <p class="primary-regular f14 waav-gray-dark mb-3">
                {{r.description}}
            </p>
            <span class="primary-bold f14 waav-gray-dark">
                {{r.createdDate | date :'MMM dd'}}.{{r.stateName}}
            </span>
        </div>
    </div>
</div>
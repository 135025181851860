import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/core/services/account.service';
import { AppService } from 'src/app/core/services/app.service';
import { SignalRServiceService } from 'src/app/core/services/signal-rservice.service';
import { StorageService } from 'src/app/core/utils/storage.service';

@Component({
  selector: 'app-common-panel',
  templateUrl: './common-panel.component.html',
  styleUrls: ['./common-panel.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition('* <=> *', [
        // Set initial style for both entering and leaving elements
        query(':enter, :leave', style({ position: 'fixed', opacity: 1 }), { optional: true }),
    
        // Group the animations for entering and leaving elements
        group([
          // Animation for entering element
          query(':enter', [
            style({ opacity: 0 }), // Set initial opacity for entering element
            animate('1000ms linear', style({ opacity: 1 })) // Animate opacity to 1 over 1000ms
          ], { optional: true }),
    
          // Animation for leaving element
          query(':leave', [
            style({ opacity: 1 }), // Set initial opacity for leaving element
            animate('800ms ease-in-out', style({ opacity: 0 })) // Animate opacity to 0 over 800ms with ease-in-out timing
          ], { optional: true }),
        ])
      ])
    ])
  ]

})
export class CommonPanelComponent implements OnInit {
  title = 'INVCAR';
  showHeader = false;
  showSidebar = false;
  cartDetails: boolean;
  isLogged = false;
  language: string = '';
  mySubjectVal: boolean;
  permissions = [
    {
      id: 'detail',
      showHeader: false,
      showSidebar: false
    }
  ]
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private appService: AppService,
    private accountService: AccountService,
    private translate: TranslateService,
    private storageService: StorageService,
    private signalRService: SignalRServiceService
  ) {
    if (this.storageService.getDefaultLang()) {
      this.language = this.storageService.getDefaultLang();
    } else {
      this.language = 'English';
    }

    translate.addLangs([
      'English',
      'Chinese',
      'Korean',
      'Vietnamese',
      'Japanese',
      'Indonesian',
      'Thailand',
      'Mongolia',
    ]);
    // translate.setDefaultLang('ch');
    // const browserLang = translate.getBrowserLang();
    translate.use(this.language);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.permissions.map((item: any) => {
          if (event.url.includes(item.id)) {
            console.log('Without H & S');
            this.showHeader = item.showHeader;
            this.showSidebar = item.showSidebar;
          } else {
            if (activateRoute.firstChild?.snapshot.data) {
              const data = activateRoute.firstChild?.snapshot.data;
              if (data) {
                this.showHeader = data.header;
                this.showSidebar = data.showSidebar;
              }
            }
          }
        })

      }
    });
  }

  ngOnInit() {
    this.appService.isLoggedIn().subscribe(
      (status) => {
        if (!this.isLogged && status) {
          this.appService.getUserStatus();
          setTimeout(() => {
            this.accountService.getAndSetAccounts();
          }, 700);
        }
        this.isLogged = status;
      },
      (e) => { }
    );

    this.router.events.subscribe((val: any) => {
      if (val && val.url) {
        if (this.isLogged) {
          if (val.url === '/home' || val.url === '/') {
            this.router.navigateByUrl('/marketplace/list');
          }
        }
      }
    });

  //   this.signalRService.startConnection();
  //   this.signalRService.getPaymentSuccessObservable().subscribe(() => {
  //     // Handle payment success notification
  //     console.log('Payment successful! Send push notification or update UI.');
  //  });
    this.cartEnableFunction();

  }


  cartEnableFunction() {
    this.appService.stringSubject.subscribe(
      data => {
        this.cartDetails = data;
        this.cartDetails = !this.cartDetails;
        console.log("cartEnable", this.cartDetails);

        if(this.cartDetails === true){
          setTimeout(()=>{
          this.ValueFromRightSidebar(true);
          },500)
          this.ValueFromRightSidebar(false);
        }
      }
    );
  }

  ValueFromRightSidebar(var1: any) {
    this.cartDetails = var1;
  }

}

<div class="w-100 bg-waav-bright-light p-3 row align-items-center justify-content-between">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <span class="primary-semi-bold f20 waav-dark-main">{{config.title}}</span>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <select [(ngModel)]="selDoc" (change)="docChange()" class="form-control">
            <option value="">Select document</option>
            <option *ngFor="let p of filterProofList" [value]="p.value">{{p.text}}</option>
        </select>
    </div>
</div>
<div id="form-container" class="mt-4 bg-white col-12" *ngIf="selDoc!==''">

    <app-medicare-card-form *ngIf="selDoc=='MEDICARE'" (medicareFormData)="getMedData($event)"
        [storedData]="config.data ?config.data.medicare: ''">
    </app-medicare-card-form>
    <app-driving-license-form *ngIf="selDoc=='DRIVING_LICENSE'" (dlFormData)="getDLData($event)"
        [storedData]="config.data ?config.data.drivingLicense: ''">
    </app-driving-license-form>
    <app-passport-form *ngIf="selDoc=='PASSPORT'" (passportFormData)="getPassportData($event)"
        [storedData]="config.data ?config.data.passport: ''"></app-passport-form>
</div>
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuySellService } from 'src/app/core/services/buy-sell.service';
import { HelperService } from 'src/app/core/utils/helper.service';
import { BuyInitiatedDialogComponent } from '../buy-initiated-dialog/buy-initiated-dialog.component';

@Component({
  selector: 'app-buy-bpp-dialog',
  templateUrl: './buy-bpp-dialog.component.html',
  styleUrls: ['./buy-bpp-dialog.component.scss']
})
export class BuyBppDialogComponent implements OnInit {

  @ViewChild('myTooltip', { static: false }) myTooltip;

  pdfLink = '';
  legalDocLink = '';
  BPPTermsCondition = '';

  historyList = [];
  taxList;
  accountBalance = 0;

  brickletCost = 0;
  initialAmount = 0;
  taxAmount = 0;
  setupFee = 0;
  totalAmount = 0;
  totalInitialAmount = 0;

  isEligibleUser = false;

  buyBrickletForm = new FormGroup({
    NoOfBricklet: new FormControl('', [Validators.required]),
    termsAndCondition: new FormControl(false, Validators.pattern('true')),
    legalDocAgreement: new FormControl(false, Validators.pattern('true'))
  });

  totalTaxPercentage = 6;
  constructor(
    public dialogRef: MatDialogRef<BuyBppDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private buyService: BuySellService,
    private helper: HelperService,
    private dialog: MatDialog
  ) {

    

  }

  ngOnInit(): void {


    // const legalDocControl = <FormControl>this.buyBrickletForm.get('legalDocAgreement');
    // const termsandConditionsControl = <FormControl>this.buyBrickletForm.get('termsAndCondition');
    // legalDocControl.valueChanges.subscribe(value => {
    //   if (this.legalDocLink) {
    //     legalDocControl.setValidators([Validators.pattern('true')]);
    //   }
    //   else {
    //     legalDocControl.setValidators(null);
    //   }
    //   legalDocControl.updateValueAndValidity();
    // });

    // termsandConditionsControl.valueChanges.subscribe(value => {
    //   if (this.pdfLink) {
    //     termsandConditionsControl.setValidators([Validators.pattern('true')]);
    //   }
    //   else {
    //     termsandConditionsControl.setValidators(null);
    //   }
    //   termsandConditionsControl.updateValueAndValidity();
    // })

    this.isBuyAvailable();
  }

  getMarketPrice() {

    let data = {
      PropertyGUID: this.data.id,
      Quantity: parseInt(this.buyBrickletForm.get('NoOfBricklet').value)
    }
    data.Quantity = data.Quantity ? data.Quantity : 0;
    if (data.Quantity > 0) {
      this.helper.showSpinner();
      this.buyService.getBppBuyerMarketPrice(data).subscribe(
        (r: any) => {
          this.helper.hideSpinner();
          const res = r.data.marketPrice;
          this.historyList = res.nearPriceRange;

          this.brickletCost = res.brickletCost;
          this.initialAmount = res.initialAmount;
          this.taxAmount = res.taxAmount;
          this.setupFee = res.setupFee;
          this.totalAmount = res.totlaBrickletAmount;
          this.totalInitialAmount = this.initialAmount + this.taxAmount + this.setupFee;
          this.taxList = res.tax;
          this.totalTaxPercentage = this.taxList?.buyerStampDutyTax + this.taxList?.buyerPropertyTransferTax + this.taxList?.buyerAdministrationTax;
        },
        (e) => {
          this.helper.error(e);
          this.helper.hideSpinner();
        }
      );
    } else {
      this.historyList = [];
      this.taxList = null;
      this.brickletCost = 0;
      this.initialAmount = 0;
      this.taxAmount = 0;
      this.setupFee = 0;
      this.totalAmount = 0;
      this.totalInitialAmount = 0;
    }
  }

  onSubmit() {
    const data = {
      PropertyGUID: this.data.id,
      NoOfBricklet: this.buyBrickletForm.get('NoOfBricklet').value
    };
    this.helper.showSpinner();
    //this.buyService.buyBppBricklet(data).subscribe(
    this.buyService.bppSign(data).subscribe(
      (r: any) => {
        this.helper.hideSpinner();
        this.helper.success(r.msg);
        const dialogRef = this.dialog.open(BuyInitiatedDialogComponent, {
          data: {
            id: this.data.id,
            qty: this.buyBrickletForm.get('NoOfBricklet').value,
            amount: this.totalInitialAmount,
            isBpp: true
          }
        });
        this.dialogRef.close(true);
        dialogRef.afterClosed().subscribe(result => {
          this.dialogRef.close(true);
        });
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  isBuyAvailable() {
    this.buyService
      .isBrickletAvailableToBppBuy({ PropertyGUID: this.data.id })
      .subscribe(
        (r: any) => {
          const data: any = r.data;
          this.accountBalance = data.buyingDetails.balance;
          this.pdfLink = data.buyingDetails.termsConditionPDF;
          //this.legalDocLink = data.buyingDetails.legalDocumentPDF;
          this.BPPTermsCondition = data.buyingDetails.bppTermsCondition;
          this.isEligibleUser = data.buyingDetails.isAvailabile;
          this.helper.hideSpinner();
          this.totalTaxPercentage = data.buyingDetails.administrationFees + data.buyingDetails.propertyTransfer + data.buyingDetails.stampDuty;
        },
        e => {
          this.helper.hideSpinner();
        }
      );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  close() {
    this.myTooltip.close();
  }

}

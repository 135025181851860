import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RapidIdDialogComponent } from '../rapid-id-dialog/rapid-id-dialog.component';

@Component({
  selector: 'app-no-investor',
  templateUrl: './no-investor.component.html',
  styleUrls: ['./no-investor.component.scss']
})
export class NoInvestorComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openRapidIdDialog() {
    let dialogRef = this.dialog.open(RapidIdDialogComponent, {
      data: {

      },
      panelClass: 'custom-static-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

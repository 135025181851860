import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { LabelType, Options } from 'ng5-slider';
import { LookupService } from 'src/app/core/services/lookup.service';
import { debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
declare var $: any;
declare var bootstrap: any;
@Component({
  selector: 'app-property-list-filter',
  templateUrl: './property-list-filter.component.html',
  styleUrls: ['./property-list-filter.component.scss'],
})
export class PropertyListFilterComponent implements OnInit {
  @Output() filterChange = new EventEmitter<any>();
  @ViewChild('propertySearchInput', { static: true }) searchInput: ElementRef;
  //@ViewChild('propertySearchInputMobile', { static: true }) searchInputMobile: ElementRef;
  value = 1000;
  highValue = 100000;

  options: Options = {
    floor: 1,
    ceil: 100000,
    translate: (value: number, label: LabelType): any => {
      switch (label) {
        case LabelType.Low:
          return this.currencyPipe.transform(value, 'AUD', '', '0.0-0');
        case LabelType.High:
          return this.currencyPipe.transform(value, 'AUD', '', '0.0-0');
        default:
          return this.currencyPipe.transform(value, 'AUD', '', '0.0-0');
      }
    },
  };

  stateList: {
    stateGUID: string;
    name: string;
    shortName: string;
  }[] = [];

  filterData = {
    startPrice: 1000,
    endPrice: 100000,
    propertyDeveloperGUID: '',
    sortyBy: 'newest',
    stateGUID: '',
    search: '',
    isWatchList: false,
    isBrickletsForSale: false,
    IsOwnedPropertyList: false,
    isBPPPropertyList: false
  };

  constructor(
    private currencyPipe: CurrencyPipe,
    private lookUpService: LookupService
  ) { }

  ngOnInit(): void {
    this.getStates();

    // fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
    //   debounceTime(1000)
    // ).subscribe(
    //   (s) => {
    //     this.filterChange.emit(this.filterData);
    //   }
    // );

    // fromEvent(this.searchInputMobile.nativeElement, 'keyup').pipe(
    //   debounceTime(1000)
    // ).subscribe(
    //   (s) => {
    //     this.filterChange.emit(this.filterData);
    //   }
    // );

    $(document).click(function (e) {
      if (!$(e.target).is('.accordion-body') && !$(e.target).is('.mobile-panel')) {
        $('#filter-accordion .collapse').collapse('hide');
      }
    });

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    })

  }

  sendFilterData() {
    this.filterChange.emit(this.filterData);
  }

  orderByChange() {
    this.filterChange.emit(this.filterData);
  }

  getStates() {
    this.lookUpService.getStates().subscribe(
      (s: any) => {
        this.stateList = s.data.states;
      },
      (e) => {

      }
    );
  }

  stateChange() {
    this.filterChange.emit(this.filterData);
  }

  priceChange(e) {
    this.filterData.startPrice = e.value;
    this.filterData.endPrice = e.highValue;
    this.filterChange.emit(this.filterData);
  }




  // openSelect() {
  //   this.openStatus = !this.openStatus;
  //   debugger;
  //   var element: any = document.getElementById('location');
  //   if (this.openStatus) {
  //     element.size = element.length;
  //   } else {
  //     element.size = 1;
  //   }
  // }
}

import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WithdrawService {

  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService) { }

  getBankList() {
    return this.apiManagerService.getRequest(RestEnds.bankAvailable, null);
  }

  addBankAccount(data) {
    return this.apiManagerService.postRequest(RestEnds.bank, data);
  }

  withdrawAmount(data) {
    return this.apiManagerService.postRequest(RestEnds.withdrawAmount, data);
  }

  deleteAccount(guid) {
    return this.apiManagerService.deleteRequest(
      `${RestEnds.bank}?SplitBankGUID=${guid}`
    );
  }

  updateTFNnumber(TFNnumber) {
    return this.apiManagerService.getRequestQueryParam(
      RestEnds.TFNnumber, 'tfn=' + TFNnumber
    );
  }

  updateBankAccount(data) {
    return this.apiManagerService.putRequest(RestEnds.bank, data);
  }

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

  getwithdrawaccountdetails() {
    return this.apiManagerService.postRequest(RestEnds.withdrawaccountdetails,null);
  }

}

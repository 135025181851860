<div mat-dialog-title class="mb-0">
    <div class="row h-50 b-title">
        <div class="col-lg-6 col-md-6 col-sm-12 pe-4">
            <h2 class="primary-bold waav-dark-main f20"> {{ 'DASHBOARD.addnewaccount' | translate }} </h2>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ps-2 pe-2 pos-rel">
            <img src="/assets/images/dialog-close.svg" class="trade-dialog-close" (click)="closeDialog()"
                title="Close dialog" alt="close_dialog">
        </div>
    </div>
</div>
<form [formGroup]="withdrawAddForm">
    <div class="row col-lg-12" id="add-container">
        <!-- <div class="box">
        <div class="d-flex justify-content-between h-50">
                <div class="select-acc-container">
                    <label class="checkbox-label pe-4">
                        <input type="checkbox" name="type" [checked]="currentlyChecked === check_box_type.payID" [disabled]="true"
                            (click)="selectCheckBox(check_box_type.payID)">
                        <span class="checkmark select-account"></span>
                    </label>
                </div>
            <label class="value payid-img"><img src="../../../../assets/images/withdraw-icons/pay-id-icon.svg"></label>
        </div>
        <label class="title">Pay ID</label>
    </div>

    <div class="box">
        <div class="d-flex justify-content-between h-50">
              
                <div class="select-acc-container">
                    <label class="checkbox-label pe-4">
                        <input type="checkbox" name="type" [checked]="currentlyChecked === check_box_type.bank" [disabled]="true"
                            (click)="selectCheckBox(check_box_type.bank)">
                        <span class="checkmark select-account"></span>
                    </label>
                </div>
            <label class="value"><img src="../../../../assets/images/withdraw-icons/bank-icon.svg"></label>
        </div>
        <label class="title">Bank Account</label>
    </div> -->
    </div>
    <mat-dialog-content class="mat-typography mh-content">

        <div class="row col-lg-12 mt-4" id="add-container">

            <div class="col-lg-12 form-group">
                <label class="form-label f14">{{ 'DASHBOARD.accountname' | translate }}</label>
                <!-- <input type="text" formControlName="AccountName" class="form-control" maxlength="50" (keypress)="letterOnly($event)" -->
                <input type="text" formControlName="AccountName" class="form-control" maxlength="30"
                    placeholder="{{ 'DASHBOARD.enteryouraccountname' | translate }}">
                <div *ngIf="canShowError('AccountName')">
                    <p class="error-txt" *ngIf="hasError('AccountName', 'required')">
                        {{ 'DASHBOARD.accountnameisrequired' | translate }}
                    </p>
                    <p class="error-txt" *ngIf="hasError('AccountName', 'pattern')">
                        {{ 'DASHBOARD.accountnameshouldbecharacters' | translate }}
                    </p>
                </div>
            </div>
            <div class="col-lg-12 form-group">
                <label class="form-label f14">{{ 'DASHBOARD.accountRouting' | translate }}</label>
                <input type="text" formControlName="Routing" class="form-control" maxlength="9"
                    placeholder="{{ 'DASHBOARD.enteryourroutinghere' | translate }}">
                <div *ngIf="canShowError('Routing')">
                    <p class="error-txt" *ngIf="hasError('Routing', 'required')">
                        {{ 'DASHBOARD.routingisrequired' | translate }}
                    </p>
                    <p class="error-txt" *ngIf="hasError('Routing', 'minlength') || hasError('Routing', 'pattern')">
                        {{ 'DASHBOARD.routingmustbe9digitnumber' | translate }}
                    </p>
                    
                </div>
            </div>
            <div class="col-lg-12 form-group">
                <label class="form-label f14">{{ 'DASHBOARD.accountnumber' | translate }}</label>
                <input type="text" formControlName="AccountNumber" class="form-control" maxlength="12"
                    placeholder="{{ 'DASHBOARD.enteryouraccountnumber' | translate }}">
                <div *ngIf="canShowError('AccountNumber')">
                    <p class="error-txt" *ngIf="hasError('AccountNumber', 'required')">
                        {{ 'DASHBOARD.accountnumberisrequired' | translate }}
                    </p>
                    <p class="error-txt" *ngIf="hasError('AccountNumber', 'minlength') || hasError('AccountNumber', 'pattern')">
                        {{ 'DASHBOARD.accountnumbershouldbedigits' | translate }}
                    </p>
                </div>
            </div>

            <div class="col-lg-12 form-group">
                <label class="form-label f14">{{ 'DASHBOARD.bankdocument' | translate }}</label>
                <input type="file" formControlName="BankDocument" class="form-control" (change)="fileChange($event)" placeholder="Upload file" accept=".pdf"
                    placeholder="{{ 'DASHBOARD.enteryourbankdocumenthere' | translate }}">
                <div *ngIf="canShowError('BankDocument')">
                    <p class="error-txt" *ngIf="hasError('BankDocument', 'required')">
                        {{ 'DASHBOARD.bankdocumentisrequired' | translate }}
                    </p>
                </div>
                <p class="mt-2">
                    Note: {{ 'DASHBOARD.bankdocumentshouldbepdfformat' | translate }}
                </p>
            </div>

        </div>
    </mat-dialog-content>
    <mat-dialog-content>
        <div class="row col-lg-12" id="add-container">
            <!-- <div class="checkbox-container">
        <label class="checkbox-label pe-4">
            Use this as my default account for withdrawl
            <input type="checkbox" formControlName="default" />
            <span class="checkmark"></span>
        </label>
    </div> -->
            <div class="form-group col-lg-8 col-md-10 col-sm-12">
                <!-- <button class="btn btn-primary w-100" [disabled]="!withdrawAddForm.valid"
                    (click)="addBankAccount()"> {{ 'DASHBOARD.add' | translate }}</button> -->
                    <button class="btn btn-primary w-100" [disabled]="!withdrawAddForm.valid"
                    (click)="addBankAccount()"> {{ 'DASHBOARD.add' | translate }}</button>
            </div>
        </div>
    </mat-dialog-content>

</form>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RestEnds } from './rest-ends';

@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {

  constructor(
    private httpClient: HttpClient
  ) { }

  baseUrl() {
    return environment.restApiBaseUrl;
  }

  prepareUrl(endPoint: RestEnds | string) {
    return this.baseUrl() + '' + endPoint;
  }

  getRequest(endPoint: RestEnds, option: any) {
    const restUrl = this.prepareUrl(endPoint);
    if (option) {
      return this.httpClient.get(restUrl, option);
    } else {
      return this.httpClient.get(restUrl);
    }
  }

  getRequestQueryParam(endPoint: RestEnds, data: string) {
    const restUrl = this.prepareUrl(endPoint);
    return this.httpClient.get(`${restUrl}?${data}`);
  }

  getRequestParam(endPoint: RestEnds, data: string) {
    const restUrl = this.prepareUrl(endPoint);
    return this.httpClient.get(`${restUrl}/${data}`);
  }

  postRequest(restEnd: RestEnds, bodyParam: any,) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.post(restUrl, bodyParam);
  }

  postRequestQueryParam(endPoint: RestEnds, data: string) {
    const restUrl = this.prepareUrl(endPoint);
    return this.httpClient.post(`${restUrl}?${data}`, null);
  }

  putRequest(restEnd: RestEnds, bodyParam: any = null) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.put(restUrl, bodyParam);
  }

  putRequestQueryParam(restEnd: RestEnds, data: string) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.put(`${restUrl}?${data}`, null);
  }

  deleteRequest(restEnd: RestEnds | string) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.delete(restUrl);
  }

  patchRequest(restEnd: RestEnds | string, bodyParam: any) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.patch(restUrl, bodyParam);
  }

  getBlobRequest(endPoint: string, option: any) {
    const restUrl = this.prepareUrl(endPoint);
    if (option) {
      return this.httpClient.get(restUrl, option);
    } else {
      return this.httpClient.get(restUrl);
    }
  }

  getCustomBlobRequest(url: string, option: any) {
    if (option) {
      return this.httpClient.get(url, option);
    } else {
      return this.httpClient.get(url);
    }
  }
}

import { Injectable } from '@angular/core';
import { isArray } from 'ngx-bootstrap/chronos';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

export interface IdProof {
  proof: string;
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class KycOrgService {

  public ProofOfIdentity = new BehaviorSubject(<any>[]);
  public getSelectedProof = this.ProofOfIdentity.asObservable();

  constructor(private apiManagerService: ApiManagerService) { }

  updateSelectedProof(proof: IdProof) {
    const data = [...this.ProofOfIdentity.getValue(), proof];
    this.ProofOfIdentity.next(data);
  }

  removeSelectedProof(id: number) {
    const proofs = this.ProofOfIdentity.getValue();

    const filterProofs = proofs.filter((f: any) => {
      return f.id !== id;
    });
    this.ProofOfIdentity.next(filterProofs);
  }

  getEntityProfile() {
    return this.apiManagerService.getRequest(RestEnds.OrgKycEntityProfile, null);
  }

  postEntityProfile(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.OrgKycEntityProfile,
      data
    );
  }

  updateEntityProfile(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.OrgKycEntityProfile,
      data
    );
  }

  getTrustee() {
    return this.apiManagerService.getRequest(RestEnds.OrgKycTrustee, null);
  }

  postTrustee(data: any) {
    return this.apiManagerService.postRequest(RestEnds.OrgKycTrustee, data);
  }

  updateTrustee(data: any) {
    return this.apiManagerService.putRequest(RestEnds.OrgKycTrustee, data);
  }

  getUbo() {
    return this.apiManagerService.getRequest(RestEnds.OrgKycUbo, null);
  }

  postUbo(data: any) {
    return this.apiManagerService.postRequest(RestEnds.OrgKycUbo, data);
  }

  updateUbo(data: any) {
    return this.apiManagerService.putRequest(RestEnds.OrgKycUbo, data);
  }

  getSettlor() {
    return this.apiManagerService.getRequest(RestEnds.OrgKycSettlor, null);
  }

  postSettlor(data: any) {
    return this.apiManagerService.postRequest(RestEnds.OrgKycSettlor, data);
  }

  updateSettlor(data: any) {
    return this.apiManagerService.putRequest(RestEnds.OrgKycSettlor, data);
  }

  acceptTc() {
    return this.apiManagerService.getRequest(RestEnds.OrgKycAcceptTC, null);
  }

  skipUbo() {
    return this.apiManagerService.patchRequest(
      RestEnds.kycSkipStep + "?step=UBO",
      null
    );
  }

  skipSettlor() {
    return this.apiManagerService.patchRequest(
      RestEnds.kycSkipStep + "?step=SETTLOR",
      null
    );
  }

  postPrivateCompEtyCompDetails(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.stepTwoPrivateCompany,
      data
    );
  }

  updatePrivateCompEtyCompDetails(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.stepTwoPrivateCompany,
      data
    );
  }

  getPrivateCompEtyCompDetails() {
    return this.apiManagerService.getRequest(RestEnds.stepTwoPrivateCompany, null);
  }

  getPartnershipIndividualDetails() {
    return this.apiManagerService.getRequest(RestEnds.stepTwoPartnership, null);
  }

  postPartnershipIndividualDetails(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.stepTwoPartnership,
      data
    );
  }

  updatePartnershipIndividualDetails(data: any) {
    return this.apiManagerService.putRequest(RestEnds.stepTwoPartnership, data);
  }

  getUnregulatedTrusteeDetails() {
    return this.apiManagerService.getRequest(RestEnds.stepTwoUnregulatedTrust, null);
  }

  postUnregulatedTrusteeDetails(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.stepTwoUnregulatedTrust,
      data
    );
  }

  updateUnregulatedTrusteeDetails(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.stepTwoUnregulatedTrust,
      data
    );
  }

  getSoleTraderDetails() {
    return this.apiManagerService.getRequest(RestEnds.stepTwoSoleTrader, null);
  }

  postSoleTraderDetails(data: any) {
    return this.apiManagerService.postRequest(RestEnds.stepTwoSoleTrader, data);
  }

  updateSoleTraderDetails(data: any) {
    return this.apiManagerService.putRequest(RestEnds.stepTwoSoleTrader, data);
  }
}

import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseForm } from '../interfaces/base-form';

@Injectable({
  providedIn: 'root'
})
export class FormControlService {
  constructor() { }

  toFormGroup(rows: BaseForm<string>[] ) {
    const group: any = {};

    rows.forEach(row => {
      group[row.key] = row.required ? new FormControl(row.value || '', Validators.required)
                                              : new FormControl(row.value || '');
    });
    return new FormGroup(group);
  }
}
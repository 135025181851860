<div class="row mt-4">
  <h2 class="primary-bold waav-dark-main f30">Viewers of this account</h2>
  <div class="col-lg-12" id="setting-container" *ngIf="viewers.length > 0">
    <table class="table primary desktop-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let viewer of viewers">
          <td class="bold">{{ viewer.name }}</td>
          <td class="bold">{{ viewer.email }}</td>
          <td class="bold">{{ viewer.mobile }}</td>
          <td class="bold">
            <img src="../../../../assets/images/action-icons/delete.svg"
              (click)="deleteViewerPopup(viewer.accountMemberGUID)">
          </td>
        </tr>
      </tbody>
    </table>

    <div class="mobile-table-container">
      <div *ngFor="let viewer of viewers" class="bg-waav-bright-light mb-4">
        <table class="table mobile">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{{ viewer.name }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ viewer.email }}</td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td>{{ viewer.mobile }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <img src="../../../../assets/images/action-icons/delete.svg"
                  (click)="deleteViewerPopup(viewer.accountMemberGUID)">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <p class="no-record text-center wp-charcoal" *ngIf="viewers?.length <=0 ">No Viewers
    Found</p>

  <h2 class="primary-bold waav-dark-main f20">Add a New User Viewer</h2>
  <form [formGroup]="addViewerForm" class="mt-4" autocomplete="off">
    <div class="col-12 d-flex flex-row flex-wrap">
      <div class="form-group col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
        <label class="form-label f14">Email</label>
        <input type="text" formControlName="email" class="form-control" maxlength=50
          placeholder="Eg. example@domain.com">
        <div *ngIf="canShowError('email')">
          <p class="error-txt" *ngIf="hasError('email','required')">e-mail is required</p>
          <p class="error-txt" *ngIf="hasError('email','email')">Enter valid email</p>
        </div>
      </div>
      <div class="d-flex justify-content-center col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
        <button class="w-100 btn btn-primary primary-medium f16 white" [disabled]="!addViewerForm.valid"
          (click)="addNewViewerForm()" id="bc-save-btn">Add
          User Viewer</button>
      </div>
    </div>
  </form>
</div>


<div class="modal fade" id="deleteViewerModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title f18 primary-semi-bold">Confirmation</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="primary-regular">Do you want to Delete Viewers ?</p>

        <div class="row d-flex justify-content-end">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <button type="button" (click)="deleteViewer()" class="btn btn-primary outlined w-100 mt-2"
              data-bs-dismiss="modal">Yes</button>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <button type="button" class="btn btn-primary w-100 mt-2" data-bs-dismiss="modal">No</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
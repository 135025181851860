import * as moment from "moment";

export interface CommunityFilter {
    PageNumber: number;
    PageSize: number;
    Search: string;
    StartDate: string;
    EndDate: string;
    StateGUID: string;
}

export const defaultCommunityFilter: CommunityFilter = {
    PageNumber: 1,
    PageSize: 3,
    Search: '',
    StartDate: 'WEEK',
    EndDate: '',
    StateGUID: ''
}
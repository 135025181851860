import { Component, Input, OnInit } from '@angular/core';
import { CommunityFilter, defaultCommunityFilter } from 'src/app/core/interfaces/community-request.interface';
import { ArticleService } from 'src/app/core/services/article.service';
import { CommunityService } from 'src/app/core/services/community.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-article-you-may-like',
  templateUrl: './article-you-may-like.component.html',
  styleUrls: ['./article-you-may-like.component.scss']
})
export class ArticleYouMayLikeComponent implements OnInit {

  @Input() viewAll;
  filterData = defaultCommunityFilter;
  records = [];
  isDataLoaded = false;
  loader = false;
  constructor(
    private articleService: ArticleService,
    private communityService: CommunityService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    if (!this.viewAll) {
      this.viewAll = true;
    } else {
      this.viewAll = false;
    }

    this.communityService.getCommunityFilter.subscribe(s => {
      if (s) {
        this.filterData = s;
        this.getRecord();
      }
    });

    //this.getRecord();
  }

  getRecord() {
    let data: CommunityFilter = this.filterData;
    data.PageSize = 3;
    //this.helper.showSpinner();
    this.isDataLoaded = false;
    this.loader = true;
    this.articleService.getYouMightLike(data).subscribe(
      (s: any) => {
        this.records = s.data.articles.articles;
        //this.helper.hideSpinner();
        this.isDataLoaded = true;
        this.loader = false;
      },
      (e) => {
        //this.helper.hideSpinner();
        this.helper.error(e);
        this.isDataLoaded = true;
        this.loader = false;
      }
    );
  }

  saveArticle(id: string) {
    this.helper.showSpinner();
    this.communityService.saveCommunity(id).subscribe(
      (s: any) => {
        this.helper.success(s.msg);
        this.helper.hideSpinner();
      },
      (e: string) => {
        this.helper.hideSpinner();
        this.helper.error(e);
      }
    );
  }

  unSaveArticle(id: string) {
    this.helper.showSpinner();
    const data = {
      ArticleGUID:id,
      VideoGUID:"",
      PodcastGUID:""
    }
    this.communityService.unSaveCommunity(data).subscribe(
      (s: any) => {
        this.helper.success(s.msg);
        this.helper.hideSpinner();
      },
      (e: string) => {
        this.helper.hideSpinner();
        this.helper.error(e);
      }
    );
  }

}

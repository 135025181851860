export interface Pagination {
    PageNum: number;
    PageSize: number;
}

export const defaultPagination: Pagination = {
    PageNum: 1,
    PageSize: 5
};



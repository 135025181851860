import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-medicare-card-form',
  templateUrl: './medicard.component.html',
  styleUrls: ['./medicard.component.scss']
})
export class MedicardComponent implements OnInit {

  imagePreview = {
    medicarePreview: null,
    default: '/assets/images/preview.png'
  };

  sendFiles = {
    docType: 'MEDICARE',
    docText: 'FRONT SIDE'
  };

  medicareCardColorList = [
    {
      label: 'BLUE',
      value: 'blue'
    },
    {
      label: 'GREEN',
      value: 'green'
    },
    {
      label: 'YELLOW',
      value: 'yellow'
    }
  ];

  medicareFile = null;
  medicareFileUrl = null;

  medicareForm: FormGroup;
  @Output() medicareFormData: EventEmitter<any> = new EventEmitter();
  @Input() storedData: any;

  @ViewChild('medicareDoc', { static: false }) medicareDoc: ElementRef;
  medicareFormLocalData = null;

  constructor(
    private imageService: ImageService,
    private datePipe: DatePipe
  ) { }
  ngOnInit() {
    this.createForm();
    this.medicareForm.statusChanges.subscribe(
      (status) => {
        if (status === 'VALID') {

          const filesAttached = [
            {
              file: this.medicareFile,
              rename: this.medicareForm.value.MedicardCardFile,
              url: this.medicareFileUrl
            },
          ];

          const tempFormData = this.medicareForm.value;

          if (tempFormData.MedicardExpiryDate) {
            tempFormData.MedicardExpiryDate = this.DateFormatConversion(tempFormData.MedicardExpiryDate);
          }

          this.medicareFormLocalData = { ...tempFormData, ...{ AttachmentFiles: filesAttached } };
          this.medicareFormData.emit(this.medicareFormLocalData);
        } else {
          if (this.medicareFormLocalData) {
            this.medicareFormLocalData = null;
            this.medicareFormData.emit(this.medicareFormLocalData);
          }
        }
      }
    );
  }

  DateFormatConversion(date) {
    const Date = moment(date);
    const shortDate = Date.format('YYYY-MM-DD');
    return shortDate;
  }

  createForm() {
    
    this.medicareForm = new FormGroup({
      MedicardCardColor: new FormControl('', [Validators.required]),
      MedicardCardNo: new FormControl('', [Validators.required, Validators.minLength(10)]),
      MedicardReferenceNo: new FormControl('', [Validators.required]),
      MedicardExpiryDate: new FormControl(new Date(), [Validators.required]),
      MedicardFullName: new FormControl('', [Validators.required]),
      MedicardCardFile: new FormControl(null, Validators.required)
    });
    if (this.storedData) {
      this.medicareForm.patchValue({
        MedicardCardColor: this.storedData.cardColor,
        MedicardCardNo: this.storedData.cardNumber,
        MedicardReferenceNo: this.storedData.individualNo,
        MedicardExpiryDate: new Date(this.storedData.expireDate),
        MedicardFullName: this.storedData.fullName,
      });
      this.imageService.appendImageFile({
        docType: 'MEDICARE',
        previewUrl: this.storedData.medicareImageURL,
        isFileExist: true
      });
    }
  }

  getValue(control: string) {
    return this.medicareForm.controls[control].value;
  }

  canShowError(control: string) {
    const field = this.medicareForm.controls[control];
    return (!field.valid) && (field.touched) && (field.dirty);
  }

  hasError(control: string, error: string) {
    return this.medicareForm.controls[control].hasError(error);
  }

  getFiles(e) {

    if (e && (e.imgFile || e.imgUrl)) {
      if (e.imgFile) {
        this.medicareFile = e.imgFile;
        this.medicareFileUrl = null;
        this.medicareForm.patchValue({
          MedicardCardFile: e.imgFile.name
        });
      }
      if (e.imgUrl) {
        this.medicareFile = null;
        this.medicareFileUrl = e.imgUrl;
        this.medicareForm.patchValue({
          MedicardCardFile: 'MEDICARE'
        });
      }
    } else {
      this.medicareFile = null;
      this.medicareFileUrl = null;
      this.medicareForm.patchValue({
        MedicardCardFile: null
      });
    }

  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransactionsService } from 'src/app/core/services/transactions.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-generate-rental-statement',
  templateUrl: './generate-rental-statement.component.html',
  styleUrls: ['./generate-rental-statement.component.scss']
})
export class GenerateRentalStatementComponent implements OnInit {
  title = 'loading...';
  constructor(
    private route: ActivatedRoute,
    private transactionService: TransactionsService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    const guid = this.route.snapshot.params['guid'];
    this.helper.showSpinner();
    if (guid) {
      this.transactionService.getRentalReceipt(guid).subscribe(
        (r: any) => {
          const url = r.data.blobURL;
          if (url) {
            window.open(url, '_self');
            this.title = 'generated';
          }
          this.helper.hideSpinner();
        }
      );
    }
  }

}

<!-- <div class="container-fluid menu-bg" id="menu"> -->
    <div class="container-fluid menu-bg">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <div class="d-flex align-items-center justify-content-between w-100">
                <a class="navbar-brand"  *ngIf="!isLogged">
                    <img src="../../../assets/InvCar-Dark-Horizontal.png" />
                </a>
                <a class="navbar-brand" (click)="homePage()" *ngIf="isLogged">
                    <img src="../../../assets/InvCar-Dark-Horizontal.png" />
                </a>
                <!-- <button *ngIf="isLogged" class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navLinkContainer" aria-controls="navLinkContainer" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="primary-main menu">
                        <img src="assets/images/user.svg" alt="">
                    </span>
                </button> -->

                <div *ngIf="!isLogged" class="mobile-login-btn">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <!-- <select class="form-select mb-2" aria-label="Default select example" #langSelect
                            (change)="translate.use(langSelect.value);setStorage(langSelect.value);">
                            <option   *ngFor="let lang of translate.getLangs()" [value]="lang"
                                [selected]="lang === translate.currentLang">{{ lang }}</option>
                        </select> -->

                        <h5 *ngIf="!isLogged" style="padding-right:20px;">Investment made easy</h5>
                       
                            <button type="button" class="btn btn-primary ms-2 btn-sm regBtn" (click)="openSignUp()"><i class="fa fa-user-plus" style="font-size:16px;"></i>&nbsp; Register</button>
                            <button type="button" class="btn btn-primary ms-2 btn-sm logBtn" (click)="openAuthModal()" ><i class="fa fa-user" style="font-size:16px;"></i>&nbsp; Login</button>
                          </div>
                </div>

                <div *ngIf="isLogged" class="mobile-login-btn">
                    <div class="row" *ngIf="isLogged && !showDashboard">
                        <div class="col-3 col-sm-6 col-md-6 col-lg-3">
                            <span class="acc">Balance</span>
                            <h4 class="blnc">${{accountBalance}}</h4>
                        </div>
                        <div class="col-3 col-sm-6 col-md-6 col-lg-3" style="margin-top:10px;">
                           <button class="btn btn-primary btn-sm depositBtn">Make a deposit</button>
                        </div>
                    </div>
                </div>
                
                <div class="collapse navbar-collapse " id="navLinkContainer">
                    
                    <div class="header-contents">
                        <h4 *ngIf="!isLogged">Investment made easy</h4>
                        <div class="row" *ngIf="isLogged && !showDashboard">
                            <div class="col-3 col-sm-6 col-md-6 col-lg-3" style="text-align:center">
                                <span class="acc">Account Balance</span>
                                <h4 class="blnc">${{accountBalance | number : '0.0-2'}}</h4>
                            </div>
                            <div class="col-3 col-sm-6 col-md-6 col-lg-3" style="margin-top:10px;">
                               <button class="btn btn-primary btn-sm depositBtn" (click)="makeDeposit()">Make a deposit</button>
                            </div>
                        </div>

                        <ul class="navbar-nav me-auto  mb-2 mb-lg-0 navbar-desktop" *ngIf="isLogged && showDashboard">
                            <ng-container *ngFor="let tab of tabs">
                                <ng-container *ngIf="(tab.logged && isLogged) || (!tab.logged) ">
                                    <li class="nav-item">
                                        <a class="nav-link" [routerLink]="tab.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                            #rla="routerLinkActive">
                                            <img [src]="rla.isActive?path+tab.img+'-active.svg':path+tab.img+'-inactive.svg'"
                                                alt="">
                                            <span class="secondary-semi-bold" *ngIf="!this.cartData">{{ ('HDR.' + tab.title ) | translate }}</span>
                                            <span class="secondary-semi-bold" *ngIf="this.cartData && rla.isActive">{{ ('HDR.' + tab.title ) | translate }}</span>
                                            <!-- <i class="fas fa-sort-up"></i> -->
                                        </a>
                                    </li>
                                </ng-container>
                            </ng-container>
                            <li class="nav-item" >
                                <a class="nav-link" routerLink="logout()" routerLinkActive="active" (click)="logout()"
                                    #rla="routerLinkActive">
                                    <!-- <img [src]="rla.isActive?path+tab.img+'-active.svg':path+tab.img+'-inactive.svg'"
                                        alt=""> -->
                                    <span class="secondary-semi-bold" > <img src="../../../assets/images/header-icons/login-icons/logout-inactive.svg"> {{ ('HDR.LOGOUT' ) | translate }} </span>
                                    <!-- <i class="fas fa-sort-up"></i> -->
                                </a>
                            </li>

                            <!-- <div class="col-lg-1" *ngIf="isLogged && showDashboard">
                                <app-username-dropdown></app-username-dropdown>
                            </div> -->
                        </ul>
                        
                    </div>

                    <!-- <div class="col-md-1 lang-dropdown"> -->
                        <!-- <select class="form-select" aria-label="Default select example" #langSelect
                            (change)="translate.use(langSelect.value);setStorage(langSelect.value);">
                            <option   *ngFor="let lang of translate.getLangs()" [value]="lang"
                                [selected]="lang === translate.currentLang">{{ lang }}</option>

                        </select> -->

                    <!-- </div> -->
                    
                    <div *ngIf="!isLogged">
                        <button class="btn btn-primary ms-2 logBtn" (click)="openAuthModal()" id="login-btn"> Login</button>
                    </div>

                    <div *ngIf="!isLogged">
                        <button class="btn btn-primary ms-2 regBtn" (click)="openSignUp()" id="login-btn" > Sign up</button>
                    </div>
                    <!-- <img id="close-menu" src="/assets/images/dialog-close-sm.svg" data-bs-toggle="collapse"
                        data-bs-target="#navLinkContainer" aria-controls="navLinkContainer" aria-expanded="true" /> -->
                   <div class="row loginHeader" *ngIf="isLogged && !showDashboard">
                    <!-- <div class="col-lg-6"> -->
                        <ul class="navbar-nav me-auto  mb-2 mb-lg-0 navbar-desktop">
                            <li class="nav-item" >
                                <a class="nav-link" (click)="showDashboardLongMenu()"  routerLinkActive="active"
                                #rla="routerLinkActive">
                                <!-- <img [src]="rla.isActive?path+tab.img+'-active.svg':path+tab.img+'-inactive.svg'"
                                    alt=""> -->
                                <span class="secondary-semi-bold">
                                    <img src="../../../assets/images/header-icons/login-icons/dashboard-inactive.svg">Dashboard</span>
                            </a>
                            </li>
                           
                            <!-- <li class="nav-item">
                                <a class="nav-link" (click)="showInboxLongMenu()" routerLinkActive="active"
                                #rla="routerLinkActive">
                                <img [src]="rla.isActive?path+tab.img+'-active.svg':path+tab.img+'-inactive.svg'"
                                    alt="">
                                <span class="secondary-semi-bold">
                                     <img src="../../../assets/images/header-icons/login-icons/inbox-inactive.svg">Inbox</span>
                            </a>
                            </li> -->
                            <li class="nav-item" >
                                <a class="nav-link" (click)="userProfile()" routerLinkActive="active"
                                #rla="routerLinkActive">
                                <span class="secondary-semi-bold">
                                    <img src="../../../assets/images/header-icons/login-icons/user-inactive.svg">Settings</span>
                            </a>
                            </li>
                            <li class="nav-item" >
                                <a class="nav-link" routerLink="logout()" routerLinkActive="active" (click)="logout()"
                                    #rla="routerLinkActive">
                                    <span class="secondary-semi-bold" > <img src="../../../assets/images/header-icons/login-icons/logout-inactive.svg"> {{ ('HDR.LOGOUT' ) | translate }} </span>
                                </a>
                            </li>
                            <!-- <li *ngIf="isLogged">
                                <a class="nav-link"><img src="../../../assets/images/header-icons/login-icons/cart.svg"></a> 
                            </li> -->
                        </ul>

                    <!-- </div> -->
                    
                   </div>
                </div>
                
                 <div class="headerCartIcon" *ngIf="(isLogged || !isLogged) && !cartData">
                    <a class="nav-link" *ngIf="!isLogged" (click)="showCart()"><img src="../../../assets/images/header-icons/login-icons/cart.svg"> <span class="button__badge woLogin" *ngIf="!cartBadge">1</span>
                    </a>
                    <a class="nav-link" *ngIf="isLogged && !showDashboard" (click)="showCart()"><img src="../../../assets/images/header-icons/login-icons/cart.svg"> <span class="button__badge" *ngIf="!cartBadge">1</span>
                    </a> 
             </div>
            </div>
            
        </nav>
        <div *ngIf="cartData" style="display:none;">
            <app-right-sidebar  *ngIf="cartData" (SendValue)="ValueFromRightSidebar($event)"></app-right-sidebar>
            </div>
    </div>
</div>




<div class="modal fade" id="authModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
            <div class="modal-body">
                <app-sign-in></app-sign-in>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="signup">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
            <div class="modal-body">
                <app-sign-up></app-sign-up>
            </div>
        </div>
    </div>
</div>

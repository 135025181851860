import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { StaticMessage } from 'src/app/core/utils/constants';
import { HelperService } from 'src/app/core/utils/helper.service';
import { ValidatePassword } from 'src/app/shared/validators/password-validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  passwordHide = true;
  confirmPasswordHide = true;
  resetPasswordDTO: ResetPasswordDTO = {} as any;
  l5OT: string;
  xt8y: string;

  passwordError = StaticMessage.pwdPatternAlert;
  showPassword = false;
  showConfirmPassword = false;
  constructor(
    public helper: HelperService,
    private authService: AuthService,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.l5OT = params["l5OT"];
      this.xt8y = params["xt8y"];
    });
  }

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(StaticMessage.passwordPattern)]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
      {
        validators: ValidatePassword.MatchPassword
      }
    );
  }

  get txtPassword() {
    return this.resetPasswordForm.get('password');
  }

  get txtConfirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }

  toggleEye() {
    this.passwordHide = !this.passwordHide;
  }

  toggleConformPassWordEye() {
    this.confirmPasswordHide = !this.confirmPasswordHide;

  }

  onResetPassword() {
    this.helper.showSpinner();
    this.resetPasswordDTO.l5OT = this.l5OT;
    this.resetPasswordDTO.xt8y = this.xt8y;
    this.resetPasswordDTO.ConfirmPassword = this.txtConfirmPassword.value;

    this.authService.resetpassword(this.resetPasswordDTO).subscribe(
      res => {
        let result: any = res;
        if (result.data !== null) {
          this.helper.hideSpinner();
          this.helper.success(result.msg);
          this.helper.navigate('/marketplace/list?login=true')
        } else {
          this.helper.hideSpinner();
          this.helper.error(result.msg);
        }
      },
      error => {
        this.helper.hideSpinner();
        this.helper.error(error);
      });
  }

  passwordMismatch() {
    return this.txtConfirmPassword?.errors?.mismatchedPasswords;
  }

}

export class ResetPasswordDTO {
  l5OT: string;
  xt8y: string;
  ConfirmPassword: string;
}

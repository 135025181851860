import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaderResponse,
  HttpHeaders
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, filter, take, catchError, finalize, map, tap } from 'rxjs/operators';
import { HelperService } from '../utils/helper.service';
import { StorageService } from '../utils/storage.service';
import { Storage } from '../enum/storage';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  isRefreshProgress = false;
  private unauthorizedApis: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  InterceptorSkipHeader = 'X-Skip-Interceptor';

  constructor(
    private helper: HelperService,
    private storageService: StorageService,
    private authService: AuthService,
    private router: Router,
    private appService: AppService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    const token = this.storageService.getItem(Storage.AUTH_TOKEN);
    if (token) {
      if (request.headers.has(this.InterceptorSkipHeader)) {
        const headers = request.headers.delete(this.InterceptorSkipHeader);
        request = request.clone({ headers });
      } else {
        request = this.addToken(request, token);
      }
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && (error.status === 400 || error.status === 450)) {
          return throwError(error.error.response.message || '400: Invalid object passed.');
        } else if (error instanceof HttpErrorResponse && error.status === 500) {
          return throwError(error.error.response.message || '500: Invalid object passed.');
        } else if (error instanceof HttpErrorResponse && error.status === 401) {
          if (request.url.includes('refreshtoken')) {
            this.unauthorizedApis.next(null);
            this.isRefreshProgress = false;
            // this.authService.logout();
            this.storageService.clear();
            this.appService.loginStatus.next(false);
            this.router.navigateByUrl('marketplace');
            // this.helper.info('Session Timed Out');
            this.helper.hideSpinner();
            return throwError(error);
          } else {
            return this.errorUnauthorized(request, next).pipe(
              catchError((error) => {
                this.helper.hideSpinner();
                if (error instanceof HttpErrorResponse && (error.status === 400 || error.status === 450)) {
                  return throwError(error.error.response.message || '400: Invalid object passed.');
                } else if (error instanceof HttpErrorResponse && error.status === 500) {
                  return throwError(error.error.response.message || '500: Invalid object passed.');
                } else if (error instanceof HttpErrorResponse && error.status === 401) {
                  return throwError(error.error.response.message || '401: Invalid object passed.');
                } else {
                  return throwError(error.status + 'Invalid Status');
                }
              })
            );
          }
        } else {
          this.helper.hideSpinner();
          return throwError(error);
        }
      }),
      tap({
        next: ((r: any) => {
          if (r instanceof HttpResponse) {
            if (r.body.type != "application/pdf" && r.body.type != "application/octet-stream") {
              let alterResponse: any = r;
              if (r.body.response && r.body.response.hasOwnProperty('data')) {
                alterResponse.body = {
                  data: r.body.response.data,
                  msg: r.body.response.message
                };
              }
              return alterResponse;
            }
          }
          return r;
        }),
        error: ((error) => {



        }),

      }),

    );
  }

  errorUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshProgress) {
      this.isRefreshProgress = true;
      this.unauthorizedApis.next(null);
      return this.authService.getRefreshToken().pipe(
        switchMap((token: string) => {
          if (token) {
            this.isRefreshProgress = false;
            this.unauthorizedApis.next(token);
            return next.handle(this.addToken(request, token));
          } else {
            return this.authService.logout() as any;
          }
        })
      );
    } else {
      return this.unauthorizedApis.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

}

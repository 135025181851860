import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class KycService {

  constructor(private apiManagerService: ApiManagerService) { }

  getUserType() {
    return this.apiManagerService.getRequest(RestEnds.KycUserType, null);
  }

  getKYCverificationStatus() {
    return this.apiManagerService.getRequest(RestEnds.getKYCstatus, null);
  }

  kycVerificationProcess(data){
    return this.apiManagerService.postRequest(RestEnds.KycVerifyStatus, data);

  }

  getPublicKey() {
    return this.apiManagerService.getRequest(RestEnds.getKycPublicKey, null);
  }
  
  exchangeEncryptedKey(data) {
    return this.apiManagerService.postRequest(RestEnds.KycKeyExchange, data);
  }

  postUserType(data: any) {
    return this.apiManagerService.postRequest(RestEnds.KycUserType, data);
  }

  updateUserType(data: { Type: string }) {
    return this.apiManagerService.putRequest(RestEnds.KycUserType, data);
  }

  getKycStepper() {
    return this.apiManagerService.getRequest(RestEnds.KycStepperDetail, null);
  }

  getImageBlob(imgUrl: string) {
    return this.apiManagerService.getBlobRequest(imgUrl, { responseType: 'blob' });
  }

  acceptTermsandConditionsKYC2() {
    return this.apiManagerService.putRequest(RestEnds.kyc2TandC);
  }

  resetKYC(key: any) {
    return this.apiManagerService.deleteRequest(`${RestEnds.resetKYC}?key=${key}`);
  }

  getKycStatusSingleAccount() {
    return this.apiManagerService.getRequest(RestEnds.getKycStatusSingleAccount, null);
  }
}
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum SaveTypes {
  saveOnly,
  saveAndNext,
}

@Component({
  selector: 'kyc-stepper-submit',
  templateUrl: './stepper-submit.component.html',
  styleUrls: ['./stepper-submit.component.scss']
})
export class StepperSubmitComponent implements OnInit {

  @Input() stepperFormInfo: any;
  @Output() emitSaveEvent = new EventEmitter<any>();
  saveTypes = SaveTypes;

  isFixedStepper = true;
  constructor(
    public cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.scrollStop(() => {
      this.isFixedStepper = true;
      this.cdRef.detectChanges();
    });
  }

  scrollStop(callback, refresh = 300) {

    // Make sure a valid callback was provided
    if (!callback || typeof callback !== 'function') return;

    // Setup scrolling variable
    let isScrolling;
    // Listen for scroll events
    window.addEventListener('scroll', (event) => {
      this.isFixedStepper = false;
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, refresh);

    }, false);

  }

  save(type: SaveTypes) {
    const data = {
      type: type,
    };
    this.emitSaveEvent.emit(data);
  }

}

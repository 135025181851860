import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HelperService } from '../../../core/utils/helper.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuyDialogComponent } from '../buy-dialog/buy-dialog.component';
import { DashboardService } from 'src/app/core/services/dashboard.service';

enum CheckBoxType { payID, bank, NONE };

@Component({
  selector: 'app-entity-update-dialog',
  templateUrl: './entity-update-dialog.component.html',
  styleUrls: ['./entity-update-dialog.component.scss']
})
export class EntityUpdateDialogComponent implements OnInit, OnDestroy {

  enityForm: FormGroup;

  check_box_type = CheckBoxType;

  currentlyChecked: CheckBoxType = CheckBoxType.bank;


  constructor(
    private helper: HelperService,
    private dashboardDataService: DashboardService,
    public dialogRef: MatDialogRef<BuyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.enityForm = new FormGroup({
      EntityName: new FormControl(this.dialogRef.componentInstance.data.id
        , [
        Validators.required,
        Validators.maxLength(100),
      ]),
    });
    console.log(this.dialogRef);
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }

  canShowError(control: string) {
    const field = this.enityForm.controls[control];
    return (!field.valid) && (field.touched) && (field.dirty);
  }

  hasError(control: string, error: string) {
    return this.enityForm.controls[control].hasError(error);
  }

  updateEntity() {
  
    this.helper.showSpinner();
    this.dashboardDataService.updateEntity(this.getFormData()).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
        this.dialogRef.close();
        this.helper.hideSpinner();
        this.enityForm.reset();
        window.location.reload() 
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  getFormData() {
    return {
      EntityName: this.getValue("EntityName"),
    };
  }

  getValue(control: string) {
    return this.enityForm.controls[control].value;
  }

  closeDialog(){
    this.dialogRef.close();
  }

  selectCheckBox(targetType: CheckBoxType) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    if(this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }

    this.currentlyChecked = targetType;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-confirm.component.html',
  styleUrls: ['./email-confirm.component.scss']
})
export class EmailConfirmComponent implements OnInit {

  l5OT: string = '';
  xt8y: string = '';
  name: string = '';
  emailConfirmationDTO: EmailConfirmationDTO = {} as any;

  constructor(
    private route: ActivatedRoute,
    private helper: HelperService,
    private authService: AuthService
  ) {
    this.route.params.subscribe(params => {
      this.l5OT = params["l5OT"];
      this.xt8y = params["xt8y"];
      this.name = params["name"];
    });
  }

  ngOnInit(): void {
    this.verifyEmail();
  }

  verifyEmail() {
    this.helper.showSpinner();
    this.emailConfirmationDTO.l5OT = this.l5OT;
    this.emailConfirmationDTO.xt8y = this.xt8y;

    this.authService.verifyEmail(this.emailConfirmationDTO).subscribe(res => {
      let result: any = res;
      if (result.data !== null) {
        this.helper.hideSpinner();
        this.helper.navigate('/login');
        this.helper.success(result.msg);
      } else {
        this.helper.hideSpinner();
        this.helper.error(result.msg);
      }
    },
    error => {    
      this.helper.hideSpinner();
      this.helper.error(error);
      if(error.toLowerCase().includes("please") || error.toLowerCase().includes("you")){
        this.helper.navigate('/login');
      }
    });
  }

}

export class EmailConfirmationDTO {
  'l5OT': string;
  'xt8y': string;

}
<div class="container property-section">
    <div class="content-section">
    <h2>ABOUT US</h2>
    <p>InvCar was founded in 2020 by Giuseppe Porcelli and Darren Younger, who were inspired by the thriving rental
        market in Hawaii. Their vision was to make rental vehicle investments accessible to anyone by lowering initial
        costs while maintaining attractive returns. Our mission is to provide an easy and manageable platform for
        investing in fractional rental cars, opening up this opportunity to a broader audience.</p>

    <p>Since partnering with our first distributor in November 2021, InvCar has grown to offer a wide variety of
        vehicles for investment, reflecting our commitment to diversification and customer satisfaction. Join us on our
        journey to revolutionize the rental vehicle investment industry and experience the InvCar difference.</p>
</div>
</div>
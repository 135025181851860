import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';
import { CartService } from 'src/app/core/services/cart.service';
import { HelperService } from 'src/app/core/utils/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/core/utils/storage.service';
declare var $: any;
export interface CustomResponse {
  data: any;
}
@Component({
  selector: 'app-property-list-box',
  templateUrl: './property-list-box.component.html',
  styleUrls: ['./property-list-box.component.scss'],
})
export class PropertyListBoxComponent implements OnInit {
  @Input() properties: any;
  isLoggedIn = false;
  selectOrderGUID: any;
  marketPriceData: any = null;
  cartInputQty: any;
  cartItemId: any = '';
  cartShow:boolean = false;
  selectedBannerImg:any;
  refreshTkn: any;
  cartResp: any;
  constructor(
    private router: Router,
    private appService: AppService,
    private cartService: CartService,
    private helper: HelperService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private storageService: StorageService

  ) {}

  ngOnInit(): void {
    this.appService.isLoggedIn().subscribe(
      (status) => {
        this.isLoggedIn = status;
      },
      (e) => {}
    );
  }

  onDetail(data: any, type: string) {
    if (type == '1') {
      this.router.navigate(['/marketplace/detail/' + data.carGUID]);
    } else {
      this.router.navigate(['/marketplace/detail/' + data.carGUID], {
        queryParams: { bpp: true },
      });
    }
  }

  onBuyModal(guid: string) {
    this.selectOrderGUID = guid;
    if(!this.isLoggedIn){
       if(this.selectOrderGUID === localStorage.getItem('cartItem')){
        $('#sameDataModal').modal('show');
       }else if(localStorage.getItem('cartItem')){
        $('#confirmModal').modal('show');
       }else{
        this.onBuyNow(guid);
       }
    }else{
      if(this.selectOrderGUID === localStorage.getItem('carGuid')){
        $('#sameDataModal').modal('show');
       }else if(localStorage.getItem('cartGuid')){
        $('#confirmModal').modal('show');
       }else{
        this.onBuyNow(guid);
       }
    }
    // if( !this.isLoggedIn && (this.selectOrderGUID === localStorage.getItem('cartItem'))){
    //   $('#sameDataModal').modal('show');
    // }else {
    //   if (!this.isLoggedIn && localStorage.getItem('cartItem')) {
    //     $('#confirmModal').modal('show');
    //   } else {
    //     this.onBuyNow(guid);
    //   }
    // }
    
  }

  onBuyNow(guid: string) {
    $('#confirmModal').modal('hide');
    if (this.isLoggedIn) {
      this.addToCart(guid, 1);
    } else {
     localStorage.setItem('cartItem', guid);
     localStorage.setItem('cartQty', '1');
      // this.router.navigateByUrl('/cart');
      this.appService.passValue(this.cartShow);

    }
  }

  addToCart(guid: string, qty: any) {
    const data = {
      carGUID: guid,
      Quantity: Number(qty),
    };
    this.helper.showSpinner();
    this.cartService.addCart(data).subscribe(
      (response: CustomResponse) => {
        // this.router.navigateByUrl('/cart');
        // console.log('resp',s);
        console.log(response);
         this.cartResp = response;
         this.cartService.cartObject(this.cartResp);
        //  let cartGuid = cartResc.cartGUID;
        //  let carGuid = cartResc.carGUID;
        //  let orderGuid = cartResc.orderGUID;
        // localStorage.setItem('cartGuid',cartGuid);
        // localStorage.setItem('carGuid',carGuid);
        // localStorage.setItem('orderGuid',orderGuid);
        this.appService.passValue(this.cartShow);
        this.helper.hideSpinner();
      },
      (e) => {
        this.helper.error(e);
        this.toastr.error(e)
        this.helper.hideSpinner();
      }
    );
  }

  copyBrowserURL(property) {
    var dummy = document.createElement('input'),
      text =
        window.location.origin + '/marketplace/detail/' + property.carGUID;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.translate.get(['MKP']).subscribe((translations) => {
      this.helper.info(translations.MKP.infopropertyurlcopied);
    });
    // this.helper.info('Property URL copied!');
  }

  emailContent(property) {
    let home = '| INV-CAR Investor';
    let url =
      window.location.origin + '/marketplace/detail/' + property.carGUID;
    let currentURL = '(' + url + ')%0D%0A';
    let deposit = 'Own this home with as little as $20,000 deposit';
    let amp = '&body=Check this out:';
    let subject =
      property.name + ' ' + property.address + ' ' + property.state + home;
    let content =
      property.name +
      ' ' +
      property.address +
      ' ' +
      property.state +
      home +
      ' ' +
      currentURL +
      deposit;
    let email = 'mailto:?subject=' + subject + amp + ' ' + content;
    // this.email = this.sampleMail;
    return email;
  }

  // getCart() {
  //   this.cartService.getCart().subscribe(
  //     (s: any) => {
  //       this.marketPriceData = s.data.carts;
  //       this.cartInputQty = this.marketPriceData?.property.quantity;
  //       this.cartItemId = this.marketPriceData?.property.propertyGUID;
  //       if (this.cartItemId) {
  //         $('#confirmModal').modal('show');
  //       }
  //     },
  //     (e: string) => {
  //       this.marketPriceData = null;
  //       $('#confirmModal').modal('hide');
  //       this.addToCart(this.selectOrderGUID, 1);
  //     }
  //   );
  // }

  ClikedCart(){
    this.appService.passValue(this.cartShow);

  }

  selectedItem:any;
  favoriteLocations: any = [];
  likedCity: any;

  Wishlists(guid:any) {
    this.selectedItem = guid;
    if(this.favoriteLocations.length ==0){
      this.favoriteLocations.push(this.selectedItem)
      console.log("this.favoriteLocations:", this.favoriteLocations)
      console.log("ADDED")
  }
  else if(this.favoriteLocations.length > 0){
    let index;
    this.favoriteLocations.push(this.selectedItem);
    for(let i =0; i<this.favoriteLocations.length; i++){
        if(this.favoriteLocations[i].Key ==this.selectedItem.Key){
            index = i
            // break;
        }
    }
    
    // if(index !=null){
    //     this.favoriteLocations.splice(index, 1)
    //     this.likedCity = ''
    //     console.log("REMOVED")
    
    // }else{
    //     this.favoriteLocations.push(this.selectedItem)
    //     console.log("ADDED")
    //     }
    console.log("this.favoriteLocations:", this.favoriteLocations)
}
    localStorage.setItem('wishlistItem', JSON.stringify(this.favoriteLocations));
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrgIndividual } from 'src/app/core/interfaces/org-individual-kyc.interface';
import { KycOrgService } from 'src/app/core/services/kyc-org.service';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-org-individual',
  templateUrl: './org-individual.component.html',
  styleUrls: ['./org-individual.component.scss']
})
export class OrgIndividualComponent implements OnInit {

  @Input() OrgIndividualData: OrgIndividual;

  @Output() OrgTrusteeIndividual = new EventEmitter();

  formData: {
    personalInfo: null | FormData,
    addressInfo: null | FormData,
    idProof1: null | FormData,
    idProof2: null | FormData
  } = {
      personalInfo: null,
      addressInfo: null,
      idProof1: null,
      idProof2: null
    };

  enableEdit = false;
  preStatus = false;
  kycOrgIndForm: FormGroup;
  sData = {
    pInfo: null,
    aInfo: null,
    idProof1: null,
    idProof2: null
  };
  constructor(
    private orgKycService: KycOrgService,
    private commonService: HelperService
  ) { }

  ngOnInit() {

    this.OrgTrusteeIndividual.emit(null);
    this.kycOrgIndForm = new FormGroup({
      personalInfo: new FormControl(false, [Validators.pattern('true')]),
      addressInfo: new FormControl(false, [Validators.pattern('true')]),
      idProof1: new FormControl(false, [Validators.pattern('true')]),
      idProof2: new FormControl(false, [Validators.pattern('true')])
    });

    if (this.OrgIndividualData) {
      this.sData.pInfo = this.OrgIndividualData.personalInfo;
      this.sData.aInfo = {
        addressInfo: this.OrgIndividualData.addressInfo,
        addressProof: this.OrgIndividualData.proofOfAddress
      };
      this.sData.idProof1 = this.OrgIndividualData.proofOfIdentity1;
      this.sData.idProof2 = this.OrgIndividualData.proofOfIdentity2;
    }

    this.kycOrgIndForm.statusChanges.subscribe(s => {
      if (s && s === 'VALID') {
        this.OrgTrusteeIndividual.emit(this.getFormData());
        this.preStatus = true;
      } else {
        if (this.preStatus) {
          this.OrgTrusteeIndividual.emit(null);
        }
        this.preStatus = false;
      }
    });
  }

  getPersonalInfo(data: null | FormData) {
    this.formData.personalInfo = data;
    if (data) {
      this.kycOrgIndForm.patchValue({
        personalInfo: true
      });
    } else {
      this.kycOrgIndForm.patchValue({
        personalInfo: false
      });
    }
  }

  getAddressInfo(data: null | FormData) {
    this.formData.addressInfo = data;
    if (data) {
      this.kycOrgIndForm.patchValue({
        addressInfo: true
      });
    } else {
      this.kycOrgIndForm.patchValue({
        addressInfo: false
      });
    }
  }

  getIdProof1(data: null | FormData) {
    this.formData.idProof1 = data;
    if (data) {
      this.kycOrgIndForm.patchValue({
        idProof1: true
      });
    } else {
      this.kycOrgIndForm.patchValue({
        idProof1: false
      });
    }
  }

  getIdProof2(data: null | FormData) {
    this.formData.idProof2 = data;
    if (data) {
      this.kycOrgIndForm.patchValue({
        idProof2: true
      });
    } else {
      this.kycOrgIndForm.patchValue({
        idProof2: false
      });
    }
  }

  getFormData() {
    const finalData = new FormData();
    if (this.formData.personalInfo) {
      for (const pair of this.formData.personalInfo.entries()) {
        finalData.append(pair[0], pair[1]);
      }
    }
    if (this.formData.addressInfo) {
      for (const pair of this.formData.addressInfo.entries()) {
        finalData.append(pair[0], pair[1]);
      }
    }
    if (this.formData.idProof1) {
      for (const pair of this.formData.idProof1.entries()) {
        finalData.append('PoI1_' + pair[0], pair[1]);
      }
    }
    if (this.formData.idProof2) {
      for (const pair of this.formData.idProof2.entries()) {
        finalData.append('PoI2_' + pair[0], pair[1]);
      }
    }
    return finalData;
  }

}

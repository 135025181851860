<div class="w-100 bg-waav-bright-light p-3 d-flex flex-row align-items-center justify-content-between">
    <span class="primary-semi-bold f20 waav-dark-main">{{formTitle}}</span>
</div>

<form [formGroup]="privateCompanyAddressForm" class="row">


        <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-6">
            <label class="form-label">Unit No.</label>
            <input type="text" formControlName="unitNo" name="unitNo" class="form-control" maxlength=4>

            <!-- <div *ngIf="canShowError('unitNo')">
                    <p class="error-txt" *ngIf="hasError('unitNo','required')">Unit No. is required</p>
                </div> -->
        </div>

        <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-6">
            <label class="form-label required">Street No.</label>
            <input type="text" formControlName="streetNo" name="streetNo" class="form-control" maxlength=50>

            <div *ngIf="canShowError('streetNo')">
                <p class="error-txt" *ngIf="hasError('streetNo','required')">Street No. is required</p>
            </div>
        </div>

        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label class="form-label required">Street Name</label>
            <input type="text" formControlName="streetName" name="streetName" class="form-control" maxlength=100>

            <div *ngIf="canShowError('streetName')">
                <p class="error-txt" *ngIf="hasError('streetName','required')">Street Name is required</p>
            </div>
        </div>

        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label class="form-label required">Street Type</label>
            <input type="text" formControlName="streetType" name="streetType" class="form-control" maxlength=100>

            <div *ngIf="canShowError('streetType')">
                <p class="error-txt" *ngIf="hasError('streetType','required')">Street Type is required</p>
            </div>
        </div>

        <div class="form-group col-lg-3 col-md-6 col-sm-12">
            <label class="form-label required">Post code</label>
            <input type="text" formControlName="postCode" name="postCode" class="form-control" maxlength=8>

            <div *ngIf="canShowError('postCode')">
                <p class="error-txt" *ngIf="hasError('postCode','required')">Post code is required</p>
            </div>
        </div>

        <div class="form-group col-lg-3 col-md-6 col-sm-12">
            <label class="form-label required">State</label>
            <select formControlName="state" class="form-control">
                <option class="f12" value="">Choose</option>
                <option class="f12" *ngFor="let s of stateList" [value]="s.stateGUID">{{s.shortName}}</option>
            </select>

            <div *ngIf="canShowError('state')">
                <p class="error-txt" *ngIf="hasError('state','required')">State is required</p>
            </div>
        </div>

        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label class="form-label required">Suburb</label>
            <input type="text" formControlName="suburb" name="suburb" class="form-control" maxlength=75>

            <div *ngIf="canShowError('suburb')">
                <p class="error-txt" *ngIf="hasError('suburb','required')">Suburb is required</p>
            </div>
        </div>


        <div class="form-group col-lg-6 col-md-12 col-sm-12">
            <label class="form-label">Additional information
                (optional)</label>
            <input type="text" formControlName="additionInfo" name="additionInfo" class="form-control" maxlength=150>
        </div>

</form>
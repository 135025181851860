import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Storage } from '../enum/storage';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { StorageService } from '../utils/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  appEvent = new BehaviorSubject(null);
  getUserAppStatus = this.appEvent.asObservable();

  public loginStatus = new BehaviorSubject<boolean>(false);

  public stringSubject =  new BehaviorSubject<boolean>(true);

  public closeSubject =  new BehaviorSubject<boolean>(false);

  public kycStausValue = new BehaviorSubject(0);


  constructor(
    private apiManagerService: ApiManagerService,
    private storageService: StorageService
  ) {
    this.storageService.isStorageChanged.subscribe(
      (s) => {
        if (s === 'changed') {
          this.isLoggedIn();
        }
      }
    );
  }

  updateUserStatus(data: any) {
    this.appEvent.next(data);
  }

  getUserStatus() {
    return this.apiManagerService.getRequest(RestEnds.userStatus, null).subscribe(
      (r: any) => {
        const result: any = r.data;
        this.updateUserStatus(result.myStatus);
      },
      (e) => {

      }
    );
  }

  isLoggedIn(): Observable<boolean> {

    const isLoggedIn = this.storageService.getItem(Storage.IS_LOGGED);
    if (isLoggedIn && isLoggedIn === 'true') {
      this.loginStatus.next(true);
    } else {
      this.loginStatus.next(false);
    }
    return this.loginStatus.asObservable();
  }


  passValue(data) {
    //passing the data as the next observable
    this.stringSubject.next(data);
  }

  closeCartValue(closedata){
    this.closeSubject.next(closedata);
  }

  getKYCValueObservable(data){
    this.kycStausValue.next(data);
 }
}

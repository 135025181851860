<div id="recommend" *ngIf="(isRecommendedLoaded && recommended.length>0 ) || loaderRecommend">
    <h2 class="title-border">
        Recommended for you
        <a routerLink="/community/all-article/recommended" class="view-more">View more</a>
    </h2>

    <div *ngIf="!isRecommendedLoaded" class="row">
        <div *ngFor="let r of [1,2]" class="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div class="row m-0">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <ngx-skeleton-loader [theme]="{'height':'150px', 'width':'100%'}" appearance="line">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <ngx-skeleton-loader [theme]="{'height':'20px'}" appearance="line"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{'height':'40px'}" appearance="line"></ngx-skeleton-loader>
                    <div class="d-flex flex-row">
                        <ngx-skeleton-loader [theme]="{'height':'30px', 'width':'30px'}" appearance="line">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{'height':'30px', 'width':'30px', 'margin-left': '8px'}"
                            appearance="line"></ngx-skeleton-loader>
                    </div>
                    <ngx-skeleton-loader [theme]="{'height':'20px', 'width':'50%'}" appearance="line">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="isRecommendedLoaded" class="row" id="recommeded">
        <div *ngFor="let r of recommended" class="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div class="row m-0">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <img class="image" [src]="r.thumbnailURL" alt="">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <a [routerLink]="'/community/feed/'+r.articleGUID">
                        <h3 class="title article-title">{{r.title | slice:0:45}}{{r.description.length>45?'...':''}}</h3>
                    </a>
                    <p>{{r.description | slice:0:100}}{{r.description.length>100?'...':''}}</p>
                    <app-share-save-icon 
                    type="article"
                    [guid]="r.articleGUID"
                    [title]="r.title"
                    [status]="r.isSaved"
                    ></app-share-save-icon>
                    <span class="primary-bold f16 waav-gray-dark">{{r.createdDate | date :'MMM dd'
                        }}.{{r.stateName}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
import * as pako from 'pako';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HelperService } from 'src/app/core/utils/helper.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebcamImage } from 'ngx-webcam';
import { LookupService } from 'src/app/core/services/lookup.service';
import { KycService } from 'src/app/core/services/kyc.service';
@Component({
  selector: 'app-kyc-modal',
  templateUrl: './kyc-modal.component.html',
  styleUrls: ['./kyc-modal.component.scss'],
})
export class KycModalComponent implements OnInit {
  IsmodelShow: boolean;
  initProcess: boolean;
  docUploadProcess: boolean = true
  selectedFile: any;
  // display: FormControl = new FormControl("", Validators.required);
  file_store: FileList;
  file_list: Array<string> = [];
  identityTypeList: any;
  f: File;
  webcamImage: any = null;
  showWebcam = false
  KYCForm: FormGroup = new FormGroup({
    IDType: new FormControl('', [
      Validators.required
    ]),
    IDNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(9),
      Validators.pattern("^[a-zA-Z0-9 ]+$")
    ]),
    UploadDoc: new FormControl('', Validators.required),
    CaptureImage: new FormControl('', Validators.required)
  });
  imageFile: any;
  selectedOption: any;



  handleFileInputChange(l: FileList): void {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes
  
    if (l.length) {
      const file = l[0];
      if (file.size <= maxSizeInBytes) {
        this.file_store = l;
        this.f = file
        console.log("Selected file:", file);
        const fileType = file.type;
  
        // Check if the selected file is a PDF
        if (fileType === 'application/pdf') {
          const count = l.length > 1 ? `(+${l.length - 1} files)` : "";
          this.KYCForm.controls['UploadDoc'].patchValue(`${file.name}${count}`);
        } else {
          console.error("Selected file is not a PDF.");
          this.KYCForm.controls['UploadDoc'].patchValue(""); // Clear display

        }
      } else {
        console.error("File size exceeds the limit of 5MB.");
        this.KYCForm.controls['UploadDoc'].patchValue(""); // Clear display
        // Optionally, you can reset the file input here
        // this.fileInput.nativeElement.value = '';
      }
    } else {
      this.file_store = null; // Reset file store if no file selected
      this.KYCForm.controls['UploadDoc'].patchValue(""); // Clear display
    }
  }
  // firstFormGroup = this._formBuilder.group({
  //   firstCtrl: ['', Validators.required],
  // });
  // secondFormGroup = this._formBuilder.group({
  //   secondCtrl: ['', Validators.required],
  // });
  isLinear = false;
  constructor(private dialogRef: MatDialogRef<KycModalComponent>,
    private helper: HelperService,
    private _formBuilder: FormBuilder,
    private lookupService: LookupService,
    private kycService: KycService,

  ) { }

  ngOnInit(): void {
    this.getIdentity();
    this.getTypeName();
  }
  records = [
    {
      iconSrc: '',
      title: 'KYC',
      step1: 'Step 1: Upload the Document.',
      step2: 'Step 2: Upload the Picture.',
      step3: 'Step 3: Complete the process.'
    }
  ]

  modalClose() {
    this.dialogRef.close();
  }
  fileChange(event) {
    this.selectedFile = event.target.files[0];
    const fileSize = this.selectedFile.size;
  }

  proceedNext() {
    this.initProcess = true;
    this.docUploadProcess = false;
  }


  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    const imageName = 'selfie.png';
    const imageBlob = this.dataURItoBlob(this.webcamImage.imageAsBase64);
    this.imageFile = new File([imageBlob], imageName, { type: 'image/png' });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  getIdentity() {
    this.lookupService.getIdentityType().subscribe(
      (s: any) => {
        this.identityTypeList = s.data.identityTypes;
      },
      (e) => { }
    );
  }

  getValue(control: string) {
    return this.KYCForm.controls[control].value;
  }

  enableCamera() {
    this.showWebcam = true;
  }

  getTypeName() {
    if (this.getValue("IDType") === 1) {
      this.selectedOption = "Passport"
    } else {
      this.selectedOption = "Driving Licence"
    }
  }

  async generateAesKeyAndIv(): Promise<{ key: CryptoKey, iv: Uint8Array }> {
    const key = await window.crypto.subtle.generateKey(
      { name: 'AES-GCM', length: 128 },
      true,
      ['encrypt', 'decrypt']
    );

    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    return { key, iv };
  }

  async compressAndEncryptFile(file: File, key: CryptoKey, iv: Uint8Array): Promise<ArrayBuffer> {
    const fileData = await file.arrayBuffer();

    const compressedData = pako.gzip(new Uint8Array(fileData));

    const encryptedData = await window.crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      key,
      compressedData
    );
    return encryptedData;
  }

  arrayBufferToBase64(buffer: ArrayBuffer): string {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
  }

  async submit() {
    this.showWebcam = false;
    const formData = new FormData();

    // Generate key and IV
    const { key, iv } = await this.generateAesKeyAndIv();
    // It should encrypt this.f and this.imageFile with method encryptFile
    const encryptedDocument = await this.compressAndEncryptFile(this.f, key, iv);
    const encryptedImage = await this.compressAndEncryptFile(this.imageFile, key, iv);

    formData.append('IdentityTypeId', this.getValue("IDType"));
    formData.append('IdentityNumber', this.getValue("IDNumber"));
    formData.append('iv', this.arrayBufferToBase64(iv));
    formData.append('IdentityPicture', new Blob([encryptedDocument]), "filedoc.pdf");
    formData.append('SelfPicture', new Blob([encryptedImage]), "selfie.png");

    this.helper.showSpinner();
    this.kycService.kycVerificationProcess(formData).subscribe({
      next: (response: any) => {
        const kycDetailId = response.data.kycDetailId;
        this.kycService.getPublicKey().subscribe({
          next: async (publicKeyResponse: any) => {
            const publicKey = publicKeyResponse.publicKey;
            try {
              await this.encryptAesKeyAndSubmit(key, publicKey, kycDetailId);
              this.helper.success("KYC Request submitted successfully!");
              window.location.reload();
            } catch (error) {
              console.error("Error during public key use or key exchange", error);
              this.helper.error("An error occurred during the KYC process.");
            }
          },
          error: (error) => {
            console.error("Error fetching public key:", error);
            this.helper.error("Failed to fetch public key.");
          },
          complete: () => {
            this.helper.hideSpinner();
            this.modalClose();
          }, 
        });
      },
      error: (error) => {
        console.error("KYC submission error", error);
        this.helper.error("An error occurred during the KYC process.");
        this.helper.hideSpinner();
        this.modalClose();
      },
    });
  }

  base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async importPublicKey(pem) {
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    const pemContents = pem.replace(pemHeader, '').replace(pemFooter, '').replace(/\s+/g, "");

    const binaryDer = this.base64ToArrayBuffer(pemContents);

    return await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: { name: 'SHA-256' },
      },
      true,
      ['encrypt']
    );
  }

  async encryptAesKeyAndSubmit(key: CryptoKey, publicKeyString: string, kycDetailId: number) {
    const exportedKey = await window.crypto.subtle.exportKey('raw', key);
    const publicKey = await this.importPublicKey(publicKeyString);
    const encryptedKey = await window.crypto.subtle.encrypt(
      { name: 'RSA-OAEP' },
      publicKey,
      exportedKey
    );

    const payload = {
      kycDetailId: kycDetailId,
      encryptedAESKey: this.arrayBufferToBase64(encryptedKey)
    };

    this.kycService.exchangeEncryptedKey(payload).subscribe(
      (success) => {
        console.log("AES key exchange successful", success);
      },
      (error) => {
        console.error("Error during AES key exchange", error);
      }
    );
  }

  completeKyc() {
    this.modalClose();
    this.helper.success("KYC verified Successfully..!!!");
  }


}

<div class="row m-0 h-50">
    <div class="col-lg-12 col-md-12 col-sm-12 h-50" style="overflow-x: hidden;">
        <div class="row h-50">
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 reset__common-panel">
                <div class="common-body">
                  <a href="">
                    <img src="../../../../assets/InvCar_Logo.svg">
                  </a>
          
                  <span class="contents">INVEST IN CARS <br> A BETTER WAY</span>
                  <p class="invDetails">InvCar helps you grow your income by <br>
                    allowing you to invest in rental vehicles</p>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 h-100 reset__card">
                <div class="row h-100">
                    <div class="col-l2 h-100 d-grid">
                       
                        <div id="sign-in-box" class="align-self-center">
                            <h2 class="secondary-bold wp-charcoal f40">RESET PASSWORD</h2>
                            <form [formGroup]="resetPasswordForm" class="row col-lg-6 col-md-12 col-sm-12 mt-4" autocomplete="off">
                                <div class="col-12">
                                    <div class="form-group col-12 ">
                                        <label for="txtPassword" class="form-label">Password</label>
                                        <input [type]="showPassword?'text':'password'" placeholder="Enter password"
                                            formControlName="password" class="form-control" id="txtPassword" autocomplete="off">
                                        <span (click)="showPassword=!showPassword" class="eye-icon" *ngIf="!showPassword">
                                            <i class="fas fa-eye"></i>
                                        </span>
                                        <span (click)="showPassword=!showPassword" class="eye-icon" *ngIf="showPassword">
                                            <i class="fas fa-eye-slash"></i>
                                        </span>
                                        <p class="form-error"
                                            *ngIf="helper.canShowError(resetPasswordForm,'password') && helper.hasError(resetPasswordForm, 'password','required')">
                                            Password is required
                                        </p>
                                        <p class="form-error"
                                            *ngIf="helper.canShowError(resetPasswordForm,'password') && helper.hasError(resetPasswordForm, 'password','pattern')">
                                            {{passwordError}}
                                        </p>
                                    </div>
        
                                    <div class="form-group col-12  ">
                                        <label for="txtConfirmPassword" class="form-label required">Confirm Password</label>
                                        <input [type]="showConfirmPassword?'text':'password'" class="form-control"
                                            id="txtConfirmPassword" formControlName="confirmPassword"
                                            placeholder="Confirm Password">
                                        <span (click)="showConfirmPassword=!showConfirmPassword" class="eye-icon"
                                            *ngIf="!showConfirmPassword">
                                            <i class="fas fa-eye"></i>
                                        </span>
                                        <span (click)="showConfirmPassword=!showConfirmPassword" class="eye-icon"
                                            *ngIf="showConfirmPassword">
                                            <i class="fas fa-eye-slash"></i>
                                        </span>
                                        <p class="form-error"
                                            *ngIf="helper.canShowError(resetPasswordForm,'confirmPassword') && helper.hasError(resetPasswordForm, 'confirmPassword','required')">
                                            Confirm your password</p>
        
                                        <p class="form-error" *ngIf="passwordMismatch()">
                                            Password does not match.
                                        </p>
                                    </div>
        
                                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                        <button class="btn btn-primary w-100 mt-4" (click)="onResetPassword()"
                                            [disabled]="!resetPasswordForm.valid">Reset Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   
</div>
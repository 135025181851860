import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PropertyService } from 'src/app/core/services/property.service';
import { HelperService } from 'src/app/core/utils/helper.service';
declare var $: any;
export interface propertyGallery {
  image: string;
}

@Component({
  selector: 'app-property-gallery',
  templateUrl: './property-gallery.component.html',
  styleUrls: ['./property-gallery.component.scss'],
  animations: [
    trigger('thumbState', [
      state('active', style({ transform: 'translateX({{position}})' }), { params: { position: '10px' } }),
      state('inactive', style({ transform: 'translateX({{position}})' }), { params: { position: '10px' } }),
      transition('* <=>*', animate('260ms ease-in'))
    ])
  ]
})
export class PropertyGalleryComponent implements OnInit, OnChanges {
  @Input() propertyGallery: propertyGallery[] = [];
  @Input() propertyId: string = '';
  @Input() isFavorite;
  @Output() change: EventEmitter<number> = new EventEmitter<number>();
  slideControl: any[] = []
  counter = 0;
  constructor(
    private toastrService: ToastrService,
    private propertyService: PropertyService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {


  }

  ngOnChanges(simpleChange: SimpleChanges) {

    if (simpleChange.propertyGallery.currentValue.length > 0) {
      setTimeout(() => {
        $(".slider").slick({
          infinite: false,
          arrows: true,
          dots: true
        });
      }, 500);
    }
  }

  ngAfterContentInit() {
    this.change.emit(0);
  }

  onClickThumb(event: any) {
    const total = this.propertyGallery.length - 1;
    this.counter = event.layerX < 150 ? this.dec(total) : this.inc(total);
    this.change.emit(this.counter);
  }

  inc(total: any) {
    return (this.counter < total) ? this.counter + 1 : 0;
  }

  dec(total: any) {
    return (this.counter > 0) ? this.counter - 1 : total;
  }

  next() {
    const total = this.propertyGallery.length - 1;
    this.counter = this.inc(total);
    this.change.emit(this.counter);
  }

  prev() {
    const total = this.propertyGallery.length - 1;
    this.counter = this.dec(total);
    this.change.emit(this.counter);
  }

  onWatch() {
    this.helper.showSpinner();
    this.propertyService.updateFavProperty(this.propertyId).subscribe(
      (s: any) => {
        this.helper.hideSpinner();

        if (this.isFavorite == true) {
          this.helper.success('Property removed from watch list');
        } else {
          this.helper.success('Property added to watch list');
        }
        //this.helper.success(s.msg);
        this.isFavorite = !this.isFavorite;
      },
      (e) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ImageService } from 'src/app/core/services/image.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { StaticMessage } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-passport-form',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.scss']
})
export class PassportComponent implements OnInit {

  passportImg: string = '';
  passportImgUrl: string = '';

  passportForm: FormGroup = new FormGroup({
    PassportNumber: new FormControl('', [Validators.required, Validators.pattern(StaticMessage.passportNumberPattern)]),
    PassportFile: new FormControl(''),
    passportImg: new FormControl(false, [Validators.pattern('true')]),
    residentCountry: new FormControl("", [Validators.required]),
    issuedCountry: new FormControl("", [Validators.required]),
  });
  @Output() passportFormData: EventEmitter<any> = new EventEmitter();
  @Input() storedData: any;
  @ViewChild('passportDoc', { static: false }) passportDoc: ElementRef | undefined;
  passportFormLocalData = null;
  sendFiles = {
    docType: 'ORG_PASSPORT',
    docText: 'FRONT SIDE'
  };

  countryList = [];
  constructor(
    private imageService: ImageService,
    private lookUpService: LookupService
  ) { }

  ngOnInit() {

    this.getCountryList();

    if (this.storedData) {
      this.passportForm.patchValue({
        PassportNumber: this.storedData.passportNo,
        residentCountry: this.storedData.countryOfResidence,
        issuedCountry:this.storedData.countryOfIssued,
        passportImg: true
      });
      this.imageService.appendImageFile({
        docType: 'ORG_PASSPORT',
        previewUrl: this.storedData.passportImageURL,
        isFileExist: true
      });
    }

    this.passportForm.statusChanges.subscribe(
      (status) => {
        if (status === 'VALID') {
          const filesAttached = [
            {
              file: this.passportImg,
              rename: this.passportForm.value.PassportFile,
              url: this.passportImgUrl
            },
          ];
          this.passportFormLocalData = { ...this.passportForm.value, ...{ AttachmentFiles: filesAttached } }
          this.passportFormData.emit(this.passportFormLocalData);
        } else {
          if (this.passportFormLocalData) {
            this.passportFormLocalData = null;
            this.passportFormData.emit(this.passportFormLocalData);
          }
        }
      }
    );

    
  }

  getCountryList() {
    this.lookUpService.getCountryList().subscribe(
      (s: any) => {
        this.countryList = s.data.countries;
      },
      (e) => { }
    );
  }

  get PassportNumber() {
    return this.passportForm.get('PassportNumber');
  }

  getValue(control: string) {
    return this.passportForm.controls[control].value;
  }

  canShowError(control: string) {
    const field = this.passportForm.controls[control];
    return (!field.valid) && (field.touched) && (field.dirty);
  }

  hasError(control: string, error: string) {
    return this.passportForm.controls[control].hasError(error);
  }

  getFiles(e: any) {

    if (e) {
      if (e.imgFile || e.imgUrl) {
        this.passportForm.patchValue({
          passportImg: true
        });
      } else {
        this.passportForm.patchValue({
          passportImg: false,
          passportFile: ''
        });
      }
      if (e.imgFile) {
        this.passportImg = e.imgFile;
        this.passportImgUrl = '';
        this.passportForm.patchValue({
          PassportFile: e.imgFile.name
        });
      }
      if (e.imgUrl) {
        this.passportImgUrl = e.imgUrl;
        this.passportImg = '';
        this.passportForm.patchValue({
          PassportFile: ''
        });
      }
    } else {
      this.passportForm.patchValue({
        PassportFile: '',
        passportImg: false
      });
      this.passportImgUrl = '';
      this.passportImg = '';
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sell-initiated-dialog',
  templateUrl: './sell-initiated-dialog.component.html',
  styleUrls: ['./sell-initiated-dialog.component.scss']
})
export class SellInitiatedDialogComponent implements OnInit {
  bricklet = '';
  amount: number | string = "";
  id = '';
  isBpp = false;
  constructor(
    public dialogRef: MatDialogRef<SellInitiatedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.bricklet = this.data.noOfBricklets;
      this.amount = parseFloat(this.data.totalAmount) / parseInt(this.bricklet);
      this.id = this.data.id;
      this.isBpp = this.data.isBpp;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

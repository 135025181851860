import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { DataShareService } from "src/app/core/services/data-share.service";
import { LookupService } from "src/app/core/services/lookup.service";
import { HelperService } from "src/app/core/utils/helper.service";

@Component({
  selector: 'app-pvt-company-address-form',
  templateUrl: './pvt-company-address-form.component.html',
  styleUrls: ['./pvt-company-address-form.component.scss']
})
export class PvtCompanyAddressFormComponent implements OnInit, OnChanges {
  @Input() formTitle: string;
  @Input() addressFormsDataObject: any;
  @Output() formDataEmitter = new EventEmitter<any>();

  privateCompanyAddressForm;

  outputData = {
    formObject: {},
    isFormValid: false,
  };
  stateList: any;

  constructor(
    private lookupService: LookupService,
    private commonService: HelperService,
    private dataShareService: DataShareService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (this.addressFormsDataObject) {
        this.privateCompanyAddressForm
          .get("unitNo")
          .setValue(this.addressFormsDataObject.unitNo);
        this.privateCompanyAddressForm
          .get("streetNo")
          .setValue(this.addressFormsDataObject.streetNo);
        this.privateCompanyAddressForm
          .get("streetName")
          .setValue(this.addressFormsDataObject.streetName);
        this.privateCompanyAddressForm
          .get("streetType")
          .setValue(this.addressFormsDataObject.streetType);
        this.privateCompanyAddressForm
          .get("postCode")
          .setValue(this.addressFormsDataObject.postalCode);
        this.privateCompanyAddressForm
          .get("state")
          .setValue(this.addressFormsDataObject.stateGUID);
        this.privateCompanyAddressForm
          .get("suburb")
          .setValue(this.addressFormsDataObject.suburb);
        this.privateCompanyAddressForm
          .get("additionInfo")
          .setValue(this.addressFormsDataObject.additionalInfo);
        // this.checkAddressFormValidation();
      }
    }, 500);
  }

  initiateForm() {
    this.privateCompanyAddressForm = new FormGroup({
      unitNo: new FormControl(""),
      streetNo: new FormControl("", Validators.required),
      streetName: new FormControl("", Validators.required),
      streetType: new FormControl("", Validators.required),
      postCode: new FormControl("", Validators.required),
      state: new FormControl("", Validators.required),
      suburb: new FormControl("", Validators.required),
      additionInfo: new FormControl(""),
    });
  }

  canShowError(control: string) {
    const field = this.privateCompanyAddressForm.controls[control];
    return !field.valid && field.touched && field.dirty;
  }

  hasError(control: string, error: string) {
    return this.privateCompanyAddressForm.controls[control].hasError(error);
  }

  getValue(control: string) {
    return this.privateCompanyAddressForm.controls[control].value;
  }

  getFormFieldsValue() {
    const data = {
      UnitNo: this.getValue("unitNo"),
      StreetNo: this.getValue("streetNo"),
      StreetName: this.getValue("streetName"),
      StreetType: this.getValue("streetType"),
      PostalCode: this.getValue("postCode"),
      StateGUID: this.getValue("state"),
      Suburb: this.getValue("suburb"),
      AdditionalInfo: this.getValue("additionInfo"),
    };
    return data;
  }

  getStateList() {
    this.lookupService.getStates().subscribe(
      (s: any) => {
        const res = s.data.states;
        this.stateList = res;
      },
      (e) => {
        this.commonService.error(e);
      }
    );
  }

  checkAddressFormValidation() {
    this.privateCompanyAddressForm.statusChanges.subscribe((s) => {
      this.outputData.formObject = this.getFormFieldsValue();
      if (s && s === "VALID") {
        this.outputData.isFormValid = true;
      } else {
        this.outputData.isFormValid = false;
      }
      this.formDataEmitter.emit(this.outputData);
    });
  }

  ngOnInit() {
    this.initiateForm();
    this.getStateList();
    this.checkAddressFormValidation();
  }

}

<div class="container property-section">
    <div class="content-section">
    <h2>INTERESTED <br> IN JOINING?</h2>
    <p>Are you interested in joining forces with InvCar and playing a pivotal role in transforming the rental vehicle
        investment industry?</p>
    <p>We invite you to become a partner and contribute to our mission of making fractional rental vehicle investments
        accessible and rewarding for everyone.
    </p>
    <p>As an InvCar partner, you'll benefit from our growing network, innovative platform, and the opportunity to
        collaborate with like-minded professionals. Whether you're a car distributor, rental agency, or an individual
        with valuable expertise to share, partnering with InvCar can open up new avenues for growth and success.
    </p>
    <p>Get in touch with us today to explore the exciting possibilities of an InvCar partnership.
    </p>
    <p>contact us now at -  <span style="font-weight: 600;"> <a href="mailto: help@invcar.com ">help@invcar.com  </a> </span>
    </p>
</div>
</div>

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ImageService } from 'src/app/core/services/image.service';
import { LookupService } from 'src/app/core/services/lookup.service';

@Component({
  selector: 'app-driving-license-form',
  templateUrl: './driving-license.component.html',
  styleUrls: ['./driving-license.component.scss']
})
export class DrivingLicenseComponent implements OnInit {

  stateList: {
    stateGUID: string;
    name: string;
    shortName: string;
  }[] = [];

  docPreview = {
    frontSide: null,
    backSide: null,
    default: '/assets/images/preview.png'
  };

  uploadedFiles = {
    frontSide: null,
    backSide: null
  };

  uploadedFilesUrl = {
    frontSide: null,
    backSide: null
  };

  sendFilesFront = {
    docType: 'DL_FRONT_SIDE',
    docText: 'FRONT SIDE'
  };

  sendFilesBack = {
    docType: 'DL_BACK_SIDE',
    docText: 'BACK SIDE'
  };

  dlForm: FormGroup;
  dlFormDataTemp = null;
  @Output() dlFormData: EventEmitter<any> = new EventEmitter();
  @Input() storedData: any;

  @ViewChild('frontSideDoc', { static: false }) frontSideDoc: ElementRef;
  @ViewChild('backSideDoc', { static: false }) backSideDoc: ElementRef;
  constructor(
    private lookupService: LookupService,
    private imageService: ImageService
  ) { }

  ngOnInit() {
    this.lookupService.getStates().subscribe(
      (s: any) => {
        this.stateList = s.data.states;
      },
      (e: any) => { }
    );
    this.createForm();

    this.dlForm.statusChanges.subscribe(
      (status) => {
        if (status === 'VALID') {
          this.dlFormDataTemp = this.dlForm.value;
          const filesAttached = [
            {
              file: this.uploadedFiles.frontSide,
              rename: this.dlForm.value.DrivingLicenseFrontSide,
              url: this.uploadedFilesUrl.frontSide
            },
            {
              file: this.uploadedFiles.backSide,
              rename: this.dlForm.value.DrivingLicenseBackSide,
              url: this.uploadedFilesUrl.backSide
            }
          ];
          this.dlFormDataTemp = { ...this.dlFormDataTemp, ...{ AttachmentFiles: filesAttached } };
          this.dlFormData.emit(this.dlFormDataTemp);
        } else {
          if (this.dlFormDataTemp) {
            this.dlFormDataTemp = null;
            this.dlFormData.emit(this.dlFormDataTemp);
          }
        }

      }
    );
  }

  createForm() {
    this.dlForm = new FormGroup({
      StateGUID: new FormControl('', Validators.required),
      DrivingLicenseNumber: new FormControl('', Validators.required),
      DrivingLicenseFrontSide: new FormControl(null, Validators.required),
      DrivingLicenseBackSide: new FormControl(null, Validators.required)
    });
    if (this.storedData) {
      const dlNo = this.storedData.drivingLicenseNumber ? this.storedData.drivingLicenseNumber : this.storedData.drivingLicenseNo;
      const state = this.storedData.stateGUID ? this.storedData.stateGUID : this.storedData.stateIssuedGUID;

      this.dlForm.patchValue({
        StateGUID: state,
        DrivingLicenseNumber: dlNo
      });
      this.imageService.appendImageFile({
        docType: 'DL_FRONT_SIDE',
        previewUrl: this.storedData.drivingLicenseFrontSideURL,
        isFileExist: true
      });
      this.imageService.appendImageFile({
        docType: 'DL_BACK_SIDE',
        previewUrl: this.storedData.drivingLicenseBackSideURL,
        isFileExist: true
      });
    }
  }

  getValue(control: string) {
    return this.dlForm.controls[control].value;
  }

  canShowError(control: string) {
    const field = this.dlForm.controls[control];
    return (!field.valid) && (field.touched) && (field.dirty);
  }

  hasError(control: string, error: string) {
    return this.dlForm.controls[control].hasError(error);
  }

  getFilesFront(e) {
    if (e && (e.imgFile || e.imgUrl)) {
      if (e.imgFile) {
        this.uploadedFiles.frontSide = e.imgFile;
        this.uploadedFilesUrl.frontSide = null;
        this.dlForm.patchValue({
          DrivingLicenseFrontSide: e.imgFile.name
        });
      }
      if (e.imgUrl) {
        this.uploadedFiles.frontSide = null;
        this.uploadedFilesUrl.frontSide = e.imgUrl;
        this.dlForm.patchValue({
          DrivingLicenseFrontSide: 'DL_FRONT_SIDE'
        });
      }
    } else {
      this.uploadedFiles.frontSide = null;
      this.uploadedFilesUrl.frontSide = null;
      this.dlForm.patchValue({
        DrivingLicenseFrontSide: null
      });
    }
  }

  getFilesBack(e) {
    if (e && (e.imgFile || e.imgUrl)) {
      if (e.imgFile) {
        this.uploadedFiles.backSide = e.imgFile;
        this.uploadedFilesUrl.backSide = null;
        this.dlForm.patchValue({
          DrivingLicenseBackSide: e.imgFile.name
        });
      }
      if (e.imgUrl) {
        this.uploadedFiles.backSide = null;
        this.uploadedFilesUrl.backSide = e.imgUrl;
        this.dlForm.patchValue({
          DrivingLicenseBackSide: 'DL_BACK_SIDE'
        });
      }
    } else {
      this.uploadedFiles.backSide = null;
      this.uploadedFilesUrl.backSide = null;
      this.dlForm.patchValue({
        DrivingLicenseBackSide: null
      });
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { AccountService } from './core/services/account.service';
import { AppService } from './core/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './core/utils/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'INVCAR';
  showHeader = false;
  isLogged = false;
  language: string = '';

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private appService: AppService,
    private accountService: AccountService,
    private translate: TranslateService,
    private storageService: StorageService
  ) {
    if (this.storageService.getDefaultLang()) {
      this.language = this.storageService.getDefaultLang();
    } else {
      this.language = 'English';
    }

    translate.addLangs([
      'English',
      'Chinese',
      'Korean',
      'Vietnamese',
      'Japanese',
      'Indonesian',
      'Thailand',
      'Mongolia',
    ]);
    // translate.setDefaultLang('ch');
    // const browserLang = translate.getBrowserLang();
    translate.use(this.language);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (activateRoute.firstChild?.snapshot.data) {
          const data = activateRoute.firstChild?.snapshot.data;
          if (data) {
            this.showHeader = data.header;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.appService.isLoggedIn().subscribe(
      (status) => {
        if (!this.isLogged && status) {
          this.appService.getUserStatus();
          setTimeout(() => {
            this.accountService.getAndSetAccounts();
          }, 700);
        }
        this.isLogged = status;
      },
      (e) => {}
    );

    this.router.events.subscribe((val: any) => {
      if (val && val.url) {
        if (this.isLogged) {
          if (val.url === '/home' || val.url === '/') {
            this.router.navigateByUrl('/home');
          }
        }
      }
    });
  }
}

<div mat-dialog-title class="mb-0">
    <div class="px-2 d-flex justify-content-between align-items-center bg-waav-bright-main pos-rel">
        <h2 class="m-0 primary-bold f20 purple-brown"> SWAP</h2>
        <img src="/assets/images/dialog-close.svg" class="swap-dialog-close mobile" (click)="closeDialog()"
            title="Close dialog" alt="close_dialog" height="70px">
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form class="p-3" [formGroup]="swapForm">
        <div>
            <div *ngIf="data?.isBpp === true">
                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label for="" class="primary-bold f16 waav-dark-main"> Available Lot for swap
                        </label>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 available-bricklet-to-swap">
                        <span class="f16">{{data.availableBrickletToSwap}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label for="" class="primary-bold f16 waav-dark-main">
                            How many Lot
                            you want to move to Ordinary ?
                        </label>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <input type="text" class="input-dialog purple-brown border" formControlName="NoofBricklets">
                        <div *ngIf="canShowError('NoofBricklets')">
                            <p class="error-txt" *ngIf="hasError('NoofBricklets','required')">Enter no of Lot</p>
                            <p class="error-txt" *ngIf="hasError('NoofBricklets','max')">Insufficient Lot</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data?.isBpp === false">
                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label for="" class="primary-bold f16 waav-dark-main"> Available Bricklet for swap
                        </label>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <span class="f16">{{data.availableBrickletToSwap}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label for="" class="primary-bold f16 waav-dark-main">
                            How many Bricklets
                            you want to move to BPP ?
                        </label>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <input type="text" class="input-dialog purple-brown border" formControlName="NoofBricklets">
                        <div *ngIf="canShowError('NoofBricklets')">
                            <p class="error-txt" *ngIf="hasError('NoofBricklets','required')">Enter no of Lot</p>
                            <p class="error-txt" *ngIf="hasError('NoofBricklets','max')">Insufficient Lot</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions class="p-0 m-0">
    <div class="m-0 my-3 w-100 text-center">
        <div class="row ms-0 me-0 mt-4 mb-4 justify-content-center">
            <button class="col-lg-4 col-md-6 col-sm-12 btn btn-primary btn-sm move-to-bpp-btn my-2" (click)="onSubmit()"
                [disabled]="!swapForm.valid">
                {{data?.isBpp === true ? 'Move to Ordinary' : 'Move to BPP'}}
            </button>
            <button class="col-lg-4 col-md-6 col-sm-12 btn btn-primary outlined cancel-btn my-2" (click)="closeDialog()">
                Cancel
            </button>
        </div>
    </div>
</mat-dialog-actions>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/core/services/account.service';
import { AppService } from 'src/app/core/services/app.service';
import { CartService } from 'src/app/core/services/cart.service';
import { PropertyService } from 'src/app/core/services/property.service';
import { StorageService } from 'src/app/core/utils/storage.service';
import { Storage } from 'src/app/core/enum/storage';

declare var $: any;
export interface CustomResponse {
  data: any;
}
export interface PriceListDto {
  availBricklet: number;
  price: number;
}
export interface MarketPriceDto {
  availBalance: number;
  totalAmount: number;
  cartResponseDTO: {
    bannerImage: string;
    invCarPrice: number;
    carAddress: string;
    quantity: number;
    subTotal: number;
    carGUID: string;
    carMake:string;
    carModel:string;
    cartGUID:string;
    orderGUID:string;
    invCarFee:number;
    total:number;
    state:string;
    country:string;
    noOfShares:number;
  };
}
@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {
  cartValue: boolean = false;
  @Output() SendValue = new EventEmitter<boolean>();
  marketPriceData: MarketPriceDto | null = null;
  cartInputQty: any;
  cartItemId: any = '';
  localCart = {
    guid: localStorage.getItem('cartItem'),
    qty: localStorage.getItem('cartQty'),
    orderGuid:localStorage.getItem('orderGuid')
  };
  isLoggedIn = false;
  getWishList:any;
  emptyWishlist:string;
  accountBalance: any;
  orderID: any;
  kycProcessStatus: boolean;
  basicFlow: string = 'basic_flow';
  cartID: string;
  carTotalList: any;
  noOfShares: number;

  
  constructor(private router: Router,
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private propertyService: PropertyService,
    private cartService: CartService,
    private toaster: ToastrService,
    private accountService:AccountService,
    private storageService: StorageService,


    ) {}

  ngOnInit(): void {
    if (this.localCart.guid) {
      this.cartItemId = this.localCart.guid;
    }
    this.appService.isLoggedIn().subscribe((s) => {
      this.isLoggedIn = s;
      this.cartService.passCartBadge(true);
    });
    setTimeout(() => {
      if (this.isLoggedIn == true) {
        this.savedCartData();
        if(localStorage.getItem('cartGuid')){
          this.getCart();
          this.cartService.passCartBadge(true);
        }else{
          if (this.localCart.guid !== '' && this.localCart.guid !== null) {
          this.addToCart(this.localCart.guid, this.localCart.qty);
          this.cartInputQty = this.localCart.qty;
          this.cartService.passCartBadge(true);
        }
        }
         
        this.cartService.currentObject.subscribe((object) => {
          if(this.marketPriceData !== null){
            this.marketPriceData = object.data.carts;
            this.cartInputQty = this.marketPriceData.cartResponseDTO.quantity;
            this.cartItemId = this.marketPriceData?.cartResponseDTO.carGUID;
            // this.orderID = this.marketPriceData?.cartResponseDTO.orderGUID;
            this.cartID = this.marketPriceData?.cartResponseDTO.cartGUID;
            this.noOfShares = this.marketPriceData?.cartResponseDTO.noOfShares;
            localStorage.setItem('carGuid', this.cartItemId);
            localStorage.setItem('cartGuid', this.cartID);

          }
          // else{
          //   this.savedCartData();
          // }
        });
        this.getAccountBlnc();
      } else {
        if (this.localCart.guid !== '' && this.localCart.guid !== null) {
          this.getGuestCartData(this.localCart.guid, this.localCart.qty);
          this.cartInputQty = this.localCart.qty;
        }
      }
    }, 500);
    this.kycStatus();
    // this.getPendingOrders();
  }

  ngAfterViewInit() {
    if(this.isLoggedIn){
      // this.getAccountBlnc();
      this.getPendingOrders();

    }
  }

  // counter =1;
  decrement() {
    this.cartInputQty--;
    if(this.cartInputQty < 1){
      this.cartInputQty=1;  
     }
  }

  increment() {
    this.cartInputQty++;
  }

  getCart() {
    this.spinner.show();
    const  cartGUID = localStorage.getItem('cartGuid');
    if(cartGUID !== null || cartGUID !== undefined){
      this.cartService.getCartValue(cartGUID).subscribe(
        (s: any) => {
          this.marketPriceData = s.data.carts;
          this.cartInputQty = this.marketPriceData?.cartResponseDTO.quantity;
          this.cartItemId = this.marketPriceData?.cartResponseDTO.carGUID;
          this.noOfShares = this.marketPriceData?.cartResponseDTO.noOfShares;
          this.orderID = localStorage.getItem('orderGuid');
          this.cartService.passCartBadge(false);
          this.spinner.hide();
        },
        (e: string) => {
          this.marketPriceData = null;
          this.spinner.hide();
          this.toaster.error(e);
        }
      );
    }else{
      this.toaster.error("Cart is empty..!!!");
    }
   
  }

  savedCartData(){
    this.cartService.getCartDetails().subscribe(
      (s: any) => {
        // console.log("cartdetails",s);
        this.marketPriceData = s.data.carts;
        this.cartItemId = this.marketPriceData?.cartResponseDTO.carGUID;
        this.cartID = this.marketPriceData?.cartResponseDTO.cartGUID;
        this.cartInputQty = this.marketPriceData?.cartResponseDTO.quantity;
        this.noOfShares = this.marketPriceData?.cartResponseDTO.noOfShares;
        localStorage.setItem('carGuid', this.cartItemId);
        localStorage.setItem('cartGuid', this.cartID);

        // localStorage.setItem('cartGuid', this.cartID);
        // localStorage.setItem('carGuid', this.cartItemId);
        // this.orderID = localStorage.getItem('orderGuid');
        this.cartService.passCartBadge(true);
        this.spinner.hide();
      },
      (e: string) => {
        this.marketPriceData = null;
        this.spinner.hide();
        // this.toaster.error(e);
        // this.toaster.error("Your cart is Empty..!!!");

      }
    );
  }

  kycStatus(){
    this.appService.kycStausValue.subscribe((kycProcessRes:any) => {
      // return kycProcessRes;
       this.kycProcessStatus = kycProcessRes;
      //  console.log("this.kycProcessStatus",this.kycProcessStatus)
     });
  }


  addToCart(guid: string, qty: any) {
    const data = {
      carGUID: guid,
      Quantity: Number(qty),
    };
        this.spinner.show();
        this.cartService.addCart(data).subscribe(
      (response: CustomResponse) => {
        // this.router.navigateByUrl('/cart');
        // console.log('resp',s);
         let cartGuid = response.data.carts.cartResponseDTO.cartGUID;
         let carGuid = response.data.carts.cartResponseDTO.carGUID;
         let orderGuid = response.data.carts.cartResponseDTO.orderGUID;   
        localStorage.setItem('cartGuid',cartGuid);
        localStorage.setItem('carGuid',carGuid);
        localStorage.setItem('orderGuid',orderGuid);
        this.spinner.hide();
            },
      (e) => {
        // this.spinner.error(e);
        // this.toastr.error(e)
        this.spinner.hide();
      }
    );
  }
  cartImg:any;
  getGuestCartData(guid: string, qty: any) {
    this.spinner.show();
    this.propertyService.getMarketPrice(guid, Number(qty)).subscribe(
      (s: any) => {
        console.log("response guest",s);
        this.marketPriceData = s.marketPrices;
        this.noOfShares = this.marketPriceData?.cartResponseDTO?.noOfShares;
        this.cartService.passCartBadge(false);
        this.spinner.hide();
      },
      (e: string) => {
        this.toaster.error(e);
        this.spinner.hide();
        this.cartInputQty = 1;
        this.updateLocalCart();
      }
    );
  }

  updateLocalCart() {
    if (this.isLoggedIn) {
      this.updateCart(this.cartItemId, this.cartInputQty);
    } else {
      localStorage.setItem('cartQty', this.cartInputQty);
      this.localCart.qty = this.cartInputQty;
      this.getGuestCartData(this.cartItemId, this.localCart.qty);
    }
  }

  updateCart(guid: string, qty: any) {
    const data = {
      carGUID: guid,
      quantity: Number(qty),
      // orderGUID:localStorage.getItem('orderGuid')
    };
    this.spinner.show();
    this.cartService.updateCart(data).subscribe(
      (s: any) => {
        this.marketPriceData = s.data.carts;
        this.spinner.hide();
      },
      (e) => {
        this.spinner.hide();
        this.toaster.error(e);
      }
    );
  }
  checkInputValue() {
    if (this.cartInputQty == 0) {
      this.cartInputQty = '1';
    }
  }

  isCartEmpty() {
    if (!this.isLoggedIn) {
      return this.localCart.guid == null || this.localCart.guid == '';
    } else {
      return this.marketPriceData == null ? true : false;
    }
  }
  closeSidebar() {
    this.SendValue.emit(this.cartValue);
    this.appService.closeCartValue(this.cartValue);

  }
  showWishlistValues(){
   this.getWishList = JSON.parse(localStorage.getItem('wishlistItem'));
   console.log(this.getWishList);
   if(this.getWishList != null){
    this.propertyService.getMarketPrice(this.getWishList, 1).subscribe(
      (s: any) => {
        this.marketPriceData = s.marketPrices;
      });
   }else {
    this.emptyWishlist ='Your wishlist is currently empty.'
   }
  }

  showCartTabValue(){
    if(this.isLoggedIn){
      //  this.getCart();
      this.savedCartData();
    }else{
      if(this.localCart.guid !== null){
        this.getGuestCartData(this.localCart.guid, this.localCart.qty);
      }else {
        this.isCartEmpty();
      }
    }
  }


  removeCart(){
    this.spinner.show();
    this.cartService.deleteCart().subscribe((r:any)=>{
      this.spinner.hide();
      // this.helper.navigate('/marketplace');
      this.toaster.success(r.data.message);
      localStorage.removeItem("cartGuid");
      localStorage.removeItem("carGuid");
      localStorage.removeItem("cartItem");
      localStorage.removeItem("cartQty");
      this.cartService.passCartBadge(true);
      this.closeSidebar();

    },
    (e) => {
      this.toaster.error(e);
      this.spinner.hide();
    })
  }

  getAccountBlnc(){
    this.accountService.getAccountList().subscribe(
      (r: any) => {
         this.accountBalance = r.data.accounts[0].accountBalance;
         this.kycProcessStatus = r.data.accounts[0].isKYCVerified
        //  this.accountBalance = 15000
      },
      error => {
        this.accountBalance = 0;
        // this.helper.hideSpinner();
        // this.helper.error(error);
      });
  }

  deleteCartAfterCheckout(){
    this.spinner.show();
    this.cartService.deleteCart().subscribe((r:any)=>{
      this.spinner.hide();
      console.log("cart is clear");
      // this.helper.navigate('/marketplace');
      // this.toaster.success(r.data.message);
      localStorage.removeItem("cartGuid");
      localStorage.removeItem("carGuid");
      // this.cartService.passCartBadge(true);
      // this.closeSidebar();

    },
    (e) => {
      this.toaster.error(e);
      this.spinner.hide();
    })
  }

  

  checkout(){
    if (!this.isLoggedIn) {
      this.SendValue.emit(this.cartValue);   
      this.router.navigateByUrl('/login');
    } else {
      if(this.kycProcessStatus){
        if(this.carTotalList.length <3){
          let checkoutData = {
            CartGUID : this.cartID,
            CarGUID : this.cartItemId
          }
    
          this.cartService.checkout(checkoutData).subscribe((s:any)=>{
            let orderGUID = s.data.checkout;
            let checkoutDetails = {
              checkoutID : orderGUID,
              cartOrderStatus : this.basicFlow
            }
            console.log("checkoutDetails",checkoutDetails);
            this.cartService.checkoutObject(checkoutDetails);
            this.deleteCartAfterCheckout();
            // localStorage.removeItem('orderGuid')
            // localStorage.removeItem('cartOrderStatus')    
          });  
           this.router.navigateByUrl('/checkout');
           this.SendValue.emit(this.cartValue);   
        }else{
          this.toaster.error("Please check the items present in the pending orders before checkout.!!!");
          this.closeSidebar();
          this.router.navigateByUrl('/dashboard/my-cars');  
        }
    
      }else{
        this.toaster.error("Once Approve your KYC and Proceed checkout..!!!");
        this.SendValue.emit(this.cartValue); 
        this.router.navigateByUrl('/dashboard/my-cars');  
      }
      
     }
    }

    getPendingOrders() {
      this.cartService.getPendingOrdersLists().subscribe(
        (res: any) => {
          this.carTotalList = res.data.dashboardCarPendingList;
          console.log("car pending",this.carTotalList.length);
        },
        (error: string) => {
          this.toaster.error(error);
        }
      );
    }

    // openDeleteCartPopup(){
    //   $('#openCartModal').modal('show');
    // }
    
    // closeDeleteCartPopup(){
    //   $('#openCartModal').modal('hide');
    // }
}


<div id="header">
    <div [ngClass]="(showHeader && cartDetails) ? 'col-9 menu-sm' : 'col-12 menu-lg' " >
        <app-header *ngIf="showHeader"></app-header>
    </div>
</div>

<div id="app-container">
    <div class="row">
        <div class="col-3 col-sm-1 col-md-2 col-lg-3 col-xl-3" *ngIf="showSidebar && !cartDetails">
            <app-left-sidebar></app-left-sidebar>
        </div>
        <div class="col-9 " [ngClass]="(showSidebar || cartDetails) ? 'col-9' : 'col-12' " >
            <div id="content">
                <main>
                    <div id="body-content" [@routerTransition]="page.activatedRouteData.state"   [ngClass]="{'half-height':showHeader, 'full-height':!showHeader}">
                        <router-outlet #page="outlet"></router-outlet>
                    </div>
                </main>
                <div *ngIf="showSidebar && !isLogged" style="margin-top:150px">
                <app-investment-card *ngIf="showSidebar"></app-investment-card>
            </div>
        </div>
        </div>
        <div class="col-3 col-sm-1 tabmediside col-lg-3 col-xl-3" *ngIf="cartDetails">
            <app-right-sidebar (SendValue)="ValueFromRightSidebar($event)"></app-right-sidebar>
        </div>
        
    </div>
  
   <!-- <app-helpdesk *ngIf="showSidebar"></app-helpdesk> -->
   
    <div id="footer">
        <app-footer *ngIf="showHeader"></app-footer>
    </div>
</div>
<ngx-spinner  bdColor="false"
size="medium"
color="#692A60"
type="ball-scale-multiple"
[fullScreen] = "false"></ngx-spinner>
<app-podcast-floating-window></app-podcast-floating-window>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Storage } from '../enum/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public StorageWatch = new BehaviorSubject<any>(null);
  public isStorageChanged = this.StorageWatch.asObservable();
  private subject = new Subject<any>();
  
  constructor() { }

  getItem(item: Storage) {
    return sessionStorage.getItem(item);
  }

  setItem(item: Storage, value: string) {
    sessionStorage.setItem(item, value);
  }

  removeItem(item: Storage){
    sessionStorage.removeItem(item);
  }

  clear() {
    sessionStorage.clear();
  }

  setDefaultLang(item: string, value: string){
    return localStorage.setItem("lang",value)
  }

  getDefaultLang(){
   return  localStorage.getItem("lang");
  //  return 'ch';
  }

  hideCartBasedRole(){
    if(this.getItem(Storage.USER_ROLE) == "IPAN-X"){
     return true;
    }else{
      return false;
    }
  }

  enableCartMenu() {
      this.subject.next();
   }

   checkCartMenu(): Observable<any>{ 
      return this.subject.asObservable();
  }
}

<div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
  
      <div *ngFor="let question of questions" class="form-row">
        <app-dynamic-form [formField]="question" [form]="form"></app-dynamic-form>
      </div>
  
      <div class="form-row">
        <button class="btn btn-primary w-100 mt-4" [disabled]="!form.valid">Submit</button>
      </div>
    </form>
  
    <div *ngIf="payLoad" class="form-row">
      <strong>Saved the following values</strong><br>{{payLoad}}
    </div>
  </div>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RecaptchaComponent } from "ng-recaptcha";
import { Storage } from 'src/app/core/enum/storage';
import { ResponseInterface } from 'src/app/core/interfaces/response-interface';
import { AccountService } from 'src/app/core/services/account.service';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { HelperService } from 'src/app/core/utils/helper.service';
import { StorageService } from 'src/app/core/utils/storage.service';
import { KycModalComponent } from 'src/app/shared/components/kyc/kyc-modal/kyc-modal.component';
import { WelcomeDialogComponent } from 'src/app/shared/components/welcome-dialog/welcome-dialog.component';
import { PatternValidator } from 'src/app/shared/validators/patternvalidators';

declare var $: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {

  signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    // txtRecaptcha: new FormControl(null, Validators.required)
  });

  isCheckMail: boolean = false;
  showSignInForm = true;
  forgorpwdForm = false;
  forgotForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.maxLength(60),
    PatternValidator(
      // tslint:disable-next-line:max-line-length
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),])
  });

  @ViewChild("regRecaptcha", { static: false })
  recaptcha!: RecaptchaComponent;
  returnUrl: string = '';
  showDesign: boolean = false; 


  showPassword = false;

  constructor(
    private authService: AuthService,
    public helper: HelperService,
    public storageService: StorageService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private appService: AppService,
  ) { 
  }



  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    // const dialogRef = this.dialog.open(WelcomeDialogComponent, {
    //   data: {}
    // });
  }

  ngOnDestroy() {
   
  }

  resolved(captchaResponse: string) {
    this.signInForm.patchValue({
      txtRecaptcha: `${captchaResponse}`,
    });
    localStorage.setItem("captcha-token", `${captchaResponse}`);
  }

  reset() {
    this.recaptcha.reset();
    this.signInForm.patchValue({
      txtRecaptcha: null,
    });
  }

  onSubmit() {
    this.helper.showSpinner();
    this.authService.signIn({
      EmailID: this.signInForm.get('email')?.value,
      Password: this.signInForm.get('password')?.value,
      // Captcha: this.signInForm.get('txtRecaptcha')?.value,
    }).subscribe(
      (result: any) => {
        this.helper.hideSpinner();
        if (result.data !== null) {
          this.signInForm.reset();
          let { isTempPWD } = result.data.credential;
          this.setStorage(result);
          this.appService.loginStatus.next(true);
          // this.appService.getUserStatus();
          // setTimeout(()=>{
          //   this.accountService.getAndSetAccounts();
          // },700);
          // $("#authModal").modal("hide");
          this.helper.navigate("/marketplace/list");
          let KYCStatus = result.data.credential.kycStatus;
          // console.log("KYCStatus",KYCStatus);
          this.appService.kycStausValue.next(KYCStatus);
          if(KYCStatus === 0 || KYCStatus === 3){
            const dialogRef = this.dialog.open(KycModalComponent, {
              data: {}
            });
          }
          if (isTempPWD) {
            this.helper.navigate("/change-password");
            return;
          }
          
          if (this.returnUrl) {
            this.helper.navigate(this.returnUrl);
          } else {
            //window.location.reload();
          }
        } else {
          this.helper.error(result.msg);
          this.recaptcha.reset();
          this.signInForm.patchValue({
            txtRecaptcha: null,
          });
        }
        this.storageService.enableCartMenu();
      },
      (error: string) => {
        this.helper.hideSpinner();
        this.helper.error(error);
        this.recaptcha.reset();
        this.signInForm.patchValue({
          txtRecaptcha: null,
        });
        if (error === "Email not verified") {
          this.helper.navigate("/verify-email/" + this.signInForm.get('email')?.value);
        }
      }
    )
  }

  setStorage(result: any) {
    let { token, refreshToken, fullName, userRole,userType, roleType, isKYCVerified, accountGUID, accountName, isPOACompleted} = result.data.credential;
    this.storageService.setItem(Storage.AUTH_TOKEN, token);
    this.storageService.setItem(Storage.REFRESH_TOKEN, refreshToken);
    this.storageService.setItem(Storage.FULL_NAME, fullName);
    this.storageService.setItem(Storage.IS_ACCOUNT_SELECTED, "false");
    this.storageService.setItem(Storage.IS_LOGGED, 'true');
    this.storageService.setItem(Storage.INITIAL_KYC_STATUS, isKYCVerified);
    this.storageService.setItem(Storage.IS_POA_COMPLETED, isPOACompleted);
    if (isKYCVerified) {
      this.accountService.setSelAccount(accountName);
      this.storageService.setItem(Storage.USER_TYPE, userType);
      this.storageService.setItem(Storage.ACCOUNT_ID, accountGUID);
      this.storageService.setItem(Storage.ROLE, roleType.toUpperCase());
      this.storageService.setItem(Storage.IS_ACCOUNT_SELECTED, "true");
      this.storageService.setItem(Storage.USER_ROLE, userRole);

    }
  }

  onRequestLink() {
    
    const data = {
      EmailID: this.forgotForm.get('email').value
    }
    this.helper.showSpinner();
    this.authService.forgotpassword(data).subscribe(
      (s: any) => {
        //this.helper.success(s.msg);
        this.helper.hideSpinner();
        this.isCheckMail = true;
      },
      (e: any) => {
        this.helper.error(e);
        this.helper.hideSpinner();
      }
    );
  }

  openSignUp() {
    //this.showSignInForm = false
    $('#createAccount').modal('show');
  }


}

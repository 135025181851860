import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Storage } from '../enum/storage';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { StorageService } from '../utils/storage.service';
import { Data } from '../interfaces/individual-stepper';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { HelperService } from '../utils/helper.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  accountData = {
    selAcc: '',
    isAccSelected: false,
    accList: []
  };

  private AccountEvents = new BehaviorSubject(this.accountData);
  public AccountList = this.AccountEvents.asObservable();

  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();

  public depositPaymentSubject = new Subject<void>();
  public accountStatusSubject = new Subject<void>();



  constructor(
    private apiManager: ApiManagerService,
    private storageService: StorageService,
    private appService: AppService,
    private authService: AuthService,
    private helper: HelperService
  ) { }

  getAccountList() {
    return this.apiManager.getRequest(RestEnds.accounts, null);
  }

  switchAccount(guid: string) {
    return this.apiManager.putRequestQueryParam(RestEnds.switchAccount, `accountGUID=${guid}`);
  }

  getAndSetAccounts() {
    this.getAccountList().subscribe(
      (r: any) => {
        const accounts = r.data.accounts;
        this.appService.getUserAppStatus.subscribe(
          (s) => {
            if (s) {
              let initKyc: any = this.storageService.getItem(Storage.INITIAL_KYC_STATUS);
              if (initKyc !== '') {
                initKyc = (initKyc === 'true');
                if (s.isKYCVerified != initKyc) {
                  // this.helper.info('Enjoy your new features..!!!',"KYC Success");
                  // this.helper.info('Please login again in few minutes and get access to this feature.');
                  // this.authService.logout();
                }
              }
            }
          }
        );
        this.setAccounts(accounts);
        const isSelected = this.storageService.getItem(Storage.IS_ACCOUNT_SELECTED);
        if (isSelected === 'true') {
          const id = this.storageService.getItem(Storage.ACCOUNT_ID);
          if (id) {
            this.setSelAccount(id);
          }
        }
      },
      (e) => {

      }
    );
  }

  setAccounts(accounts: any) {
    this.accountData.accList = accounts;
    this.accountData.isAccSelected = false;
    this.accountData.selAcc = '';
    this.AccountEvents.next(this.accountData);
  }

  setSelAccount(account: string) {
    this.accountData.selAcc = account;
    this.accountData.isAccSelected = true;
    this.AccountEvents.next(this.accountData);
  }

  emptyAccounts() {
    this.accountData.accList = [];
    this.accountData.isAccSelected = false;
    this.accountData.selAcc = '';
    this.AccountEvents.next(this.accountData);
  }

  getManagerList(GUID) {
    const data = "accountGUID=" + GUID;
    return this.apiManager.getRequestQueryParam(RestEnds.managers, data);
  }

  getViewerList(GUID) {
    const data = "accountGUID=" + GUID;
    return this.apiManager.getRequestQueryParam(RestEnds.getViewersList, data);
  }

  deleteViewer(data) {
    return this.apiManager.deleteRequest(
      `${RestEnds.deleteViewers}?accountMemberGUID=${data}`
    );
  }

  searchViewer(email) {
    const data = 'email=' + email;
    return this.apiManager.getRequestQueryParam(RestEnds.searchViewer, data
    );
  }

  addViewer(email) {
    const data = 'emailID=' + email;
    return this.apiManager.postRequestQueryParam(RestEnds.addViewer, data
    );
  }

  getBankList() {
    return this.apiManager.getRequest(RestEnds.bank, null);
  }

  changePassword(data) {
    return this.apiManager.postRequest(RestEnds.changePassword, data);
  }

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

  getUserProfileDetails() {
    return this.apiManager.getRequest(RestEnds.userProfile, null);
  }

  postUserProfile(data) {
    const userData = 'mobileNo=' + data.Mobile + '&countryCode=' + data.countryCode + '&address=' + data.Address;
    return this.apiManager.putRequestQueryParam(RestEnds.userProfile,userData);
  }

  changeEmail(data: any) {
    return this.apiManager.postRequest(RestEnds.updateEmail, data);
  }

  updateChangeEmail(data: any) {
    return this.apiManager.putRequest(RestEnds.updateEmail, data)
  }

  cancelChangeEmail(data: any) {
    return this.apiManager.putRequest(RestEnds.cancelUpdateEmail, data)
  }

  getUpdateEmail() {
    return this.apiManager.getRequest(RestEnds.updateEmail, null)
  }

  verifyNewEmail(data: any) {
    return this.apiManager.postRequest(RestEnds.verifyNewEmail, data);
  }

  getPaymentSDepositObservable(): Observable<void> {
    return this.depositPaymentSubject.asObservable();
 }

 getWithdrawAccountStatusObservable(): Observable<void> {
  return this.accountStatusSubject.asObservable();
}

}

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(
    private apiManager: ApiManagerService
  ) { }

  getArticleBanner(stateId: string) {
    return this.apiManager.getRequestQueryParam(RestEnds.articleBanner, 'stateGUID='+stateId);
  }

  getArticleRecommended(data: any) {
    return this.apiManager.postRequest(RestEnds.articleRecommended, data);
  }

  getArticleTopStories(data: any) {
    return this.apiManager.postRequest(RestEnds.articleTopStories, data);
  }

  getInspiredFromSavedList(data: any) {
    return this.apiManager.postRequest(RestEnds.articleInspiredSaved, data);
  }

  getPopularRightNow(data: any) {
    return this.apiManager.postRequest(RestEnds.articlePopular, data);
  }

  getYouMightLike(data: any) {
    return this.apiManager.postRequest(RestEnds.articleYouMightLike, data);
  }

  getArticleDetail(id: string) {
    return this.apiManager.getRequestQueryParam(RestEnds.getArticle, 'articleGUID=' + id);
  }

  getArticleContent(url: string) {


    return this.apiManager.getCustomBlobRequest(url, { headers: { 'X-Skip-Interceptor': '' }, responseType: 'text' });
  }

}

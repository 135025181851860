<!-- <div class="container-fluid ice-blue mb-3 mt-5">
    <div class="row justify-content-center align-items-center m-0 p-0">
        <div class="col-lg-5 col-xl-5 col-md-6 col-sm-12 text-center align-middle">
            <div class="row m-0 p-0">
                <div class="col-12 m-0 p-0">
                    <p class="confirm-name cornflower-blue">Hi, {{name}}</p>
                </div>
            </div>
            <div class="row m-0 p-0">
                <div class="col-12 m-0 p-0 text-left">
                    <p class="term-details cool-grey">
                        Proceed to activate your email verification.
                    </p>
                </div>
            </div>
            <div class="row m-0 p-0 justify-content-center mt-4">
                <div class="col-md-12   m-0  p-0 text-center align-middle">
                    <button class="btn btn-primary btn-proceed w-100 pb-2" (click)="verifyEmail()">
                        Proceed
                    </button>
                </div>
                <div class="col-12">
                    <label class="f16 generic-palette-b-w-gray secondary-semi-bold">
                        Already verified? <a class="water-blue" href="signin">Click here</a>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div> -->

Email verification is in progress...
<div mat-dialog-title class="mb-0 purple-brown primary-bold">
    Installments

    <img src="/assets/images/dialog-close-sm.svg" (click)="closeDialog()" title="Close dialog" alt="close_dialog" class="float-end c-pointer">
</div>
<mat-dialog-content class="mat-typography">

    <table class="table primary desktop-table">
        <thead>
            <tr>
                <th>Txn ID</th>
                <th>Due Date</th>
                <th>Date Received</th>
                <th>Amount Due</th>
                <th>Remaining Amount</th>
                <th>Overdue</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let r of records;let i = index">
                <td>{{r.transactionID}}</td>
                <td>{{r.dueDate| date :'dd MMM yyyy'}}</td>
                <td class="text-center">
                    <span *ngIf="r.paymentReceivedDate">{{r.paymentReceivedDate| date :'dd MMM yyyy'}} </span>
                    <span *ngIf="!r.paymentReceivedDate">-</span>
                </td>
                <td class="text-end">${{r.amountDue| number : '0.2-2'}}</td>
                <td class="text-end">${{r.balanceAmount| number : '0.2-2'}}</td>
                <td class="late-fee text-end">
                    <span *ngIf="r.overDue<=0">-</span>
                    <span *ngIf="r.overDue>0">${{r.overDue| number : '0.2-2'}}</span>
                </td>
                <td class="status" *ngIf="r.status!==null">{{r.status}}</td>
                <td *ngIf="r.status==null">
                    <!-- <button class="btn view-more w-100" (click)="payInstalment(r.ref)">Pay Now</button> -->
                    -
                </td>
            </tr>
        </tbody>
    </table>

    <div class="mobile-table-container">
        <div *ngFor="let r of records" class="bg-waav-bright-light mb-4">
            <table class="table mobile">
                <tbody>
                    <tr>
                        <td>Txn ID</td>
                        <td>{{r.transactionID}}</td>
                    </tr>
                    <tr>
                        <td>Due Date</td>
                        <td>{{r.dueDate| date :'dd MMM yyyy'}}</td>
                    </tr>
                    <tr>
                        <td>Date Received</td>
                        <td>{{r.paymentReceivedDate| date :'dd MMM yyyy'}} </td>
                    </tr>
                    <tr>
                        <td>Amount Due</td>
                        <td>${{r.amountDue| number : '0.2-2'}}</td>
                    </tr>
                    <tr>
                        <td>Remaining Amount</td>
                        <td>${{r.balanceAmount| number : '0.2-2'}}</td>
                    </tr>
                    <tr>
                        <td>Overdue</td>
                        <td class="late-fee" *ngIf="r.overDue>0">${{r.overDue| number : '0.2-2'}}</td>
                        <td class="late-fee" *ngIf="r.overDue<=0">-</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td class="status" *ngIf="r.status!==null">{{r.status}}</td>
                        <td *ngIf="r.status==null">
                            <!-- <button class="btn view-more w-100" (click)="payInstalment(r.ref)">Pay Now</button> -->
                            -
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions class="bg-waav-bright-main" *ngIf="status==false">
    <!-- <button class="btn btn-primary" (click)="payInstalment()" *ngIf="!status">Pay Installment</button> -->
    <div class="d-flex flex-row align-items-center justify-content-center col-12 m-auto flex-wrap px-2">
        <p class="primary-regular purple-brown f12 m-0 col-lg-5 col-md-12 col-12 text-center">No. of installment to pay off </p>
        <div class="form-group px-2 col-lg-3 col-md-4 col-sm-5">
            <select type="text" class="form-control"
                [(ngModel)]="dueCount">
                <option value="0">Choose</option>
                <option *ngFor="let in of unPaidCounter(unPaidCount);let i = index" [value]="i+1">{{i+1}}</option>
            </select>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6">
            <button class="btn btn-sm btn-primary w-100 c-pointer" (click)="payInstalment()"
                [disabled]="dueCount<=0">Pay Now</button>
        </div>
    </div>

</mat-dialog-actions>